/* tslint:disable */
/* eslint-disable */
/**
 * Yukon SO Module
 * [DEV/QA]
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddBarcodeDevicePayload
 */
export interface AddBarcodeDevicePayload {
    /**
     * 
     * @type {string}
     * @memberof AddBarcodeDevicePayload
     */
    'barcode'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddBarcodeDevicePayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface AddBatteryPayload
 */
export interface AddBatteryPayload {
    /**
     * 
     * @type {string}
     * @memberof AddBatteryPayload
     */
    'serial'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddBatteryPayload
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddBatteryPayload
     */
    'proiz'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddBatteryPayload
     */
    'dataProiz'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddBatteryPayload
     */
    'garantia'?: boolean;
}
/**
 * 
 * @export
 * @interface AddInvoiceDevicesPayload
 */
export interface AddInvoiceDevicesPayload {
    /**
     * Номер коробки
     * @type {number}
     * @memberof AddInvoiceDevicesPayload
     */
    'boxNumber'?: number | null;
    /**
     * Приборы для ручного добавления
     * @type {Array<InvoiceAddDeviceDto>}
     * @memberof AddInvoiceDevicesPayload
     */
    'devices'?: Array<InvoiceAddDeviceDto>;
}
/**
 * 
 * @export
 * @interface AddRepairAreaSparePartsPayload
 */
export interface AddRepairAreaSparePartsPayload {
    /**
     * Участок ремонта
     * @type {string}
     * @memberof AddRepairAreaSparePartsPayload
     */
    'repairArea'?: string;
    /**
     * Дата выдачи
     * @type {string}
     * @memberof AddRepairAreaSparePartsPayload
     */
    'date'?: string;
    /**
     * Комплектация
     * @type {Array<AddSparePartDto>}
     * @memberof AddRepairAreaSparePartsPayload
     */
    'spareParts'?: Array<AddSparePartDto>;
}
/**
 * 
 * @export
 * @interface AddRmaDevicesPayload
 */
export interface AddRmaDevicesPayload {
    /**
     * Приборы для ручного добавления
     * @type {Array<RmaAddDeviceDto>}
     * @memberof AddRmaDevicesPayload
     */
    'devices'?: Array<RmaAddDeviceDto>;
}
/**
 * 
 * @export
 * @interface AddRmaRequestDevicesPayload
 */
export interface AddRmaRequestDevicesPayload {
    /**
     * Приборы для ручного добавления
     * @type {Array<RmaRequestAddDeviceDto>}
     * @memberof AddRmaRequestDevicesPayload
     */
    'devices'?: Array<RmaRequestAddDeviceDto>;
}
/**
 * 
 * @export
 * @interface AddSparePartDto
 */
export interface AddSparePartDto {
    /**
     * 
     * @type {string}
     * @memberof AddSparePartDto
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddSparePartDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface AddedCEAddDto
 */
export interface AddedCEAddDto {
    /**
     * 
     * @type {string}
     * @memberof AddedCEAddDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddedCEAddDto
     */
    'seKParent'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddedCEAddDto
     */
    'kol'?: number;
}
/**
 * 
 * @export
 * @interface AddedCEBrowseDto
 */
export interface AddedCEBrowseDto {
    /**
     * 
     * @type {string}
     * @memberof AddedCEBrowseDto
     */
    'se'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddedCEBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddedCEBrowseDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddedCEBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddedCEBrowseDto
     */
    'isContains'?: boolean;
}
/**
 * 
 * @export
 * @interface AddedCEDetailsBrowseDto
 */
export interface AddedCEDetailsBrowseDto {
    /**
     * 
     * @type {string}
     * @memberof AddedCEDetailsBrowseDto
     */
    'se'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddedCEDetailsBrowseDto
     */
    'seParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddedCEDetailsBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddedCEDetailsBrowseDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddedCEDetailsBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddedCEDetailsBrowseDto
     */
    'kol'?: number;
}
/**
 * 
 * @export
 * @interface AddedCEPagingResult
 */
export interface AddedCEPagingResult {
    /**
     * 
     * @type {Array<AddedCEBrowseDto>}
     * @memberof AddedCEPagingResult
     */
    'data'?: Array<AddedCEBrowseDto>;
    /**
     * 
     * @type {number}
     * @memberof AddedCEPagingResult
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddedCEPagingResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddedCEPagingResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddedCEPagingResult
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface AddedPartsReportExportPayload
 */
export interface AddedPartsReportExportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof AddedPartsReportExportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof AddedPartsReportExportPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof AddedPartsReportExportPayload
     */
    'firmId'?: number;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof AddedPartsReportExportPayload
     */
    'proizvId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof AddedPartsReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface AgreeRepairPlanPayload
 */
export interface AgreeRepairPlanPayload {
    /**
     * Ключ компании пользователя
     * @type {number}
     * @memberof AgreeRepairPlanPayload
     */
    'firmId'?: number;
    /**
     * Номер участка
     * @type {string}
     * @memberof AgreeRepairPlanPayload
     */
    'areaRepair'?: string;
    /**
     * Выбранные приборы
     * @type {Array<RepairPlanDevicePrimaryKeyDto>}
     * @memberof AgreeRepairPlanPayload
     */
    'devices'?: Array<RepairPlanDevicePrimaryKeyDto>;
}
/**
 * 
 * @export
 * @interface AssignControlPayload
 */
export interface AssignControlPayload {
    /**
     * 
     * @type {boolean}
     * @memberof AssignControlPayload
     */
    'primaryConrol'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssignControlPayload
     */
    'secondaryConrol'?: boolean;
}
/**
 * 
 * @export
 * @interface AssignInvoiceLicensesResponse
 */
export interface AssignInvoiceLicensesResponse {
    /**
     * 
     * @type {AssignLicensesResult}
     * @memberof AssignInvoiceLicensesResponse
     */
    'result'?: AssignLicensesResult;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AssignLicensesResult = {
    NoLicensedDevices: 'NoLicensedDevices',
    Success: 'Success',
    NotEnoughLicensesImport: 'NotEnoughLicensesImport',
    NotEnoughLicensesTempImport: 'NotEnoughLicensesTempImport',
    ExchangeRateNotFound: 'ExchangeRateNotFound',
    NotEnoughLicenses: 'NotEnoughLicenses'
} as const;

export type AssignLicensesResult = typeof AssignLicensesResult[keyof typeof AssignLicensesResult];


/**
 * 
 * @export
 * @interface AuthUserDto
 */
export interface AuthUserDto {
    /**
     * 
     * @type {string}
     * @memberof AuthUserDto
     */
    'aadUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserDto
     */
    'area'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserDto
     */
    'workEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserDto
     */
    'workNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserDto
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserDto
     */
    'company'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthUserDto
     */
    'permissions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AuthenticationResult
 */
export interface AuthenticationResult {
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationResult
     */
    'isAuthenticated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResult
     */
    'error'?: string | null;
    /**
     * 
     * @type {AuthUserDto}
     * @memberof AuthenticationResult
     */
    'user'?: AuthUserDto;
}
/**
 * 
 * @export
 * @interface AverageRepairPeriodBrowseItemDto
 */
export interface AverageRepairPeriodBrowseItemDto {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'firmId'?: number;
    /**
     * Год
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'year'?: number;
    /**
     * Месяц
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'month'?: number;
    /**
     * Среднее время ремонта целевого показателя
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'targetAverageRepairTime'?: number;
    /**
     * Количество приборов целевого показателя
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'targetDevicesTotal'?: number;
    /**
     * Количество приборов целевого показателя со сроком ремонта 30 и более дней
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'targetDevicesGreat30days'?: number;
    /**
     * Количество приборов целевого показателя со сроком ремонта 20-29 дней
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'targetDevicesGreat20days'?: number;
    /**
     * Количество приборов целевого показателя со сроком ремонта 14-19 дней
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'targetDevicesGreat14days'?: number;
    /**
     * Количество приборов целевого показателя со сроком ремонта 10-13 дней
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'targetDevicesGreat10days'?: number;
    /**
     * Общий срок ремонта дней приборов целевого показателя
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'targetDevicesRepairPeriod'?: number;
    /**
     * Среднее время ремонта с корректировкой
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'correctedAverageRepairTime'?: number;
    /**
     * Общее количество приборов с корректировкой
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'correctedDevicesTotal'?: number;
    /**
     * Количество приборов с корректировкой со сроком ремонта 30 дней и более
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'correctedDevicesGreat30days'?: number;
    /**
     * Общее время ремонта приборов с корректировкой
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'correctedDevicesRepairPeriod'?: number;
    /**
     * Среднее время ремонта без корректировки
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'baseAverageRepairTime'?: number;
    /**
     * Общее количетсво приборов без корректировки
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'baseDevicesTotal'?: number;
    /**
     * Количетсво приборов без корректировки сщ сроком ремонта 30 дней и более
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'baseDevicesGreat30days'?: number;
    /**
     * Общее время ремонта приборов без корректировки
     * @type {number}
     * @memberof AverageRepairPeriodBrowseItemDto
     */
    'baseDevicesRepairPeriod'?: number;
}
/**
 * 
 * @export
 * @interface AverageRepairPeriodReportImportResponse
 */
export interface AverageRepairPeriodReportImportResponse {
    /**
     * Кол-во успешно добавленных приборов
     * @type {Array<AverageRepairPeriodReportItemDto>}
     * @memberof AverageRepairPeriodReportImportResponse
     */
    'importedDevices'?: Array<AverageRepairPeriodReportItemDto>;
    /**
     * Ошибки найденные в документе
     * @type {Array<ExcelRowError>}
     * @memberof AverageRepairPeriodReportImportResponse
     */
    'errors'?: Array<ExcelRowError>;
}
/**
 * 
 * @export
 * @interface AverageRepairPeriodReportItemDto
 */
export interface AverageRepairPeriodReportItemDto {
    /**
     * Код Прибора
     * @type {number}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'deviceId'?: number;
    /**
     * Код фирмы прибора
     * @type {number}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'deviceFirmId'?: number;
    /**
     * Дострибьютор
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'counterparty'?: string | null;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'sku'?: string | null;
    /**
     * Наименование прибора En
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'nameEn'?: string;
    /**
     * Наименование прибора Ru
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'nameRu'?: string;
    /**
     * Гарантия
     * @type {boolean}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'warranty'?: boolean;
    /**
     * Старый серийный номер
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'serial'?: string;
    /**
     * Новый серийный номер
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'newSerial'?: string;
    /**
     * Дата поступления на ремонт от Дистрибьютора
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'incomingDate'?: string | null;
    /**
     * Дата передачи на склад СО с последнего участка ремонта или внешнего участка
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'warehouseDate'?: string | null;
    /**
     * Дата отправки Дистрибьютору после ремонта
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'outgoingDate'?: string | null;
    /**
     * Срок ремонта с корректировкой (дней)
     * @type {number}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'correctedRepairPeriod'?: number | null;
    /**
     * Срок ремонта (дней)
     * @type {number}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'repairPeriod'?: number | null;
    /**
     * Целевой показатель срока ремонта (дней)
     * @type {number}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'targetRepairPeriod'?: number | null;
    /**
     * Партия - количество приборов в инвойсе поступления
     * @type {number}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'party'?: number | null;
    /**
     * Примечания
     * @type {string}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'notes'?: string | null;
    /**
     * Месяц
     * @type {number}
     * @memberof AverageRepairPeriodReportItemDto
     */
    'month'?: number;
}
/**
 * 
 * @export
 * @interface AverageRepairPeriodReportSavePayload
 */
export interface AverageRepairPeriodReportSavePayload {
    /**
     * Ключ фирмы
     * @type {number}
     * @memberof AverageRepairPeriodReportSavePayload
     */
    'firmId'?: number;
    /**
     * Год
     * @type {number}
     * @memberof AverageRepairPeriodReportSavePayload
     */
    'year'?: number;
    /**
     * Месяц
     * @type {number}
     * @memberof AverageRepairPeriodReportSavePayload
     */
    'month'?: number;
    /**
     * Приборы
     * @type {Array<AverageRepairPeriodReportItemDto>}
     * @memberof AverageRepairPeriodReportSavePayload
     */
    'devices'?: Array<AverageRepairPeriodReportItemDto>;
}
/**
 * 
 * @export
 * @interface AverageRepairTimePeriodicalReporExportPayload
 */
export interface AverageRepairTimePeriodicalReporExportPayload {
    /**
     * Ид предприятия
     * @type {number}
     * @memberof AverageRepairTimePeriodicalReporExportPayload
     */
    'firmId'?: number;
    /**
     * Начало периода
     * @type {string}
     * @memberof AverageRepairTimePeriodicalReporExportPayload
     */
    'periodStart'?: string;
    /**
     * Окончание периода
     * @type {string}
     * @memberof AverageRepairTimePeriodicalReporExportPayload
     */
    'periodEnd'?: string;
    /**
     * Язык
     * @type {number}
     * @memberof AverageRepairTimePeriodicalReporExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface BlameBrowseDto
 */
export interface BlameBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof BlameBrowseDto
     */
    'blameId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlameBrowseDto
     */
    'nameR'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlameBrowseDto
     */
    'nameE'?: string;
}
/**
 * 
 * @export
 * @interface BrowseEmailDto
 */
export interface BrowseEmailDto {
    /**
     * Адресат
     * @type {string}
     * @memberof BrowseEmailDto
     */
    'mailTo'?: string;
    /**
     * Заголовок письма
     * @type {string}
     * @memberof BrowseEmailDto
     */
    'mailHeader'?: string;
    /**
     * Тело письма
     * @type {string}
     * @memberof BrowseEmailDto
     */
    'mailBody'?: string;
    /**
     * Дата отправки
     * @type {string}
     * @memberof BrowseEmailDto
     */
    'sentAt'?: string;
    /**
     * Приложение которое отправило письмо
     * @type {string}
     * @memberof BrowseEmailDto
     */
    'applicationName'?: string;
}
/**
 * 
 * @export
 * @interface BrowseEmailsPagingResult
 */
export interface BrowseEmailsPagingResult {
    /**
     * 
     * @type {Array<BrowseEmailDto>}
     * @memberof BrowseEmailsPagingResult
     */
    'data'?: Array<BrowseEmailDto>;
    /**
     * 
     * @type {number}
     * @memberof BrowseEmailsPagingResult
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseEmailsPagingResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseEmailsPagingResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrowseEmailsPagingResult
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface BrowseExtendedWarrantyPagingResult
 */
export interface BrowseExtendedWarrantyPagingResult {
    /**
     * 
     * @type {Array<ExtendedWarrantyBrowseDto>}
     * @memberof BrowseExtendedWarrantyPagingResult
     */
    'data'?: Array<ExtendedWarrantyBrowseDto>;
    /**
     * 
     * @type {number}
     * @memberof BrowseExtendedWarrantyPagingResult
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseExtendedWarrantyPagingResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseExtendedWarrantyPagingResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrowseExtendedWarrantyPagingResult
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface BrowseFaqItemDto
 */
export interface BrowseFaqItemDto {
    /**
     * Ключ
     * @type {number}
     * @memberof BrowseFaqItemDto
     */
    'id'?: number;
    /**
     * Вопрос
     * @type {string}
     * @memberof BrowseFaqItemDto
     */
    'theme'?: string;
    /**
     * Ответ
     * @type {string}
     * @memberof BrowseFaqItemDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface BrowseMaintainabilityPagingResult
 */
export interface BrowseMaintainabilityPagingResult {
    /**
     * 
     * @type {Array<MaintainabilityDeviceDto>}
     * @memberof BrowseMaintainabilityPagingResult
     */
    'data'?: Array<MaintainabilityDeviceDto>;
    /**
     * 
     * @type {number}
     * @memberof BrowseMaintainabilityPagingResult
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseMaintainabilityPagingResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseMaintainabilityPagingResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrowseMaintainabilityPagingResult
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface BrowseRepairDevicesPagingResult
 */
export interface BrowseRepairDevicesPagingResult {
    /**
     * 
     * @type {Array<RepairDeviceBrowseDto>}
     * @memberof BrowseRepairDevicesPagingResult
     */
    'data'?: Array<RepairDeviceBrowseDto>;
    /**
     * 
     * @type {number}
     * @memberof BrowseRepairDevicesPagingResult
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseRepairDevicesPagingResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseRepairDevicesPagingResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrowseRepairDevicesPagingResult
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface BrowseRmaAuditPagingResult
 */
export interface BrowseRmaAuditPagingResult {
    /**
     * 
     * @type {Array<RmaAuditBrowseDto>}
     * @memberof BrowseRmaAuditPagingResult
     */
    'data'?: Array<RmaAuditBrowseDto>;
    /**
     * 
     * @type {number}
     * @memberof BrowseRmaAuditPagingResult
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseRmaAuditPagingResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseRmaAuditPagingResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrowseRmaAuditPagingResult
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface BrowseSerialsPagingResult
 */
export interface BrowseSerialsPagingResult {
    /**
     * 
     * @type {Array<SerialBrowseDto>}
     * @memberof BrowseSerialsPagingResult
     */
    'data'?: Array<SerialBrowseDto>;
    /**
     * 
     * @type {number}
     * @memberof BrowseSerialsPagingResult
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseSerialsPagingResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowseSerialsPagingResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrowseSerialsPagingResult
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface BrowseUsersForBadgePayload
 */
export interface BrowseUsersForBadgePayload {
    /**
     * 
     * @type {number}
     * @memberof BrowseUsersForBadgePayload
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface BrowseUsersPayload
 */
export interface BrowseUsersPayload {
    /**
     * 
     * @type {number}
     * @memberof BrowseUsersPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrowseUsersPayload
     */
    'allFirms'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrowseUsersPayload
     */
    'includeDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CancelRmaPayload
 */
export interface CancelRmaPayload {
    /**
     * 
     * @type {Array<number>}
     * @memberof CancelRmaPayload
     */
    'deviceIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CancelRmaPayload
     */
    'cancelAll'?: boolean;
}
/**
 * 
 * @export
 * @interface CategoryAReportDto
 */
export interface CategoryAReportDto {
    /**
     * 
     * @type {Array<CategoryAReportItemDto>}
     * @memberof CategoryAReportDto
     */
    'devices'?: Array<CategoryAReportItemDto>;
}
/**
 * 
 * @export
 * @interface CategoryAReportExportPayload
 */
export interface CategoryAReportExportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof CategoryAReportExportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof CategoryAReportExportPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof CategoryAReportExportPayload
     */
    'firmId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof CategoryAReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface CategoryAReportItemDto
 */
export interface CategoryAReportItemDto {
    /**
     * Страна
     * @type {string}
     * @memberof CategoryAReportItemDto
     */
    'country'?: string;
    /**
     * имя контрагента
     * @type {string}
     * @memberof CategoryAReportItemDto
     */
    'counterpartyName'?: string;
    /**
     * Sku прибора
     * @type {string}
     * @memberof CategoryAReportItemDto
     */
    'sku'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof CategoryAReportItemDto
     */
    'seK'?: string;
    /**
     * Наимнование прибора
     * @type {string}
     * @memberof CategoryAReportItemDto
     */
    'nameSe'?: string;
    /**
     * название лицензии
     * @type {string}
     * @memberof CategoryAReportItemDto
     */
    'licenseName'?: string;
    /**
     * количество
     * @type {number}
     * @memberof CategoryAReportItemDto
     */
    'qty'?: number;
}
/**
 * 
 * @export
 * @interface ChangeBatterySerialPayload
 */
export interface ChangeBatterySerialPayload {
    /**
     * 
     * @type {string}
     * @memberof ChangeBatterySerialPayload
     */
    'serial'?: string;
}
/**
 * 
 * @export
 * @interface ChangeRepairPlanAreaPayload
 */
export interface ChangeRepairPlanAreaPayload {
    /**
     * Ключ компании пользователя
     * @type {number}
     * @memberof ChangeRepairPlanAreaPayload
     */
    'firmId'?: number;
    /**
     * Номер старого участка
     * @type {string}
     * @memberof ChangeRepairPlanAreaPayload
     */
    'areaRepair'?: string;
    /**
     * Номер нового участка
     * @type {string}
     * @memberof ChangeRepairPlanAreaPayload
     */
    'newAreaRepair'?: string;
    /**
     * Выбранные приборы
     * @type {Array<RepairPlanDevicePrimaryKeyDto>}
     * @memberof ChangeRepairPlanAreaPayload
     */
    'devices'?: Array<RepairPlanDevicePrimaryKeyDto>;
}
/**
 * 
 * @export
 * @interface ChangeRepairPlanDatePlanRepairPayload
 */
export interface ChangeRepairPlanDatePlanRepairPayload {
    /**
     * Ключ компании пользователя
     * @type {number}
     * @memberof ChangeRepairPlanDatePlanRepairPayload
     */
    'firmId'?: number;
    /**
     * Номер  участка
     * @type {string}
     * @memberof ChangeRepairPlanDatePlanRepairPayload
     */
    'areaRepair'?: string;
    /**
     * Новая дата ремонта
     * @type {string}
     * @memberof ChangeRepairPlanDatePlanRepairPayload
     */
    'newDateRepair'?: string;
    /**
     * Выбранные приборы
     * @type {Array<RepairPlanDevicePrimaryKeyDto>}
     * @memberof ChangeRepairPlanDatePlanRepairPayload
     */
    'devices'?: Array<RepairPlanDevicePrimaryKeyDto>;
}
/**
 * 
 * @export
 * @interface ChangeSeCheckResponse
 */
export interface ChangeSeCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeSeCheckResponse
     */
    'canChangeSe'?: boolean;
}
/**
 * 
 * @export
 * @interface ChangeSePayload
 */
export interface ChangeSePayload {
    /**
     * 
     * @type {string}
     * @memberof ChangeSePayload
     */
    'sek'?: string;
}
/**
 * 
 * @export
 * @interface ChangeSerialCheckResponse
 */
export interface ChangeSerialCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeSerialCheckResponse
     */
    'canChangeSerial'?: boolean;
}
/**
 * 
 * @export
 * @interface ChangeSerialPayload
 */
export interface ChangeSerialPayload {
    /**
     * 
     * @type {string}
     * @memberof ChangeSerialPayload
     */
    'serial'?: string;
}
/**
 * 
 * @export
 * @interface CheckLicensesResponse
 */
export interface CheckLicensesResponse {
    /**
     * 
     * @type {AssignLicensesResult}
     * @memberof CheckLicensesResponse
     */
    'result'?: AssignLicensesResult;
}


/**
 * 
 * @export
 * @interface ClearRepairDataDeviceDto
 */
export interface ClearRepairDataDeviceDto {
    /**
     * Ид прибора
     * @type {number}
     * @memberof ClearRepairDataDeviceDto
     */
    'deviceId'?: number;
    /**
     * Ид фирмы прибора
     * @type {number}
     * @memberof ClearRepairDataDeviceDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface ClearRepairDataPayload
 */
export interface ClearRepairDataPayload {
    /**
     * 
     * @type {number}
     * @memberof ClearRepairDataPayload
     */
    'firmId'?: number;
    /**
     * Приборы для отмены ремонта
     * @type {Array<ClearRepairDataDeviceDto>}
     * @memberof ClearRepairDataPayload
     */
    'devices'?: Array<ClearRepairDataDeviceDto>;
}
/**
 * 
 * @export
 * @interface CompleteControlPayload
 */
export interface CompleteControlPayload {
    /**
     * 
     * @type {boolean}
     * @memberof CompleteControlPayload
     */
    'primaryConrol'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompleteControlPayload
     */
    'secondaryConrol'?: boolean;
}
/**
 * 
 * @export
 * @interface ConfirmInvoiceDevicesPayload
 */
export interface ConfirmInvoiceDevicesPayload {
    /**
     * Ключи приборов в счете
     * @type {Array<number>}
     * @memberof ConfirmInvoiceDevicesPayload
     */
    'deviceIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ConfirmInvoiceDevicesResponse
 */
export interface ConfirmInvoiceDevicesResponse {
    /**
     * 
     * @type {InvoiceConfirmResult}
     * @memberof ConfirmInvoiceDevicesResponse
     */
    'result'?: InvoiceConfirmResult;
}


/**
 * 
 * @export
 * @interface ConfirmInvoiceResponse
 */
export interface ConfirmInvoiceResponse {
    /**
     * 
     * @type {InvoiceConfirmResult}
     * @memberof ConfirmInvoiceResponse
     */
    'result'?: InvoiceConfirmResult;
}


/**
 * 
 * @export
 * @interface ConfirmReceiptPayload
 */
export interface ConfirmReceiptPayload {
    /**
     * Ключ компании пользователя
     * @type {number}
     * @memberof ConfirmReceiptPayload
     */
    'firmId'?: number;
    /**
     * Дата прихода, формат yyyy-MM-dd
     * @type {string}
     * @memberof ConfirmReceiptPayload
     */
    'datePrihod'?: string;
    /**
     * Выбранные приборы
     * @type {Array<MaintenanceConfirmReceiptDeviceDto>}
     * @memberof ConfirmReceiptPayload
     */
    'devices'?: Array<MaintenanceConfirmReceiptDeviceDto>;
}
/**
 * 
 * @export
 * @interface ConfirmRmaPayload
 */
export interface ConfirmRmaPayload {
    /**
     * 
     * @type {Array<number>}
     * @memberof ConfirmRmaPayload
     */
    'deviceIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ConfirmRmaPayload
     */
    'confirmAll'?: boolean;
}
/**
 * 
 * @export
 * @interface CorrectBatterySerialPayload
 */
export interface CorrectBatterySerialPayload {
    /**
     * 
     * @type {string}
     * @memberof CorrectBatterySerialPayload
     */
    'serial'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectBatterySerialPayload
     */
    'sek'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CorrectBatterySerialPayload
     */
    'dataProiz'?: string;
    /**
     * 
     * @type {number}
     * @memberof CorrectBatterySerialPayload
     */
    'proiz'?: number | null;
}
/**
 * 
 * @export
 * @interface CorrectSerialPayload
 */
export interface CorrectSerialPayload {
    /**
     * 
     * @type {string}
     * @memberof CorrectSerialPayload
     */
    'serial'?: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectSerialPayload
     */
    'sek'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CorrectSerialPayload
     */
    'dataProiz'?: string;
    /**
     * 
     * @type {number}
     * @memberof CorrectSerialPayload
     */
    'proiz'?: number | null;
}
/**
 * 
 * @export
 * @interface CorrectSerialSearchResponse
 */
export interface CorrectSerialSearchResponse {
    /**
     * 
     * @type {DeviceBySerialDto}
     * @memberof CorrectSerialSearchResponse
     */
    'deviceBySerial'?: DeviceBySerialDto;
}
/**
 * 
 * @export
 * @interface CounterpartyExternalServiceCenterDto
 */
export interface CounterpartyExternalServiceCenterDto {
    /**
     * Ключ контрагента с внешним сервисным центром
     * @type {number}
     * @memberof CounterpartyExternalServiceCenterDto
     */
    'counterpartyId'?: number;
    /**
     * Краткое наименование
     * @type {string}
     * @memberof CounterpartyExternalServiceCenterDto
     */
    'shortName'?: string;
    /**
     * Полное наименование
     * @type {string}
     * @memberof CounterpartyExternalServiceCenterDto
     */
    'fullName'?: string;
    /**
     * Основная валюта контрагента
     * @type {number}
     * @memberof CounterpartyExternalServiceCenterDto
     */
    'baseCurrency'?: number;
    /**
     * Номер участка ремонта
     * @type {string}
     * @memberof CounterpartyExternalServiceCenterDto
     */
    'repairArea'?: string;
}
/**
 * 
 * @export
 * @interface CounterpartyFirmDto
 */
export interface CounterpartyFirmDto {
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof CounterpartyFirmDto
     */
    'counterpartyId'?: number;
    /**
     * Краткое наименование
     * @type {string}
     * @memberof CounterpartyFirmDto
     */
    'shortName'?: string;
    /**
     * Полное наименование
     * @type {string}
     * @memberof CounterpartyFirmDto
     */
    'fullName'?: string;
    /**
     * Буквенное обозначение
     * @type {string}
     * @memberof CounterpartyFirmDto
     */
    'letter'?: string;
}
/**
 * 
 * @export
 * @interface CounterpartySuggestionDto
 */
export interface CounterpartySuggestionDto {
    /**
     * 
     * @type {number}
     * @memberof CounterpartySuggestionDto
     */
    'counterpartyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterpartySuggestionDto
     */
    'shortName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterpartySuggestionDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CounterpartySuggestionDto
     */
    'isInternal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterpartySuggestionDto
     */
    'isEmployee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CounterpartySuggestionDto
     */
    'idVal1'?: number | null;
}
/**
 * 
 * @export
 * @interface CounterpartySuggestionsResponse
 */
export interface CounterpartySuggestionsResponse {
    /**
     * 
     * @type {Array<CounterpartySuggestionDto>}
     * @memberof CounterpartySuggestionsResponse
     */
    'data'?: Array<CounterpartySuggestionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CounterpartySuggestionsResponse
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateDefectPayload
 */
export interface CreateDefectPayload {
    /**
     * Номер дефекта в формате dd.dd.dd.dd.dd
     * @type {string}
     * @memberof CreateDefectPayload
     */
    'defectNumber': string;
    /**
     * Наименование дефекта (RU) (Max 100)
     * @type {string}
     * @memberof CreateDefectPayload
     */
    'nameR': string;
    /**
     * Наименование дефекта (EN) (Max 100)
     * @type {string}
     * @memberof CreateDefectPayload
     */
    'nameE': string;
    /**
     * Ключ вины
     * @type {number}
     * @memberof CreateDefectPayload
     */
    'blameId'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateDefectResponse
 */
export interface CreateDefectResponse {
    /**
     * Ключ дефекта
     * @type {number}
     * @memberof CreateDefectResponse
     */
    'defectId'?: number;
}
/**
 * 
 * @export
 * @interface CreateDocNotePayload
 */
export interface CreateDocNotePayload {
    /**
     * Id компании
     * @type {number}
     * @memberof CreateDocNotePayload
     */
    'firmId': number;
    /**
     * Код документа
     * @type {string}
     * @memberof CreateDocNotePayload
     */
    'documentCode'?: string;
    /**
     * Текст примечания
     * @type {string}
     * @memberof CreateDocNotePayload
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface CreateDocNoteResponse
 */
export interface CreateDocNoteResponse {
    /**
     * Ключ дефекта
     * @type {number}
     * @memberof CreateDocNoteResponse
     */
    'noteId'?: number;
}
/**
 * 
 * @export
 * @interface CreateFaqPayload
 */
export interface CreateFaqPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateFaqPayload
     */
    'theme'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqPayload
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateInnerManufacturingAreaPayload
 */
export interface CreateInnerManufacturingAreaPayload {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof CreateInnerManufacturingAreaPayload
     */
    'firmId'?: number;
    /**
     * Фамилия ремонтника
     * @type {string}
     * @memberof CreateInnerManufacturingAreaPayload
     */
    'lastName'?: string;
    /**
     * Имя ремонтника
     * @type {string}
     * @memberof CreateInnerManufacturingAreaPayload
     */
    'firstName'?: string;
}
/**
 * 
 * @export
 * @interface CreateInvoicePayload
 */
export interface CreateInvoicePayload {
    /**
     * Ключ компании
     * @type {number}
     * @memberof CreateInvoicePayload
     */
    'firmId'?: number;
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof CreateInvoicePayload
     */
    'counterpartyId'?: number;
    /**
     * Дата документа, формат yyyy-MM-dd
     * @type {string}
     * @memberof CreateInvoicePayload
     */
    'docDate'?: string;
    /**
     * Дата получения, формат yyyy-MM-dd
     * @type {string}
     * @memberof CreateInvoicePayload
     */
    'receiveDate'?: string | null;
    /**
     * Возврат от покупателя
     * @type {boolean}
     * @memberof CreateInvoicePayload
     */
    'return'?: boolean;
    /**
     * Валюта контрагента
     * @type {number}
     * @memberof CreateInvoicePayload
     */
    'docVal'?: number;
}
/**
 * 
 * @export
 * @interface CreateInvoiceResponse
 */
export interface CreateInvoiceResponse {
    /**
     * Ключ инвойса
     * @type {number}
     * @memberof CreateInvoiceResponse
     */
    'invoiceId'?: number;
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof CreateInvoiceResponse
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface CreateOutgoingInvoicePayload
 */
export interface CreateOutgoingInvoicePayload {
    /**
     * Ид инвойса
     * @type {number}
     * @memberof CreateOutgoingInvoicePayload
     */
    'invoiceId'?: number | null;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof CreateOutgoingInvoicePayload
     */
    'firmId'?: number;
    /**
     * Номер коробки
     * @type {number}
     * @memberof CreateOutgoingInvoicePayload
     */
    'boxNumber'?: number | null;
    /**
     * Добавляемые приборы
     * @type {Array<OutgoingInvoiceAddDeviceDto>}
     * @memberof CreateOutgoingInvoicePayload
     */
    'devices'?: Array<OutgoingInvoiceAddDeviceDto>;
}
/**
 * 
 * @export
 * @interface CreateOutgoingInvoiceResponse
 */
export interface CreateOutgoingInvoiceResponse {
    /**
     * Ключ инвойса
     * @type {number}
     * @memberof CreateOutgoingInvoiceResponse
     */
    'invoiceId'?: number;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof CreateOutgoingInvoiceResponse
     */
    'firmId'?: number;
    /**
     * Номер инвойса
     * @type {string}
     * @memberof CreateOutgoingInvoiceResponse
     */
    'invoiceNumber'?: string;
}
/**
 * 
 * @export
 * @interface CreateRepairGroupPayload
 */
export interface CreateRepairGroupPayload {
    /**
     * Наименование (RU) maxlength=50
     * @type {string}
     * @memberof CreateRepairGroupPayload
     */
    'nameR': string;
    /**
     * Наименование (EN) maxlength=50
     * @type {string}
     * @memberof CreateRepairGroupPayload
     */
    'nameE': string;
    /**
     * Норма-часы
     * @type {number}
     * @memberof CreateRepairGroupPayload
     */
    'normHours'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateRepairGroupResponse
 */
export interface CreateRepairGroupResponse {
    /**
     * Ключ группы
     * @type {number}
     * @memberof CreateRepairGroupResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface CreateRmaFromRmaRequestDto
 */
export interface CreateRmaFromRmaRequestDto {
    /**
     * 
     * @type {number}
     * @memberof CreateRmaFromRmaRequestDto
     */
    'docId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateRmaFromRmaRequestDto
     */
    'rmaFirmId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRmaFromRmaRequestDto
     */
    'isBuyerReturn'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateRmaFromRmaRequestResponse
 */
export interface CreateRmaFromRmaRequestResponse {
    /**
     * 
     * @type {Array<CreateRmaFromRmaRequestDto>}
     * @memberof CreateRmaFromRmaRequestResponse
     */
    'docs'?: Array<CreateRmaFromRmaRequestDto>;
}
/**
 * 
 * @export
 * @interface CreateRmaPayload
 */
export interface CreateRmaPayload {
    /**
     * Ключ компании
     * @type {number}
     * @memberof CreateRmaPayload
     */
    'rmaFirmId'?: number;
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof CreateRmaPayload
     */
    'counterpartyId'?: number;
    /**
     * Валюта контрагента
     * @type {number}
     * @memberof CreateRmaPayload
     */
    'docVal'?: number;
    /**
     * Дата документа, формат yyyy-MM-dd
     * @type {string}
     * @memberof CreateRmaPayload
     */
    'docDate'?: string;
    /**
     * Возврат от покупателя
     * @type {boolean}
     * @memberof CreateRmaPayload
     */
    'return'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateRmaRequestPayload
 */
export interface CreateRmaRequestPayload {
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof CreateRmaRequestPayload
     */
    'counterpartyId'?: number;
}
/**
 * 
 * @export
 * @interface CreateRmaRequestResponse
 */
export interface CreateRmaRequestResponse {
    /**
     * Ключ RMA заявки
     * @type {number}
     * @memberof CreateRmaRequestResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface CreateRmaResponse
 */
export interface CreateRmaResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateRmaResponse
     */
    'doc'?: number;
}
/**
 * 
 * @export
 * @interface CreateSerialPayload
 */
export interface CreateSerialPayload {
    /**
     * Серийный номер (max 10, min 10, numbers only)
     * @type {string}
     * @memberof CreateSerialPayload
     */
    'serial': string;
    /**
     * Id дистрибьютора
     * @type {number}
     * @memberof CreateSerialPayload
     */
    'counterpartyId': number;
    /**
     * Дата, формат yyyy-MM-dd
     * @type {string}
     * @memberof CreateSerialPayload
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface CreateTimeRatesPayload
 */
export interface CreateTimeRatesPayload {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof CreateTimeRatesPayload
     */
    'firmId'?: number;
    /**
     * Год
     * @type {number}
     * @memberof CreateTimeRatesPayload
     */
    'year'?: number;
    /**
     * Месяц
     * @type {number}
     * @memberof CreateTimeRatesPayload
     */
    'month'?: number;
    /**
     * Норма времени
     * @type {number}
     * @memberof CreateTimeRatesPayload
     */
    'timeRate'?: number;
}
/**
 * 
 * @export
 * @interface CreateUpgradeRmaPayload
 */
export interface CreateUpgradeRmaPayload {
    /**
     * Ключ компании
     * @type {number}
     * @memberof CreateUpgradeRmaPayload
     */
    'rmaFirmId'?: number;
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof CreateUpgradeRmaPayload
     */
    'counterpartyId'?: number;
    /**
     * Валюта контрагента
     * @type {number}
     * @memberof CreateUpgradeRmaPayload
     */
    'docVal'?: number;
    /**
     * Дата документа, формат yyyy-MM-dd
     * @type {string}
     * @memberof CreateUpgradeRmaPayload
     */
    'docDate'?: string;
    /**
     * Возврат от покупателя
     * @type {boolean}
     * @memberof CreateUpgradeRmaPayload
     */
    'return'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateUpgradeRmaResponse
 */
export interface CreateUpgradeRmaResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateUpgradeRmaResponse
     */
    'doc'?: number;
}
/**
 * 
 * @export
 * @interface CreateUserForBadgePayload
 */
export interface CreateUserForBadgePayload {
    /**
     * Табельный номер
     * @type {string}
     * @memberof CreateUserForBadgePayload
     */
    'tn'?: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof CreateUserForBadgePayload
     */
    'lastName'?: string;
    /**
     * Имя
     * @type {string}
     * @memberof CreateUserForBadgePayload
     */
    'firstName'?: string;
    /**
     * Закрепленный участок ремонта
     * @type {string}
     * @memberof CreateUserForBadgePayload
     */
    'area'?: string;
    /**
     * Принадлежность к организации
     * @type {string}
     * @memberof CreateUserForBadgePayload
     */
    'company'?: string;
    /**
     * Предпочитаемый язык Уведомлений
     * @type {string}
     * @memberof CreateUserForBadgePayload
     */
    'language'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserPayload
 */
export interface CreateUserPayload {
    /**
     * Табельный номер
     * @type {string}
     * @memberof CreateUserPayload
     */
    'tn'?: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof CreateUserPayload
     */
    'lastName'?: string;
    /**
     * Имя
     * @type {string}
     * @memberof CreateUserPayload
     */
    'firstName'?: string;
    /**
     * email
     * @type {string}
     * @memberof CreateUserPayload
     */
    'email'?: string;
    /**
     * Принадлежность к организации
     * @type {string}
     * @memberof CreateUserPayload
     */
    'company'?: string;
    /**
     * Предпочитаемый язык Уведомлений
     * @type {string}
     * @memberof CreateUserPayload
     */
    'language'?: string;
}
/**
 * 
 * @export
 * @interface CreateWorkPayload
 */
export interface CreateWorkPayload {
    /**
     * Наименование русское
     * @type {string}
     * @memberof CreateWorkPayload
     */
    'nameR'?: string;
    /**
     * Наименование английское
     * @type {string}
     * @memberof CreateWorkPayload
     */
    'nameE'?: string;
    /**
     * Наименование литовское
     * @type {string}
     * @memberof CreateWorkPayload
     */
    'nameL'?: string;
    /**
     * Примечание
     * @type {string}
     * @memberof CreateWorkPayload
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface CreateWorkResponse
 */
export interface CreateWorkResponse {
    /**
     * Ид работы
     * @type {number}
     * @memberof CreateWorkResponse
     */
    'workId'?: number;
}
/**
 * 
 * @export
 * @interface CreatedStockAreaDto
 */
export interface CreatedStockAreaDto {
    /**
     * Номер склада
     * @type {string}
     * @memberof CreatedStockAreaDto
     */
    'skl'?: string;
    /**
     * Название склада (En)
     * @type {string}
     * @memberof CreatedStockAreaDto
     */
    'nameEn'?: string;
    /**
     * Название склада (Ru)
     * @type {string}
     * @memberof CreatedStockAreaDto
     */
    'nameRu'?: string;
}
/**
 * 
 * @export
 * @interface CurrencyBrowseDto
 */
export interface CurrencyBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof CurrencyBrowseDto
     */
    'currencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyBrowseDto
     */
    'currencyName'?: string;
}
/**
 * 
 * @export
 * @interface DefectBrowseDto
 */
export interface DefectBrowseDto {
    /**
     * Ключ родительского узла
     * @type {number}
     * @memberof DefectBrowseDto
     */
    'parentId'?: number;
    /**
     * Ключи дефекта
     * @type {number}
     * @memberof DefectBrowseDto
     */
    'defectId'?: number;
    /**
     * Номер дефекта в формате dd.dd.dd.dd.dd
     * @type {string}
     * @memberof DefectBrowseDto
     */
    'defectNumber'?: string;
    /**
     * Наименование дефекта (RU)
     * @type {string}
     * @memberof DefectBrowseDto
     */
    'nameR'?: string;
    /**
     * Наименование дефекта (EN)
     * @type {string}
     * @memberof DefectBrowseDto
     */
    'nameE'?: string;
    /**
     * Примечание
     * @type {string}
     * @memberof DefectBrowseDto
     */
    'notes'?: string | null;
    /**
     * Ключ вины
     * @type {number}
     * @memberof DefectBrowseDto
     */
    'blameId'?: number | null;
    /**
     * Наименование вины (RU)
     * @type {string}
     * @memberof DefectBrowseDto
     */
    'blameNameR'?: string | null;
    /**
     * Наименование вины (EN)
     * @type {string}
     * @memberof DefectBrowseDto
     */
    'blameNameE'?: string | null;
    /**
     * Связанные группы приборов
     * @type {Array<number>}
     * @memberof DefectBrowseDto
     */
    'groupIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DefectsComplianceExportPayload
 */
export interface DefectsComplianceExportPayload {
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceExportPayload
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceExportPayload
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefectsComplianceExportPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefectsComplianceExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface DefectsComplianceReportDto
 */
export interface DefectsComplianceReportDto {
    /**
     * 
     * @type {Array<DefectsComplianceReportItemDto>}
     * @memberof DefectsComplianceReportDto
     */
    'devices'?: Array<DefectsComplianceReportItemDto>;
}
/**
 * 
 * @export
 * @interface DefectsComplianceReportItemDto
 */
export interface DefectsComplianceReportItemDto {
    /**
     * 
     * @type {number}
     * @memberof DefectsComplianceReportItemDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefectsComplianceReportItemDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'dateIn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'dateOut'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefectsComplianceReportItemDto
     */
    'defectConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefectsComplianceReportItemDto
     */
    'curKonId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'complaints'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'skl'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefectsComplianceReportItemDto
     */
    'firmRep'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'firmName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'nomer'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'newNomer'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'oper'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefectsComplianceReportItemDto
     */
    'konId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefectsComplianceReportItemDto
     */
    'company'?: string;
}
/**
 * 
 * @export
 * @interface DefectsMovementReportCounterpartyDto
 */
export interface DefectsMovementReportCounterpartyDto {
    /**
     * Id контрагента
     * @type {number}
     * @memberof DefectsMovementReportCounterpartyDto
     */
    'counterpartyId'?: number;
    /**
     * Название контрагента
     * @type {string}
     * @memberof DefectsMovementReportCounterpartyDto
     */
    'counterpartyName'?: string | null;
}
/**
 * 
 * @export
 * @interface DefectsMovementReportDto
 */
export interface DefectsMovementReportDto {
    /**
     * 
     * @type {Array<DefectsMovementReportCounterpartyDto>}
     * @memberof DefectsMovementReportDto
     */
    'counterparties'?: Array<DefectsMovementReportCounterpartyDto>;
    /**
     * 
     * @type {Array<DefectsMovementReportItemDto>}
     * @memberof DefectsMovementReportDto
     */
    'devices'?: Array<DefectsMovementReportItemDto>;
}
/**
 * 
 * @export
 * @interface DefectsMovementReportExportPayload
 */
export interface DefectsMovementReportExportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof DefectsMovementReportExportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof DefectsMovementReportExportPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof DefectsMovementReportExportPayload
     */
    'firmId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof DefectsMovementReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface DefectsMovementReportExportToPdfPayload
 */
export interface DefectsMovementReportExportToPdfPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof DefectsMovementReportExportToPdfPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof DefectsMovementReportExportToPdfPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof DefectsMovementReportExportToPdfPayload
     */
    'firmId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof DefectsMovementReportExportToPdfPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface DefectsMovementReportItemDto
 */
export interface DefectsMovementReportItemDto {
    /**
     * Id контрагента
     * @type {number}
     * @memberof DefectsMovementReportItemDto
     */
    'counterpartyId'?: number;
    /**
     * Название контрагента
     * @type {string}
     * @memberof DefectsMovementReportItemDto
     */
    'counterpartyName'?: string | null;
    /**
     * Sku прибора
     * @type {string}
     * @memberof DefectsMovementReportItemDto
     */
    'sku'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof DefectsMovementReportItemDto
     */
    'seK'?: string;
    /**
     * Наимнование прибора
     * @type {string}
     * @memberof DefectsMovementReportItemDto
     */
    'se'?: string;
    /**
     * начальный остаток
     * @type {number}
     * @memberof DefectsMovementReportItemDto
     */
    'nachOst'?: number;
    /**
     * Приход
     * @type {number}
     * @memberof DefectsMovementReportItemDto
     */
    'prihod'?: number;
    /**
     * Расход
     * @type {number}
     * @memberof DefectsMovementReportItemDto
     */
    'rashod'?: number;
    /**
     * Конечный остаток
     * @type {number}
     * @memberof DefectsMovementReportItemDto
     */
    'konechOst'?: number;
}
/**
 * 
 * @export
 * @interface DeleteInvoiceDevicesPayload
 */
export interface DeleteInvoiceDevicesPayload {
    /**
     * Ключи приборов в счете
     * @type {Array<number>}
     * @memberof DeleteInvoiceDevicesPayload
     */
    'deviceIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeleteRepairPlansPayload
 */
export interface DeleteRepairPlansPayload {
    /**
     * Ключ компании пользователя
     * @type {number}
     * @memberof DeleteRepairPlansPayload
     */
    'firmId'?: number;
    /**
     * Номер участка
     * @type {string}
     * @memberof DeleteRepairPlansPayload
     */
    'areaRepair'?: string;
    /**
     * Выбранные приборы
     * @type {Array<RepairPlanDevicePrimaryKeyDto>}
     * @memberof DeleteRepairPlansPayload
     */
    'devices'?: Array<RepairPlanDevicePrimaryKeyDto>;
}
/**
 * 
 * @export
 * @interface DeleteRmaDevicesPayload
 */
export interface DeleteRmaDevicesPayload {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteRmaDevicesPayload
     */
    'deviceIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeleteRmaRequestDevicesPayload
 */
export interface DeleteRmaRequestDevicesPayload {
    /**
     * Ключи приборов в заявке
     * @type {Array<number>}
     * @memberof DeleteRmaRequestDevicesPayload
     */
    'deviceIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeleteUserPayload
 */
export interface DeleteUserPayload {
    /**
     * 
     * @type {string}
     * @memberof DeleteUserPayload
     */
    'tn'?: string;
}
/**
 * 
 * @export
 * @interface DeviceAddAddedCEPayload
 */
export interface DeviceAddAddedCEPayload {
    /**
     * 
     * @type {Array<AddedCEAddDto>}
     * @memberof DeviceAddAddedCEPayload
     */
    'devices'?: Array<AddedCEAddDto>;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddAddedCEPayload
     */
    'skl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceAddAddedCEPayload
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface DeviceAddDefectsPayload
 */
export interface DeviceAddDefectsPayload {
    /**
     * Ключи дефектов
     * @type {Array<number>}
     * @memberof DeviceAddDefectsPayload
     */
    'defectIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeviceAddReplacedCEPayload
 */
export interface DeviceAddReplacedCEPayload {
    /**
     * 
     * @type {Array<ReplacedCEAddDto>}
     * @memberof DeviceAddReplacedCEPayload
     */
    'devices'?: Array<ReplacedCEAddDto>;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddReplacedCEPayload
     */
    'skl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceAddReplacedCEPayload
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface DeviceAddWorksPayload
 */
export interface DeviceAddWorksPayload {
    /**
     * Ключи типовых работ
     * @type {Array<number>}
     * @memberof DeviceAddWorksPayload
     */
    'workIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeviceAddedSeDto
 */
export interface DeviceAddedSeDto {
    /**
     * 
     * @type {string}
     * @memberof DeviceAddedSeDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddedSeDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddedSeDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddedSeDto
     */
    'seKParent'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddedSeDto
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceAddedSeDto
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddedSeDto
     */
    'skl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddedSeDto
     */
    'tn'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceAddedSeDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceAddedSeDto
     */
    'valId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddedSeDto
     */
    'obozn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAddedSeDto
     */
    'additionDate'?: string | null;
}
/**
 * 
 * @export
 * @interface DeviceArrivalTimeReportPayload
 */
export interface DeviceArrivalTimeReportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof DeviceArrivalTimeReportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof DeviceArrivalTimeReportPayload
     */
    'dateTo'?: string;
    /**
     * язык
     * @type {number}
     * @memberof DeviceArrivalTimeReportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface DeviceAuditBrowseDto
 */
export interface DeviceAuditBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof DeviceAuditBrowseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'partitionType'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'eventData'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'initiatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceAuditBrowseDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceAuditBrowseDto
     */
    'version'?: number;
}
/**
 * 
 * @export
 * @interface DeviceBatteryDto
 */
export interface DeviceBatteryDto {
    /**
     * Номер батареи
     * @type {number}
     * @memberof DeviceBatteryDto
     */
    'partsId'?: number;
    /**
     * Код СЕ
     * @type {string}
     * @memberof DeviceBatteryDto
     */
    'seK'?: string;
    /**
     * Серийный номер текущий
     * @type {string}
     * @memberof DeviceBatteryDto
     */
    'nomer'?: string;
    /**
     * серийный номер старый
     * @type {string}
     * @memberof DeviceBatteryDto
     */
    'oldNomer'?: string;
    /**
     * id-производителя
     * @type {number}
     * @memberof DeviceBatteryDto
     */
    'proiz'?: number | null;
    /**
     * Дата производства
     * @type {string}
     * @memberof DeviceBatteryDto
     */
    'dataProiz'?: string | null;
    /**
     * Гарантийный прибор
     * @type {boolean}
     * @memberof DeviceBatteryDto
     */
    'warranty'?: boolean;
    /**
     * Наименование английское
     * @type {string}
     * @memberof DeviceBatteryDto
     */
    'nameEn'?: string;
    /**
     * Наименование русское
     * @type {string}
     * @memberof DeviceBatteryDto
     */
    'nameRu'?: string;
}
/**
 * 
 * @export
 * @interface DeviceBatteryTypeBrowseDto
 */
export interface DeviceBatteryTypeBrowseDto {
    /**
     * 
     * @type {string}
     * @memberof DeviceBatteryTypeBrowseDto
     */
    'se'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBatteryTypeBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBatteryTypeBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBatteryTypeBrowseDto
     */
    'nameL'?: string;
}
/**
 * 
 * @export
 * @interface DeviceBrowseDto
 */
export interface DeviceBrowseDto {
    /**
     * 
     * @type {DeviceGeneralInformationDto}
     * @memberof DeviceBrowseDto
     */
    'generalInformation'?: DeviceGeneralInformationDto;
    /**
     * 
     * @type {Array<DeviceBatteryDto>}
     * @memberof DeviceBrowseDto
     */
    'batteries'?: Array<DeviceBatteryDto>;
    /**
     * 
     * @type {Array<DeviceDefectsDto>}
     * @memberof DeviceBrowseDto
     */
    'defects'?: Array<DeviceDefectsDto>;
    /**
     * 
     * @type {Array<DeviceReplacedSeDto>}
     * @memberof DeviceBrowseDto
     */
    'replacedSes'?: Array<DeviceReplacedSeDto>;
    /**
     * 
     * @type {Array<DeviceAddedSeDto>}
     * @memberof DeviceBrowseDto
     */
    'addedSes'?: Array<DeviceAddedSeDto>;
    /**
     * 
     * @type {Array<DeviceWorklistDto>}
     * @memberof DeviceBrowseDto
     */
    'worklists'?: Array<DeviceWorklistDto>;
}
/**
 * 
 * @export
 * @interface DeviceBySerialDto
 */
export interface DeviceBySerialDto {
    /**
     * 
     * @type {string}
     * @memberof DeviceBySerialDto
     */
    'se'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBySerialDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBySerialDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBySerialDto
     */
    'productionDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceBySerialDto
     */
    'proiz'?: number;
}
/**
 * 
 * @export
 * @interface DeviceDefectsDto
 */
export interface DeviceDefectsDto {
    /**
     * Ключ дефекта
     * @type {number}
     * @memberof DeviceDefectsDto
     */
    'defId'?: number;
    /**
     * Описание родительских дефектов
     * @type {string}
     * @memberof DeviceDefectsDto
     */
    'defE'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDefectsDto
     */
    'defL'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDefectsDto
     */
    'defR'?: string;
    /**
     * Описание дефекта
     * @type {string}
     * @memberof DeviceDefectsDto
     */
    'repE'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDefectsDto
     */
    'repL'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDefectsDto
     */
    'repR'?: string;
    /**
     * Ключ виновника
     * @type {number}
     * @memberof DeviceDefectsDto
     */
    'vinId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceDefectsDto
     */
    'primec'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceDefectsDto
     */
    'osn'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceDefectsDto
     */
    'tn'?: number | null;
}
/**
 * 
 * @export
 * @interface DeviceDeleteAddedCEPayload
 */
export interface DeviceDeleteAddedCEPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeleteAddedCEPayload
     */
    'ses'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DeviceDeleteDefectsPayload
 */
export interface DeviceDeleteDefectsPayload {
    /**
     * Ключи дефектов
     * @type {Array<number>}
     * @memberof DeviceDeleteDefectsPayload
     */
    'defectIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeviceDeleteReplacedCEPayload
 */
export interface DeviceDeleteReplacedCEPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeleteReplacedCEPayload
     */
    'ses'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DeviceDeleteWorksPayload
 */
export interface DeviceDeleteWorksPayload {
    /**
     * Ключи типовых работ
     * @type {Array<number>}
     * @memberof DeviceDeleteWorksPayload
     */
    'workIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeviceEquipmentBrowseDto
 */
export interface DeviceEquipmentBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof DeviceEquipmentBrowseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceEquipmentBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceEquipmentBrowseDto
     */
    'nameRu'?: string;
}
/**
 * 
 * @export
 * @interface DeviceExpiringLicenseDto
 */
export interface DeviceExpiringLicenseDto {
    /**
     * Ид прибора
     * @type {number}
     * @memberof DeviceExpiringLicenseDto
     */
    'deviceId'?: number;
    /**
     * Ид фирмы прибора
     * @type {number}
     * @memberof DeviceExpiringLicenseDto
     */
    'deviceFirmId'?: number;
    /**
     * Код SKU
     * @type {string}
     * @memberof DeviceExpiringLicenseDto
     */
    'sku'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof DeviceExpiringLicenseDto
     */
    'seK'?: string;
    /**
     * Наименование английское
     * @type {string}
     * @memberof DeviceExpiringLicenseDto
     */
    'nameEn'?: string;
    /**
     * Наименование английское
     * @type {string}
     * @memberof DeviceExpiringLicenseDto
     */
    'nameRu'?: string;
    /**
     * Дистрибьютор
     * @type {string}
     * @memberof DeviceExpiringLicenseDto
     */
    'counterparty'?: string;
    /**
     * Номер разрешения (RMA)
     * @type {string}
     * @memberof DeviceExpiringLicenseDto
     */
    'rmaNumber'?: string;
    /**
     * Номер Лицензии
     * @type {string}
     * @memberof DeviceExpiringLicenseDto
     */
    'licenseNumber'?: string;
    /**
     * Дата окончания лицензии
     * @type {string}
     * @memberof DeviceExpiringLicenseDto
     */
    'licenseExpiring'?: string;
    /**
     * Дата поступления прибора
     * @type {string}
     * @memberof DeviceExpiringLicenseDto
     */
    'receiptDate'?: string;
}
/**
 * 
 * @export
 * @interface DeviceGeneralInformationDto
 */
export interface DeviceGeneralInformationDto {
    /**
     * получен-неполучен
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'chk'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'fake'?: boolean;
    /**
     * Блокировка
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'loc'?: boolean | null;
    /**
     * id-прибора в RMA
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'parentDeviceId'?: number | null;
    /**
     * id- фирмы прибора в RMA
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'parentFirmId'?: number | null;
    /**
     * id замененного прибора
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'idZam'?: number | null;
    /**
     * id фирмы замененного прибора
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'fidZam'?: number | null;
    /**
     * id-прибора
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'deviceId'?: number;
    /**
     * id-фирмы получателя
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'firmId'?: number;
    /**
     * id дистрибьютора
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'konId'?: number;
    /**
     * Код СЕ
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'seK'?: string;
    /**
     * Серийный номер текущий
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'nomer'?: string;
    /**
     * серийный номер старый
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'oldNomer'?: string;
    /**
     * операция замены номера
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'oper'?: string | null;
    /**
     * Дата производства
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'dataProiz'?: string | null;
    /**
     * id-производителя
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'proiz'?: number | null;
    /**
     * Дата продажи
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'dataProd'?: string | null;
    /**
     * Ид покупателя
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'buyerId'?: number | null;
    /**
     * Покупатель
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'buyer'?: string | null;
    /**
     * Гарантийный прибор
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'warranty'?: boolean;
    /**
     * Дефект подтвержден
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'defectConfirmed'?: boolean;
    /**
     * стоимость ремонта
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'stoimRem'?: number;
    /**
     * Предварительная стоимость
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'preliminaryCost'?: boolean;
    /**
     * Ремонт без стоимости
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'repairWithoutCost'?: boolean;
    /**
     * id-валюты стоимости ремонта
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'valRem'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'sklRem'?: string | null;
    /**
     * Согласие Дистрибьютора на платный ремонт
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'platRem'?: boolean | null;
    /**
     * Выставлять счет
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'schet'?: boolean;
    /**
     * Срочный ремонт
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'speedRepair'?: boolean;
    /**
     * Вернуть покупателю
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'wait'?: boolean;
    /**
     * Отремонтировать
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'repairToSaleState'?: boolean;
    /**
     * Укомплектовать
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'completeToSaleState'?: boolean;
    /**
     * Вернуть без ремонта
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'vozvrBezR'?: boolean;
    /**
     * Необходим предварительный контроль
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'beControl'?: boolean;
    /**
     * Предварительный контроль выполнен
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'beControlC'?: boolean;
    /**
     * Необходим повторный контроль
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'reControl'?: boolean;
    /**
     * Повторный контроль выполнен
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'reControlC'?: boolean;
    /**
     * Повторный ремонт подтвержден
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'repeatedRepairConfirmed'?: boolean;
    /**
     * Предварительный анализ неисправностей
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'pAnaliz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'vneshVid'?: string | null;
    /**
     * комплект поставки
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'komplekt'?: string | null;
    /**
     * заявленные неисправности
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'complaints'?: string | null;
    /**
     * Примечания
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'notes'?: string | null;
    /**
     * Примечания ОТК
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'notesQc'?: string | null;
    /**
     * Примечания клиента
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'notesKli'?: string | null;
    /**
     * Прибор Простафера
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'prostaf'?: boolean;
    /**
     * Дополнительные работы
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'dopWorks'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'nameN'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'warrantyTerm'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'firmName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'konName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceGeneralInformationDto
     */
    'konVal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'deviceOnRepairArea'?: boolean;
    /**
     * Повторный возврат (в течении 3х месяцев)
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'secondaryReturn'?: boolean;
    /**
     * Url упаковочной инструкции
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'packingInstructionsUrl'?: string;
    /**
     * Участок прибора
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'skl'?: string;
    /**
     * Общение с покупателем
     * @type {string}
     * @memberof DeviceGeneralInformationDto
     */
    'customerCommunication'?: string | null;
    /**
     * Замена на новый прибор
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'replacementForNew'?: boolean;
    /**
     * Запросить уточнение
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'requestConfirmation'?: boolean;
    /**
     * Признак упакованного прибора
     * @type {boolean}
     * @memberof DeviceGeneralInformationDto
     */
    'isPacked'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviceGroupBrowseDto
 */
export interface DeviceGroupBrowseDto {
    /**
     * Ключ родительского узла
     * @type {number}
     * @memberof DeviceGroupBrowseDto
     */
    'parentId'?: number;
    /**
     * Ключ группы приборов
     * @type {number}
     * @memberof DeviceGroupBrowseDto
     */
    'deviceGroupId'?: number;
    /**
     * Наименование гурппы (RU)
     * @type {string}
     * @memberof DeviceGroupBrowseDto
     */
    'nameR'?: string;
    /**
     * Наименование гурппы (EN)
     * @type {string}
     * @memberof DeviceGroupBrowseDto
     */
    'nameE'?: string;
}
/**
 * 
 * @export
 * @interface DeviceHistoryBrowseDto
 */
export interface DeviceHistoryBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof DeviceHistoryBrowseDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceHistoryBrowseDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceHistoryBrowseDto
     */
    'transactionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'soPr'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'soDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'sklIn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'docNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHistoryBrowseDto
     */
    'docDate'?: string | null;
}
/**
 * 
 * @export
 * @interface DevicePackagingInformationDto
 */
export interface DevicePackagingInformationDto {
    /**
     * Ид прибора
     * @type {number}
     * @memberof DevicePackagingInformationDto
     */
    'devId'?: number;
    /**
     * Ид фирмы прибора
     * @type {number}
     * @memberof DevicePackagingInformationDto
     */
    'firmId'?: number;
    /**
     * Комплектация проверена
     * @type {boolean}
     * @memberof DevicePackagingInformationDto
     */
    'checkEquipment'?: boolean;
    /**
     * Наличие батарей проверено
     * @type {boolean}
     * @memberof DevicePackagingInformationDto
     */
    'checkBatteries'?: boolean;
    /**
     * Наличие комментария проверено
     * @type {boolean}
     * @memberof DevicePackagingInformationDto
     */
    'checkComments'?: boolean;
    /**
     * Проверена упаковка прибора до нового (продажного) состояния
     * @type {boolean}
     * @memberof DevicePackagingInformationDto
     */
    'checkPackedAsNew'?: boolean;
    /**
     * Табельный номер сотрудника, подтвердившего упаковку
     * @type {string}
     * @memberof DevicePackagingInformationDto
     */
    'tn'?: string | null;
}
/**
 * 
 * @export
 * @interface DeviceRepairCostCalculationBrowseDto
 */
export interface DeviceRepairCostCalculationBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof DeviceRepairCostCalculationBrowseDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceRepairCostCalculationBrowseDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceRepairCostCalculationBrowseDto
     */
    'primec'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceRepairCostCalculationBrowseDto
     */
    'rccData'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceRepairCostCalculationBrowseDto
     */
    'sotrId1'?: number | null;
}
/**
 * 
 * @export
 * @interface DeviceReplacedSeDto
 */
export interface DeviceReplacedSeDto {
    /**
     * 
     * @type {string}
     * @memberof DeviceReplacedSeDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceReplacedSeDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceReplacedSeDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceReplacedSeDto
     */
    'seKParent'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceReplacedSeDto
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceReplacedSeDto
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceReplacedSeDto
     */
    'skl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceReplacedSeDto
     */
    'tn'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceReplacedSeDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceReplacedSeDto
     */
    'valId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceReplacedSeDto
     */
    'obozn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceReplacedSeDto
     */
    'replacedDate'?: string | null;
}
/**
 * 
 * @export
 * @interface DeviceReturnWithoutRepairPayload
 */
export interface DeviceReturnWithoutRepairPayload {
    /**
     * 
     * @type {boolean}
     * @memberof DeviceReturnWithoutRepairPayload
     */
    'vozvrBezR'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeviceReturnWithoutRepairPayload
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface DeviceSerialNumberEan13Dto
 */
export interface DeviceSerialNumberEan13Dto {
    /**
     * 
     * @type {string}
     * @memberof DeviceSerialNumberEan13Dto
     */
    'ean13'?: string;
}
/**
 * 
 * @export
 * @interface DeviceSetPaymentConsentPayload
 */
export interface DeviceSetPaymentConsentPayload {
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSetPaymentConsentPayload
     */
    'platRem'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DeviceSetRepairCostPayload
 */
export interface DeviceSetRepairCostPayload {
    /**
     * 
     * @type {number}
     * @memberof DeviceSetRepairCostPayload
     */
    'stoimRem'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceSetRepairCostPayload
     */
    'valRem'?: number;
}
/**
 * 
 * @export
 * @interface DeviceSetRepairCostResponse
 */
export interface DeviceSetRepairCostResponse {
    /**
     * 
     * @type {UpdateDeviceRepairCostResult}
     * @memberof DeviceSetRepairCostResponse
     */
    'result'?: UpdateDeviceRepairCostResult;
    /**
     * 
     * @type {UpdateDeviceRepairCostResult}
     * @memberof DeviceSetRepairCostResponse
     */
    'addedPartsResult'?: UpdateDeviceRepairCostResult;
}


/**
 * 
 * @export
 * @interface DeviceTechnicalConclusionBrowseDto
 */
export interface DeviceTechnicalConclusionBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'devId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'tzData'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'tzNomer'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'tzFid'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'sotrId1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'sotrId2'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'vOsmotr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'test'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'zakl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'prichina1'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'prichina2'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'defectNotConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'meetsQuality'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'prevention'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'showAdvice'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'konId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'warranty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'vozvrBezR'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'nomer'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'oldNomer'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'nameN'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'warrantyTerm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'isTalion'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'dataProd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'dataProiz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'complaints'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'stoimRem'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'obozn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'konName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'rmaNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseDto
     */
    'isUpgradable'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviceTechnicalConclusionBrowseResponse
 */
export interface DeviceTechnicalConclusionBrowseResponse {
    /**
     * 
     * @type {DeviceTechnicalConclusionBrowseDto}
     * @memberof DeviceTechnicalConclusionBrowseResponse
     */
    'technicalConclusion'?: DeviceTechnicalConclusionBrowseDto;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseResponse
     */
    'isValidWarranty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseResponse
     */
    'enableReason1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceTechnicalConclusionBrowseResponse
     */
    'enableReason2'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviceToSendDto
 */
export interface DeviceToSendDto {
    /**
     * Ид прибора
     * @type {number}
     * @memberof DeviceToSendDto
     */
    'deviceId'?: number;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof DeviceToSendDto
     */
    'firmId'?: number;
    /**
     * Буква фирмы
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'ind'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'sek'?: string;
    /**
     * код Sku
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'sku'?: string;
    /**
     * Наименование прибора английское
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'nameE'?: string;
    /**
     * Наименование прибора русское
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'nameR'?: string;
    /**
     * Старый серийный номер прибора
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'oldSerial'?: string;
    /**
     * Серийный номер прибора
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'serial'?: string;
    /**
     * Возврат владельцу
     * @type {boolean}
     * @memberof DeviceToSendDto
     */
    'returnToOwner'?: boolean;
    /**
     * Примечания дистрибьютора к прибору
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'notes'?: string;
    /**
     * Статус прибора: 0 - отремонтрирован, 1 - несправен
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'soPr'?: string;
    /**
     * Наименование дистрибьютора
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'counterpartyName'?: string;
    /**
     * Номер счета поступления
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'invoiceNumber'?: string;
    /**
     * Дата счета
     * @type {string}
     * @memberof DeviceToSendDto
     */
    'incomingDate'?: string;
}
/**
 * 
 * @export
 * @interface DeviceUpdateAddedCEPayload
 */
export interface DeviceUpdateAddedCEPayload {
    /**
     * 
     * @type {number}
     * @memberof DeviceUpdateAddedCEPayload
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceUpdateAddedCEPayload
     */
    'skl'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceUpdateAddedCEPayload
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface DeviceUpdateDefectPayload
 */
export interface DeviceUpdateDefectPayload {
    /**
     * Ключ причины
     * @type {number}
     * @memberof DeviceUpdateDefectPayload
     */
    'blameId'?: number;
    /**
     * Описание неисправности
     * @type {string}
     * @memberof DeviceUpdateDefectPayload
     */
    'primec'?: string | null;
    /**
     * Признак основной дефект
     * @type {boolean}
     * @memberof DeviceUpdateDefectPayload
     */
    'osn'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviceUpdateReplacedCEPayload
 */
export interface DeviceUpdateReplacedCEPayload {
    /**
     * 
     * @type {number}
     * @memberof DeviceUpdateReplacedCEPayload
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceUpdateReplacedCEPayload
     */
    'skl'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceUpdateReplacedCEPayload
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface DeviceWarrantyCheckDto
 */
export interface DeviceWarrantyCheckDto {
    /**
     * Номер строки прибора в Excel
     * @type {number}
     * @memberof DeviceWarrantyCheckDto
     */
    'deviceRowNumber'?: number;
    /**
     * Код СЕ прибора
     * @type {string}
     * @memberof DeviceWarrantyCheckDto
     */
    'seK'?: string;
    /**
     * Код СЕ прибора
     * @type {string}
     * @memberof DeviceWarrantyCheckDto
     */
    'sku'?: string;
    /**
     * Серийный номер прибора
     * @type {string}
     * @memberof DeviceWarrantyCheckDto
     */
    'serial'?: string;
    /**
     * Поврежден пользователем
     * @type {boolean}
     * @memberof DeviceWarrantyCheckDto
     */
    'userFault'?: boolean;
    /**
     * Дата производства
     * @type {string}
     * @memberof DeviceWarrantyCheckDto
     */
    'dataProiz'?: string | null;
    /**
     * Производитель
     * @type {number}
     * @memberof DeviceWarrantyCheckDto
     */
    'proiz'?: number | null;
    /**
     * Гарантийность прибора
     * @type {boolean}
     * @memberof DeviceWarrantyCheckDto
     */
    'warranty'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviceWarrantyCheckPayload
 */
export interface DeviceWarrantyCheckPayload {
    /**
     * 
     * @type {Array<DeviceWarrantyCheckDto>}
     * @memberof DeviceWarrantyCheckPayload
     */
    'devices'?: Array<DeviceWarrantyCheckDto>;
}
/**
 * 
 * @export
 * @interface DeviceWorkBrowseDto
 */
export interface DeviceWorkBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof DeviceWorkBrowseDto
     */
    'workId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceWorkBrowseDto
     */
    'u1'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceWorkBrowseDto
     */
    'u2'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceWorkBrowseDto
     */
    'u3'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceWorkBrowseDto
     */
    'u4'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceWorkBrowseDto
     */
    'u5'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceWorkBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceWorkBrowseDto
     */
    'nameEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceWorkBrowseDto
     */
    'nameL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceWorkBrowseDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface DeviceWorklistDto
 */
export interface DeviceWorklistDto {
    /**
     * 
     * @type {number}
     * @memberof DeviceWorklistDto
     */
    'workId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceWorklistDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceWorklistDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceWorklistDto
     */
    'nameRu'?: string;
}
/**
 * 
 * @export
 * @interface DisagreeRepairPlanPayload
 */
export interface DisagreeRepairPlanPayload {
    /**
     * Ключ компании пользователя
     * @type {number}
     * @memberof DisagreeRepairPlanPayload
     */
    'firmId'?: number;
    /**
     * Номер участка
     * @type {string}
     * @memberof DisagreeRepairPlanPayload
     */
    'areaRepair'?: string;
    /**
     * Выбранные приборы
     * @type {Array<RepairPlanDevicePrimaryKeyDto>}
     * @memberof DisagreeRepairPlanPayload
     */
    'devices'?: Array<RepairPlanDevicePrimaryKeyDto>;
}
/**
 * 
 * @export
 * @interface DistributorDeviceBrowseDto
 */
export interface DistributorDeviceBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof DistributorDeviceBrowseDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DistributorDeviceBrowseDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'date1'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'dateIn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'dateOut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'dataProiz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'dataProd'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DistributorDeviceBrowseDto
     */
    'konId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'invoice'?: string;
    /**
     * 
     * @type {number}
     * @memberof DistributorDeviceBrowseDto
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'rmaNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'serial'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'newSerial'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'soPr'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'transit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'po'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'r2O'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'warranty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DistributorDeviceBrowseDto
     */
    'stoimRem'?: number;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'valRem'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'platRem'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'schet'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'speedRepair'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'vozvrBezR'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'defectConfirmed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DistributorDeviceBrowseDto
     */
    'defectCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'komplekt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'complaints'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'skl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'sklName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'dpdNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'tz'?: boolean;
    /**
     * Дата сдачи прибора Владельцем в ремонт Дистрибьютору
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'dataReceiptForRepair'?: string | null;
    /**
     * Номер исходячешго счета
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'outgoingInvoiceDocNumber'?: string | null;
    /**
     * План ремонта
     * @type {string}
     * @memberof DistributorDeviceBrowseDto
     */
    'planRepair'?: string | null;
    /**
     * Предварительная стоимость
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'preliminaryCost'?: boolean;
    /**
     * Ремонт без стоимости
     * @type {boolean}
     * @memberof DistributorDeviceBrowseDto
     */
    'repairWithoutCost'?: boolean;
}
/**
 * 
 * @export
 * @interface DistributorInfoBrowseDto
 */
export interface DistributorInfoBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof DistributorInfoBrowseDto
     */
    'counterpartyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DistributorInfoBrowseDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DistributorInfoBrowseDto
     */
    'devicesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DistributorInfoBrowseDto
     */
    'devicesCountDamaged'?: number;
    /**
     * 
     * @type {number}
     * @memberof DistributorInfoBrowseDto
     */
    'devicesCountReadyForShipment'?: number;
}
/**
 * 
 * @export
 * @interface DistributorsDataSavePayload
 */
export interface DistributorsDataSavePayload {
    /**
     * 
     * @type {number}
     * @memberof DistributorsDataSavePayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DistributorsDataSavePayload
     */
    'counterpartyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DistributorsDataSavePayload
     */
    'currencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DistributorsDataSavePayload
     */
    'documentDate'?: string;
    /**
     * 
     * @type {Array<ExternalServiceDeviceDto>}
     * @memberof DistributorsDataSavePayload
     */
    'devices'?: Array<ExternalServiceDeviceDto>;
}
/**
 * 
 * @export
 * @interface DistributorsDevicesReportExcelPayload
 */
export interface DistributorsDevicesReportExcelPayload {
    /**
     * 
     * @type {number}
     * @memberof DistributorsDevicesReportExcelPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DistributorsDevicesReportExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface DistributorsRatingReportExcelPayload
 */
export interface DistributorsRatingReportExcelPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof DistributorsRatingReportExcelPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof DistributorsRatingReportExcelPayload
     */
    'dateTo'?: string;
    /**
     * язык
     * @type {number}
     * @memberof DistributorsRatingReportExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface DistributorsRatingReportItemDto
 */
export interface DistributorsRatingReportItemDto {
    /**
     * имя контрагента
     * @type {string}
     * @memberof DistributorsRatingReportItemDto
     */
    'counterparty'?: string;
    /**
     * Количество согласований платных ремонтов - просроченных (больше 14 дней)
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'overduePaidRepairApproval'?: number;
    /**
     * Количество согласований платных ремонтов - не просроченных (14 дней и меньше)
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'inTimePaidRepairApproval'?: number;
    /**
     * Общее количество отгрузок
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'totalShipments'?: number;
    /**
     * Средний срок доставки (дней)
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'averageTransportationTime'?: number;
    /**
     * Отгрузки с приложенным Инвойсом
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'shipmentsWithInvoice'?: number;
    /**
     * Количество отгрузок без ошибок
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'shipmentsWithoutErrors'?: number;
    /**
     * Качество упаковки груза, без замечаний, количества
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'goodPackagedShipments'?: number;
    /**
     * Всего приборов
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'totalDevices'?: number;
    /**
     * Годные приборы (дефекты не обнаружены)
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'goodDevices'?: number;
    /**
     * Приборы с подтвержденным дефектом
     * @type {number}
     * @memberof DistributorsRatingReportItemDto
     */
    'defectConfirmed'?: number;
}
/**
 * 
 * @export
 * @interface DocNoteBrowseDto
 */
export interface DocNoteBrowseDto {
    /**
     * Id примечания
     * @type {number}
     * @memberof DocNoteBrowseDto
     */
    'noteId'?: number;
    /**
     * Id компании
     * @type {number}
     * @memberof DocNoteBrowseDto
     */
    'firmId'?: number;
    /**
     * Код документа
     * @type {string}
     * @memberof DocNoteBrowseDto
     */
    'documentCode'?: string;
    /**
     * Текст примечания
     * @type {string}
     * @memberof DocNoteBrowseDto
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeBrowseDto
 */
export interface EmployeeBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeBrowseDto
     */
    'sotrId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeBrowseDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBrowseDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBrowseDto
     */
    'jobTitleRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBrowseDto
     */
    'jobTitleEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeBrowseDto
     */
    'jobTitleL'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeBrowseDto
     */
    'pravoPod'?: number;
}
/**
 * 
 * @export
 * @interface ExcelRowError
 */
export interface ExcelRowError {
    /**
     * 
     * @type {number}
     * @memberof ExcelRowError
     */
    'rowNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExcelRowError
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ExpiringLicenseDto
 */
export interface ExpiringLicenseDto {
    /**
     * Ключ Лицензии
     * @type {number}
     * @memberof ExpiringLicenseDto
     */
    'licenseId'?: number;
    /**
     * Дистрибьютор
     * @type {string}
     * @memberof ExpiringLicenseDto
     */
    'counterparty'?: string;
    /**
     * Номер лицензии
     * @type {string}
     * @memberof ExpiringLicenseDto
     */
    'licenseNumber'?: string;
    /**
     * Дата окончания лицензии
     * @type {string}
     * @memberof ExpiringLicenseDto
     */
    'expiringDate'?: string;
}
/**
 * 
 * @export
 * @interface ExportAverageRepairPeriodForYearReportPayload
 */
export interface ExportAverageRepairPeriodForYearReportPayload {
    /**
     * 
     * @type {number}
     * @memberof ExportAverageRepairPeriodForYearReportPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportAverageRepairPeriodForYearReportPayload
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportAverageRepairPeriodForYearReportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ExportAverageRepairPeriodReportPayload
 */
export interface ExportAverageRepairPeriodReportPayload {
    /**
     * 
     * @type {number}
     * @memberof ExportAverageRepairPeriodReportPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportAverageRepairPeriodReportPayload
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportAverageRepairPeriodReportPayload
     */
    'month'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExportAverageRepairPeriodReportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ExportDisposalToPdfPayload
 */
export interface ExportDisposalToPdfPayload {
    /**
     * Выбранные приборы
     * @type {Array<MaintenanceDisposalDeviceDto>}
     * @memberof ExportDisposalToPdfPayload
     */
    'devices'?: Array<MaintenanceDisposalDeviceDto>;
    /**
     * 
     * @type {number}
     * @memberof ExportDisposalToPdfPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ExportDistributorDevicesToExcelPayload
 */
export interface ExportDistributorDevicesToExcelPayload {
    /**
     * Начало периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof ExportDistributorDevicesToExcelPayload
     */
    'periodStart': string;
    /**
     * Конец периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof ExportDistributorDevicesToExcelPayload
     */
    'periodEnd': string;
    /**
     * Фильтр по контрагенту
     * @type {number}
     * @memberof ExportDistributorDevicesToExcelPayload
     */
    'counterpartyId': number;
    /**
     * 
     * @type {number}
     * @memberof ExportDistributorDevicesToExcelPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportDistributorDevicesToExcelPayload
     */
    'lcid'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportDistributorDevicesToExcelPayload
     */
    'soPr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExportDistributorDevicesToExcelPayload
     */
    'platRem'?: boolean | null;
    /**
     * Поиск по серийному номеру/SKU
     * @type {string}
     * @memberof ExportDistributorDevicesToExcelPayload
     */
    'number'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExportDistributorDevicesToExcelPayload
     */
    'deviceIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ExportDistributorDevicesToPdfPayload
 */
export interface ExportDistributorDevicesToPdfPayload {
    /**
     * Начало периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof ExportDistributorDevicesToPdfPayload
     */
    'periodStart': string;
    /**
     * Конец периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof ExportDistributorDevicesToPdfPayload
     */
    'periodEnd': string;
    /**
     * Фильтр по контрагенту
     * @type {number}
     * @memberof ExportDistributorDevicesToPdfPayload
     */
    'counterpartyId': number;
    /**
     * 
     * @type {number}
     * @memberof ExportDistributorDevicesToPdfPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportDistributorDevicesToPdfPayload
     */
    'lcid'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportDistributorDevicesToPdfPayload
     */
    'soPr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExportDistributorDevicesToPdfPayload
     */
    'platRem'?: boolean | null;
    /**
     * Поиск по серийному номеру/SKU
     * @type {string}
     * @memberof ExportDistributorDevicesToPdfPayload
     */
    'number'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExportDistributorDevicesToPdfPayload
     */
    'deviceIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ExportGeneralDailyReportToExcelPayload
 */
export interface ExportGeneralDailyReportToExcelPayload {
    /**
     * 
     * @type {number}
     * @memberof ExportGeneralDailyReportToExcelPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportGeneralDailyReportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ExportMaintenanceToExcelPayload
 */
export interface ExportMaintenanceToExcelPayload {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'firmId'?: number;
    /**
     * Все участки на всех предприятиях
     * @type {boolean}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'includeRepairAreas'?: boolean;
    /**
     * 
     * @type {MaintenanceView}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'view'?: MaintenanceView;
    /**
     * Статусы
     * @type {Array<MaintenanceDeviceStatus>}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'statuses'?: Array<MaintenanceDeviceStatus>;
    /**
     * Ключ владельца
     * @type {number}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'ownerId'?: number | null;
    /**
     * Поиск по компаниям
     * @type {Array<number>}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'companyIds'?: Array<number>;
    /**
     * Поиск по номеру документа/серийному номеру
     * @type {string}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'number'?: string | null;
    /**
     * Поиск по sku
     * @type {string}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'sku'?: string | null;
    /**
     * Язык интерфейса
     * @type {number}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'lcid'?: number;
    /**
     * Отмеченные приборы
     * @type {Array<MaintenanceDeviceSelectedDto>}
     * @memberof ExportMaintenanceToExcelPayload
     */
    'selectedDevices'?: Array<MaintenanceDeviceSelectedDto>;
}


/**
 * 
 * @export
 * @interface ExportRepairAreaSparePartsToExcelPayload
 */
export interface ExportRepairAreaSparePartsToExcelPayload {
    /**
     * Участок ремонта
     * @type {string}
     * @memberof ExportRepairAreaSparePartsToExcelPayload
     */
    'repairArea'?: string;
    /**
     * Язык
     * @type {number}
     * @memberof ExportRepairAreaSparePartsToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ExportRepairAreaSparePartsToPdfPayload
 */
export interface ExportRepairAreaSparePartsToPdfPayload {
    /**
     * Участок ремонта
     * @type {string}
     * @memberof ExportRepairAreaSparePartsToPdfPayload
     */
    'repairArea'?: string;
    /**
     * Язык отчета
     * @type {number}
     * @memberof ExportRepairAreaSparePartsToPdfPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ExportTransferCertificateDeviceDto
 */
export interface ExportTransferCertificateDeviceDto {
    /**
     * 
     * @type {number}
     * @memberof ExportTransferCertificateDeviceDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportTransferCertificateDeviceDto
     */
    'deviceFirmId'?: number;
}
/**
 * 
 * @export
 * @interface ExportTransferCertificateToPdfPayload
 */
export interface ExportTransferCertificateToPdfPayload {
    /**
     * 
     * @type {string}
     * @memberof ExportTransferCertificateToPdfPayload
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTransferCertificateToPdfPayload
     */
    'area'?: string;
    /**
     * 
     * @type {Array<ExportTransferCertificateDeviceDto>}
     * @memberof ExportTransferCertificateToPdfPayload
     */
    'devices'?: Array<ExportTransferCertificateDeviceDto>;
    /**
     * 
     * @type {number}
     * @memberof ExportTransferCertificateToPdfPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ExtendedWarrantyBrowseDto
 */
export interface ExtendedWarrantyBrowseDto {
    /**
     * Серийный номер
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'serial'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'seK'?: string;
    /**
     * Наименование английское
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'nameE'?: string;
    /**
     * Наименование русское
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'nameR'?: string;
    /**
     * Ид предприятия производителя
     * @type {number}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'productionId'?: number;
    /**
     * Наименование предприятия производителя
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'productionName'?: string;
    /**
     * Дата производства
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'productionDate'?: string;
    /**
     * Дата приборетения покупателем у Дилера
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'purchaseDate'?: string;
    /**
     * Расширенный срок гарантии (лет)
     * @type {number}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'extendedWarrantyTerm'?: number;
    /**
     * Табельный номер сотрудника загрузившего данные
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'tn'?: string;
    /**
     * Фамилия сотрудника загрузившего данные
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'lastName'?: string;
    /**
     * Имя сотрудника загрузившего данные
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'firstName'?: string;
    /**
     * Дата загрузки данных
     * @type {string}
     * @memberof ExtendedWarrantyBrowseDto
     */
    'uploadDate'?: string;
}
/**
 * 
 * @export
 * @interface ExtendedWarrantyDeviceDto
 */
export interface ExtendedWarrantyDeviceDto {
    /**
     * Серийный номер (max 10, min 10, numbers only)
     * @type {string}
     * @memberof ExtendedWarrantyDeviceDto
     */
    'serial'?: string;
    /**
     * Наименование прибора из Excel
     * @type {string}
     * @memberof ExtendedWarrantyDeviceDto
     */
    'deviceName'?: string;
    /**
     * Дата приобретения покупателем
     * @type {string}
     * @memberof ExtendedWarrantyDeviceDto
     */
    'purchaseDate'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof ExtendedWarrantyDeviceDto
     */
    'seK'?: string;
    /**
     * Наименование из справочника производства
     * @type {string}
     * @memberof ExtendedWarrantyDeviceDto
     */
    'name'?: string | null;
    /**
     * Ид предприятия производителя
     * @type {number}
     * @memberof ExtendedWarrantyDeviceDto
     */
    'productionId'?: number;
    /**
     * Дата производства
     * @type {string}
     * @memberof ExtendedWarrantyDeviceDto
     */
    'productionDate'?: string;
}
/**
 * 
 * @export
 * @interface ExtendedWarrantySavePayload
 */
export interface ExtendedWarrantySavePayload {
    /**
     * 
     * @type {Array<ExtendedWarrantyDeviceDto>}
     * @memberof ExtendedWarrantySavePayload
     */
    'devices'?: Array<ExtendedWarrantyDeviceDto>;
}
/**
 * 
 * @export
 * @interface ExternalServiceDefectDto
 */
export interface ExternalServiceDefectDto {
    /**
     * Основной дефект
     * @type {boolean}
     * @memberof ExternalServiceDefectDto
     */
    'osn'?: boolean;
    /**
     * Ид дефекта
     * @type {number}
     * @memberof ExternalServiceDefectDto
     */
    'defectId'?: number;
    /**
     * Код дефекта
     * @type {string}
     * @memberof ExternalServiceDefectDto
     */
    'defectCode'?: string;
    /**
     * Наименование дефекта русское
     * @type {string}
     * @memberof ExternalServiceDefectDto
     */
    'nameR'?: string;
    /**
     * Наименование дефекта английское
     * @type {string}
     * @memberof ExternalServiceDefectDto
     */
    'nameE'?: string;
    /**
     * Причина дефекта
     * @type {number}
     * @memberof ExternalServiceDefectDto
     */
    'blameId'?: number | null;
    /**
     * Наименование причины дефекта русское
     * @type {string}
     * @memberof ExternalServiceDefectDto
     */
    'blameNameR'?: string;
    /**
     * Наименование причины дефекта английское
     * @type {string}
     * @memberof ExternalServiceDefectDto
     */
    'blameNameE'?: string;
    /**
     * стоимость устранения
     * @type {number}
     * @memberof ExternalServiceDefectDto
     */
    'cost'?: number;
}
/**
 * 
 * @export
 * @interface ExternalServiceDeviceDto
 */
export interface ExternalServiceDeviceDto {
    /**
     * Номер строки прибора в Excel
     * @type {number}
     * @memberof ExternalServiceDeviceDto
     */
    'deviceRowNumber'?: number;
    /**
     * Ид прибора
     * @type {number}
     * @memberof ExternalServiceDeviceDto
     */
    'deviceId'?: number;
    /**
     * Код СЕ прибора
     * @type {string}
     * @memberof ExternalServiceDeviceDto
     */
    'seK'?: string;
    /**
     * Код SKU
     * @type {string}
     * @memberof ExternalServiceDeviceDto
     */
    'sku'?: string;
    /**
     * Наимнование прибора русское
     * @type {string}
     * @memberof ExternalServiceDeviceDto
     */
    'nameR'?: string;
    /**
     * Наименование прибора английское
     * @type {string}
     * @memberof ExternalServiceDeviceDto
     */
    'nameE'?: string;
    /**
     * Серийный номер прибора
     * @type {string}
     * @memberof ExternalServiceDeviceDto
     */
    'serial'?: string;
    /**
     * Дата продажи
     * @type {string}
     * @memberof ExternalServiceDeviceDto
     */
    'saleDate'?: string | null;
    /**
     * Дефекты, заявленные пользователем
     * @type {string}
     * @memberof ExternalServiceDeviceDto
     */
    'customerDefects'?: string;
    /**
     * Вернуть владельцу
     * @type {boolean}
     * @memberof ExternalServiceDeviceDto
     */
    'returnToOwner'?: boolean;
    /**
     * Отремонтировать до продажного состояния
     * @type {boolean}
     * @memberof ExternalServiceDeviceDto
     */
    'repairToSaleState'?: boolean;
    /**
     * Доукомлектовать до продажного состояния
     * @type {boolean}
     * @memberof ExternalServiceDeviceDto
     */
    'completeToSaleState'?: boolean;
    /**
     * Дефекты, обнаруженные сервисом
     * @type {string}
     * @memberof ExternalServiceDeviceDto
     */
    'defectsFoundByService'?: string;
    /**
     * Поврежден пользователем
     * @type {boolean}
     * @memberof ExternalServiceDeviceDto
     */
    'userFault'?: boolean;
    /**
     * Дата производства
     * @type {string}
     * @memberof ExternalServiceDeviceDto
     */
    'dataProiz'?: string | null;
    /**
     * Производитель
     * @type {number}
     * @memberof ExternalServiceDeviceDto
     */
    'proiz'?: number | null;
    /**
     * Гарантийность прибора
     * @type {boolean}
     * @memberof ExternalServiceDeviceDto
     */
    'warranty'?: boolean;
    /**
     * Перечень дефектов
     * @type {Array<ExternalServiceDefectDto>}
     * @memberof ExternalServiceDeviceDto
     */
    'defects'?: Array<ExternalServiceDefectDto>;
    /**
     * Перечень замененных запчастей
     * @type {Array<ExternalServiceReplacedPartDto>}
     * @memberof ExternalServiceDeviceDto
     */
    'replacedParts'?: Array<ExternalServiceReplacedPartDto>;
}
/**
 * 
 * @export
 * @interface ExternalServiceReplacedPartDto
 */
export interface ExternalServiceReplacedPartDto {
    /**
     * Код СЕ замененной запчасти
     * @type {string}
     * @memberof ExternalServiceReplacedPartDto
     */
    'seK'?: string;
    /**
     * Наименование запчасти русское
     * @type {string}
     * @memberof ExternalServiceReplacedPartDto
     */
    'nameR'?: string;
    /**
     * Наименование запчасти английское
     * @type {string}
     * @memberof ExternalServiceReplacedPartDto
     */
    'nameE'?: string;
    /**
     * Количество запчастей
     * @type {number}
     * @memberof ExternalServiceReplacedPartDto
     */
    'kol'?: number;
    /**
     * Стоимость запчасти
     * @type {number}
     * @memberof ExternalServiceReplacedPartDto
     */
    'cost'?: number;
}
/**
 * 
 * @export
 * @interface FixRepairAreaDevicePayload
 */
export interface FixRepairAreaDevicePayload {
    /**
     * Id компании
     * @type {number}
     * @memberof FixRepairAreaDevicePayload
     */
    'firmId'?: number;
    /**
     * Номер склада
     * @type {string}
     * @memberof FixRepairAreaDevicePayload
     */
    'skl'?: string;
    /**
     * Выбранные приборы
     * @type {Array<RepairAreaDevicePrimaryKeyDto>}
     * @memberof FixRepairAreaDevicePayload
     */
    'devices'?: Array<RepairAreaDevicePrimaryKeyDto>;
}
/**
 * 
 * @export
 * @interface GetExpiredRepairPlansResponse
 */
export interface GetExpiredRepairPlansResponse {
    /**
     * 
     * @type {number}
     * @memberof GetExpiredRepairPlansResponse
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetInvoiceCounterpartiesDto
 */
export interface GetInvoiceCounterpartiesDto {
    /**
     * Id компании
     * @type {number}
     * @memberof GetInvoiceCounterpartiesDto
     */
    'counterpartyId'?: number;
    /**
     * Название компании
     * @type {string}
     * @memberof GetInvoiceCounterpartiesDto
     */
    'name'?: string;
    /**
     * Ключ валюты
     * @type {number}
     * @memberof GetInvoiceCounterpartiesDto
     */
    'idVal1'?: number | null;
    /**
     * Количество счетов
     * @type {number}
     * @memberof GetInvoiceCounterpartiesDto
     */
    'invoicesCount'?: number;
}
/**
 * 
 * @export
 * @interface GetPaidRepairReportPayload
 */
export interface GetPaidRepairReportPayload {
    /**
     * Начало периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof GetPaidRepairReportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof GetPaidRepairReportPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof GetPaidRepairReportPayload
     */
    'firmId'?: number;
    /**
     * Язык
     * @type {number}
     * @memberof GetPaidRepairReportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface GetProductionDeviceDto
 */
export interface GetProductionDeviceDto {
    /**
     * 
     * @type {string}
     * @memberof GetProductionDeviceDto
     */
    'serial'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductionDeviceDto
     */
    'deviceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductionDeviceDto
     */
    'sek'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductionDeviceDto
     */
    'productionDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProductionDeviceDto
     */
    'productionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductionDeviceDto
     */
    'shipmentDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProductionDeviceDto
     */
    'buyerId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductionDeviceDto
     */
    'warranty'?: boolean;
}
/**
 * 
 * @export
 * @interface GetRepairAreaActOfTransferPayload
 */
export interface GetRepairAreaActOfTransferPayload {
    /**
     * Номер склада
     * @type {string}
     * @memberof GetRepairAreaActOfTransferPayload
     */
    'skl'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRepairAreaActOfTransferPayload
     */
    'lcid'?: number;
    /**
     * 
     * @type {Array<RepairAreaActOfTransferDeviceDto>}
     * @memberof GetRepairAreaActOfTransferPayload
     */
    'devices'?: Array<RepairAreaActOfTransferDeviceDto>;
}
/**
 * 
 * @export
 * @interface ImportDistributorsDataFromExcelResponse
 */
export interface ImportDistributorsDataFromExcelResponse {
    /**
     * Приборы, загруженные из файла
     * @type {Array<ExternalServiceDeviceDto>}
     * @memberof ImportDistributorsDataFromExcelResponse
     */
    'devices'?: Array<ExternalServiceDeviceDto>;
    /**
     * Ошибки, найденные в документе
     * @type {Array<ExcelRowError>}
     * @memberof ImportDistributorsDataFromExcelResponse
     */
    'errors'?: Array<ExcelRowError>;
}
/**
 * 
 * @export
 * @interface ImportExtendedWarrantyFromExcelResponse
 */
export interface ImportExtendedWarrantyFromExcelResponse {
    /**
     * Кол-во успешно добавленных гарантий
     * @type {number}
     * @memberof ImportExtendedWarrantyFromExcelResponse
     */
    'numberOfItemsAdded'?: number;
    /**
     * Кол-во успешно добавленных гарантий
     * @type {number}
     * @memberof ImportExtendedWarrantyFromExcelResponse
     */
    'numberOfExistingItems'?: number;
    /**
     * Ошибки найденные в документе
     * @type {Array<ExcelRowError>}
     * @memberof ImportExtendedWarrantyFromExcelResponse
     */
    'errors'?: Array<ExcelRowError>;
    /**
     * Загруженные серийные номера приборов
     * @type {Array<ExtendedWarrantyDeviceDto>}
     * @memberof ImportExtendedWarrantyFromExcelResponse
     */
    'devices'?: Array<ExtendedWarrantyDeviceDto>;
}
/**
 * 
 * @export
 * @interface ImportRmaRequestDevicesFromExcelResponse
 */
export interface ImportRmaRequestDevicesFromExcelResponse {
    /**
     * Кол-во успешно добавленных приборов
     * @type {number}
     * @memberof ImportRmaRequestDevicesFromExcelResponse
     */
    'numberOfDevicesAdded'?: number;
    /**
     * Ошибки найденные в документе
     * @type {Array<ExcelRowError>}
     * @memberof ImportRmaRequestDevicesFromExcelResponse
     */
    'errors'?: Array<ExcelRowError>;
}
/**
 * 
 * @export
 * @interface IncomeForecastsExportPayload
 */
export interface IncomeForecastsExportPayload {
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof IncomeForecastsExportPayload
     */
    'firmId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof IncomeForecastsExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface IncomeForecastsReportDto
 */
export interface IncomeForecastsReportDto {
    /**
     * 
     * @type {Array<IncomeForecastsReportItemDto>}
     * @memberof IncomeForecastsReportDto
     */
    'forecasts'?: Array<IncomeForecastsReportItemDto>;
    /**
     * 
     * @type {Array<IncomeForecastsWarehouseDto>}
     * @memberof IncomeForecastsReportDto
     */
    'warehouses'?: Array<IncomeForecastsWarehouseDto>;
    /**
     * 
     * @type {Array<IncomeForecastsTransitDeviceDto>}
     * @memberof IncomeForecastsReportDto
     */
    'transitDevices'?: Array<IncomeForecastsTransitDeviceDto>;
    /**
     * 
     * @type {Array<IncomeForecastsUnplannedDeviceDto>}
     * @memberof IncomeForecastsReportDto
     */
    'unplannedDevices'?: Array<IncomeForecastsUnplannedDeviceDto>;
}
/**
 * 
 * @export
 * @interface IncomeForecastsReportItemDto
 */
export interface IncomeForecastsReportItemDto {
    /**
     * 
     * @type {number}
     * @memberof IncomeForecastsReportItemDto
     */
    'lotGreater30Qty'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeForecastsReportItemDto
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsReportItemDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsReportItemDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeForecastsReportItemDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsReportItemDto
     */
    'pr'?: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeForecastsReportItemDto
     */
    'normHours'?: number | null;
}
/**
 * 
 * @export
 * @interface IncomeForecastsTransitDeviceDto
 */
export interface IncomeForecastsTransitDeviceDto {
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsTransitDeviceDto
     */
    'docDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsTransitDeviceDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsTransitDeviceDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsTransitDeviceDto
     */
    'counterpartyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeForecastsTransitDeviceDto
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsTransitDeviceDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsTransitDeviceDto
     */
    'nameRu'?: string;
}
/**
 * 
 * @export
 * @interface IncomeForecastsUnplannedDeviceDto
 */
export interface IncomeForecastsUnplannedDeviceDto {
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsUnplannedDeviceDto
     */
    'recvDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsUnplannedDeviceDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsUnplannedDeviceDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsUnplannedDeviceDto
     */
    'counterpartyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeForecastsUnplannedDeviceDto
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsUnplannedDeviceDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsUnplannedDeviceDto
     */
    'nameRu'?: string;
}
/**
 * 
 * @export
 * @interface IncomeForecastsWarehouseDto
 */
export interface IncomeForecastsWarehouseDto {
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsWarehouseDto
     */
    'skl'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsWarehouseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsWarehouseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeForecastsWarehouseDto
     */
    'us'?: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeForecastsWarehouseDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface InformerNewsRecordUpdatePayload
 */
export interface InformerNewsRecordUpdatePayload {
    /**
     * 
     * @type {string}
     * @memberof InformerNewsRecordUpdatePayload
     */
    'startAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformerNewsRecordUpdatePayload
     */
    'expireAt'?: string;
}
/**
 * 
 * @export
 * @interface InvoiceAddDeviceDto
 */
export interface InvoiceAddDeviceDto {
    /**
     * Производственный код
     * @type {string}
     * @memberof InvoiceAddDeviceDto
     */
    'se'?: string;
    /**
     * Кол-во приборов
     * @type {number}
     * @memberof InvoiceAddDeviceDto
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface InvoiceBrowseDto
 */
export interface InvoiceBrowseDto {
    /**
     * Id компании
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'firmId'?: number;
    /**
     * Id компании
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'counterpartyId'?: number;
    /**
     * Название компании
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'counterpartyName'?: string;
    /**
     * Id документа
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'invoiceId'?: number;
    /**
     * Id документа из которого был создан (Rma)
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'parentId'?: number | null;
    /**
     * Id компании документа из которого был создан (Rma)
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'parentFirmId'?: number | null;
    /**
     * Номер документа
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'documentNumber'?: string;
    /**
     * Код документа
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'documentCode'?: string;
    /**
     * Количество всех приборов в Счете
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'itemsTotalCount'?: number;
    /**
     * Количество подтвержденных приборов в Счете
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'itemsConfirmedCount'?: number;
    /**
     * Дата документа
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'documentDate'?: string;
    /**
     * Дата поступления
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'receiptDate'?: string | null;
    /**
     * Номер распроряжения
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'raspNumber'?: string | null;
    /**
     * Дата распроряжения
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'raspDate'?: string | null;
    /**
     * Номер распроряжения на Upgrade прибора
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'raspNumberUpgradable'?: string | null;
    /**
     * Дата распроряжения на Upgrade прибора
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'raspDateUpgradable'?: string | null;
    /**
     * Возврат от покупателя
     * @type {boolean}
     * @memberof InvoiceBrowseDto
     */
    'isReturned'?: boolean;
    /**
     * Статус
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'status'?: string;
    /**
     * Признак отгрузки
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'shipmentFlag'?: number;
    /**
     * Страна происхождения документа
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'countryOrigin'?: string | null;
    /**
     * Импортная декларация
     * @type {string}
     * @memberof InvoiceBrowseDto
     */
    'custDecl'?: string | null;
    /**
     * Валюта документа
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'docVal'?: number;
    /**
     * Общая стоимость ремонта
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'paidRepair'?: number;
    /**
     * Стоимость упаковки
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'packageCost'?: number;
    /**
     * Валюта стоимости упаковки
     * @type {number}
     * @memberof InvoiceBrowseDto
     */
    'packageCurrency'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceConfirmResult = {
    Success: 'Success',
    LicenseClosed: 'LicenseClosed',
    ReusingLicenses: 'ReusingLicenses',
    NotEnoughLicenses: 'NotEnoughLicenses'
} as const;

export type InvoiceConfirmResult = typeof InvoiceConfirmResult[keyof typeof InvoiceConfirmResult];


/**
 * 
 * @export
 * @interface InvoiceCountryOriginDto
 */
export interface InvoiceCountryOriginDto {
    /**
     * Код страны
     * @type {string}
     * @memberof InvoiceCountryOriginDto
     */
    'kod'?: string;
    /**
     * Наименование страны русское
     * @type {string}
     * @memberof InvoiceCountryOriginDto
     */
    'nameR'?: string;
    /**
     * Наименование страны английское
     * @type {string}
     * @memberof InvoiceCountryOriginDto
     */
    'nameE'?: string;
}
/**
 * 
 * @export
 * @interface InvoiceDeviceBrowseDto
 */
export interface InvoiceDeviceBrowseDto {
    /**
     * Id компании
     * @type {number}
     * @memberof InvoiceDeviceBrowseDto
     */
    'firmId'?: number;
    /**
     * Id прибора
     * @type {number}
     * @memberof InvoiceDeviceBrowseDto
     */
    'deviceId'?: number;
    /**
     * Номер лицензии
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'nLic'?: string | null;
    /**
     * Дата окончания лицензии
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'dataOkon'?: string | null;
    /**
     * Номер строки
     * @type {number}
     * @memberof InvoiceDeviceBrowseDto
     */
    'idStr'?: number | null;
    /**
     * Производственный код
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'sek'?: string;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'sku'?: string | null;
    /**
     * Название прибора на английском
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'nameEn'?: string;
    /**
     * Название прибора на русском
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'nameRu'?: string;
    /**
     * Серийный номер (Новый)
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'nomer'?: string;
    /**
     * Серийный номер (Старый)
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'nomerOld'?: string;
    /**
     * Дата продажи нами Дистрибьютору
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'dataProd'?: string | null;
    /**
     * Дата производства
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'dataProiz'?: string | null;
    /**
     * Признак гарантийности прибора
     * @type {boolean}
     * @memberof InvoiceDeviceBrowseDto
     */
    'warranty'?: boolean;
    /**
     * Вернуть владельцу
     * @type {boolean}
     * @memberof InvoiceDeviceBrowseDto
     */
    'wait'?: boolean;
    /**
     * Жалобы
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'complaints'?: string | null;
    /**
     * Примечание
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'notes'?: string | null;
    /**
     * Стоимость ремонта
     * @type {number}
     * @memberof InvoiceDeviceBrowseDto
     */
    'stoimRem'?: number;
    /**
     * Комплектность (что отсутствует)
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'komplekt'?: string | null;
    /**
     * Ключ валюты по прибору
     * @type {number}
     * @memberof InvoiceDeviceBrowseDto
     */
    'val'?: number;
    /**
     * Обозначение валюты по прибору
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'obozn'?: string;
    /**
     * Признак ремонтопригодности
     * @type {number}
     * @memberof InvoiceDeviceBrowseDto
     */
    'prRem'?: number;
    /**
     * Признак лицензии
     * @type {boolean}
     * @memberof InvoiceDeviceBrowseDto
     */
    'prLic'?: boolean;
    /**
     * Цена поступления
     * @type {number}
     * @memberof InvoiceDeviceBrowseDto
     */
    'cena'?: number;
    /**
     * получен-неполучен
     * @type {boolean}
     * @memberof InvoiceDeviceBrowseDto
     */
    'chk'?: boolean | null;
    /**
     * Номер коробки
     * @type {number}
     * @memberof InvoiceDeviceBrowseDto
     */
    'boxNumber'?: number;
    /**
     * Страна происхождения документа
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'countryOrigin'?: string | null;
    /**
     * Есть техническое заключение
     * @type {boolean}
     * @memberof InvoiceDeviceBrowseDto
     */
    'tz'?: boolean;
    /**
     * Дата сдачи прибора Владельцем в ремонт Дистрибьютору
     * @type {string}
     * @memberof InvoiceDeviceBrowseDto
     */
    'dataReceiptForRepair'?: string | null;
}
/**
 * 
 * @export
 * @interface InvoiceIncomingNumberDto
 */
export interface InvoiceIncomingNumberDto {
    /**
     * Id документа
     * @type {number}
     * @memberof InvoiceIncomingNumberDto
     */
    'documentId'?: number;
    /**
     * Id компании
     * @type {number}
     * @memberof InvoiceIncomingNumberDto
     */
    'firmId'?: number;
    /**
     * Номер документа
     * @type {string}
     * @memberof InvoiceIncomingNumberDto
     */
    'documentNumber'?: string;
    /**
     * Дата документа
     * @type {string}
     * @memberof InvoiceIncomingNumberDto
     */
    'documentDate'?: string | null;
}
/**
 * 
 * @export
 * @interface InvoicePrintDataDto
 */
export interface InvoicePrintDataDto {
    /**
     * Ид Документа
     * @type {number}
     * @memberof InvoicePrintDataDto
     */
    'invoiceId'?: number;
    /**
     * Ид фирмы документа
     * @type {number}
     * @memberof InvoicePrintDataDto
     */
    'firmId'?: number;
    /**
     * Ид контрагента
     * @type {number}
     * @memberof InvoicePrintDataDto
     */
    'counterpartyId'?: number;
    /**
     * Наименование документа в зависимости от фирмы
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'documentName'?: string;
    /**
     * Перечень Лицензий в Инвойсе
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'licenses'?: string;
    /**
     * Количество мест (коробок)
     * @type {number}
     * @memberof InvoicePrintDataDto
     */
    'boxes'?: number;
    /**
     * Вес
     * @type {number}
     * @memberof InvoicePrintDataDto
     */
    'weight'?: number;
    /**
     * Условия доставки
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'deliveryTerms'?: string;
    /**
     * Примечание 1
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'notes1'?: string;
    /**
     * Примечание 2
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'notes2'?: string;
    /**
     * Перевозчик
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'carrier'?: string;
    /**
     * Транспортный документ
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'carrierDocument'?: string;
    /**
     * Стоимость доставки
     * @type {number}
     * @memberof InvoicePrintDataDto
     */
    'carrierCost'?: number;
    /**
     * Валюта доставки
     * @type {number}
     * @memberof InvoicePrintDataDto
     */
    'carrierCurrency'?: number;
    /**
     * Получатель и Плательщик это одно лицо
     * @type {boolean}
     * @memberof InvoicePrintDataDto
     */
    'recipientIsPurchaser'?: boolean;
    /**
     * Покупатель
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'purchaser'?: string;
    /**
     * Адрес покупателя 1
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'purchaserAddress1'?: string;
    /**
     * Адрес покупателя 2
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'purchaserAddress2'?: string;
    /**
     * Телефон покупателя
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'purchaserPhone'?: string;
    /**
     * Факс покупателя
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'purchaserFax'?: string;
    /**
     * VAT покупателя
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'purchaserVat'?: string;
    /**
     * EORI покупателя
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'purchaserEori'?: string;
    /**
     * Получатель
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'recipient'?: string;
    /**
     * Адрес получателя 1
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'recipientAddress1'?: string;
    /**
     * Адрес получателя 2
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'recipientAddress2'?: string;
    /**
     * Телефон получателя
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'recipientPhone'?: string;
    /**
     * Факс получателя
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'recipientFax'?: string;
    /**
     * VAT получателя
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'recipientVat'?: string;
    /**
     * EORI получателя
     * @type {string}
     * @memberof InvoicePrintDataDto
     */
    'recipientEori'?: string;
    /**
     * VAT для Инвойса
     * @type {number}
     * @memberof InvoicePrintDataDto
     */
    'vat'?: number;
    /**
     * Информация о связанных входящих инвойсах
     * @type {Array<InvoiceIncomingNumberDto>}
     * @memberof InvoicePrintDataDto
     */
    'incomingInvoices'?: Array<InvoiceIncomingNumberDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceRejectResult = {
    Success: 'Success'
} as const;

export type InvoiceRejectResult = typeof InvoiceRejectResult[keyof typeof InvoiceRejectResult];


/**
 * 
 * @export
 * @interface LicensesUsedDeviceReportDto
 */
export interface LicensesUsedDeviceReportDto {
    /**
     * 
     * @type {number}
     * @memberof LicensesUsedDeviceReportDto
     */
    'licenseId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LicensesUsedDeviceReportDto
     */
    'idSe'?: number;
    /**
     * 
     * @type {string}
     * @memberof LicensesUsedDeviceReportDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicensesUsedDeviceReportDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof LicensesUsedDeviceReportDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof LicensesUsedDeviceReportDto
     */
    'cle'?: string;
}
/**
 * 
 * @export
 * @interface LicensesUsedFirmReportDto
 */
export interface LicensesUsedFirmReportDto {
    /**
     * 
     * @type {number}
     * @memberof LicensesUsedFirmReportDto
     */
    'counterpartyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LicensesUsedFirmReportDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicensesUsedFirmReportDto
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface LicensesUsedLicenseReportDto
 */
export interface LicensesUsedLicenseReportDto {
    /**
     * 
     * @type {number}
     * @memberof LicensesUsedLicenseReportDto
     */
    'counterpartyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LicensesUsedLicenseReportDto
     */
    'licenseId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LicensesUsedLicenseReportDto
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicensesUsedLicenseReportDto
     */
    'licenseDate'?: string;
}
/**
 * 
 * @export
 * @interface LicesesUsedExportPayload
 */
export interface LicesesUsedExportPayload {
    /**
     * 
     * @type {string}
     * @memberof LicesesUsedExportPayload
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicesesUsedExportPayload
     */
    'periodEnd'?: string;
}
/**
 * 
 * @export
 * @interface LicesesUsedReportDto
 */
export interface LicesesUsedReportDto {
    /**
     * 
     * @type {Array<LicensesUsedFirmReportDto>}
     * @memberof LicesesUsedReportDto
     */
    'firms'?: Array<LicensesUsedFirmReportDto>;
    /**
     * 
     * @type {Array<LicensesUsedLicenseReportDto>}
     * @memberof LicesesUsedReportDto
     */
    'licenses'?: Array<LicensesUsedLicenseReportDto>;
    /**
     * 
     * @type {Array<LicensesUsedDeviceReportDto>}
     * @memberof LicesesUsedReportDto
     */
    'devices'?: Array<LicensesUsedDeviceReportDto>;
}
/**
 * 
 * @export
 * @interface ListOfComplaintsExternalReportExportPayload
 */
export interface ListOfComplaintsExternalReportExportPayload {
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportExportPayload
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportExportPayload
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsExternalReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ListOfComplaintsExternalReportItemDto
 */
export interface ListOfComplaintsExternalReportItemDto {
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'npp'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'devId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'kodGrp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'seK1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'sku1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'grp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'sek'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'complaints'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'firmRep'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'firmName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'nomer'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'newNomer'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'konId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'k1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'k2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'k3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'def'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'rep'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'nameVin'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'tn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'fio'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'primec'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'dateProd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'nProd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'dateProv'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'proiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'rab'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsExternalReportItemDto
     */
    'kon'?: string;
}
/**
 * 
 * @export
 * @interface ListOfComplaintsReportCounterpartiesDto
 */
export interface ListOfComplaintsReportCounterpartiesDto {
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsReportCounterpartiesDto
     */
    'counterpartyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportCounterpartiesDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListOfComplaintsReportExportPayload
 */
export interface ListOfComplaintsReportExportPayload {
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportExportPayload
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportExportPayload
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsReportExportPayload
     */
    'firmId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsReportExportPayload
     */
    'counterpartyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ListOfComplaintsReportItemDto
 */
export interface ListOfComplaintsReportItemDto {
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsReportItemDto
     */
    'npp'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsReportItemDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsReportItemDto
     */
    'devId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'kodGrp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'seK1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'sku1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'grp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'sek'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'complaints'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsReportItemDto
     */
    'firmRep'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'firmName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'nomer'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'newNomer'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListOfComplaintsReportItemDto
     */
    'konId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'k1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'k2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'k3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'def'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'rep'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'nameVin'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'tn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'fio'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'primec'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'dateProd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'nProd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'dateProv'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'proiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'rab'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListOfComplaintsReportItemDto
     */
    'kon'?: string;
}
/**
 * 
 * @export
 * @interface LockedDeviceBrowseDto
 */
export interface LockedDeviceBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof LockedDeviceBrowseDto
     */
    'lockId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LockedDeviceBrowseDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LockedDeviceBrowseDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LockedDeviceBrowseDto
     */
    'tn'?: string;
    /**
     * 
     * @type {string}
     * @memberof LockedDeviceBrowseDto
     */
    'fio'?: string;
}
/**
 * 
 * @export
 * @interface MaintainabilityDeviceDto
 */
export interface MaintainabilityDeviceDto {
    /**
     * Ключ прибора
     * @type {number}
     * @memberof MaintainabilityDeviceDto
     */
    'idSe'?: number;
    /**
     * Производственный код
     * @type {string}
     * @memberof MaintainabilityDeviceDto
     */
    'se'?: string;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof MaintainabilityDeviceDto
     */
    'sku'?: string;
    /**
     * Наименование на RU
     * @type {string}
     * @memberof MaintainabilityDeviceDto
     */
    'nameR'?: string;
    /**
     * Наименование на EN
     * @type {string}
     * @memberof MaintainabilityDeviceDto
     */
    'nameE'?: string;
    /**
     * Ключ ремонтопригодности
     * @type {number}
     * @memberof MaintainabilityDeviceDto
     */
    'repairId'?: number;
}
/**
 * 
 * @export
 * @interface MaintainabilityRepairDto
 */
export interface MaintainabilityRepairDto {
    /**
     * Ключ ремонтопригодности
     * @type {number}
     * @memberof MaintainabilityRepairDto
     */
    'id'?: number;
    /**
     * Наименование
     * @type {string}
     * @memberof MaintainabilityRepairDto
     */
    'repair'?: string;
}
/**
 * 
 * @export
 * @interface MaintenanceConfirmReceiptDeviceDto
 */
export interface MaintenanceConfirmReceiptDeviceDto {
    /**
     * Ключ прибора
     * @type {number}
     * @memberof MaintenanceConfirmReceiptDeviceDto
     */
    'deviceId'?: number;
    /**
     * Ключ компании
     * @type {number}
     * @memberof MaintenanceConfirmReceiptDeviceDto
     */
    'firmId'?: number;
    /**
     * док №
     * @type {string}
     * @memberof MaintenanceConfirmReceiptDeviceDto
     */
    'invoice'?: string;
    /**
     * наименование контрагента
     * @type {string}
     * @memberof MaintenanceConfirmReceiptDeviceDto
     */
    'kon'?: string;
    /**
     * Признак перемещения
     * @type {boolean}
     * @memberof MaintenanceConfirmReceiptDeviceDto
     */
    'transit'?: boolean;
}
/**
 * 
 * @export
 * @interface MaintenanceDeviceDto
 */
export interface MaintenanceDeviceDto {
    /**
     * Ключ прибора
     * @type {number}
     * @memberof MaintenanceDeviceDto
     */
    'deviceId'?: number;
    /**
     * Ключ компании
     * @type {number}
     * @memberof MaintenanceDeviceDto
     */
    'firmId'?: number;
    /**
     * буквенное обозначение компании
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'firm'?: string;
    /**
     * дата получения
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'dateRecv'?: string;
    /**
     * дата прихода по предприятию
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'dateIn'?: string;
    /**
     * Дата сдачи прибора Владельцем в ремонт Дистрибьютору
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'dataReceiptForRepair'?: string | null;
    /**
     * ключ контрагента
     * @type {number}
     * @memberof MaintenanceDeviceDto
     */
    'konId'?: number;
    /**
     * наименование контрагента
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'kon'?: string;
    /**
     * Номер разрешения (Rma)
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'rma'?: string;
    /**
     * Номер счета (Invoice)
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'invoice'?: string;
    /**
     * серийный №
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'serial'?: string;
    /**
     * серийный № (новый)
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'newSerial'?: string;
    /**
     * наименование прибора (RU)
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'seRu'?: string;
    /**
     * наименование прибора (EN)
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'seEn'?: string;
    /**
     * операция замены серийного номера
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'oper'?: string;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'sku'?: string;
    /**
     * Производственный код
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'sek'?: string;
    /**
     * 0 - исправный прибор, 1 - неисправный прибор
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'soPr'?: string;
    /**
     * признак перемещения
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'transit'?: boolean;
    /**
     * подготовлен к отправке (1 - да, 0-нет)
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'po'?: boolean;
    /**
     * возврат покупателю (ВВ)
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'returnToOwner'?: boolean;
    /**
     * Признак гарантийности прибора
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'warranty'?: boolean;
    /**
     * вернуть без ремонта
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'vozvrBezRem'?: boolean;
    /**
     * дефект подтвержден (ДП)
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'defectConfirmed'?: boolean;
    /**
     * кол-во дефектов
     * @type {number}
     * @memberof MaintenanceDeviceDto
     */
    'qtyDef'?: number;
    /**
     * согласие на платный ремонт
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'platRem'?: boolean | null;
    /**
     * стоимость ремонта
     * @type {number}
     * @memberof MaintenanceDeviceDto
     */
    'stoimRem'?: number;
    /**
     * Признак предварительной стоимости ремонта
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'preliminaryCost'?: boolean;
    /**
     * обозначение валюты ремонта
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'valRem'?: string;
    /**
     * примечание
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'notes'?: string | null;
    /**
     * код склада
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'skl'?: string;
    /**
     * наименование склада
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'sklName'?: string;
    /**
     * Предварительный контроль
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'bcc'?: boolean;
    /**
     * Повтроный контроль назначен
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'rcc'?: boolean;
    /**
     * Быстрый ремонт
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'speedRepair'?: boolean;
    /**
     * 0 - пришел по RMA, 1 - пришел по инвойсу
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'notRma'?: boolean;
    /**
     * Есть техническое заключение
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'tz'?: boolean;
    /**
     * 1 - счет не согласован, 0 - счет согласован, null - стоимость ремонта отсутствует
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'lostMoney'?: boolean | null;
    /**
     * Ключ Rma документа
     * @type {number}
     * @memberof MaintenanceDeviceDto
     */
    'rmaDocId'?: number | null;
    /**
     * Ключ Rma фирмы
     * @type {number}
     * @memberof MaintenanceDeviceDto
     */
    'rmaFirmId'?: number | null;
    /**
     * Статус Rma документа
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'rmaStatus'?: string | null;
    /**
     * Статус планирования
     * @type {number}
     * @memberof MaintenanceDeviceDto
     */
    'planStatus'?: number | null;
    /**
     * Запланированный участок ремонта
     * @type {string}
     * @memberof MaintenanceDeviceDto
     */
    'plannedRepairArea'?: string;
    /**
     * Ремонт без стоимости
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'repairWithoutCost'?: boolean;
    /**
     * Замена на новый прибор
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'replacementForNew'?: boolean;
    /**
     * Запросить уточнение
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'requestConfirmation'?: boolean;
    /**
     * Признак прибора для Модернизации
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'upgradable'?: boolean;
    /**
     * Прибор упакован
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'isPacked'?: boolean;
    /**
     * Может быть отгружен дистрибьютору
     * @type {boolean}
     * @memberof MaintenanceDeviceDto
     */
    'canBeShipped'?: boolean;
}
/**
 * 
 * @export
 * @interface MaintenanceDeviceSelectedDto
 */
export interface MaintenanceDeviceSelectedDto {
    /**
     * Ид прибора
     * @type {number}
     * @memberof MaintenanceDeviceSelectedDto
     */
    'deviceId'?: number;
    /**
     * Ид фирмы прибора
     * @type {number}
     * @memberof MaintenanceDeviceSelectedDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MaintenanceDeviceStatus = {
    All: 'All',
    InTransit: 'InTransit',
    ReadyForShipment: 'ReadyForShipment',
    Repaired: 'Repaired',
    Damaged: 'Damaged',
    ToPlan: 'ToPlan',
    ToPlanUpgrade: 'ToPlanUpgrade',
    SecondaryControl: 'SecondaryControl',
    Packed: 'Packed'
} as const;

export type MaintenanceDeviceStatus = typeof MaintenanceDeviceStatus[keyof typeof MaintenanceDeviceStatus];


/**
 * 
 * @export
 * @interface MaintenanceDisposalDeviceDto
 */
export interface MaintenanceDisposalDeviceDto {
    /**
     * Ключ прибора
     * @type {number}
     * @memberof MaintenanceDisposalDeviceDto
     */
    'deviceId'?: number;
    /**
     * Ключ компании
     * @type {number}
     * @memberof MaintenanceDisposalDeviceDto
     */
    'firmId'?: number;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof MaintenanceDisposalDeviceDto
     */
    'sku'?: string;
    /**
     * Производственный код
     * @type {string}
     * @memberof MaintenanceDisposalDeviceDto
     */
    'sek'?: string;
    /**
     * наименование прибора (RU)
     * @type {string}
     * @memberof MaintenanceDisposalDeviceDto
     */
    'seRu'?: string;
    /**
     * наименование прибора (EN)
     * @type {string}
     * @memberof MaintenanceDisposalDeviceDto
     */
    'seEn'?: string;
    /**
     * серийный №
     * @type {string}
     * @memberof MaintenanceDisposalDeviceDto
     */
    'serial'?: string;
    /**
     * примечание
     * @type {string}
     * @memberof MaintenanceDisposalDeviceDto
     */
    'notes'?: string | null;
    /**
     * наименование контрагента
     * @type {string}
     * @memberof MaintenanceDisposalDeviceDto
     */
    'kon'?: string;
}
/**
 * 
 * @export
 * @interface MaintenanceOutgoingInvoiceDto
 */
export interface MaintenanceOutgoingInvoiceDto {
    /**
     * Id фирмы
     * @type {number}
     * @memberof MaintenanceOutgoingInvoiceDto
     */
    'firmId'?: number;
    /**
     * Id документа
     * @type {number}
     * @memberof MaintenanceOutgoingInvoiceDto
     */
    'documentId'?: number;
    /**
     * Номер документа
     * @type {string}
     * @memberof MaintenanceOutgoingInvoiceDto
     */
    'documentNumber'?: string;
    /**
     * Дата документа
     * @type {string}
     * @memberof MaintenanceOutgoingInvoiceDto
     */
    'documentDate'?: string | null;
    /**
     * Отгружать согласно RMA
     * @type {boolean}
     * @memberof MaintenanceOutgoingInvoiceDto
     */
    'shipAsRma'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MaintenanceView = {
    All: 'All',
    ApprovalRequired: 'ApprovalRequired',
    ExpiredDevices: 'ExpiredDevices',
    DefectsNotEntered: 'DefectsNotEntered',
    NotAWarranty: 'NotAWarranty',
    RepairCostConfirmed: 'RepairCostConfirmed',
    SendToRepairRequired: 'SendToRepairRequired',
    ReplacementForNew: 'ReplacementForNew',
    RequestConfirmation: 'RequestConfirmation'
} as const;

export type MaintenanceView = typeof MaintenanceView[keyof typeof MaintenanceView];


/**
 * 
 * @export
 * @interface NewsFileBrowseResponse
 */
export interface NewsFileBrowseResponse {
    /**
     * 
     * @type {number}
     * @memberof NewsFileBrowseResponse
     */
    'newsId'?: number;
    /**
     * 
     * @type {NewsType}
     * @memberof NewsFileBrowseResponse
     */
    'newsType'?: NewsType;
    /**
     * 
     * @type {string}
     * @memberof NewsFileBrowseResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsFileBrowseResponse
     */
    'startAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsFileBrowseResponse
     */
    'expireAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsFileBrowseResponse
     */
    'fileUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsFileBrowseResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsFileBrowseResponse
     */
    'createdBy'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NewsType = {
    News: 'News',
    FunFact: 'FunFact',
    Birthday: 'Birthday',
    Advertisement: 'Advertisement'
} as const;

export type NewsType = typeof NewsType[keyof typeof NewsType];


/**
 * 
 * @export
 * @interface NotShippedDevicesReportDeviceTotalDto
 */
export interface NotShippedDevicesReportDeviceTotalDto {
    /**
     * 
     * @type {string}
     * @memberof NotShippedDevicesReportDeviceTotalDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotShippedDevicesReportDeviceTotalDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotShippedDevicesReportDeviceTotalDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotShippedDevicesReportDeviceTotalDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotShippedDevicesReportDeviceTotalDto
     */
    'kol'?: number;
}
/**
 * 
 * @export
 * @interface NotShippedDevicesReportDto
 */
export interface NotShippedDevicesReportDto {
    /**
     * 
     * @type {Array<NotShippedDevicesReportItemDto>}
     * @memberof NotShippedDevicesReportDto
     */
    'devicesByCounterparty'?: Array<NotShippedDevicesReportItemDto>;
    /**
     * 
     * @type {Array<NotShippedDevicesReportDeviceTotalDto>}
     * @memberof NotShippedDevicesReportDto
     */
    'devices'?: Array<NotShippedDevicesReportDeviceTotalDto>;
}
/**
 * 
 * @export
 * @interface NotShippedDevicesReportExportPayload
 */
export interface NotShippedDevicesReportExportPayload {
    /**
     * 
     * @type {number}
     * @memberof NotShippedDevicesReportExportPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotShippedDevicesReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface NotShippedDevicesReportItemDto
 */
export interface NotShippedDevicesReportItemDto {
    /**
     * 
     * @type {string}
     * @memberof NotShippedDevicesReportItemDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotShippedDevicesReportItemDto
     */
    'counterpartyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotShippedDevicesReportItemDto
     */
    'recvDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotShippedDevicesReportItemDto
     */
    'kol'?: number;
}
/**
 * 
 * @export
 * @interface NotificationBrowseDto
 */
export interface NotificationBrowseDto {
    /**
     * Id уведомления
     * @type {number}
     * @memberof NotificationBrowseDto
     */
    'id'?: number;
    /**
     * Наименование уведомления русское
     * @type {string}
     * @memberof NotificationBrowseDto
     */
    'nameRu'?: string;
    /**
     * Наименование уведомление английское
     * @type {string}
     * @memberof NotificationBrowseDto
     */
    'nameEn'?: string;
    /**
     * Табельный номер сотрудника
     * @type {string}
     * @memberof NotificationBrowseDto
     */
    'tn'?: string;
    /**
     * Имя сотрудника
     * @type {string}
     * @memberof NotificationBrowseDto
     */
    'firstName'?: string;
    /**
     * Фамилия сотрудника
     * @type {string}
     * @memberof NotificationBrowseDto
     */
    'lastName'?: string;
    /**
     * Получать от предприятия Pulsar
     * @type {boolean}
     * @memberof NotificationBrowseDto
     */
    'p'?: boolean;
    /**
     * Получать от предприятия Yukon
     * @type {boolean}
     * @memberof NotificationBrowseDto
     */
    'y'?: boolean;
    /**
     * Получать от предприятия Polaris
     * @type {boolean}
     * @memberof NotificationBrowseDto
     */
    'l'?: boolean;
    /**
     * Признак удаленного пользователя
     * @type {boolean}
     * @memberof NotificationBrowseDto
     */
    'isUserDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * Код уведомления
     * @type {number}
     * @memberof NotificationDto
     */
    'id'?: number;
    /**
     * Наименование уведомления русское
     * @type {string}
     * @memberof NotificationDto
     */
    'nameRu'?: string;
    /**
     * Наименование уведомления английское
     * @type {string}
     * @memberof NotificationDto
     */
    'nameEn'?: string;
}
/**
 * 
 * @export
 * @interface NotificationUserDto
 */
export interface NotificationUserDto {
    /**
     * Табельный номер сотрудника
     * @type {string}
     * @memberof NotificationUserDto
     */
    'tn'?: string;
    /**
     * Имя сотрудника
     * @type {string}
     * @memberof NotificationUserDto
     */
    'firstName'?: string;
    /**
     * Фамилия слтрудника
     * @type {string}
     * @memberof NotificationUserDto
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderByDirection = {
    Ignore: 'Ignore',
    Ascending: 'Ascending',
    Descending: 'Descending'
} as const;

export type OrderByDirection = typeof OrderByDirection[keyof typeof OrderByDirection];


/**
 * 
 * @export
 * @interface OutgoingInvoiceAddDeviceDto
 */
export interface OutgoingInvoiceAddDeviceDto {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof OutgoingInvoiceAddDeviceDto
     */
    'firmId'?: number;
    /**
     * Ид прибора
     * @type {number}
     * @memberof OutgoingInvoiceAddDeviceDto
     */
    'deviceId'?: number;
    /**
     * Ид прибора Rma
     * @type {number}
     * @memberof OutgoingInvoiceAddDeviceDto
     */
    'parentId'?: number | null;
    /**
     * Ид контрагента
     * @type {number}
     * @memberof OutgoingInvoiceAddDeviceDto
     */
    'counterpartyId'?: number;
    /**
     * Признак отремонтированного прибора
     * @type {boolean}
     * @memberof OutgoingInvoiceAddDeviceDto
     */
    'repairStatus'?: boolean;
    /**
     * Признак подтверждения платного ремонта
     * @type {boolean}
     * @memberof OutgoingInvoiceAddDeviceDto
     */
    'hasLostMoney'?: boolean;
}
/**
 * 
 * @export
 * @interface PackagingInformationUpdatePayload
 */
export interface PackagingInformationUpdatePayload {
    /**
     * 
     * @type {boolean}
     * @memberof PackagingInformationUpdatePayload
     */
    'checkEquipment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackagingInformationUpdatePayload
     */
    'checkBatteries'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackagingInformationUpdatePayload
     */
    'checkComments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackagingInformationUpdatePayload
     */
    'checkPackedAsNew'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaperSizeType = {
    A4: 'A4',
    A5: 'A5'
} as const;

export type PaperSizeType = typeof PaperSizeType[keyof typeof PaperSizeType];


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductionDeviceBrowseDto
 */
export interface ProductionDeviceBrowseDto {
    /**
     * 
     * @type {string}
     * @memberof ProductionDeviceBrowseDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionDeviceBrowseDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionDeviceBrowseDto
     */
    'nameR'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionDeviceBrowseDto
     */
    'nameE'?: string;
}
/**
 * 
 * @export
 * @interface ReceiveRmaPayload
 */
export interface ReceiveRmaPayload {
    /**
     * 
     * @type {boolean}
     * @memberof ReceiveRmaPayload
     */
    'shipmentHasRma'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceiveRmaPayload
     */
    'shipmentHasInvoice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceiveRmaPayload
     */
    'invoiceHasErrors'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceiveRmaPayload
     */
    'packageHasGoodQuality'?: boolean;
}
/**
 * 
 * @export
 * @interface RejectInvoiceDevicesPayload
 */
export interface RejectInvoiceDevicesPayload {
    /**
     * Ключи приборов в счете
     * @type {Array<number>}
     * @memberof RejectInvoiceDevicesPayload
     */
    'deviceIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface RejectInvoiceDevicesResponse
 */
export interface RejectInvoiceDevicesResponse {
    /**
     * 
     * @type {InvoiceRejectResult}
     * @memberof RejectInvoiceDevicesResponse
     */
    'result'?: InvoiceRejectResult;
}


/**
 * 
 * @export
 * @interface RejectInvoiceResponse
 */
export interface RejectInvoiceResponse {
    /**
     * 
     * @type {InvoiceRejectResult}
     * @memberof RejectInvoiceResponse
     */
    'result'?: InvoiceRejectResult;
}


/**
 * 
 * @export
 * @interface RepairAreaActOfTransferDeviceDto
 */
export interface RepairAreaActOfTransferDeviceDto {
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof RepairAreaActOfTransferDeviceDto
     */
    'sku'?: string | null;
    /**
     * Название прибора на английском
     * @type {string}
     * @memberof RepairAreaActOfTransferDeviceDto
     */
    'nameEn'?: string;
    /**
     * Название прибора на русском
     * @type {string}
     * @memberof RepairAreaActOfTransferDeviceDto
     */
    'nameRu'?: string;
    /**
     * Серийный номер
     * @type {string}
     * @memberof RepairAreaActOfTransferDeviceDto
     */
    'nomer'?: string;
}
/**
 * 
 * @export
 * @interface RepairAreaDeviceDto
 */
export interface RepairAreaDeviceDto {
    /**
     * Id устройства
     * @type {number}
     * @memberof RepairAreaDeviceDto
     */
    'deviceId'?: number;
    /**
     * Id фирмы
     * @type {number}
     * @memberof RepairAreaDeviceDto
     */
    'firmId'?: number;
    /**
     * Владелец
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'b'?: string | null;
    /**
     * Id дистрибьютора
     * @type {number}
     * @memberof RepairAreaDeviceDto
     */
    'konId'?: number;
    /**
     * Компания
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'company'?: string | null;
    /**
     * Дата прихода
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'dateIn'?: string | null;
    /**
     * Дата планируемого ремонта
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'datePlanRepair'?: string | null;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'sku'?: string | null;
    /**
     * Производственный код
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'sek'?: string;
    /**
     * Название прибора на английском
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'nameEn'?: string;
    /**
     * Название прибора на русском
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'nameRu'?: string;
    /**
     * 0 - зеленый (без брака), 1 - красный (с браком),  2 - синий (поступивший, код этой раскраски не найден)
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'soPr'?: string;
    /**
     * Серийный номер
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'nomer'?: string;
    /**
     * Старый серийный номер
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'oldNomer'?: string;
    /**
     * Возврат покупателю (ВВ)
     * @type {boolean}
     * @memberof RepairAreaDeviceDto
     */
    'returnToOwner'?: boolean;
    /**
     * Гарантийный прибор
     * @type {boolean}
     * @memberof RepairAreaDeviceDto
     */
    'warranty'?: boolean;
    /**
     * Примечания
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'notes'?: string | null;
    /**
     * Примечания ОТК
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'notesQC'?: string | null;
    /**
     * Стоимость ремонта
     * @type {number}
     * @memberof RepairAreaDeviceDto
     */
    'stoimRem'?: number;
    /**
     * Согласие на платный ремонт
     * @type {boolean}
     * @memberof RepairAreaDeviceDto
     */
    'platRem'?: boolean | null;
    /**
     * Код склада
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'skl'?: string;
    /**
     * Быстрый ремонт
     * @type {boolean}
     * @memberof RepairAreaDeviceDto
     */
    'speedRepair'?: boolean;
    /**
     * 0 - пришел по RMA, 1 - пришел по инвойсу
     * @type {boolean}
     * @memberof RepairAreaDeviceDto
     */
    'notRma'?: boolean;
    /**
     * Кол-во дефектов
     * @type {number}
     * @memberof RepairAreaDeviceDto
     */
    'qtyDef'?: number;
    /**
     * Дата сдачи прибора Владельцем в ремонт Дистрибьютору
     * @type {string}
     * @memberof RepairAreaDeviceDto
     */
    'dataReceiptForRepair'?: string | null;
}
/**
 * 
 * @export
 * @interface RepairAreaDevicePrimaryKeyDto
 */
export interface RepairAreaDevicePrimaryKeyDto {
    /**
     * Ключ прибора в заявке
     * @type {number}
     * @memberof RepairAreaDevicePrimaryKeyDto
     */
    'deviceId'?: number;
    /**
     * Id компании
     * @type {number}
     * @memberof RepairAreaDevicePrimaryKeyDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface RepairAreaDto
 */
export interface RepairAreaDto {
    /**
     * Номер участка (skl)
     * @type {string}
     * @memberof RepairAreaDto
     */
    'areaRepair'?: string;
    /**
     * Название участка (En)
     * @type {string}
     * @memberof RepairAreaDto
     */
    'naimEn'?: string;
    /**
     * Название участка (En)
     * @type {string}
     * @memberof RepairAreaDto
     */
    'naimRu'?: string;
    /**
     * Количество планов ремонта
     * @type {number}
     * @memberof RepairAreaDto
     */
    'kol'?: number;
    /**
     * Id компании
     * @type {number}
     * @memberof RepairAreaDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface RepairAreaSparePartsDto
 */
export interface RepairAreaSparePartsDto {
    /**
     * Номер участка ремонта
     * @type {string}
     * @memberof RepairAreaSparePartsDto
     */
    'repairArea'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof RepairAreaSparePartsDto
     */
    'seK'?: string;
    /**
     * Наименвание английское
     * @type {string}
     * @memberof RepairAreaSparePartsDto
     */
    'nameEn'?: string;
    /**
     * Наименование русское
     * @type {string}
     * @memberof RepairAreaSparePartsDto
     */
    'nameRu'?: string;
    /**
     * Количество
     * @type {number}
     * @memberof RepairAreaSparePartsDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface RepairAreasExportToExcelPayload
 */
export interface RepairAreasExportToExcelPayload {
    /**
     * Id компании
     * @type {number}
     * @memberof RepairAreasExportToExcelPayload
     */
    'firmId'?: number;
    /**
     * Номер склада
     * @type {string}
     * @memberof RepairAreasExportToExcelPayload
     */
    'skl'?: string;
    /**
     * Поиск по компании
     * @type {string}
     * @memberof RepairAreasExportToExcelPayload
     */
    'company'?: string | null;
    /**
     * Поиск по SKU/серийному номеру
     * @type {string}
     * @memberof RepairAreasExportToExcelPayload
     */
    'number'?: string | null;
    /**
     * 0 - зеленый (без брака), 1 - красный (с браком),  2 - синий (поступивший, код этой раскраски не найден)
     * @type {string}
     * @memberof RepairAreasExportToExcelPayload
     */
    'soPr'?: string | null;
    /**
     * Язык интерфейса
     * @type {number}
     * @memberof RepairAreasExportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairCostCalculationUpdatePayload
 */
export interface RepairCostCalculationUpdatePayload {
    /**
     * 
     * @type {string}
     * @memberof RepairCostCalculationUpdatePayload
     */
    'primec'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairCostCalculationUpdatePayload
     */
    'sotrId1'?: number | null;
}
/**
 * 
 * @export
 * @interface RepairDeadlinesExceededReportExportPayload
 */
export interface RepairDeadlinesExceededReportExportPayload {
    /**
     * 
     * @type {number}
     * @memberof RepairDeadlinesExceededReportExportPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairDeadlinesExceededReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairDeviceBrowseDto
 */
export interface RepairDeviceBrowseDto {
    /**
     * Ключ наименования прибора
     * @type {string}
     * @memberof RepairDeviceBrowseDto
     */
    'se'?: string;
    /**
     * Поле Sku (сбытовое)
     * @type {string}
     * @memberof RepairDeviceBrowseDto
     */
    'sku'?: string;
    /**
     * Наименование прибора (RU)
     * @type {string}
     * @memberof RepairDeviceBrowseDto
     */
    'deviceNameR'?: string;
    /**
     * Наименование прибора (EN)
     * @type {string}
     * @memberof RepairDeviceBrowseDto
     */
    'deviceNameE'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairDeviceBrowseDto
     */
    'groupId'?: number;
    /**
     * Наименование ремонтной группы (RU)
     * @type {string}
     * @memberof RepairDeviceBrowseDto
     */
    'groupNameR'?: string;
    /**
     * Наименование ремонтной группы (EN)
     * @type {string}
     * @memberof RepairDeviceBrowseDto
     */
    'groupNameE'?: string;
    /**
     * Необходимость лицензии
     * @type {boolean}
     * @memberof RepairDeviceBrowseDto
     */
    'prLic'?: boolean;
    /**
     * Url упаковочной инструкции
     * @type {string}
     * @memberof RepairDeviceBrowseDto
     */
    'packingInstructionsUrl'?: string;
}
/**
 * 
 * @export
 * @interface RepairGroupBrowseDto
 */
export interface RepairGroupBrowseDto {
    /**
     * Ключ ремонтной группы
     * @type {number}
     * @memberof RepairGroupBrowseDto
     */
    'id'?: number;
    /**
     * Наименование (RU)
     * @type {string}
     * @memberof RepairGroupBrowseDto
     */
    'nameR'?: string;
    /**
     * Наименование (EN)
     * @type {string}
     * @memberof RepairGroupBrowseDto
     */
    'nameE'?: string;
    /**
     * Норма-часы
     * @type {number}
     * @memberof RepairGroupBrowseDto
     */
    'normHours'?: number | null;
}
/**
 * 
 * @export
 * @interface RepairListBrowseDto
 */
export interface RepairListBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof RepairListBrowseDto
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairListBrowseDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairListBrowseDto
     */
    'utvId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'utvNameEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'utvNameRu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'utvJobTitleEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'utvJobTitleRu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RepairListBrowseDto
     */
    'an1Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an1NameEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an1NameRu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an1JobTitleEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an1JobTitleRu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RepairListBrowseDto
     */
    'an2Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an2NameEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an2NameRu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an2JobTitleEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an2JobTitleRu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RepairListBrowseDto
     */
    'an3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an3NameEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an3NameRu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an3JobTitleEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListBrowseDto
     */
    'an3JobTitleRu'?: string | null;
    /**
     * 
     * @type {Array<RepairListDeviceBrowseDto>}
     * @memberof RepairListBrowseDto
     */
    'devices'?: Array<RepairListDeviceBrowseDto>;
}
/**
 * 
 * @export
 * @interface RepairListDeviceBrowseDto
 */
export interface RepairListDeviceBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof RepairListDeviceBrowseDto
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairListDeviceBrowseDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairListDeviceBrowseDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'serial'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'newSerial'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepairListDeviceBrowseDto
     */
    'warranty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairListDeviceBrowseDto
     */
    'vozvrBezR'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairListDeviceBrowseDto
     */
    'wait'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairListDeviceBrowseDto
     */
    'stoimRem'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'valRem'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'complaintsReported'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'complaintsDetected'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListDeviceBrowseDto
     */
    'repairs'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairListDeviceBrowseDto
     */
    'tz'?: boolean;
}
/**
 * 
 * @export
 * @interface RepairListLoadDefectsPayload
 */
export interface RepairListLoadDefectsPayload {
    /**
     * 
     * @type {number}
     * @memberof RepairListLoadDefectsPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairListUpdateDeviceDto
 */
export interface RepairListUpdateDeviceDto {
    /**
     * 
     * @type {number}
     * @memberof RepairListUpdateDeviceDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairListUpdateDeviceDto
     */
    'deviceFirmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairListUpdateDeviceDto
     */
    'complaints'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairListUpdateDeviceDto
     */
    'repairs'?: string | null;
}
/**
 * 
 * @export
 * @interface RepairPlanAreaDto
 */
export interface RepairPlanAreaDto {
    /**
     * Номер участка (skl)
     * @type {string}
     * @memberof RepairPlanAreaDto
     */
    'areaRepair'?: string;
    /**
     * Название участка (En)
     * @type {string}
     * @memberof RepairPlanAreaDto
     */
    'nSklEn'?: string;
    /**
     * Название участка (En)
     * @type {string}
     * @memberof RepairPlanAreaDto
     */
    'nSklRu'?: string;
    /**
     * Количество планов ремонта
     * @type {number}
     * @memberof RepairPlanAreaDto
     */
    'kol'?: number;
}
/**
 * 
 * @export
 * @interface RepairPlanDeviceBrowseDto
 */
export interface RepairPlanDeviceBrowseDto {
    /**
     * Ключ прибора в заявке
     * @type {number}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'deviceId'?: number;
    /**
     * Id компании
     * @type {number}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'firmId'?: number;
    /**
     * Согласован
     * @type {boolean}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'agree'?: boolean | null;
    /**
     * Номер участка
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'areaRepair'?: string;
    /**
     * План ремонта
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'planRepair'?: string;
    /**
     * Дата прихода
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'dateIn'?: string | null;
    /**
     * Номер документа
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'docNumber'?: string | null;
    /**
     * Компания
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'company'?: string | null;
    /**
     * Производственный код
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'seK'?: string;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'sku'?: string | null;
    /**
     * Серийный номер
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'serial'?: string;
    /**
     * Название прибора (RU)
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'seRu'?: string;
    /**
     * Название прибора (EN)
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'seEn'?: string;
    /**
     * Гарантия
     * @type {boolean}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'warranty'?: boolean;
    /**
     * Возврат покупателю (ВВ)
     * @type {boolean}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'returnToOwner'?: boolean;
    /**
     * Примечание
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'notes'?: string | null;
    /**
     * Предварительный контроль назначен
     * @type {boolean}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'beControl'?: boolean;
    /**
     * Предварительный контроль пройден
     * @type {boolean}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'beControlC'?: boolean;
    /**
     * Статус плана
     * @type {number}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'planStatus'?: number;
    /**
     * Получен
     * @type {boolean}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'isReceived'?: boolean;
    /**
     * Дата сдачи прибора Владельцем в ремонт Дистрибьютору
     * @type {string}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'dataReceiptForRepair'?: string | null;
    /**
     * Получен на участок ремонта отличный от запланированного
     * @type {boolean}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'err'?: boolean;
    /**
     * Местонахождение прибора
     * @type {number}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'repairLocation'?: number;
    /**
     * Предварительный контроль
     * @type {boolean}
     * @memberof RepairPlanDeviceBrowseDto
     */
    'bcc'?: boolean;
}
/**
 * 
 * @export
 * @interface RepairPlanDevicePrimaryKeyDto
 */
export interface RepairPlanDevicePrimaryKeyDto {
    /**
     * Ключ прибора в заявке
     * @type {number}
     * @memberof RepairPlanDevicePrimaryKeyDto
     */
    'deviceId'?: number;
    /**
     * Id компании
     * @type {number}
     * @memberof RepairPlanDevicePrimaryKeyDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface RepairPlansExportToExcelPayload
 */
export interface RepairPlansExportToExcelPayload {
    /**
     * Номер участка
     * @type {string}
     * @memberof RepairPlansExportToExcelPayload
     */
    'areaRepair'?: string;
    /**
     * Поиск по компании
     * @type {string}
     * @memberof RepairPlansExportToExcelPayload
     */
    'company'?: string | null;
    /**
     * Поиск по номеру документа/серийному номеру
     * @type {string}
     * @memberof RepairPlansExportToExcelPayload
     */
    'number'?: string | null;
    /**
     * Статус
     * @type {number}
     * @memberof RepairPlansExportToExcelPayload
     */
    'status'?: number | null;
    /**
     * Получен
     * @type {boolean}
     * @memberof RepairPlansExportToExcelPayload
     */
    'isReceived'?: boolean | null;
    /**
     * Язык интерфейса
     * @type {number}
     * @memberof RepairPlansExportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairPlansFulfillmentExportPayload
 */
export interface RepairPlansFulfillmentExportPayload {
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentExportPayload
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentExportPayload
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentExportPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairPlansFulfillmentReportDto
 */
export interface RepairPlansFulfillmentReportDto {
    /**
     * 
     * @type {Array<RepairPlansFulfillmentReportItemDto>}
     * @memberof RepairPlansFulfillmentReportDto
     */
    'devices'?: Array<RepairPlansFulfillmentReportItemDto>;
    /**
     * 
     * @type {Array<RepairPlansFulfillmentReportForecastDto>}
     * @memberof RepairPlansFulfillmentReportDto
     */
    'forecasts'?: Array<RepairPlansFulfillmentReportForecastDto>;
}
/**
 * 
 * @export
 * @interface RepairPlansFulfillmentReportForecastDto
 */
export interface RepairPlansFulfillmentReportForecastDto {
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentReportForecastDto
     */
    'kol'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentReportForecastDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportForecastDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportForecastDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportForecastDto
     */
    'pr'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentReportForecastDto
     */
    'normHours'?: number;
}
/**
 * 
 * @export
 * @interface RepairPlansFulfillmentReportItemDto
 */
export interface RepairPlansFulfillmentReportItemDto {
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'dp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'areaRepair'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'dateRepair'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'planRepair'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'groupId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'groupNameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'groupNameRu'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'normHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'nomer'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairPlansFulfillmentReportItemDto
     */
    'pr'?: number;
}
/**
 * 
 * @export
 * @interface RepairPriceProstafExportPayload
 */
export interface RepairPriceProstafExportPayload {
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafExportPayload
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafExportPayload
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafExportPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairPriceProstafReportDeviceDto
 */
export interface RepairPriceProstafReportDeviceDto {
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'devId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'counterpartyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'dateOut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'serial'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'newSerial'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'stoimRem'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportDeviceDto
     */
    'valRem'?: string;
}
/**
 * 
 * @export
 * @interface RepairPriceProstafReportDto
 */
export interface RepairPriceProstafReportDto {
    /**
     * 
     * @type {Array<RepairPriceProstafReportDeviceDto>}
     * @memberof RepairPriceProstafReportDto
     */
    'devices'?: Array<RepairPriceProstafReportDeviceDto>;
    /**
     * 
     * @type {Array<RepairPriceProstafReportReplacedSeDto>}
     * @memberof RepairPriceProstafReportDto
     */
    'replacedSes'?: Array<RepairPriceProstafReportReplacedSeDto>;
    /**
     * 
     * @type {Array<RepairPriceProstafReportReplacedSeDto>}
     * @memberof RepairPriceProstafReportDto
     */
    'works'?: Array<RepairPriceProstafReportReplacedSeDto>;
}
/**
 * 
 * @export
 * @interface RepairPriceProstafReportReplacedSeDto
 */
export interface RepairPriceProstafReportReplacedSeDto {
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'devId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'dId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'fId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'valRemId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'skl'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'valId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'idSe'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPriceProstafReportReplacedSeDto
     */
    'obozn'?: string;
}
/**
 * 
 * @export
 * @interface RepairReportExportPayload
 */
export interface RepairReportExportPayload {
    /**
     * Год
     * @type {number}
     * @memberof RepairReportExportPayload
     */
    'year'?: number;
    /**
     * Месяц
     * @type {number}
     * @memberof RepairReportExportPayload
     */
    'month'?: number;
    /**
     * Язык
     * @type {number}
     * @memberof RepairReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairTimeAnalysisReportExportPayload
 */
export interface RepairTimeAnalysisReportExportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof RepairTimeAnalysisReportExportPayload
     */
    'periodStart'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof RepairTimeAnalysisReportExportPayload
     */
    'periodEnd'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof RepairTimeAnalysisReportExportPayload
     */
    'firmId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof RepairTimeAnalysisReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairTimeOtkReportExportPayload
 */
export interface RepairTimeOtkReportExportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof RepairTimeOtkReportExportPayload
     */
    'periodStart'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof RepairTimeOtkReportExportPayload
     */
    'periodEnd'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof RepairTimeOtkReportExportPayload
     */
    'firmId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof RepairTimeOtkReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairTimeReportExportToExcelPayload
 */
export interface RepairTimeReportExportToExcelPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof RepairTimeReportExportToExcelPayload
     */
    'periodStart'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof RepairTimeReportExportToExcelPayload
     */
    'periodEnd'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof RepairTimeReportExportToExcelPayload
     */
    'firmId'?: number;
    /**
     * Список участков
     * @type {Array<string>}
     * @memberof RepairTimeReportExportToExcelPayload
     */
    'areas'?: Array<string>;
    /**
     * Язык
     * @type {number}
     * @memberof RepairTimeReportExportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairTimeReportExportToPdfPayload
 */
export interface RepairTimeReportExportToPdfPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof RepairTimeReportExportToPdfPayload
     */
    'periodStart'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof RepairTimeReportExportToPdfPayload
     */
    'periodEnd'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof RepairTimeReportExportToPdfPayload
     */
    'firmId'?: number;
    /**
     * Список участков
     * @type {Array<string>}
     * @memberof RepairTimeReportExportToPdfPayload
     */
    'areas'?: Array<string>;
    /**
     * Язык
     * @type {number}
     * @memberof RepairTimeReportExportToPdfPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairTimeReportItemDto
 */
export interface RepairTimeReportItemDto {
    /**
     * Ид Фирмы Прибора
     * @type {number}
     * @memberof RepairTimeReportItemDto
     */
    'deviceFirmId'?: number;
    /**
     * Ид прибора
     * @type {number}
     * @memberof RepairTimeReportItemDto
     */
    'deviceId'?: number;
    /**
     * Номер Участка ремонта
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'repairAreaNumber'?: string;
    /**
     * Дата получения от Дистрибьютора
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'receiptDate'?: string | null;
    /**
     * Дата поступлдения на участок ремонта
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'dateIn'?: string | null;
    /**
     * Дата возврата с участка ремонта
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'dateOut'?: string | null;
    /**
     * Ид Дистрибьютора
     * @type {number}
     * @memberof RepairTimeReportItemDto
     */
    'counterpartyId'?: number | null;
    /**
     * Наименование Дистрибьютора
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'counterparty'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'seK'?: string;
    /**
     * Код SKU
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'sku'?: string;
    /**
     * Серийный номер
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'serial'?: string;
    /**
     * Наименование прибора
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'name'?: string;
    /**
     * Участок ремонта
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'repairArea'?: string;
    /**
     * Дней ремонта
     * @type {number}
     * @memberof RepairTimeReportItemDto
     */
    'daysRepair'?: number;
    /**
     * Колчиество приборов в партии в которой пришел прибор
     * @type {number}
     * @memberof RepairTimeReportItemDto
     */
    'party'?: number | null;
    /**
     * Гарантия
     * @type {string}
     * @memberof RepairTimeReportItemDto
     */
    'warranty'?: string;
    /**
     * Дней до ремонта
     * @type {number}
     * @memberof RepairTimeReportItemDto
     */
    'daysBeforeRepair'?: number;
}
/**
 * 
 * @export
 * @interface RepairTimeReportRepairAreaDto
 */
export interface RepairTimeReportRepairAreaDto {
    /**
     * 
     * @type {string}
     * @memberof RepairTimeReportRepairAreaDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairTimeReportRepairAreaDto
     */
    'skl'?: string;
}
/**
 * 
 * @export
 * @interface RepairedDevicesReportCounterpartiesDto
 */
export interface RepairedDevicesReportCounterpartiesDto {
    /**
     * id контрагента
     * @type {number}
     * @memberof RepairedDevicesReportCounterpartiesDto
     */
    'counterpartyId'?: number;
    /**
     * Имя контрагента
     * @type {string}
     * @memberof RepairedDevicesReportCounterpartiesDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RepairedDevicesReportDto
 */
export interface RepairedDevicesReportDto {
    /**
     * 
     * @type {Array<RepairedDevicesReportItemDto>}
     * @memberof RepairedDevicesReportDto
     */
    'devices'?: Array<RepairedDevicesReportItemDto>;
}
/**
 * 
 * @export
 * @interface RepairedDevicesReportExportPayload
 */
export interface RepairedDevicesReportExportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof RepairedDevicesReportExportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof RepairedDevicesReportExportPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof RepairedDevicesReportExportPayload
     */
    'firmId'?: number;
    /**
     * ключ склада
     * @type {number}
     * @memberof RepairedDevicesReportExportPayload
     */
    'konId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof RepairedDevicesReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairedDevicesReportExportToPdfPayload
 */
export interface RepairedDevicesReportExportToPdfPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof RepairedDevicesReportExportToPdfPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof RepairedDevicesReportExportToPdfPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof RepairedDevicesReportExportToPdfPayload
     */
    'firmId'?: number;
    /**
     * ключ склада
     * @type {number}
     * @memberof RepairedDevicesReportExportToPdfPayload
     */
    'konId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof RepairedDevicesReportExportToPdfPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepairedDevicesReportItemDto
 */
export interface RepairedDevicesReportItemDto {
    /**
     * ключ прибора
     * @type {number}
     * @memberof RepairedDevicesReportItemDto
     */
    'deviceId'?: number;
    /**
     * ключ фирмы прибора
     * @type {number}
     * @memberof RepairedDevicesReportItemDto
     */
    'firmId'?: number;
    /**
     * Sku прибора
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'sku'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'seK'?: string;
    /**
     * Наимнование прибора на русском
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'nameRu'?: string;
    /**
     * Наимнование прибора на английском
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'nameEn'?: string;
    /**
     * Вернуть владельцу
     * @type {boolean}
     * @memberof RepairedDevicesReportItemDto
     */
    'wait'?: boolean;
    /**
     * Гарантия прибора
     * @type {boolean}
     * @memberof RepairedDevicesReportItemDto
     */
    'warranty'?: boolean;
    /**
     * Годность прибора
     * @type {boolean}
     * @memberof RepairedDevicesReportItemDto
     */
    'godn'?: boolean;
    /**
     * стоимость ремонта
     * @type {number}
     * @memberof RepairedDevicesReportItemDto
     */
    'stoimRem'?: number;
    /**
     * обозначение валюты стоимости ремонта
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'obozn'?: string;
    /**
     * Серийный номер
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'oldNomer'?: string;
    /**
     * серийный номер новый
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'newNomer'?: string;
    /**
     * id Предприятия, где произведен прибор
     * @type {number}
     * @memberof RepairedDevicesReportItemDto
     */
    'firmProizv'?: number;
    /**
     * Дата производства
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'dataProiz'?: string | null;
    /**
     * Дата продажи
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'dataProd'?: string | null;
    /**
     * Предприятие, имя продавца, где прибор продался
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'nameProd'?: string;
    /**
     * имя контрагента
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'counterpartyName'?: string;
    /**
     * Дата поступления прибора
     * @type {string}
     * @memberof RepairedDevicesReportItemDto
     */
    'recvDate'?: string | null;
    /**
     * стоимость прибора
     * @type {number}
     * @memberof RepairedDevicesReportItemDto
     */
    'stoimPrib'?: number;
}
/**
 * 
 * @export
 * @interface RepeatedReturnsDaylyReportExportToExcelPayload
 */
export interface RepeatedReturnsDaylyReportExportToExcelPayload {
    /**
     * 
     * @type {number}
     * @memberof RepeatedReturnsDaylyReportExportToExcelPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepeatedReturnsDaylyReportExportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepeatedReturnsReportExportToExcelPayload
 */
export interface RepeatedReturnsReportExportToExcelPayload {
    /**
     * 
     * @type {string}
     * @memberof RepeatedReturnsReportExportToExcelPayload
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepeatedReturnsReportExportToExcelPayload
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepeatedReturnsReportExportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RepeatedReturnsReportItemDto
 */
export interface RepeatedReturnsReportItemDto {
    /**
     * Код СЕ
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'seK'?: string;
    /**
     * Код SKU
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'sku'?: string;
    /**
     * Наименование компании
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'company'?: string;
    /**
     * Наименование прибора
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'name'?: string;
    /**
     * Серийный номер
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'serial'?: string;
    /**
     * Дата производства
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'productionDate'?: string | null;
    /**
     * Гарантия
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'warranty'?: string;
    /**
     * Дата последнего поступления
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'receiptDate'?: string | null;
    /**
     * Вернуть владельцу
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'wait'?: string;
    /**
     * Жалобы
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'complaints'?: string;
    /**
     * Предварительный анализ
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'preliminaryAnalysis'?: string;
    /**
     * Выявленные дефекты
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'defects'?: string;
    /**
     * Произведенные работы и замененные запчасти
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'repairs'?: string;
    /**
     * Причины дефектов
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'reasons'?: string;
    /**
     * Дефект подтверждён
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'defectConfirmed'?: string;
    /**
     * Повторный ремонт подтвержден
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'repeatedRepairConfirmed'?: string;
    /**
     * Запросить уточнение
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'requestConfirmation'?: string;
    /**
     * Общение с покупателем
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'customerCommunication'?: string;
    /**
     * Дата отгрузки (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'shipmentDate'?: string | null;
    /**
     * Вернуть владельцу (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'waitPrior'?: string;
    /**
     * Жалобы (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'complaintsPrior'?: string;
    /**
     * Предварительный анализ (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'preliminaryAnalysisPrior'?: string;
    /**
     * Выявленные дефекты (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'defectsPrior'?: string;
    /**
     * Произведенные работы и замененные запчасти (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'repairsPrior'?: string;
    /**
     * Причины дефнктов (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'reasonsPrior'?: string;
    /**
     * Гарантия (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'warrantyPrior'?: string;
    /**
     * Дефект подтверждён (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'defectConfirmedPrior'?: string;
    /**
     * Запросить уточнение (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'requestConfirmationPrior'?: string;
    /**
     * Общение с покупателем (предыдущее поступление)
     * @type {string}
     * @memberof RepeatedReturnsReportItemDto
     */
    'customerCommunicationPrior'?: string;
}
/**
 * 
 * @export
 * @interface ReplaceToNewDeviceDto
 */
export interface ReplaceToNewDeviceDto {
    /**
     * 
     * @type {number}
     * @memberof ReplaceToNewDeviceDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplaceToNewDeviceDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceToNewDeviceDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceToNewDeviceDto
     */
    'serial'?: string;
}
/**
 * 
 * @export
 * @interface ReplaceToNewPayload
 */
export interface ReplaceToNewPayload {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof ReplaceToNewPayload
     */
    'firmId'?: number;
    /**
     * Дата замены: yyyy-MM-dd
     * @type {string}
     * @memberof ReplaceToNewPayload
     */
    'operationDate'?: string;
    /**
     * Приборы для замены
     * @type {Array<ReplaceToNewDeviceDto>}
     * @memberof ReplaceToNewPayload
     */
    'devices'?: Array<ReplaceToNewDeviceDto>;
}
/**
 * 
 * @export
 * @interface ReplacedCEAddDto
 */
export interface ReplacedCEAddDto {
    /**
     * 
     * @type {string}
     * @memberof ReplacedCEAddDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacedCEAddDto
     */
    'seKParent'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplacedCEAddDto
     */
    'kol'?: number;
}
/**
 * 
 * @export
 * @interface ReplacementCEBrowseDto
 */
export interface ReplacementCEBrowseDto {
    /**
     * 
     * @type {string}
     * @memberof ReplacementCEBrowseDto
     */
    'se'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementCEBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementCEBrowseDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementCEBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementCEBrowseDto
     */
    'isContains'?: boolean;
}
/**
 * 
 * @export
 * @interface ReplacementCEDetailsBrowseDto
 */
export interface ReplacementCEDetailsBrowseDto {
    /**
     * 
     * @type {string}
     * @memberof ReplacementCEDetailsBrowseDto
     */
    'se'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementCEDetailsBrowseDto
     */
    'seParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplacementCEDetailsBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementCEDetailsBrowseDto
     */
    'nameL'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementCEDetailsBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReplacementCEDetailsBrowseDto
     */
    'kol'?: number;
}
/**
 * 
 * @export
 * @interface ReplacementCEPagingResult
 */
export interface ReplacementCEPagingResult {
    /**
     * 
     * @type {Array<ReplacementCEBrowseDto>}
     * @memberof ReplacementCEPagingResult
     */
    'data'?: Array<ReplacementCEBrowseDto>;
    /**
     * 
     * @type {number}
     * @memberof ReplacementCEPagingResult
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplacementCEPagingResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplacementCEPagingResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementCEPagingResult
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface ReplacementDeviceBrowseDto
 */
export interface ReplacementDeviceBrowseDto {
    /**
     * Код SKU
     * @type {string}
     * @memberof ReplacementDeviceBrowseDto
     */
    'sku'?: string;
    /**
     * Код СЕ
     * @type {string}
     * @memberof ReplacementDeviceBrowseDto
     */
    'sek'?: string;
    /**
     * Английское наименование прибора
     * @type {string}
     * @memberof ReplacementDeviceBrowseDto
     */
    'nameRu'?: string;
    /**
     * Русское наименование прибора
     * @type {string}
     * @memberof ReplacementDeviceBrowseDto
     */
    'nameEn'?: string;
    /**
     * Количество приборов на участке Резервного фонда в производстве
     * @type {number}
     * @memberof ReplacementDeviceBrowseDto
     */
    'rfQty'?: number;
    /**
     * Количество приборов на участке Подменного фонда в производстве
     * @type {number}
     * @memberof ReplacementDeviceBrowseDto
     */
    'pfQty'?: number;
    /**
     * Количество приборов на складе Сервисного обслуживания
     * @type {number}
     * @memberof ReplacementDeviceBrowseDto
     */
    'areaSoQty'?: number;
    /**
     * Количество приборов на участках ремонта
     * @type {number}
     * @memberof ReplacementDeviceBrowseDto
     */
    'repairAreaQty'?: number;
    /**
     * Количество приборов на внешних участках ремонта
     * @type {number}
     * @memberof ReplacementDeviceBrowseDto
     */
    'externalQty'?: number;
}
/**
 * 
 * @export
 * @interface ReplacementDevicesBrowseDto
 */
export interface ReplacementDevicesBrowseDto {
    /**
     * 
     * @type {string}
     * @memberof ReplacementDevicesBrowseDto
     */
    'se'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementDevicesBrowseDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementDevicesBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplacementDevicesBrowseDto
     */
    'nameEn'?: string;
}
/**
 * 
 * @export
 * @interface ReplacementDevicesPagingResult
 */
export interface ReplacementDevicesPagingResult {
    /**
     * 
     * @type {Array<ReplacementDevicesBrowseDto>}
     * @memberof ReplacementDevicesPagingResult
     */
    'data'?: Array<ReplacementDevicesBrowseDto>;
    /**
     * 
     * @type {number}
     * @memberof ReplacementDevicesPagingResult
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplacementDevicesPagingResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplacementDevicesPagingResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplacementDevicesPagingResult
     */
    'hasNextPage'?: boolean;
}
/**
 * 
 * @export
 * @interface ReplacementPartsReportExportPayload
 */
export interface ReplacementPartsReportExportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof ReplacementPartsReportExportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof ReplacementPartsReportExportPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReplacementPartsReportExportPayload
     */
    'firmId'?: number;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReplacementPartsReportExportPayload
     */
    'proizvId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof ReplacementPartsReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ReportFileBrowseResponse
 */
export interface ReportFileBrowseResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportFileBrowseResponse
     */
    'fileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportFileBrowseResponse
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFileBrowseResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFileBrowseResponse
     */
    'createBy'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportType = {
    RepairDeadlinesExceeded: 'RepairDeadlinesExceeded',
    RepeatedReturns: 'RepeatedReturns',
    AverageRepairTime: 'AverageRepairTime',
    GeneralDailyReport: 'GeneralDailyReport'
} as const;

export type ReportType = typeof ReportType[keyof typeof ReportType];


/**
 * 
 * @export
 * @interface RequestRepairPlanApprovalPayload
 */
export interface RequestRepairPlanApprovalPayload {
    /**
     * Ключ компании пользователя
     * @type {number}
     * @memberof RequestRepairPlanApprovalPayload
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface ReturnLevelDataLoadingInfoDto
 */
export interface ReturnLevelDataLoadingInfoDto {
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelDataLoadingInfoDto
     */
    'sales'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelDataLoadingInfoDto
     */
    'salesSellmark'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelDataLoadingInfoDto
     */
    'repairs'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelDataLoadingInfoDto
     */
    'repairsSellmark'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReturnLevelDataType = {
    Sales: 'Sales',
    SalesSellmark: 'SalesSellmark',
    Repairs: 'Repairs',
    RepairsSellmark: 'RepairsSellmark'
} as const;

export type ReturnLevelDataType = typeof ReturnLevelDataType[keyof typeof ReturnLevelDataType];


/**
 * 
 * @export
 * @interface ReturnLevelRepairsRecordDto
 */
export interface ReturnLevelRepairsRecordDto {
    /**
     * 
     * @type {string}
     * @memberof ReturnLevelRepairsRecordDto
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelRepairsRecordDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelRepairsRecordDto
     */
    'date'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReturnLevelRepairsRecordDto
     */
    'isNwr'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReturnLevelRepairsRecordDto
     */
    'isXXXX'?: boolean;
}
/**
 * 
 * @export
 * @interface ReturnLevelRepairsSellmarkRecordDto
 */
export interface ReturnLevelRepairsSellmarkRecordDto {
    /**
     * 
     * @type {string}
     * @memberof ReturnLevelRepairsSellmarkRecordDto
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelRepairsSellmarkRecordDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelRepairsSellmarkRecordDto
     */
    'date'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReturnLevelRepairsSellmarkRecordDto
     */
    'isNwr'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReturnLevelRepairsSellmarkRecordDto
     */
    'isXXXX'?: boolean;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportDto
 */
export interface ReturnLevelReportDto {
    /**
     * 
     * @type {Array<ReturnLevelReportTotalDataDto>}
     * @memberof ReturnLevelReportDto
     */
    'totalData'?: Array<ReturnLevelReportTotalDataDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevel'?: Array<ReturnLevelReportReturnLevelDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelEurope'?: Array<ReturnLevelReportReturnLevelDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelOnCompany'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelOnCompanySelmark'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelBeltex'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelBeltexSelmark'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelYukon'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelYukonSelmark'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelPolaris'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelPolarisSelmark'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelPulsar'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelOnCompanyDto>}
     * @memberof ReturnLevelReportDto
     */
    'returnLevelPulsarSelmark'?: Array<ReturnLevelReportReturnLevelOnCompanyDto>;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportDto
     */
    'reportYear'?: number;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportImportRepairsResponse
 */
export interface ReturnLevelReportImportRepairsResponse {
    /**
     * Кол-во успешно добавленных приборов
     * @type {Array<ReturnLevelRepairsRecordDto>}
     * @memberof ReturnLevelReportImportRepairsResponse
     */
    'records'?: Array<ReturnLevelRepairsRecordDto>;
    /**
     * Ошибки найденные в документе
     * @type {Array<ExcelRowError>}
     * @memberof ReturnLevelReportImportRepairsResponse
     */
    'errors'?: Array<ExcelRowError>;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportImportRepairsSellmarkResponse
 */
export interface ReturnLevelReportImportRepairsSellmarkResponse {
    /**
     * Кол-во успешно добавленных приборов
     * @type {Array<ReturnLevelRepairsSellmarkRecordDto>}
     * @memberof ReturnLevelReportImportRepairsSellmarkResponse
     */
    'records'?: Array<ReturnLevelRepairsSellmarkRecordDto>;
    /**
     * Ошибки найденные в документе
     * @type {Array<ExcelRowError>}
     * @memberof ReturnLevelReportImportRepairsSellmarkResponse
     */
    'errors'?: Array<ExcelRowError>;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportImportSalesResponse
 */
export interface ReturnLevelReportImportSalesResponse {
    /**
     * Кол-во успешно добавленных приборов
     * @type {Array<ReturnLevelSalesRecordDto>}
     * @memberof ReturnLevelReportImportSalesResponse
     */
    'records'?: Array<ReturnLevelSalesRecordDto>;
    /**
     * Ошибки найденные в документе
     * @type {Array<ExcelRowError>}
     * @memberof ReturnLevelReportImportSalesResponse
     */
    'errors'?: Array<ExcelRowError>;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportImportSalesSellmarkResponse
 */
export interface ReturnLevelReportImportSalesSellmarkResponse {
    /**
     * Кол-во успешно добавленных приборов
     * @type {Array<ReturnLevelSalesSellmarkRecordDto>}
     * @memberof ReturnLevelReportImportSalesSellmarkResponse
     */
    'records'?: Array<ReturnLevelSalesSellmarkRecordDto>;
    /**
     * Ошибки найденные в документе
     * @type {Array<ExcelRowError>}
     * @memberof ReturnLevelReportImportSalesSellmarkResponse
     */
    'errors'?: Array<ExcelRowError>;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportReturnLevelDto
 */
export interface ReturnLevelReportReturnLevelDto {
    /**
     * 
     * @type {Array<ReturnLevelReportReturnLevelForYearDto>}
     * @memberof ReturnLevelReportReturnLevelDto
     */
    'returnLevelForYear'?: Array<ReturnLevelReportReturnLevelForYearDto>;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelDto
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportReturnLevelForYearDto
 */
export interface ReturnLevelReportReturnLevelForYearDto {
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'firm'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'firmName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'sales16'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'repairs16'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelForYearDto
     */
    'prc16'?: number;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportReturnLevelOnCompanyDto
 */
export interface ReturnLevelReportReturnLevelOnCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'seK'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'kodGr'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'kodPgr'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year41'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year31'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year21'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year42'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year32'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year22'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year43'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year33'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year23'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year44'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year34'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year24'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year14'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year45'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year35'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year25'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year15'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX5'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year46'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year36'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year26'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year16'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX6'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year47'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year37'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year27'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year17'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year48'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year38'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year28'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year18'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year49'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year39'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year29'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year19'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX9'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year410'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year310'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year210'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year110'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX10'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year411'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year311'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year211'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year111'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX11'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year412'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year312'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year212'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year112'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX12'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'qtySale13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defAll13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcAll13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'defWar13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'prcWar13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'nwr13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year413'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year313'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year213'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'year113'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'yearC13'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportReturnLevelOnCompanyDto
     */
    'xxxX13'?: number;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportTotalDataDto
 */
export interface ReturnLevelReportTotalDataDto {
    /**
     * 
     * @type {Array<ReturnLevelReportTotalDataForYearDto>}
     * @memberof ReturnLevelReportTotalDataDto
     */
    'totalDataForYear'?: Array<ReturnLevelReportTotalDataForYearDto>;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataDto
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface ReturnLevelReportTotalDataForYearDto
 */
export interface ReturnLevelReportTotalDataForYearDto {
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'caption'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'sales1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defAll1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcAll1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defWar1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcWar1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'sales8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defAll8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcAll8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defWar8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcWar8'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'sales7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defAll7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcAll7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defWar7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcWar7'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'sales4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defAll4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcAll4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defWar4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcWar4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'salesTt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defAllTt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcAllTt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'defWarTt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'prcWarTt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'qtyNwr'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'qtyYear4'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'qtyYear3'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'qtyYear2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'qtyYear1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'qtyYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelReportTotalDataForYearDto
     */
    'qtyXXXX'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReturnLevelReportType = {
    Full: 'Full',
    TotalData: 'TotalData',
    CurrentYearDevices: 'CurrentYearDevices',
    ReturnLevelAll: 'ReturnLevelAll',
    ReturnLevelBeltex: 'ReturnLevelBeltex',
    ReturnLevelYukon: 'ReturnLevelYukon',
    ReturnLevelPolaris: 'ReturnLevelPolaris',
    ReturnLevelPulsar: 'ReturnLevelPulsar'
} as const;

export type ReturnLevelReportType = typeof ReturnLevelReportType[keyof typeof ReturnLevelReportType];


/**
 * 
 * @export
 * @interface ReturnLevelSalesRecordDto
 */
export interface ReturnLevelSalesRecordDto {
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelSalesRecordDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelSalesRecordDto
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReturnLevelSalesRecordDto
     */
    'seK'?: string;
}
/**
 * 
 * @export
 * @interface ReturnLevelSalesSellmarkRecordDto
 */
export interface ReturnLevelSalesSellmarkRecordDto {
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelSalesSellmarkRecordDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelSalesSellmarkRecordDto
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReturnLevelSalesSellmarkRecordDto
     */
    'seK'?: string;
}
/**
 * 
 * @export
 * @interface ReturnLevelYearReportItemDto
 */
export interface ReturnLevelYearReportItemDto {
    /**
     * 
     * @type {number}
     * @memberof ReturnLevelYearReportItemDto
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface ReturnRepairAreaDevicePayload
 */
export interface ReturnRepairAreaDevicePayload {
    /**
     * Id компании
     * @type {number}
     * @memberof ReturnRepairAreaDevicePayload
     */
    'firmId'?: number;
    /**
     * Номер склада
     * @type {string}
     * @memberof ReturnRepairAreaDevicePayload
     */
    'skl'?: string;
    /**
     * Дата документа, формат yyyy-MM-dd
     * @type {string}
     * @memberof ReturnRepairAreaDevicePayload
     */
    'dateMove'?: string;
    /**
     * Выбранные приборы
     * @type {Array<ReturnRepairAreaDevicePrimaryKeyDto>}
     * @memberof ReturnRepairAreaDevicePayload
     */
    'devices'?: Array<ReturnRepairAreaDevicePrimaryKeyDto>;
}
/**
 * 
 * @export
 * @interface ReturnRepairAreaDevicePrimaryKeyDto
 */
export interface ReturnRepairAreaDevicePrimaryKeyDto {
    /**
     * Ключ прибора в заявке
     * @type {number}
     * @memberof ReturnRepairAreaDevicePrimaryKeyDto
     */
    'deviceId'?: number;
    /**
     * Id компании
     * @type {number}
     * @memberof ReturnRepairAreaDevicePrimaryKeyDto
     */
    'firmId'?: number;
    /**
     * Новый серийный номер
     * @type {string}
     * @memberof ReturnRepairAreaDevicePrimaryKeyDto
     */
    'newNomer'?: string;
}
/**
 * 
 * @export
 * @interface ReturnRepairAreaSparePartsPayload
 */
export interface ReturnRepairAreaSparePartsPayload {
    /**
     * 
     * @type {string}
     * @memberof ReturnRepairAreaSparePartsPayload
     */
    'repairArea'?: string;
    /**
     * 
     * @type {Array<ReturnSparePartDto>}
     * @memberof ReturnRepairAreaSparePartsPayload
     */
    'spareParts'?: Array<ReturnSparePartDto>;
}
/**
 * 
 * @export
 * @interface ReturnSparePartDto
 */
export interface ReturnSparePartDto {
    /**
     * 
     * @type {string}
     * @memberof ReturnSparePartDto
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReturnSparePartDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface ReturnToReplacementAreaDeviceDto
 */
export interface ReturnToReplacementAreaDeviceDto {
    /**
     * Ключ прибора
     * @type {number}
     * @memberof ReturnToReplacementAreaDeviceDto
     */
    'deviceId'?: number;
    /**
     * Ключ компании
     * @type {number}
     * @memberof ReturnToReplacementAreaDeviceDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface ReturnToReplacementAreaPayload
 */
export interface ReturnToReplacementAreaPayload {
    /**
     * Дата передачи, формат yyyy-MM-dd
     * @type {string}
     * @memberof ReturnToReplacementAreaPayload
     */
    'dateTransfer'?: string;
    /**
     * Ключ компании пользователя
     * @type {number}
     * @memberof ReturnToReplacementAreaPayload
     */
    'firmId'?: number;
    /**
     * Выбранные приборы
     * @type {Array<ReturnToReplacementAreaDeviceDto>}
     * @memberof ReturnToReplacementAreaPayload
     */
    'devices'?: Array<ReturnToReplacementAreaDeviceDto>;
}
/**
 * 
 * @export
 * @interface ReturnWithoutRepairCheckResponse
 */
export interface ReturnWithoutRepairCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ReturnWithoutRepairCheckResponse
     */
    'canReturnWithoutRepair'?: boolean;
}
/**
 * 
 * @export
 * @interface ReturnWithoutRepairDeviceDto
 */
export interface ReturnWithoutRepairDeviceDto {
    /**
     * Ключ прибора
     * @type {number}
     * @memberof ReturnWithoutRepairDeviceDto
     */
    'deviceId'?: number;
    /**
     * Ключ компании
     * @type {number}
     * @memberof ReturnWithoutRepairDeviceDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface ReturnWithoutRepairPayload
 */
export interface ReturnWithoutRepairPayload {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof ReturnWithoutRepairPayload
     */
    'firmId'?: number;
    /**
     * Выбранные приборы
     * @type {Array<ReturnWithoutRepairDeviceDto>}
     * @memberof ReturnWithoutRepairPayload
     */
    'devices'?: Array<ReturnWithoutRepairDeviceDto>;
}
/**
 * 
 * @export
 * @interface ReturnsReasonsReportPayload
 */
export interface ReturnsReasonsReportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof ReturnsReasonsReportPayload
     */
    'dateFrom'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof ReturnsReasonsReportPayload
     */
    'dateTo'?: string;
    /**
     * язык
     * @type {number}
     * @memberof ReturnsReasonsReportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface RmaAddDeviceDto
 */
export interface RmaAddDeviceDto {
    /**
     * Производственный код
     * @type {string}
     * @memberof RmaAddDeviceDto
     */
    'se'?: string;
    /**
     * Кол-во приборов
     * @type {number}
     * @memberof RmaAddDeviceDto
     */
    'count'?: number;
    /**
     * Признак лицензии
     * @type {boolean}
     * @memberof RmaAddDeviceDto
     */
    'prLic'?: boolean;
}
/**
 * 
 * @export
 * @interface RmaAuditBrowseDto
 */
export interface RmaAuditBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof RmaAuditBrowseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'partitionType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'eventData'?: string;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'initiatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditBrowseDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof RmaAuditBrowseDto
     */
    'version'?: number;
}
/**
 * 
 * @export
 * @interface RmaAuditEventTypeDto
 */
export interface RmaAuditEventTypeDto {
    /**
     * 
     * @type {string}
     * @memberof RmaAuditEventTypeDto
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof RmaAuditEventTypeDto
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface RmaBrowseDto
 */
export interface RmaBrowseDto {
    /**
     * Id фирмы разрешения (RMA)
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'rmaFirmId'?: number;
    /**
     * ID разрешения (RMA)
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'rmaId'?: number;
    /**
     * Номер Разрешения
     * @type {string}
     * @memberof RmaBrowseDto
     */
    'rmaNumber'?: string;
    /**
     * Дата Разрешения
     * @type {string}
     * @memberof RmaBrowseDto
     */
    'rmaDate'?: string;
    /**
     * Статус Разрешения
     * @type {string}
     * @memberof RmaBrowseDto
     */
    'rmaStatus'?: string;
    /**
     * Количество всех приборов в Разрешении
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'itemsTotalCount'?: number;
    /**
     * Количество подтвержденных приборов в Разрешении
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'itemsConfirmedCount'?: number;
    /**
     * Количество приборов с неправильной ценой лицензии
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'itemsWithInvalidLicensePriceCount'?: number;
    /**
     * ID Дистрибьютора
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'counterpartyId'?: number;
    /**
     * Краткое наименование Дистрибьютора
     * @type {string}
     * @memberof RmaBrowseDto
     */
    'counterpartyName'?: string;
    /**
     * ID Фирмы Счета (Invoice)
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'invoiceFirmId'?: number | null;
    /**
     * ID Счета (Invoice)
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'invoiceId'?: number | null;
    /**
     * Номер Счета
     * @type {string}
     * @memberof RmaBrowseDto
     */
    'invoiceNumber'?: string | null;
    /**
     * ID Запроса на Разрешение
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'rmaRequestId'?: number | null;
    /**
     * Номер Запроса на Разрешение
     * @type {string}
     * @memberof RmaBrowseDto
     */
    'rmaRequestNumber'?: string | null;
    /**
     * Id валюты документа
     * @type {number}
     * @memberof RmaBrowseDto
     */
    'docVal'?: number;
    /**
     * Признак возврата
     * @type {boolean}
     * @memberof RmaBrowseDto
     */
    'return'?: boolean;
}
/**
 * 
 * @export
 * @interface RmaDeviceBrowseDto
 */
export interface RmaDeviceBrowseDto {
    /**
     * получен-неполучен
     * @type {boolean}
     * @memberof RmaDeviceBrowseDto
     */
    'chk'?: boolean | null;
    /**
     * Id прибора в RMA
     * @type {number}
     * @memberof RmaDeviceBrowseDto
     */
    'deviceId'?: number;
    /**
     * Id предприятия в RMA
     * @type {number}
     * @memberof RmaDeviceBrowseDto
     */
    'firmId'?: number;
    /**
     * Серийный номер текущий
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'nomer'?: string;
    /**
     * Номер строки
     * @type {number}
     * @memberof RmaDeviceBrowseDto
     */
    'idStr'?: number | null;
    /**
     * Действует до
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'dataOkon'?: string | null;
    /**
     * Производственный код
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'sek'?: string;
    /**
     * Признак ремонтопригодности
     * @type {number}
     * @memberof RmaDeviceBrowseDto
     */
    'prRem'?: number;
    /**
     * Признак лицензии
     * @type {boolean}
     * @memberof RmaDeviceBrowseDto
     */
    'prLic'?: boolean;
    /**
     * Id лицензии
     * @type {number}
     * @memberof RmaDeviceBrowseDto
     */
    'idLic'?: number | null;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'sku'?: string | null;
    /**
     * Название прибора на английском
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'nameEn'?: string;
    /**
     * Название прибора на русском
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'nameRu'?: string;
    /**
     * Цена
     * @type {number}
     * @memberof RmaDeviceBrowseDto
     */
    'cena'?: number;
    /**
     * Ключ валюты по прибору
     * @type {number}
     * @memberof RmaDeviceBrowseDto
     */
    'val'?: number;
    /**
     * Обозначение валюты по прибору
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'obozn'?: string;
    /**
     * Дата изготовления
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'dataProiz'?: string | null;
    /**
     * Дата продажи
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'dataProd'?: string | null;
    /**
     * Дата сдачи прибора Владельцем в ремонт Дистрибьютору
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'dataReceiptForRepair'?: string | null;
    /**
     * Гарантия
     * @type {boolean}
     * @memberof RmaDeviceBrowseDto
     */
    'warranty'?: boolean;
    /**
     * Вернуть владельцу
     * @type {boolean}
     * @memberof RmaDeviceBrowseDto
     */
    'wait'?: boolean;
    /**
     * Жалобы
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'complaints'?: string | null;
    /**
     * Стоймость ремонта
     * @type {number}
     * @memberof RmaDeviceBrowseDto
     */
    'stoimRem'?: number;
    /**
     * Номер лицензии
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'nLic'?: string | null;
    /**
     * Комплектность (что присутствует)
     * @type {string}
     * @memberof RmaDeviceBrowseDto
     */
    'komplekt'?: string | null;
    /**
     * Цена прибора соответствует цене в Лицензии
     * @type {boolean}
     * @memberof RmaDeviceBrowseDto
     */
    'isValidLicensePrice'?: boolean;
    /**
     * Дистрибьютор не является покупателем
     * @type {boolean}
     * @memberof RmaDeviceBrowseDto
     */
    'isDistributorNotBuyer'?: boolean;
}
/**
 * 
 * @export
 * @interface RmaGlobalCounterpartyDto
 */
export interface RmaGlobalCounterpartyDto {
    /**
     * Id компании
     * @type {number}
     * @memberof RmaGlobalCounterpartyDto
     */
    'counterpartyId'?: number;
    /**
     * Название компании
     * @type {string}
     * @memberof RmaGlobalCounterpartyDto
     */
    'name'?: string;
    /**
     * Количество заявок
     * @type {number}
     * @memberof RmaGlobalCounterpartyDto
     */
    'rmaCount'?: number;
    /**
     * Ключ валюты
     * @type {number}
     * @memberof RmaGlobalCounterpartyDto
     */
    'currencyId'?: number;
}
/**
 * 
 * @export
 * @interface RmaRequestAddDeviceDto
 */
export interface RmaRequestAddDeviceDto {
    /**
     * Производственный код
     * @type {string}
     * @memberof RmaRequestAddDeviceDto
     */
    'se'?: string;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof RmaRequestAddDeviceDto
     */
    'sku'?: string;
    /**
     * Наименование (RU)
     * @type {string}
     * @memberof RmaRequestAddDeviceDto
     */
    'nameR'?: string;
    /**
     * Наименование (EN)
     * @type {string}
     * @memberof RmaRequestAddDeviceDto
     */
    'nameE'?: string;
    /**
     * Кол-во приборов
     * @type {number}
     * @memberof RmaRequestAddDeviceDto
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface RmaRequestBrowseDto
 */
export interface RmaRequestBrowseDto {
    /**
     * Ключ заявки
     * @type {number}
     * @memberof RmaRequestBrowseDto
     */
    'id'?: number;
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof RmaRequestBrowseDto
     */
    'counterpartyId'?: number;
    /**
     * Наименование контрагента
     * @type {string}
     * @memberof RmaRequestBrowseDto
     */
    'counterpartyName'?: string;
    /**
     * Номер заявки
     * @type {string}
     * @memberof RmaRequestBrowseDto
     */
    'requestNumber'?: string;
    /**
     * Дата заявки
     * @type {string}
     * @memberof RmaRequestBrowseDto
     */
    'requestDate'?: string;
    /**
     * 
     * @type {RmaRequestStatus}
     * @memberof RmaRequestBrowseDto
     */
    'status'?: RmaRequestStatus;
    /**
     * Кол-во приборов в заявке
     * @type {number}
     * @memberof RmaRequestBrowseDto
     */
    'numberOfDevices'?: number;
    /**
     * Кол-во RMAs относящихся к заявке
     * @type {number}
     * @memberof RmaRequestBrowseDto
     */
    'numberOfRMAs'?: number;
    /**
     * Заявка на обновление
     * @type {boolean}
     * @memberof RmaRequestBrowseDto
     */
    'isUpgradable'?: boolean;
}


/**
 * 
 * @export
 * @interface RmaRequestDeviceBrowseDto
 */
export interface RmaRequestDeviceBrowseDto {
    /**
     * Ключ заявки
     * @type {number}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'requestId'?: number;
    /**
     * Ключ прибора в заявке
     * @type {number}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'deviceId'?: number;
    /**
     * Производственный код
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'se'?: string;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'sku'?: string;
    /**
     * Наименование (RU)
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'nameR'?: string;
    /**
     * Наименование (EN)
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'nameE'?: string;
    /**
     * Серийный номер
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'serial'?: string | null;
    /**
     * Жалобы
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'complaints'?: string | null;
    /**
     * Описание причины
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'note'?: string | null;
    /**
     * Дата продажи (по дистрибьютору)
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'dataProd'?: string | null;
    /**
     * Производитель (по серийному номеру)
     * @type {number}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'proiz'?: number | null;
    /**
     * Дата производства (по серийному номеру)
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'dataProiz'?: string | null;
    /**
     * Дата отгрузки прибора дистрибьютору (по серийному номеру)
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'dataOtgr'?: string | null;
    /**
     * Дата поступления прибора на ремонт от Покупателя Дистрибьютору
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'dataReceiptForRepair'?: string | null;
    /**
     * Возврат владельцу или дистрибьютору
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'returnToOwner'?: boolean;
    /**
     * Укомплектовка до продажного состояния
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'completeToSaleState'?: boolean;
    /**
     * Ремонт до продажного состояния
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'repairToSaleState'?: boolean;
    /**
     * Возврат от покупателя
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'isBuyerReturn'?: boolean;
    /**
     * Гарантийный
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'warranty'?: boolean;
    /**
     * Нужен быстрый ремонт
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'speedRepair'?: boolean;
    /**
     * Комментарий
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'comments'?: string | null;
    /**
     * Rma фирма ()
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'beltex'?: boolean;
    /**
     * Rma фирма ()
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'mezon'?: boolean;
    /**
     * Rma фирма ()
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'polaris'?: boolean;
    /**
     * Rma фирма ()
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'yukon'?: boolean;
    /**
     * Rma фирма ()
     * @type {boolean}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'pulsar'?: boolean;
    /**
     * Срок гарантии (лет)
     * @type {number}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'warrantyYears'?: number;
    /**
     * Ремонтопригодность
     * @type {number}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'repairId'?: number | null;
    /**
     * Ремонтопригодность
     * @type {string}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'repair'?: string | null;
    /**
     * Ключ связанного RMA документа
     * @type {number}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'rmaId'?: number | null;
    /**
     * Ключ связанного прибора с RMA или Invoice
     * @type {number}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'rmaDeviceId'?: number | null;
    /**
     * Ключ компании
     * @type {number}
     * @memberof RmaRequestDeviceBrowseDto
     */
    'rmaFirmId'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RmaRequestDeviceFirmSelection = {
    Beltex: 'Beltex',
    Mezon: 'Mezon',
    Polaris: 'Polaris',
    Yukon: 'Yukon',
    Pulsar: 'Pulsar'
} as const;

export type RmaRequestDeviceFirmSelection = typeof RmaRequestDeviceFirmSelection[keyof typeof RmaRequestDeviceFirmSelection];


/**
 * 
 * @export
 * @interface RmaRequestGlobalCounterpartyDto
 */
export interface RmaRequestGlobalCounterpartyDto {
    /**
     * Id компании
     * @type {number}
     * @memberof RmaRequestGlobalCounterpartyDto
     */
    'counterpartyId'?: number;
    /**
     * Название компании
     * @type {string}
     * @memberof RmaRequestGlobalCounterpartyDto
     */
    'name'?: string;
    /**
     * Количество заявок
     * @type {number}
     * @memberof RmaRequestGlobalCounterpartyDto
     */
    'rmaCount'?: number;
    /**
     * Наличие отправленных заявок
     * @type {boolean}
     * @memberof RmaRequestGlobalCounterpartyDto
     */
    'hasSubmitted'?: boolean;
}
/**
 * 
 * @export
 * @interface RmaRequestRmaLookupDto
 */
export interface RmaRequestRmaLookupDto {
    /**
     * Id фирмы разрешения (RMA)
     * @type {number}
     * @memberof RmaRequestRmaLookupDto
     */
    'rmaFirmId'?: number;
    /**
     * ID разрешения (RMA)
     * @type {number}
     * @memberof RmaRequestRmaLookupDto
     */
    'rmaId'?: number;
    /**
     * Номер Разрешения
     * @type {string}
     * @memberof RmaRequestRmaLookupDto
     */
    'rmaNumber'?: string;
    /**
     * Статус Разрешения
     * @type {string}
     * @memberof RmaRequestRmaLookupDto
     */
    'rmaStatus'?: string;
    /**
     * Дата Разрешения
     * @type {string}
     * @memberof RmaRequestRmaLookupDto
     */
    'rmaDate'?: string;
    /**
     * Краткое наименование Дистрибьютора
     * @type {string}
     * @memberof RmaRequestRmaLookupDto
     */
    'counterpartyName'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RmaRequestStatus = {
    InProcess: 'InProcess',
    Submitted: 'Submitted',
    Received: 'Received',
    Approved: 'Approved',
    Closed: 'Closed',
    Rejected: 'Rejected'
} as const;

export type RmaRequestStatus = typeof RmaRequestStatus[keyof typeof RmaRequestStatus];


/**
 * 
 * @export
 * @interface RmaUpdateDeviceDto
 */
export interface RmaUpdateDeviceDto {
    /**
     * Id прибора
     * @type {number}
     * @memberof RmaUpdateDeviceDto
     */
    'deviceId'?: number;
    /**
     * Цена прибора
     * @type {number}
     * @memberof RmaUpdateDeviceDto
     */
    'cena'?: number;
}
/**
 * 
 * @export
 * @interface SearchDeviceBrowseDto
 */
export interface SearchDeviceBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceBrowseDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceBrowseDto
     */
    'firmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'deviceOwner'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceBrowseDto
     */
    'counterpartyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'counterpartyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'se'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'serial'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'newSerial'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'replaceSerialOperation'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'soPr'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDeviceBrowseDto
     */
    'inTransit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDeviceBrowseDto
     */
    'readyForShipment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'areaNameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'areaNameEn'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceBrowseDto
     */
    'replacedDeviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceBrowseDto
     */
    'replacedDeviceFirmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceBrowseDto
     */
    'replacedDeviceCounterpartyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'replacementDeviceSe'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceBrowseDto
     */
    'rmaDeviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceBrowseDto
     */
    'rmaDeviceFirmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceBrowseDto
     */
    'rmaDeviceCounterpartyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceBrowseDto
     */
    'rmaDeviceSe'?: string;
}
/**
 * 
 * @export
 * @interface SearchDeviceDocumentBrowseDto
 */
export interface SearchDeviceDocumentBrowseDto {
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'deviceFirmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'counterpartyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'counterpartyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'documentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'receiveDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'documentCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'documentStatus'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'isShipped'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'deliveryCompany'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDeviceDocumentBrowseDto
     */
    'isRefund'?: boolean;
}
/**
 * 
 * @export
 * @interface SendInvoicePayload
 */
export interface SendInvoicePayload {
    /**
     * 
     * @type {number}
     * @memberof SendInvoicePayload
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SendInvoicePayload
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface SendInvoiceReplacedPartsToSbytResponse
 */
export interface SendInvoiceReplacedPartsToSbytResponse {
    /**
     * 
     * @type {SendReplacedPartsToSbytResult}
     * @memberof SendInvoiceReplacedPartsToSbytResponse
     */
    'result'?: SendReplacedPartsToSbytResult;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SendReplacedPartsToSbytResult = {
    Success: 'Success',
    ReplacedDevicesNotFound: 'ReplacedDevicesNotFound',
    SomeDevicesAreMissingInSbyt: 'SomeDevicesAreMissingInSbyt'
} as const;

export type SendReplacedPartsToSbytResult = typeof SendReplacedPartsToSbytResult[keyof typeof SendReplacedPartsToSbytResult];


/**
 * 
 * @export
 * @interface SendToExternalDeviceDto
 */
export interface SendToExternalDeviceDto {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SendToExternalDeviceDto
     */
    'firmId'?: number;
    /**
     * Ид прибора
     * @type {number}
     * @memberof SendToExternalDeviceDto
     */
    'deviceId'?: number;
    /**
     * Код СЕ
     * @type {string}
     * @memberof SendToExternalDeviceDto
     */
    'sek'?: string;
    /**
     * Серийный номер
     * @type {string}
     * @memberof SendToExternalDeviceDto
     */
    'serial'?: string;
    /**
     * Признак транзита
     * @type {boolean}
     * @memberof SendToExternalDeviceDto
     */
    'transit'?: boolean;
    /**
     * Признак подготовки к отправке
     * @type {boolean}
     * @memberof SendToExternalDeviceDto
     */
    'po'?: boolean;
    /**
     * Номер склада, отличный от склада СО
     * @type {string}
     * @memberof SendToExternalDeviceDto
     */
    'skl'?: string;
}
/**
 * 
 * @export
 * @interface SendToExternalPayload
 */
export interface SendToExternalPayload {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SendToExternalPayload
     */
    'firmId'?: number;
    /**
     * Номер внешнего Сервисного центра
     * @type {string}
     * @memberof SendToExternalPayload
     */
    'skl'?: string;
    /**
     * Номер документа
     * @type {string}
     * @memberof SendToExternalPayload
     */
    'documentNumber'?: string;
    /**
     * Дата документа, формат yyyy-MM-dd
     * @type {string}
     * @memberof SendToExternalPayload
     */
    'documentDate'?: string;
    /**
     * Список приборов для передачи
     * @type {Array<SendToExternalDeviceDto>}
     * @memberof SendToExternalPayload
     */
    'devices'?: Array<SendToExternalDeviceDto>;
}
/**
 * 
 * @export
 * @interface SendToExternalResponse
 */
export interface SendToExternalResponse {
    /**
     * 
     * @type {SendToExternalResult}
     * @memberof SendToExternalResponse
     */
    'result'?: SendToExternalResult;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SendToExternalResult = {
    Success: 'Success',
    NoDevicesForTransfer: 'NoDevicesForTransfer'
} as const;

export type SendToExternalResult = typeof SendToExternalResult[keyof typeof SendToExternalResult];


/**
 * 
 * @export
 * @interface SendToRepairDeviceDto
 */
export interface SendToRepairDeviceDto {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SendToRepairDeviceDto
     */
    'firmId'?: number;
    /**
     * Ид прибора
     * @type {number}
     * @memberof SendToRepairDeviceDto
     */
    'deviceId'?: number;
    /**
     * Предварительный контроль назначен и не пройден
     * @type {boolean}
     * @memberof SendToRepairDeviceDto
     */
    'bcc'?: boolean;
}
/**
 * 
 * @export
 * @interface SendToRepairPayload
 */
export interface SendToRepairPayload {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SendToRepairPayload
     */
    'firmId'?: number;
    /**
     * Номер участка передачи
     * @type {string}
     * @memberof SendToRepairPayload
     */
    'repairArea'?: string;
    /**
     * Дата передачи, формат yyyy-MM-dd
     * @type {string}
     * @memberof SendToRepairPayload
     */
    'dateTransfer'?: string;
    /**
     * Список приборов для передачи
     * @type {Array<SendToRepairDeviceDto>}
     * @memberof SendToRepairPayload
     */
    'devices'?: Array<SendToRepairDeviceDto>;
}
/**
 * 
 * @export
 * @interface SendToRepairPlanDeviceDto
 */
export interface SendToRepairPlanDeviceDto {
    /**
     * Ключ прибора
     * @type {number}
     * @memberof SendToRepairPlanDeviceDto
     */
    'deviceId'?: number;
    /**
     * Ключ компании
     * @type {number}
     * @memberof SendToRepairPlanDeviceDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface SendToRepairPlanPayload
 */
export interface SendToRepairPlanPayload {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SendToRepairPlanPayload
     */
    'firmId'?: number;
    /**
     * Участок
     * @type {string}
     * @memberof SendToRepairPlanPayload
     */
    'repairArea'?: string;
    /**
     * Дата планируемого ремонта, формат yyyy-MM-dd
     * @type {string}
     * @memberof SendToRepairPlanPayload
     */
    'dateRepairPlan'?: string;
    /**
     * Выбранные прибор
     * @type {Array<SendToRepairPlanDeviceDto>}
     * @memberof SendToRepairPlanPayload
     */
    'devices'?: Array<SendToRepairPlanDeviceDto>;
}
/**
 * 
 * @export
 * @interface SerialBrowseDto
 */
export interface SerialBrowseDto {
    /**
     * Серийный номер
     * @type {string}
     * @memberof SerialBrowseDto
     */
    'serial'?: string;
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof SerialBrowseDto
     */
    'counterpartyId'?: number;
    /**
     * Наименование контрагента
     * @type {string}
     * @memberof SerialBrowseDto
     */
    'counterpartyName'?: string;
    /**
     * Дата
     * @type {string}
     * @memberof SerialBrowseDto
     */
    'date'?: string;
    /**
     * Примечание
     * @type {string}
     * @memberof SerialBrowseDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface SerialExistCheckResponse
 */
export interface SerialExistCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SerialExistCheckResponse
     */
    'isUnique'?: boolean;
}
/**
 * 
 * @export
 * @interface ServiceCostsReportAggregateDataDto
 */
export interface ServiceCostsReportAggregateDataDto {
    /**
     * 
     * @type {Array<ServiceCostsReportDataDto>}
     * @memberof ServiceCostsReportAggregateDataDto
     */
    'warrantyRepairMaterials'?: Array<ServiceCostsReportDataDto>;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportAggregateDataDto
     */
    'warrantyRepairSalaryEur'?: number;
    /**
     * 
     * @type {Array<ServiceCostsReportDataDto>}
     * @memberof ServiceCostsReportAggregateDataDto
     */
    'nonWarrantyRepairMaterials'?: Array<ServiceCostsReportDataDto>;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportAggregateDataDto
     */
    'nonWarrantyRepairSalaryEur'?: number;
    /**
     * 
     * @type {Array<ServiceCostsReportDataDto>}
     * @memberof ServiceCostsReportAggregateDataDto
     */
    'issuedInvoices'?: Array<ServiceCostsReportDataDto>;
    /**
     * 
     * @type {Array<ServiceCostsReportDataDto>}
     * @memberof ServiceCostsReportAggregateDataDto
     */
    'transportation'?: Array<ServiceCostsReportDataDto>;
    /**
     * 
     * @type {Array<ServiceCostsReportDataDto>}
     * @memberof ServiceCostsReportAggregateDataDto
     */
    'repairedDevices'?: Array<ServiceCostsReportDataDto>;
}
/**
 * 
 * @export
 * @interface ServiceCostsReportBrowseItemDto
 */
export interface ServiceCostsReportBrowseItemDto {
    /**
     * Фирма
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'firmId'?: number;
    /**
     * Год
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'year'?: number;
    /**
     * Месяц
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'month'?: number;
    /**
     * Гарантийный ремонт - Зарплата
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'warrantyRepairSalary'?: number;
    /**
     * Гарантийный ремонт - Материалы
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'warrantyRepairMaterials'?: number;
    /**
     * Негарантийный ремонт - Зарплата
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'nonWarrantyRepairSalary'?: number;
    /**
     * Негарантийный ремонт - Материалы
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'nonWarrantyRepairMaterials'?: number;
    /**
     * Выставленный Счета за негарагтийный ремонт
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'issuedInvoices'?: number;
    /**
     * Транспортные расходы
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'transportation'?: number;
    /**
     * Общая стоимость отремонтированных приборов (гарантийные, не Простаф, не ВернутьБезРемонта и не Годные)
     * @type {number}
     * @memberof ServiceCostsReportBrowseItemDto
     */
    'repairedDevices'?: number;
}
/**
 * 
 * @export
 * @interface ServiceCostsReportDataDto
 */
export interface ServiceCostsReportDataDto {
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportDataDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceCostsReportDataDto
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportDataDto
     */
    'amountEur'?: number;
}
/**
 * 
 * @export
 * @interface ServiceCostsReportExportPayload
 */
export interface ServiceCostsReportExportPayload {
    /**
     * Период - Год
     * @type {number}
     * @memberof ServiceCostsReportExportPayload
     */
    'year'?: number;
    /**
     * Язык
     * @type {number}
     * @memberof ServiceCostsReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface ServiceCostsReportSaveItemPayload
 */
export interface ServiceCostsReportSaveItemPayload {
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'warrantyRepairMaterials'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'warrantyRepairSalary'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'nonWarrantyRepairMaterials'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'nonWarrantyRepairSalary'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'issuedInvoices'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'transportation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceCostsReportSaveItemPayload
     */
    'repairedDevices'?: number;
}
/**
 * 
 * @export
 * @interface SetPreliminaryCostPayload
 */
export interface SetPreliminaryCostPayload {
    /**
     * 
     * @type {boolean}
     * @memberof SetPreliminaryCostPayload
     */
    'preliminaryRepairCost'?: boolean;
}
/**
 * 
 * @export
 * @interface SetRepeatedRepairConfirmedPayload
 */
export interface SetRepeatedRepairConfirmedPayload {
    /**
     * 
     * @type {boolean}
     * @memberof SetRepeatedRepairConfirmedPayload
     */
    'repeatedRepairConfirmed'?: boolean;
}
/**
 * 
 * @export
 * @interface SetUserNamePayload
 */
export interface SetUserNamePayload {
    /**
     * 
     * @type {string}
     * @memberof SetUserNamePayload
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetUserNamePayload
     */
    'firstName'?: string;
}
/**
 * 
 * @export
 * @interface SetWarrantyPayload
 */
export interface SetWarrantyPayload {
    /**
     * 
     * @type {boolean}
     * @memberof SetWarrantyPayload
     */
    'enableWarranty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SetWarrantyPayload
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface SparePartBySeDto
 */
export interface SparePartBySeDto {
    /**
     * Код СЕ
     * @type {string}
     * @memberof SparePartBySeDto
     */
    'seK'?: string;
    /**
     * Наименование
     * @type {string}
     * @memberof SparePartBySeDto
     */
    'name'?: string;
    /**
     * Количество
     * @type {number}
     * @memberof SparePartBySeDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface SparePartsAreaDto
 */
export interface SparePartsAreaDto {
    /**
     * 
     * @type {string}
     * @memberof SparePartsAreaDto
     */
    'repairArea'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsAreaDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsAreaDto
     */
    'nameRu'?: string;
}
/**
 * 
 * @export
 * @interface SparePartsReceiptPayload
 */
export interface SparePartsReceiptPayload {
    /**
     * Начала периода
     * @type {string}
     * @memberof SparePartsReceiptPayload
     */
    'dateFrom'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof SparePartsReceiptPayload
     */
    'dateTo'?: string;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SparePartsReceiptPayload
     */
    'firmId'?: number;
    /**
     * Номер участка ремонта
     * @type {string}
     * @memberof SparePartsReceiptPayload
     */
    'repairArea'?: string | null;
}
/**
 * 
 * @export
 * @interface SparePartsReceiptReportDto
 */
export interface SparePartsReceiptReportDto {
    /**
     * Номер участка
     * @type {string}
     * @memberof SparePartsReceiptReportDto
     */
    'repairArea'?: string;
    /**
     * Наименование участка английское
     * @type {string}
     * @memberof SparePartsReceiptReportDto
     */
    'repairAreaNameEn'?: string;
    /**
     * Наименование участка русское
     * @type {string}
     * @memberof SparePartsReceiptReportDto
     */
    'repairAreaNameRu'?: string;
    /**
     * Код СЕ комплектации
     * @type {string}
     * @memberof SparePartsReceiptReportDto
     */
    'seK'?: string;
    /**
     * Наименование комплектации английское
     * @type {string}
     * @memberof SparePartsReceiptReportDto
     */
    'nameEn'?: string;
    /**
     * Наименование комплектации русское
     * @type {string}
     * @memberof SparePartsReceiptReportDto
     */
    'nameRu'?: string;
    /**
     * Количество, выданное на участок
     * @type {number}
     * @memberof SparePartsReceiptReportDto
     */
    'quantity'?: number;
    /**
     * Дата выдачи на участок
     * @type {string}
     * @memberof SparePartsReceiptReportDto
     */
    'operationDate'?: string;
    /**
     * Имя сотрудника, выдавшего комплектацию
     * @type {string}
     * @memberof SparePartsReceiptReportDto
     */
    'firstName'?: string;
    /**
     * Фамилия сотрудника, выдавшего комплектацию
     * @type {string}
     * @memberof SparePartsReceiptReportDto
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface SparePartsReceiptReportToExcelPayload
 */
export interface SparePartsReceiptReportToExcelPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof SparePartsReceiptReportToExcelPayload
     */
    'dateFrom'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof SparePartsReceiptReportToExcelPayload
     */
    'dateTo'?: string;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SparePartsReceiptReportToExcelPayload
     */
    'firmId'?: number;
    /**
     * Участок ремонта
     * @type {string}
     * @memberof SparePartsReceiptReportToExcelPayload
     */
    'repairArea'?: string | null;
    /**
     * Язык отчета
     * @type {number}
     * @memberof SparePartsReceiptReportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface SparePartsReceiptReportToPdfPayload
 */
export interface SparePartsReceiptReportToPdfPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof SparePartsReceiptReportToPdfPayload
     */
    'dateFrom'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof SparePartsReceiptReportToPdfPayload
     */
    'dateTo'?: string;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SparePartsReceiptReportToPdfPayload
     */
    'firmId'?: number;
    /**
     * Участок ремонта
     * @type {string}
     * @memberof SparePartsReceiptReportToPdfPayload
     */
    'repairArea'?: string | null;
    /**
     * Язык отчета
     * @type {number}
     * @memberof SparePartsReceiptReportToPdfPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface SparePartsReportToExcelPayload
 */
export interface SparePartsReportToExcelPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof SparePartsReportToExcelPayload
     */
    'dateFrom'?: string;
    /**
     * Конц периода
     * @type {string}
     * @memberof SparePartsReportToExcelPayload
     */
    'dateTo'?: string;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SparePartsReportToExcelPayload
     */
    'firmId'?: number;
    /**
     * Номер участка ремонта
     * @type {string}
     * @memberof SparePartsReportToExcelPayload
     */
    'repairArea'?: string | null;
    /**
     * Язык
     * @type {number}
     * @memberof SparePartsReportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface SparePartsSpendinDetailsDto
 */
export interface SparePartsSpendinDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendinDetailsDto
     */
    'repairArea'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendinDetailsDto
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof SparePartsSpendinDetailsDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof SparePartsSpendinDetailsDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SparePartsSpendinDetailsDto
     */
    'deviceFirmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendinDetailsDto
     */
    'deviceSeK'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendinDetailsDto
     */
    'deviceSku'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendinDetailsDto
     */
    'deviceNameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendinDetailsDto
     */
    'deviceNameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendinDetailsDto
     */
    'deviceSerial'?: string;
}
/**
 * 
 * @export
 * @interface SparePartsSpendingDto
 */
export interface SparePartsSpendingDto {
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendingDto
     */
    'repairArea'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendingDto
     */
    'repairAreaNameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendingDto
     */
    'repairAreaNameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendingDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendingDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof SparePartsSpendingDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {number}
     * @memberof SparePartsSpendingDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface SparePartsSpendingReportDto
 */
export interface SparePartsSpendingReportDto {
    /**
     * 
     * @type {Array<SparePartsSpendingDto>}
     * @memberof SparePartsSpendingReportDto
     */
    'sparePartsSpending'?: Array<SparePartsSpendingDto>;
    /**
     * 
     * @type {Array<SparePartsSpendinDetailsDto>}
     * @memberof SparePartsSpendingReportDto
     */
    'sparePartsSpendingDetails'?: Array<SparePartsSpendinDetailsDto>;
}
/**
 * 
 * @export
 * @interface SparePartsSpendingReportPayload
 */
export interface SparePartsSpendingReportPayload {
    /**
     * Дата начала периода
     * @type {string}
     * @memberof SparePartsSpendingReportPayload
     */
    'dateFrom'?: string;
    /**
     * Дата окончания периода
     * @type {string}
     * @memberof SparePartsSpendingReportPayload
     */
    'dateTo'?: string;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SparePartsSpendingReportPayload
     */
    'firmId'?: number;
    /**
     * Номер участка ремонта
     * @type {string}
     * @memberof SparePartsSpendingReportPayload
     */
    'repairArea'?: string | null;
}
/**
 * 
 * @export
 * @interface SparePartsSpendingReportToExcelPayload
 */
export interface SparePartsSpendingReportToExcelPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof SparePartsSpendingReportToExcelPayload
     */
    'dateFrom'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof SparePartsSpendingReportToExcelPayload
     */
    'dateTo'?: string;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SparePartsSpendingReportToExcelPayload
     */
    'firmId'?: number;
    /**
     * Участок ремонта
     * @type {string}
     * @memberof SparePartsSpendingReportToExcelPayload
     */
    'repairArea'?: string | null;
    /**
     * Язык отчета
     * @type {number}
     * @memberof SparePartsSpendingReportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface SparePartsSpendingReportToPdfPayload
 */
export interface SparePartsSpendingReportToPdfPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof SparePartsSpendingReportToPdfPayload
     */
    'dateFrom'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof SparePartsSpendingReportToPdfPayload
     */
    'dateTo'?: string;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SparePartsSpendingReportToPdfPayload
     */
    'firmId'?: number;
    /**
     * Участок ремонта
     * @type {string}
     * @memberof SparePartsSpendingReportToPdfPayload
     */
    'repairArea'?: string | null;
    /**
     * Язык отчета
     * @type {number}
     * @memberof SparePartsSpendingReportToPdfPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface SparePartsTotalSpendingReportToExcelPayload
 */
export interface SparePartsTotalSpendingReportToExcelPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof SparePartsTotalSpendingReportToExcelPayload
     */
    'dateFrom'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof SparePartsTotalSpendingReportToExcelPayload
     */
    'dateTo'?: string;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SparePartsTotalSpendingReportToExcelPayload
     */
    'firmId'?: number;
    /**
     * Язык отчета
     * @type {number}
     * @memberof SparePartsTotalSpendingReportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload
 */
export interface SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload
     */
    'dateFrom'?: string;
    /**
     * Конец периода
     * @type {string}
     * @memberof SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload
     */
    'dateTo'?: string;
    /**
     * Ид фирмы
     * @type {number}
     * @memberof SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload
     */
    'firmId'?: number;
    /**
     * Язык отчета
     * @type {number}
     * @memberof SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface StockAreaDto
 */
export interface StockAreaDto {
    /**
     * Номер склада
     * @type {string}
     * @memberof StockAreaDto
     */
    'skl'?: string;
    /**
     * Название склада (En)
     * @type {string}
     * @memberof StockAreaDto
     */
    'naimEn'?: string;
    /**
     * Название склада (Ru)
     * @type {string}
     * @memberof StockAreaDto
     */
    'naimRu'?: string;
    /**
     * Относится к Отделку тех. контроля
     * @type {boolean}
     * @memberof StockAreaDto
     */
    'otk'?: boolean;
    /**
     * Количество планов ремонта
     * @type {number}
     * @memberof StockAreaDto
     */
    'kol'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StockAreaType = {
    All: 'All',
    Inner: 'Inner',
    InnerManufacturing: 'InnerManufacturing',
    External: 'External',
    ForPlan: 'ForPlan',
    SpareParts: 'SpareParts'
} as const;

export type StockAreaType = typeof StockAreaType[keyof typeof StockAreaType];


/**
 * 
 * @export
 * @interface SubscribePayload
 */
export interface SubscribePayload {
    /**
     * 
     * @type {number}
     * @memberof SubscribePayload
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscribePayload
     */
    'tn'?: string;
}
/**
 * 
 * @export
 * @interface TechnicalConclusionLoadDefectsPayload
 */
export interface TechnicalConclusionLoadDefectsPayload {
    /**
     * 
     * @type {number}
     * @memberof TechnicalConclusionLoadDefectsPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface TechnicalConclusionUpdatePayload
 */
export interface TechnicalConclusionUpdatePayload {
    /**
     * 
     * @type {string}
     * @memberof TechnicalConclusionUpdatePayload
     */
    'vOsmotr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicalConclusionUpdatePayload
     */
    'test'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicalConclusionUpdatePayload
     */
    'zakl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicalConclusionUpdatePayload
     */
    'prichina1'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicalConclusionUpdatePayload
     */
    'prichina2'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicalConclusionUpdatePayload
     */
    'defectNotConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicalConclusionUpdatePayload
     */
    'meetsQuality'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicalConclusionUpdatePayload
     */
    'prevention'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicalConclusionUpdatePayload
     */
    'showAdvice'?: boolean;
}
/**
 * 
 * @export
 * @interface TechnicalConclusionsCreateMissingPayload
 */
export interface TechnicalConclusionsCreateMissingPayload {
    /**
     * 
     * @type {number}
     * @memberof TechnicalConclusionsCreateMissingPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface TimeRateDto
 */
export interface TimeRateDto {
    /**
     * Год
     * @type {number}
     * @memberof TimeRateDto
     */
    'year'?: number;
    /**
     * Месяц
     * @type {number}
     * @memberof TimeRateDto
     */
    'month'?: number;
    /**
     * Участок ремонта
     * @type {string}
     * @memberof TimeRateDto
     */
    'area'?: string;
    /**
     * Наименование участка английское
     * @type {string}
     * @memberof TimeRateDto
     */
    'areaNameEn'?: string;
    /**
     * Наименование участка русское
     * @type {string}
     * @memberof TimeRateDto
     */
    'areaNameRu'?: string;
    /**
     * Норма времени
     * @type {number}
     * @memberof TimeRateDto
     */
    'timeRate'?: number;
}
/**
 * 
 * @export
 * @interface ToRepairAreaPayload
 */
export interface ToRepairAreaPayload {
    /**
     * Ключ компании пользователя
     * @type {number}
     * @memberof ToRepairAreaPayload
     */
    'firmId'?: number;
    /**
     * Номер нового участка
     * @type {string}
     * @memberof ToRepairAreaPayload
     */
    'newAreaRepair'?: string;
    /**
     * Выбранные приборы
     * @type {Array<RepairPlanDevicePrimaryKeyDto>}
     * @memberof ToRepairAreaPayload
     */
    'devices'?: Array<RepairPlanDevicePrimaryKeyDto>;
}
/**
 * 
 * @export
 * @interface TodayEventDto
 */
export interface TodayEventDto {
    /**
     * 
     * @type {string}
     * @memberof TodayEventDto
     */
    'type'?: string;
    /**
     * 
     * @type {any}
     * @memberof TodayEventDto
     */
    'data'?: any;
}
/**
 * 
 * @export
 * @interface TransportCostsReportExportPayload
 */
export interface TransportCostsReportExportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof TransportCostsReportExportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof TransportCostsReportExportPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof TransportCostsReportExportPayload
     */
    'firmId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof TransportCostsReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface UnFixRepairAreaDevicePayload
 */
export interface UnFixRepairAreaDevicePayload {
    /**
     * Id компании
     * @type {number}
     * @memberof UnFixRepairAreaDevicePayload
     */
    'firmId'?: number;
    /**
     * Номер склада
     * @type {string}
     * @memberof UnFixRepairAreaDevicePayload
     */
    'skl'?: string;
    /**
     * Выбранные приборы
     * @type {Array<RepairAreaDevicePrimaryKeyDto>}
     * @memberof UnFixRepairAreaDevicePayload
     */
    'devices'?: Array<RepairAreaDevicePrimaryKeyDto>;
}
/**
 * 
 * @export
 * @interface UnplannedDevicesExportPayload
 */
export interface UnplannedDevicesExportPayload {
    /**
     * 
     * @type {number}
     * @memberof UnplannedDevicesExportPayload
     */
    'firmId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UnplannedDevicesExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface UnplannedDevicesReportDto
 */
export interface UnplannedDevicesReportDto {
    /**
     * 
     * @type {Array<UnplannedDevicesReportItemDto>}
     * @memberof UnplannedDevicesReportDto
     */
    'devices'?: Array<UnplannedDevicesReportItemDto>;
}
/**
 * 
 * @export
 * @interface UnplannedDevicesReportItemDto
 */
export interface UnplannedDevicesReportItemDto {
    /**
     * 
     * @type {string}
     * @memberof UnplannedDevicesReportItemDto
     */
    'recvDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnplannedDevicesReportItemDto
     */
    'seK'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnplannedDevicesReportItemDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnplannedDevicesReportItemDto
     */
    'counterpartyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UnplannedDevicesReportItemDto
     */
    'kol'?: number;
    /**
     * 
     * @type {string}
     * @memberof UnplannedDevicesReportItemDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnplannedDevicesReportItemDto
     */
    'nameRu'?: string;
}
/**
 * 
 * @export
 * @interface UnsubscribePayload
 */
export interface UnsubscribePayload {
    /**
     * 
     * @type {number}
     * @memberof UnsubscribePayload
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribePayload
     */
    'tn'?: string;
}
/**
 * 
 * @export
 * @interface UpdateBatteryPayload
 */
export interface UpdateBatteryPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateBatteryPayload
     */
    'serial'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatteryPayload
     */
    'seK'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBatteryPayload
     */
    'proiz'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatteryPayload
     */
    'dataProiz'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBatteryPayload
     */
    'garantia'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateClientAppVersionPayload
 */
export interface UpdateClientAppVersionPayload {
    /**
     * Версия клиентского приложения
     * @type {string}
     * @memberof UpdateClientAppVersionPayload
     */
    'clientAppVersion'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDefectDeviceGroupsPayload
 */
export interface UpdateDefectDeviceGroupsPayload {
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateDefectDeviceGroupsPayload
     */
    'deviceGroupIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateDefectNotesPayload
 */
export interface UpdateDefectNotesPayload {
    /**
     * Текст примечания
     * @type {string}
     * @memberof UpdateDefectNotesPayload
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDefectPayload
 */
export interface UpdateDefectPayload {
    /**
     * Номер дефекта в формате dd.dd.dd.dd.dd
     * @type {string}
     * @memberof UpdateDefectPayload
     */
    'defectNumber': string;
    /**
     * Наименование дефекта (RU) (Max 100)
     * @type {string}
     * @memberof UpdateDefectPayload
     */
    'nameR': string;
    /**
     * Наименование дефекта (EN) (Max 100)
     * @type {string}
     * @memberof UpdateDefectPayload
     */
    'nameE': string;
    /**
     * Ключ вины
     * @type {number}
     * @memberof UpdateDefectPayload
     */
    'blameId'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateDeviceCountryOriginsDeviceDto
 */
export interface UpdateDeviceCountryOriginsDeviceDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDeviceCountryOriginsDeviceDto
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeviceCountryOriginsDeviceDto
     */
    'firmId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateDeviceCountryOriginsPayload
 */
export interface UpdateDeviceCountryOriginsPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceCountryOriginsPayload
     */
    'countryOriginKod'?: string;
    /**
     * 
     * @type {Array<UpdateDeviceCountryOriginsDeviceDto>}
     * @memberof UpdateDeviceCountryOriginsPayload
     */
    'devices'?: Array<UpdateDeviceCountryOriginsDeviceDto>;
}
/**
 * 
 * @export
 * @interface UpdateDevicePayload
 */
export interface UpdateDevicePayload {
    /**
     * Срочный ремонт
     * @type {boolean}
     * @memberof UpdateDevicePayload
     */
    'speedRepair'?: boolean;
    /**
     * Дата производства, формат yyyy-MM-dd
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'dataProiz'?: string | null;
    /**
     * id-производителя
     * @type {number}
     * @memberof UpdateDevicePayload
     */
    'proiz'?: number | null;
    /**
     * Дата продажи, формат yyyy-MM-dd
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'dataProd'?: string | null;
    /**
     * Вернуть покупателю
     * @type {boolean}
     * @memberof UpdateDevicePayload
     */
    'wait'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDevicePayload
     */
    'defectConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDevicePayload
     */
    'repairToSaleState'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDevicePayload
     */
    'completeToSaleState'?: boolean;
    /**
     * комплект поставки
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'komplekt'?: string | null;
    /**
     * Внешний вид
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'vneshVid'?: string | null;
    /**
     * заявленные неисправности
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'complaints'?: string | null;
    /**
     * Прибор Простафера
     * @type {boolean}
     * @memberof UpdateDevicePayload
     */
    'prostaf'?: boolean;
    /**
     * Примечания
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'notes'?: string | null;
    /**
     * Примечания ОТК
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'notesQc'?: string | null;
    /**
     * Примечания клиента
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'notesKli'?: string | null;
    /**
     * Предварительный анализ неисправностей
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'pAnaliz'?: string | null;
    /**
     * Дополнительные работы
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'dopWorks'?: string | null;
    /**
     * отремонтировать бесплатно (для негарантийных приборов)
     * @type {boolean}
     * @memberof UpdateDevicePayload
     */
    'repairWithoutCost'?: boolean;
    /**
     * Общение с покупателем
     * @type {string}
     * @memberof UpdateDevicePayload
     */
    'customerCommunication'?: string | null;
    /**
     * Замена на новый прибор
     * @type {boolean}
     * @memberof UpdateDevicePayload
     */
    'replacementForNew'?: boolean;
    /**
     * Запросить уточнение
     * @type {boolean}
     * @memberof UpdateDevicePayload
     */
    'requestConfirmation'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UpdateDeviceRepairCostResult = {
    Success: 'Success',
    Empty: 'Empty',
    DeviceRepairCostNotUpdated: 'DeviceRepairCostNotUpdated'
} as const;

export type UpdateDeviceRepairCostResult = typeof UpdateDeviceRepairCostResult[keyof typeof UpdateDeviceRepairCostResult];


/**
 * 
 * @export
 * @interface UpdateDocNotePayload
 */
export interface UpdateDocNotePayload {
    /**
     * Текст примечания
     * @type {string}
     * @memberof UpdateDocNotePayload
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface UpdateFaqPayload
 */
export interface UpdateFaqPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqPayload
     */
    'theme'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqPayload
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateFirmForRmaRequestDevicesPayload
 */
export interface UpdateFirmForRmaRequestDevicesPayload {
    /**
     * 
     * @type {RmaRequestDeviceFirmSelection}
     * @memberof UpdateFirmForRmaRequestDevicesPayload
     */
    'firmSelection'?: RmaRequestDeviceFirmSelection;
    /**
     * Необходимость в создании документа RMA
     * @type {boolean}
     * @memberof UpdateFirmForRmaRequestDevicesPayload
     */
    'rma'?: boolean;
    /**
     * Ключи приборов в заявке
     * @type {Array<number>}
     * @memberof UpdateFirmForRmaRequestDevicesPayload
     */
    'deviceIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface UpdateInvoiceDeclarationPayload
 */
export interface UpdateInvoiceDeclarationPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoiceDeclarationPayload
     */
    'declarationNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateInvoiceDeviceBoxPayload
 */
export interface UpdateInvoiceDeviceBoxPayload {
    /**
     * 
     * @type {number}
     * @memberof UpdateInvoiceDeviceBoxPayload
     */
    'deviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvoiceDeviceBoxPayload
     */
    'box'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateInvoiceDevicePricePayload
 */
export interface UpdateInvoiceDevicePricePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoiceDevicePricePayload
     */
    'sek'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvoiceDevicePricePayload
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface UpdateInvoicePayload
 */
export interface UpdateInvoicePayload {
    /**
     * Номер инвойса
     * @type {string}
     * @memberof UpdateInvoicePayload
     */
    'invoiceNumber'?: string;
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof UpdateInvoicePayload
     */
    'counterpartyId'?: number;
    /**
     * Возврат от покупателя
     * @type {boolean}
     * @memberof UpdateInvoicePayload
     */
    'return'?: boolean;
    /**
     * Валюта контрагента
     * @type {number}
     * @memberof UpdateInvoicePayload
     */
    'docVal'?: number | null;
    /**
     * Дата документа, формат yyyy-MM-dd
     * @type {string}
     * @memberof UpdateInvoicePayload
     */
    'docDate'?: string;
    /**
     * Дата получения, формат yyyy-MM-dd
     * @type {string}
     * @memberof UpdateInvoicePayload
     */
    'receiveDate'?: string | null;
    /**
     * Страна происхождения
     * @type {string}
     * @memberof UpdateInvoicePayload
     */
    'countryOrigin'?: string | null;
    /**
     * Стоимость упаковки/перепаковки
     * @type {number}
     * @memberof UpdateInvoicePayload
     */
    'packageCost'?: number;
    /**
     * Валюта стоимости упаковки/перепаковки
     * @type {number}
     * @memberof UpdateInvoicePayload
     */
    'packageCostCurrency'?: number;
}
/**
 * 
 * @export
 * @interface UpdateInvoicePrintDataPayload
 */
export interface UpdateInvoicePrintDataPayload {
    /**
     * Количество мест (коробок)
     * @type {number}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'boxes'?: number;
    /**
     * Вес
     * @type {number}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'weight'?: number;
    /**
     * Условия доставки
     * @type {string}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'deliveryTerms'?: string;
    /**
     * Примечание 1
     * @type {string}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'notes1'?: string;
    /**
     * Примечание 2
     * @type {string}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'notes2'?: string;
    /**
     * Перевозчик
     * @type {string}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'carrier'?: string;
    /**
     * Транспортный документ
     * @type {string}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'carrierDocument'?: string;
    /**
     * Стоимость доставки
     * @type {number}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'carrierCost'?: number;
    /**
     * Валюта доставки
     * @type {number}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'carrierCurrency'?: number;
    /**
     * Получатель и Плательщик это одно лицо
     * @type {boolean}
     * @memberof UpdateInvoicePrintDataPayload
     */
    'recipientIsPurchaser'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateInvoiceReceiveDatePayload
 */
export interface UpdateInvoiceReceiveDatePayload {
    /**
     * Дата получения, формат yyyy-MM-dd
     * @type {string}
     * @memberof UpdateInvoiceReceiveDatePayload
     */
    'receiveDate'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateMaintainabilityDevicePayload
 */
export interface UpdateMaintainabilityDevicePayload {
    /**
     * Ключ ремонтопригодности
     * @type {number}
     * @memberof UpdateMaintainabilityDevicePayload
     */
    'repairId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateRepairDevicePayload
 */
export interface UpdateRepairDevicePayload {
    /**
     * Id группы ремонта
     * @type {number}
     * @memberof UpdateRepairDevicePayload
     */
    'repairGroupId'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateRepairGroupPayload
 */
export interface UpdateRepairGroupPayload {
    /**
     * Наименование (RU) maxlength=50
     * @type {string}
     * @memberof UpdateRepairGroupPayload
     */
    'nameR': string;
    /**
     * Наименование (EN) maxlength=50
     * @type {string}
     * @memberof UpdateRepairGroupPayload
     */
    'nameE': string;
    /**
     * Норма-часы
     * @type {number}
     * @memberof UpdateRepairGroupPayload
     */
    'normHours'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateRepairListDevicesPayload
 */
export interface UpdateRepairListDevicesPayload {
    /**
     * 
     * @type {Array<RepairListUpdateDeviceDto>}
     * @memberof UpdateRepairListDevicesPayload
     */
    'devices'?: Array<RepairListUpdateDeviceDto>;
}
/**
 * 
 * @export
 * @interface UpdateRepairListPayload
 */
export interface UpdateRepairListPayload {
    /**
     * 
     * @type {number}
     * @memberof UpdateRepairListPayload
     */
    'utvId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRepairListPayload
     */
    'an1Id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRepairListPayload
     */
    'an2Id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRepairListPayload
     */
    'an3Id'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateRmaDevicePricePayload
 */
export interface UpdateRmaDevicePricePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateRmaDevicePricePayload
     */
    'sek'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRmaDevicePricePayload
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface UpdateRmaDevicesPayload
 */
export interface UpdateRmaDevicesPayload {
    /**
     * Приборы для ручного обновления
     * @type {Array<RmaUpdateDeviceDto>}
     * @memberof UpdateRmaDevicesPayload
     */
    'devices'?: Array<RmaUpdateDeviceDto>;
}
/**
 * 
 * @export
 * @interface UpdateRmaPayload
 */
export interface UpdateRmaPayload {
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof UpdateRmaPayload
     */
    'counterpartyId'?: number;
    /**
     * Валюта контрагента
     * @type {number}
     * @memberof UpdateRmaPayload
     */
    'docVal'?: number | null;
    /**
     * Дата документа, формат yyyy-MM-dd
     * @type {string}
     * @memberof UpdateRmaPayload
     */
    'docDate'?: string;
    /**
     * Возврат от покупателя
     * @type {boolean}
     * @memberof UpdateRmaPayload
     */
    'return'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateRmaRequestDevicePayload
 */
export interface UpdateRmaRequestDevicePayload {
    /**
     * Производственный код
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'se'?: string;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'sku'?: string;
    /**
     * Наименование (RU)
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'nameR'?: string;
    /**
     * Наименование (EN)
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'nameE'?: string;
    /**
     * Серийный номер
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'serial'?: string | null;
    /**
     * Жалобы
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'complaints'?: string | null;
    /**
     * Описание причины
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'note'?: string | null;
    /**
     * Дата продажи (по дистрибьютору), формат даты yyyy-MM-dd
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'dataProd'?: string | null;
    /**
     * Производитель (по серийному номеру)
     * @type {number}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'proiz'?: number | null;
    /**
     * Дата производства (по серийному номеру), формат даты yyyy-MM-dd
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'dataProiz'?: string | null;
    /**
     * Дата отгрузки прибора дистрибьютору (по серийному номеру), формат даты yyyy-MM-dd
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'dataOtgr'?: string | null;
    /**
     * Возврат владельцу или дистрибьютору
     * @type {boolean}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'returnToOwner'?: boolean;
    /**
     * Укомплектовка до продажного состояния
     * @type {boolean}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'completeToSaleState'?: boolean;
    /**
     * Ремонт до продажного состояния
     * @type {boolean}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'repairToSaleState'?: boolean;
    /**
     * Возврат от покупателя
     * @type {boolean}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'isBuyerReturn'?: boolean;
    /**
     * Гарантийный
     * @type {boolean}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'warranty'?: boolean;
    /**
     * Нужен быстрый ремонт
     * @type {boolean}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'speedRepair'?: boolean;
    /**
     * Комментарий
     * @type {string}
     * @memberof UpdateRmaRequestDevicePayload
     */
    'comments'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateRmaRequestPayload
 */
export interface UpdateRmaRequestPayload {
    /**
     * Ключ контрагента
     * @type {number}
     * @memberof UpdateRmaRequestPayload
     */
    'counterpartyId'?: number;
    /**
     * Номер заявки
     * @type {string}
     * @memberof UpdateRmaRequestPayload
     */
    'requestNumber'?: string;
    /**
     * Дата, формат yyyy-MM-dd
     * @type {string}
     * @memberof UpdateRmaRequestPayload
     */
    'requestDate'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSerialNotesPayload
 */
export interface UpdateSerialNotesPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateSerialNotesPayload
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSerialPayload
 */
export interface UpdateSerialPayload {
    /**
     * Id дистрибьютора
     * @type {number}
     * @memberof UpdateSerialPayload
     */
    'counterpartyId': number;
    /**
     * Дата, формат yyyy-MM-dd
     * @type {string}
     * @memberof UpdateSerialPayload
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface UpdateSubscriptionPayload
 */
export interface UpdateSubscriptionPayload {
    /**
     * Код уведомления
     * @type {number}
     * @memberof UpdateSubscriptionPayload
     */
    'id'?: number;
    /**
     * Табельный номер сотрудника
     * @type {string}
     * @memberof UpdateSubscriptionPayload
     */
    'tn'?: string;
    /**
     * Получать уведомления от Polaris
     * @type {boolean}
     * @memberof UpdateSubscriptionPayload
     */
    'p'?: boolean;
    /**
     * Получать уведомления от Pulsar LV
     * @type {boolean}
     * @memberof UpdateSubscriptionPayload
     */
    'l'?: boolean;
    /**
     * Получать уведомления от Yukon
     * @type {boolean}
     * @memberof UpdateSubscriptionPayload
     */
    'y'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateTimeRatePayload
 */
export interface UpdateTimeRatePayload {
    /**
     * норма времени
     * @type {number}
     * @memberof UpdateTimeRatePayload
     */
    'timeRate'?: number;
}
/**
 * 
 * @export
 * @interface UpdateUserPermissionsPayload
 */
export interface UpdateUserPermissionsPayload {
    /**
     * Табельный номер
     * @type {string}
     * @memberof UpdateUserPermissionsPayload
     */
    'tn'?: string;
    /**
     * Полные права (для руководителя)
     * @type {boolean}
     * @memberof UpdateUserPermissionsPayload
     */
    'fullControl'?: boolean;
    /**
     * Переключение между организациями
     * @type {boolean}
     * @memberof UpdateUserPermissionsPayload
     */
    'browseOrganizations'?: boolean;
    /**
     * Работа со складом
     * @type {boolean}
     * @memberof UpdateUserPermissionsPayload
     */
    'skl'?: boolean;
    /**
     * Работа с OTK
     * @type {boolean}
     * @memberof UpdateUserPermissionsPayload
     */
    'otk'?: boolean;
    /**
     * Работа с планами ремонта
     * @type {boolean}
     * @memberof UpdateUserPermissionsPayload
     */
    'planRem'?: boolean;
    /**
     * Работа с ремонтом
     * @type {boolean}
     * @memberof UpdateUserPermissionsPayload
     */
    'rem'?: boolean;
    /**
     * Разрешение на передачу данных в Сбыт
     * @type {boolean}
     * @memberof UpdateUserPermissionsPayload
     */
    'sbyt'?: boolean;
    /**
     * Работа с дефектами
     * @type {boolean}
     * @memberof UpdateUserPermissionsPayload
     */
    'defect'?: boolean;
    /**
     * Разрешение на отправку приборов дистрибьютору
     * @type {boolean}
     * @memberof UpdateUserPermissionsPayload
     */
    'otpr'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateWorkPayload
 */
export interface UpdateWorkPayload {
    /**
     * Наименование русское
     * @type {string}
     * @memberof UpdateWorkPayload
     */
    'nameR'?: string;
    /**
     * Наименование английское
     * @type {string}
     * @memberof UpdateWorkPayload
     */
    'nameE'?: string;
    /**
     * Наименование литовское
     * @type {string}
     * @memberof UpdateWorkPayload
     */
    'nameL'?: string;
    /**
     * Примечание
     * @type {string}
     * @memberof UpdateWorkPayload
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface UpgradeDevicesExportPayload
 */
export interface UpgradeDevicesExportPayload {
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof UpgradeDevicesExportPayload
     */
    'firmId'?: number;
    /**
     * Начало периода
     * @type {string}
     * @memberof UpgradeDevicesExportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof UpgradeDevicesExportPayload
     */
    'dateTo'?: string;
}
/**
 * 
 * @export
 * @interface UpgradeDevicesReportDto
 */
export interface UpgradeDevicesReportDto {
    /**
     * 
     * @type {Array<UpgradeDevicesReportItemDto>}
     * @memberof UpgradeDevicesReportDto
     */
    'devices'?: Array<UpgradeDevicesReportItemDto>;
}
/**
 * 
 * @export
 * @interface UpgradeDevicesReportItemDto
 */
export interface UpgradeDevicesReportItemDto {
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'rmaNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'distributorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'serialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'skuBeforeUpgrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'skuAfterUpgrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'nameBeforeUpgrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'nameAfterUpgrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'sekTransition'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'tzDocNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'recevingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeDevicesReportItemDto
     */
    'outgoingDate'?: string;
}
/**
 * 
 * @export
 * @interface UserBrowseDto
 */
export interface UserBrowseDto {
    /**
     * Табельный номер
     * @type {string}
     * @memberof UserBrowseDto
     */
    'tn'?: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof UserBrowseDto
     */
    'lastName'?: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof UserBrowseDto
     */
    'firstName'?: string;
    /**
     * Закрепленный участок ремонта
     * @type {string}
     * @memberof UserBrowseDto
     */
    'area'?: string | null;
    /**
     * Электронная почта
     * @type {string}
     * @memberof UserBrowseDto
     */
    'email'?: string | null;
    /**
     * Язык уведомлений
     * @type {string}
     * @memberof UserBrowseDto
     */
    'language'?: string;
    /**
     * Компания
     * @type {string}
     * @memberof UserBrowseDto
     */
    'company'?: string;
    /**
     * Признак удаленного пользователя
     * @type {boolean}
     * @memberof UserBrowseDto
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UserPermissions}
     * @memberof UserBrowseDto
     */
    'permissions'?: UserPermissions;
}
/**
 * 
 * @export
 * @interface UserForBadgeBrowseDto
 */
export interface UserForBadgeBrowseDto {
    /**
     * Табельный номер
     * @type {string}
     * @memberof UserForBadgeBrowseDto
     */
    'tn'?: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof UserForBadgeBrowseDto
     */
    'lastName'?: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof UserForBadgeBrowseDto
     */
    'firstName'?: string;
    /**
     * Фото сотрудника
     * @type {string}
     * @memberof UserForBadgeBrowseDto
     */
    'photo'?: string;
    /**
     * Компания
     * @type {string}
     * @memberof UserForBadgeBrowseDto
     */
    'company'?: string;
    /**
     * Закрепленный участок ремонта
     * @type {string}
     * @memberof UserForBadgeBrowseDto
     */
    'area'?: string;
    /**
     * Ключ доступа
     * @type {string}
     * @memberof UserForBadgeBrowseDto
     */
    'secretId'?: string;
    /**
     * Признак удаленного пользователя
     * @type {boolean}
     * @memberof UserForBadgeBrowseDto
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface UserPermissions
 */
export interface UserPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    'fullControl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    'browseOrganizations'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    'skl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    'otk'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    'planRem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    'rem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    'sbyt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    'defect'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissions
     */
    'otpr'?: boolean;
}
/**
 * 
 * @export
 * @interface UserShortProfileDto
 */
export interface UserShortProfileDto {
    /**
     * Ключ контакта
     * @type {string}
     * @memberof UserShortProfileDto
     */
    'id'?: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof UserShortProfileDto
     */
    'lastName'?: string;
    /**
     * Имя
     * @type {string}
     * @memberof UserShortProfileDto
     */
    'firstName'?: string;
    /**
     * Эл.Адрес
     * @type {string}
     * @memberof UserShortProfileDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface WarehouseMovementReportDto
 */
export interface WarehouseMovementReportDto {
    /**
     * 
     * @type {Array<WarehouseMovementReportItemDto>}
     * @memberof WarehouseMovementReportDto
     */
    'devices'?: Array<WarehouseMovementReportItemDto>;
}
/**
 * 
 * @export
 * @interface WarehouseMovementReportExportPayload
 */
export interface WarehouseMovementReportExportPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof WarehouseMovementReportExportPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof WarehouseMovementReportExportPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof WarehouseMovementReportExportPayload
     */
    'firmId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof WarehouseMovementReportExportPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface WarehouseMovementReportExportToPdfPayload
 */
export interface WarehouseMovementReportExportToPdfPayload {
    /**
     * Начало периода
     * @type {string}
     * @memberof WarehouseMovementReportExportToPdfPayload
     */
    'dateFrom'?: string;
    /**
     * конец периода
     * @type {string}
     * @memberof WarehouseMovementReportExportToPdfPayload
     */
    'dateTo'?: string;
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof WarehouseMovementReportExportToPdfPayload
     */
    'firmId'?: number;
    /**
     * язык
     * @type {number}
     * @memberof WarehouseMovementReportExportToPdfPayload
     */
    'lcid'?: number;
}
/**
 * 
 * @export
 * @interface WarehouseMovementReportItemDto
 */
export interface WarehouseMovementReportItemDto {
    /**
     * Номер участка (skl)
     * @type {string}
     * @memberof WarehouseMovementReportItemDto
     */
    'areaRepair'?: string;
    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof WarehouseMovementReportItemDto
     */
    'sku'?: string | null;
    /**
     * Производственный код
     * @type {string}
     * @memberof WarehouseMovementReportItemDto
     */
    'sek'?: string;
    /**
     * Название прибора
     * @type {string}
     * @memberof WarehouseMovementReportItemDto
     */
    'se'?: string;
    /**
     * Начальный остаток: брак
     * @type {number}
     * @memberof WarehouseMovementReportItemDto
     */
    'nachOstBrak'?: number;
    /**
     * Начальный остаток: годые
     * @type {number}
     * @memberof WarehouseMovementReportItemDto
     */
    'nachOstGodn'?: number;
    /**
     * Начальный остаток: участок ремонта
     * @type {number}
     * @memberof WarehouseMovementReportItemDto
     */
    'nachOstAreaRepair'?: number;
    /**
     * Приход СО
     * @type {number}
     * @memberof WarehouseMovementReportItemDto
     */
    'prihodSo'?: number;
    /**
     * Расход СО
     * @type {number}
     * @memberof WarehouseMovementReportItemDto
     */
    'rashodSo'?: number;
    /**
     * Конечный остаток: участок ремонта
     * @type {number}
     * @memberof WarehouseMovementReportItemDto
     */
    'konechOstAreaRepair'?: number;
    /**
     * Конечный остаток: брак
     * @type {number}
     * @memberof WarehouseMovementReportItemDto
     */
    'konechOstBrak'?: number;
    /**
     * Конечный остаток: годные
     * @type {number}
     * @memberof WarehouseMovementReportItemDto
     */
    'konechOstGodn'?: number;
}
/**
 * 
 * @export
 * @interface WorkDto
 */
export interface WorkDto {
    /**
     * Ид работы
     * @type {number}
     * @memberof WorkDto
     */
    'workId'?: number;
    /**
     * Наименование русское
     * @type {string}
     * @memberof WorkDto
     */
    'nameR'?: string;
    /**
     * Наименование английское
     * @type {string}
     * @memberof WorkDto
     */
    'nameE'?: string;
    /**
     * Наименование литовское
     * @type {string}
     * @memberof WorkDto
     */
    'nameL'?: string;
    /**
     * Примечание
     * @type {string}
     * @memberof WorkDto
     */
    'notes'?: string;
}

/**
 * DailyReportsGeneralApi - axios parameter creator
 * @export
 */
export const DailyReportsGeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Общий отчет (ежедневный) - выгрузка в Excel
         * @param {ExportGeneralDailyReportToExcelPayload} [exportGeneralDailyReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDailyReportsGeneralExportExcelPost: async (exportGeneralDailyReportToExcelPayload?: ExportGeneralDailyReportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/daily-reports-general/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportGeneralDailyReportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DailyReportsGeneralApi - functional programming interface
 * @export
 */
export const DailyReportsGeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DailyReportsGeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Общий отчет (ежедневный) - выгрузка в Excel
         * @param {ExportGeneralDailyReportToExcelPayload} [exportGeneralDailyReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDailyReportsGeneralExportExcelPost(exportGeneralDailyReportToExcelPayload?: ExportGeneralDailyReportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDailyReportsGeneralExportExcelPost(exportGeneralDailyReportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DailyReportsGeneralApi - factory interface
 * @export
 */
export const DailyReportsGeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DailyReportsGeneralApiFp(configuration)
    return {
        /**
         * 
         * @summary Общий отчет (ежедневный) - выгрузка в Excel
         * @param {DailyReportsGeneralApiApiDailyReportsGeneralExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDailyReportsGeneralExportExcelPost(requestParameters: DailyReportsGeneralApiApiDailyReportsGeneralExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDailyReportsGeneralExportExcelPost(requestParameters.exportGeneralDailyReportToExcelPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDailyReportsGeneralExportExcelPost operation in DailyReportsGeneralApi.
 * @export
 * @interface DailyReportsGeneralApiApiDailyReportsGeneralExportExcelPostRequest
 */
export interface DailyReportsGeneralApiApiDailyReportsGeneralExportExcelPostRequest {
    /**
     * 
     * @type {ExportGeneralDailyReportToExcelPayload}
     * @memberof DailyReportsGeneralApiApiDailyReportsGeneralExportExcelPost
     */
    readonly exportGeneralDailyReportToExcelPayload?: ExportGeneralDailyReportToExcelPayload
}

/**
 * DailyReportsGeneralApi - object-oriented interface
 * @export
 * @class DailyReportsGeneralApi
 * @extends {BaseAPI}
 */
export class DailyReportsGeneralApi extends BaseAPI {
    /**
     * 
     * @summary Общий отчет (ежедневный) - выгрузка в Excel
     * @param {DailyReportsGeneralApiApiDailyReportsGeneralExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyReportsGeneralApi
     */
    public apiDailyReportsGeneralExportExcelPost(requestParameters: DailyReportsGeneralApiApiDailyReportsGeneralExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return DailyReportsGeneralApiFp(this.configuration).apiDailyReportsGeneralExportExcelPost(requestParameters.exportGeneralDailyReportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DailyReportsRepeatedReturnsApi - axios parameter creator
 * @export
 */
export const DailyReportsRepeatedReturnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Повторные поступления (3 месяца) - выгрузка в Excel
         * @param {RepeatedReturnsDaylyReportExportToExcelPayload} [repeatedReturnsDaylyReportExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDailyReportsRepeatedReturnsExportExcelPost: async (repeatedReturnsDaylyReportExportToExcelPayload?: RepeatedReturnsDaylyReportExportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/daily-reports-repeated-returns/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repeatedReturnsDaylyReportExportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DailyReportsRepeatedReturnsApi - functional programming interface
 * @export
 */
export const DailyReportsRepeatedReturnsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DailyReportsRepeatedReturnsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Повторные поступления (3 месяца) - выгрузка в Excel
         * @param {RepeatedReturnsDaylyReportExportToExcelPayload} [repeatedReturnsDaylyReportExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDailyReportsRepeatedReturnsExportExcelPost(repeatedReturnsDaylyReportExportToExcelPayload?: RepeatedReturnsDaylyReportExportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDailyReportsRepeatedReturnsExportExcelPost(repeatedReturnsDaylyReportExportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DailyReportsRepeatedReturnsApi - factory interface
 * @export
 */
export const DailyReportsRepeatedReturnsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DailyReportsRepeatedReturnsApiFp(configuration)
    return {
        /**
         * 
         * @summary Повторные поступления (3 месяца) - выгрузка в Excel
         * @param {DailyReportsRepeatedReturnsApiApiDailyReportsRepeatedReturnsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDailyReportsRepeatedReturnsExportExcelPost(requestParameters: DailyReportsRepeatedReturnsApiApiDailyReportsRepeatedReturnsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDailyReportsRepeatedReturnsExportExcelPost(requestParameters.repeatedReturnsDaylyReportExportToExcelPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDailyReportsRepeatedReturnsExportExcelPost operation in DailyReportsRepeatedReturnsApi.
 * @export
 * @interface DailyReportsRepeatedReturnsApiApiDailyReportsRepeatedReturnsExportExcelPostRequest
 */
export interface DailyReportsRepeatedReturnsApiApiDailyReportsRepeatedReturnsExportExcelPostRequest {
    /**
     * 
     * @type {RepeatedReturnsDaylyReportExportToExcelPayload}
     * @memberof DailyReportsRepeatedReturnsApiApiDailyReportsRepeatedReturnsExportExcelPost
     */
    readonly repeatedReturnsDaylyReportExportToExcelPayload?: RepeatedReturnsDaylyReportExportToExcelPayload
}

/**
 * DailyReportsRepeatedReturnsApi - object-oriented interface
 * @export
 * @class DailyReportsRepeatedReturnsApi
 * @extends {BaseAPI}
 */
export class DailyReportsRepeatedReturnsApi extends BaseAPI {
    /**
     * 
     * @summary Повторные поступления (3 месяца) - выгрузка в Excel
     * @param {DailyReportsRepeatedReturnsApiApiDailyReportsRepeatedReturnsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyReportsRepeatedReturnsApi
     */
    public apiDailyReportsRepeatedReturnsExportExcelPost(requestParameters: DailyReportsRepeatedReturnsApiApiDailyReportsRepeatedReturnsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return DailyReportsRepeatedReturnsApiFp(this.configuration).apiDailyReportsRepeatedReturnsExportExcelPost(requestParameters.repeatedReturnsDaylyReportExportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Количество разрешенных к планированию приборов с согласованной стоимостью ремонта
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdAgreedRepairCostPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdAgreedRepairCostPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/agreed-repair-cost`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Количество приборов без введенных дефектов
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDefectsRequiredPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdDefectsRequiredPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/defects-required`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Приборы со статусом замена на новый
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesReplacementForNewPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdDevicesReplacementForNewPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/devices-replacement-for-new`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Приборы для которых запрошено уточнение
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesRequestConfirmationPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdDevicesRequestConfirmationPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/devices-request-confirmation`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Приборы для которых заканчивается действие лицензии
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesWithExpiringLicensesPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdDevicesWithExpiringLicensesPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/devices-with-expiring-licenses`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Количество приборов для которых заканчивается действие лицензии
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/devices-with-expiring-licenses-qty`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Негарантийные приборы без стоимости ремонта
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesWithoutCostPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdDevicesWithoutCostPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/devices-without-cost`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Количество лицензий со сроком действия меньше месяца
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdLicensesPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdLicensesPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/licenses`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Количество платных ремонтов, требущих согласования
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdPaidRepairsPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdPaidRepairsPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/paid-repairs`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Количество просроченнных ремонтов
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdRepairPlansPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdRepairPlansPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/repair-plans`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary План ремонта согласован, требуется передача на участок ремонта
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdSendToRepairRequiredPost: async (firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDashboardFirmIdSendToRepairRequiredPost', 'firmId', firmId)
            const localVarPath = `/api/dashboard/{firmId}/send-to-repair-required`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Количество заявок RMA в статусе Отправлена
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardRmaRequestsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/rma-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Количество разрешенных к планированию приборов с согласованной стоимостью ремонта
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdAgreedRepairCostPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdAgreedRepairCostPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Количество приборов без введенных дефектов
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdDefectsRequiredPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdDefectsRequiredPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Приборы со статусом замена на новый
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdDevicesReplacementForNewPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdDevicesReplacementForNewPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Приборы для которых запрошено уточнение
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdDevicesRequestConfirmationPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdDevicesRequestConfirmationPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Приборы для которых заканчивается действие лицензии
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdDevicesWithExpiringLicensesPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceExpiringLicenseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdDevicesWithExpiringLicensesPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Количество приборов для которых заканчивается действие лицензии
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Негарантийные приборы без стоимости ремонта
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdDevicesWithoutCostPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdDevicesWithoutCostPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Количество лицензий со сроком действия меньше месяца
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdLicensesPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpiringLicenseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdLicensesPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Количество платных ремонтов, требущих согласования
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdPaidRepairsPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdPaidRepairsPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Количество просроченнных ремонтов
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdRepairPlansPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExpiredRepairPlansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdRepairPlansPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary План ремонта согласован, требуется передача на участок ремонта
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardFirmIdSendToRepairRequiredPost(firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardFirmIdSendToRepairRequiredPost(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Количество заявок RMA в статусе Отправлена
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardRmaRequestsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardRmaRequestsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Количество разрешенных к планированию приборов с согласованной стоимостью ремонта
         * @param {DashboardApiApiDashboardFirmIdAgreedRepairCostPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdAgreedRepairCostPost(requestParameters: DashboardApiApiDashboardFirmIdAgreedRepairCostPostRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.apiDashboardFirmIdAgreedRepairCostPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Количество приборов без введенных дефектов
         * @param {DashboardApiApiDashboardFirmIdDefectsRequiredPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDefectsRequiredPost(requestParameters: DashboardApiApiDashboardFirmIdDefectsRequiredPostRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.apiDashboardFirmIdDefectsRequiredPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Приборы со статусом замена на новый
         * @param {DashboardApiApiDashboardFirmIdDevicesReplacementForNewPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesReplacementForNewPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesReplacementForNewPostRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.apiDashboardFirmIdDevicesReplacementForNewPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Приборы для которых запрошено уточнение
         * @param {DashboardApiApiDashboardFirmIdDevicesRequestConfirmationPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesRequestConfirmationPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesRequestConfirmationPostRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.apiDashboardFirmIdDevicesRequestConfirmationPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Приборы для которых заканчивается действие лицензии
         * @param {DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesWithExpiringLicensesPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesPostRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DeviceExpiringLicenseDto>> {
            return localVarFp.apiDashboardFirmIdDevicesWithExpiringLicensesPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Количество приборов для которых заканчивается действие лицензии
         * @param {DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesQtyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesQtyPostRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Негарантийные приборы без стоимости ремонта
         * @param {DashboardApiApiDashboardFirmIdDevicesWithoutCostPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdDevicesWithoutCostPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesWithoutCostPostRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.apiDashboardFirmIdDevicesWithoutCostPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Количество лицензий со сроком действия меньше месяца
         * @param {DashboardApiApiDashboardFirmIdLicensesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdLicensesPost(requestParameters: DashboardApiApiDashboardFirmIdLicensesPostRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ExpiringLicenseDto>> {
            return localVarFp.apiDashboardFirmIdLicensesPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Количество платных ремонтов, требущих согласования
         * @param {DashboardApiApiDashboardFirmIdPaidRepairsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdPaidRepairsPost(requestParameters: DashboardApiApiDashboardFirmIdPaidRepairsPostRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.apiDashboardFirmIdPaidRepairsPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Количество просроченнных ремонтов
         * @param {DashboardApiApiDashboardFirmIdRepairPlansPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdRepairPlansPost(requestParameters: DashboardApiApiDashboardFirmIdRepairPlansPostRequest, options?: AxiosRequestConfig): AxiosPromise<GetExpiredRepairPlansResponse> {
            return localVarFp.apiDashboardFirmIdRepairPlansPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary План ремонта согласован, требуется передача на участок ремонта
         * @param {DashboardApiApiDashboardFirmIdSendToRepairRequiredPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardFirmIdSendToRepairRequiredPost(requestParameters: DashboardApiApiDashboardFirmIdSendToRepairRequiredPostRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.apiDashboardFirmIdSendToRepairRequiredPost(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Количество заявок RMA в статусе Отправлена
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardRmaRequestsPost(options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.apiDashboardRmaRequestsPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDashboardFirmIdAgreedRepairCostPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdAgreedRepairCostPostRequest
 */
export interface DashboardApiApiDashboardFirmIdAgreedRepairCostPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdAgreedRepairCostPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdDefectsRequiredPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdDefectsRequiredPostRequest
 */
export interface DashboardApiApiDashboardFirmIdDefectsRequiredPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdDefectsRequiredPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdDevicesReplacementForNewPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdDevicesReplacementForNewPostRequest
 */
export interface DashboardApiApiDashboardFirmIdDevicesReplacementForNewPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdDevicesReplacementForNewPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdDevicesRequestConfirmationPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdDevicesRequestConfirmationPostRequest
 */
export interface DashboardApiApiDashboardFirmIdDevicesRequestConfirmationPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdDevicesRequestConfirmationPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdDevicesWithExpiringLicensesPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesPostRequest
 */
export interface DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesQtyPostRequest
 */
export interface DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesQtyPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesQtyPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdDevicesWithoutCostPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdDevicesWithoutCostPostRequest
 */
export interface DashboardApiApiDashboardFirmIdDevicesWithoutCostPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdDevicesWithoutCostPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdLicensesPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdLicensesPostRequest
 */
export interface DashboardApiApiDashboardFirmIdLicensesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdLicensesPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdPaidRepairsPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdPaidRepairsPostRequest
 */
export interface DashboardApiApiDashboardFirmIdPaidRepairsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdPaidRepairsPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdRepairPlansPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdRepairPlansPostRequest
 */
export interface DashboardApiApiDashboardFirmIdRepairPlansPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdRepairPlansPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiDashboardFirmIdSendToRepairRequiredPost operation in DashboardApi.
 * @export
 * @interface DashboardApiApiDashboardFirmIdSendToRepairRequiredPostRequest
 */
export interface DashboardApiApiDashboardFirmIdSendToRepairRequiredPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DashboardApiApiDashboardFirmIdSendToRepairRequiredPost
     */
    readonly firmId: number
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Количество разрешенных к планированию приборов с согласованной стоимостью ремонта
     * @param {DashboardApiApiDashboardFirmIdAgreedRepairCostPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdAgreedRepairCostPost(requestParameters: DashboardApiApiDashboardFirmIdAgreedRepairCostPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdAgreedRepairCostPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Количество приборов без введенных дефектов
     * @param {DashboardApiApiDashboardFirmIdDefectsRequiredPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdDefectsRequiredPost(requestParameters: DashboardApiApiDashboardFirmIdDefectsRequiredPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdDefectsRequiredPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Приборы со статусом замена на новый
     * @param {DashboardApiApiDashboardFirmIdDevicesReplacementForNewPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdDevicesReplacementForNewPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesReplacementForNewPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdDevicesReplacementForNewPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Приборы для которых запрошено уточнение
     * @param {DashboardApiApiDashboardFirmIdDevicesRequestConfirmationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdDevicesRequestConfirmationPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesRequestConfirmationPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdDevicesRequestConfirmationPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Приборы для которых заканчивается действие лицензии
     * @param {DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdDevicesWithExpiringLicensesPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdDevicesWithExpiringLicensesPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Количество приборов для которых заканчивается действие лицензии
     * @param {DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesQtyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesWithExpiringLicensesQtyPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdDevicesWithExpiringLicensesQtyPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Негарантийные приборы без стоимости ремонта
     * @param {DashboardApiApiDashboardFirmIdDevicesWithoutCostPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdDevicesWithoutCostPost(requestParameters: DashboardApiApiDashboardFirmIdDevicesWithoutCostPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdDevicesWithoutCostPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Количество лицензий со сроком действия меньше месяца
     * @param {DashboardApiApiDashboardFirmIdLicensesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdLicensesPost(requestParameters: DashboardApiApiDashboardFirmIdLicensesPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdLicensesPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Количество платных ремонтов, требущих согласования
     * @param {DashboardApiApiDashboardFirmIdPaidRepairsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdPaidRepairsPost(requestParameters: DashboardApiApiDashboardFirmIdPaidRepairsPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdPaidRepairsPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Количество просроченнных ремонтов
     * @param {DashboardApiApiDashboardFirmIdRepairPlansPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdRepairPlansPost(requestParameters: DashboardApiApiDashboardFirmIdRepairPlansPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdRepairPlansPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary План ремонта согласован, требуется передача на участок ремонта
     * @param {DashboardApiApiDashboardFirmIdSendToRepairRequiredPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardFirmIdSendToRepairRequiredPost(requestParameters: DashboardApiApiDashboardFirmIdSendToRepairRequiredPostRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardFirmIdSendToRepairRequiredPost(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Количество заявок RMA в статусе Отправлена
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardRmaRequestsPost(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardRmaRequestsPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictBlamesApi - axios parameter creator
 * @export
 */
export const DictBlamesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список причин поломок)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictBlamesListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-blames/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictBlamesApi - functional programming interface
 * @export
 */
export const DictBlamesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictBlamesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список причин поломок)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictBlamesListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlameBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictBlamesListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictBlamesApi - factory interface
 * @export
 */
export const DictBlamesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictBlamesApiFp(configuration)
    return {
        /**
         * 
         * @summary Список причин поломок)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictBlamesListGet(options?: AxiosRequestConfig): AxiosPromise<Array<BlameBrowseDto>> {
            return localVarFp.apiDictBlamesListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictBlamesApi - object-oriented interface
 * @export
 * @class DictBlamesApi
 * @extends {BaseAPI}
 */
export class DictBlamesApi extends BaseAPI {
    /**
     * 
     * @summary Список причин поломок)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictBlamesApi
     */
    public apiDictBlamesListGet(options?: AxiosRequestConfig) {
        return DictBlamesApiFp(this.configuration).apiDictBlamesListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictCounterpartiesApi - axios parameter creator
 * @export
 */
export const DictCounterpartiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Поиск компаний c внешними сервисными центрами
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictCounterpartiesExternalServiceCentersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-counterparties/external-service-centers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Поиск компаний производителей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictCounterpartiesFirmsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-counterparties/firms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Поиск контрагентов
         * @param {number} firmId 
         * @param {boolean} includeEmployees 
         * @param {boolean} includeWarehouse 
         * @param {string} [queryText] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictCounterpartiesSuggestionsFirmIdGet: async (firmId: number, includeEmployees: boolean, includeWarehouse: boolean, queryText?: string, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiDictCounterpartiesSuggestionsFirmIdGet', 'firmId', firmId)
            // verify required parameter 'includeEmployees' is not null or undefined
            assertParamExists('apiDictCounterpartiesSuggestionsFirmIdGet', 'includeEmployees', includeEmployees)
            // verify required parameter 'includeWarehouse' is not null or undefined
            assertParamExists('apiDictCounterpartiesSuggestionsFirmIdGet', 'includeWarehouse', includeWarehouse)
            const localVarPath = `/api/dict-counterparties/suggestions/{firmId}`
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (queryText !== undefined) {
                localVarQueryParameter['QueryText'] = queryText;
            }

            if (includeEmployees !== undefined) {
                localVarQueryParameter['IncludeEmployees'] = includeEmployees;
            }

            if (includeWarehouse !== undefined) {
                localVarQueryParameter['IncludeWarehouse'] = includeWarehouse;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictCounterpartiesApi - functional programming interface
 * @export
 */
export const DictCounterpartiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictCounterpartiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Поиск компаний c внешними сервисными центрами
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictCounterpartiesExternalServiceCentersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CounterpartyExternalServiceCenterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictCounterpartiesExternalServiceCentersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Поиск компаний производителей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictCounterpartiesFirmsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CounterpartyFirmDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictCounterpartiesFirmsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Поиск контрагентов
         * @param {number} firmId 
         * @param {boolean} includeEmployees 
         * @param {boolean} includeWarehouse 
         * @param {string} [queryText] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictCounterpartiesSuggestionsFirmIdGet(firmId: number, includeEmployees: boolean, includeWarehouse: boolean, queryText?: string, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounterpartySuggestionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictCounterpartiesSuggestionsFirmIdGet(firmId, includeEmployees, includeWarehouse, queryText, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictCounterpartiesApi - factory interface
 * @export
 */
export const DictCounterpartiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictCounterpartiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Поиск компаний c внешними сервисными центрами
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictCounterpartiesExternalServiceCentersGet(options?: AxiosRequestConfig): AxiosPromise<Array<CounterpartyExternalServiceCenterDto>> {
            return localVarFp.apiDictCounterpartiesExternalServiceCentersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Поиск компаний производителей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictCounterpartiesFirmsGet(options?: AxiosRequestConfig): AxiosPromise<Array<CounterpartyFirmDto>> {
            return localVarFp.apiDictCounterpartiesFirmsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Поиск контрагентов
         * @param {DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictCounterpartiesSuggestionsFirmIdGet(requestParameters: DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<CounterpartySuggestionsResponse> {
            return localVarFp.apiDictCounterpartiesSuggestionsFirmIdGet(requestParameters.firmId, requestParameters.includeEmployees, requestParameters.includeWarehouse, requestParameters.queryText, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictCounterpartiesSuggestionsFirmIdGet operation in DictCounterpartiesApi.
 * @export
 * @interface DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGetRequest
 */
export interface DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGet
     */
    readonly firmId: number

    /**
     * 
     * @type {boolean}
     * @memberof DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGet
     */
    readonly includeEmployees: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGet
     */
    readonly includeWarehouse: boolean

    /**
     * 
     * @type {string}
     * @memberof DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGet
     */
    readonly queryText?: string

    /**
     * 
     * @type {number}
     * @memberof DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGet
     */
    readonly pagingPageSize?: number
}

/**
 * DictCounterpartiesApi - object-oriented interface
 * @export
 * @class DictCounterpartiesApi
 * @extends {BaseAPI}
 */
export class DictCounterpartiesApi extends BaseAPI {
    /**
     * 
     * @summary Поиск компаний c внешними сервисными центрами
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictCounterpartiesApi
     */
    public apiDictCounterpartiesExternalServiceCentersGet(options?: AxiosRequestConfig) {
        return DictCounterpartiesApiFp(this.configuration).apiDictCounterpartiesExternalServiceCentersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Поиск компаний производителей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictCounterpartiesApi
     */
    public apiDictCounterpartiesFirmsGet(options?: AxiosRequestConfig) {
        return DictCounterpartiesApiFp(this.configuration).apiDictCounterpartiesFirmsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Поиск контрагентов
     * @param {DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictCounterpartiesApi
     */
    public apiDictCounterpartiesSuggestionsFirmIdGet(requestParameters: DictCounterpartiesApiApiDictCounterpartiesSuggestionsFirmIdGetRequest, options?: AxiosRequestConfig) {
        return DictCounterpartiesApiFp(this.configuration).apiDictCounterpartiesSuggestionsFirmIdGet(requestParameters.firmId, requestParameters.includeEmployees, requestParameters.includeWarehouse, requestParameters.queryText, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictCurrenciesApi - axios parameter creator
 * @export
 */
export const DictCurrenciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список валют)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictCurrenciesListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-currencies/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictCurrenciesApi - functional programming interface
 * @export
 */
export const DictCurrenciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictCurrenciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список валют)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictCurrenciesListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictCurrenciesListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictCurrenciesApi - factory interface
 * @export
 */
export const DictCurrenciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictCurrenciesApiFp(configuration)
    return {
        /**
         * 
         * @summary Список валют)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictCurrenciesListGet(options?: AxiosRequestConfig): AxiosPromise<Array<CurrencyBrowseDto>> {
            return localVarFp.apiDictCurrenciesListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictCurrenciesApi - object-oriented interface
 * @export
 * @class DictCurrenciesApi
 * @extends {BaseAPI}
 */
export class DictCurrenciesApi extends BaseAPI {
    /**
     * 
     * @summary Список валют)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictCurrenciesApi
     */
    public apiDictCurrenciesListGet(options?: AxiosRequestConfig) {
        return DictCurrenciesApiFp(this.configuration).apiDictCurrenciesListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictDefectsApi - axios parameter creator
 * @export
 */
export const DictDefectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал дефектов - элемент
         * @param {number} defectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsBrowseDefectIdGet: async (defectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defectId' is not null or undefined
            assertParamExists('apiDictDefectsBrowseDefectIdGet', 'defectId', defectId)
            const localVarPath = `/api/dict-defects/browse/{defectId}`
                .replace(`{${"defectId"}}`, encodeURIComponent(String(defectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал дефектов - список
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsBrowseGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-defects/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить дефект
         * @param {number} defectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsDefectIdDelete: async (defectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defectId' is not null or undefined
            assertParamExists('apiDictDefectsDefectIdDelete', 'defectId', defectId)
            const localVarPath = `/api/dict-defects/{defectId}`
                .replace(`{${"defectId"}}`, encodeURIComponent(String(defectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить дефект
         * @param {number} defectId 
         * @param {UpdateDefectPayload} [updateDefectPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsDefectIdPut: async (defectId: number, updateDefectPayload?: UpdateDefectPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defectId' is not null or undefined
            assertParamExists('apiDictDefectsDefectIdPut', 'defectId', defectId)
            const localVarPath = `/api/dict-defects/{defectId}`
                .replace(`{${"defectId"}}`, encodeURIComponent(String(defectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDefectPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение групп приборов
         * @param {number} defectId 
         * @param {UpdateDefectDeviceGroupsPayload} [updateDefectDeviceGroupsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsDefectIdSetDeviceGroupsPost: async (defectId: number, updateDefectDeviceGroupsPayload?: UpdateDefectDeviceGroupsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defectId' is not null or undefined
            assertParamExists('apiDictDefectsDefectIdSetDeviceGroupsPost', 'defectId', defectId)
            const localVarPath = `/api/dict-defects/{defectId}/set-device-groups`
                .replace(`{${"defectId"}}`, encodeURIComponent(String(defectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDefectDeviceGroupsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить примечание
         * @param {number} defectId 
         * @param {UpdateDefectNotesPayload} [updateDefectNotesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsDefectIdSetNotesPost: async (defectId: number, updateDefectNotesPayload?: UpdateDefectNotesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defectId' is not null or undefined
            assertParamExists('apiDictDefectsDefectIdSetNotesPost', 'defectId', defectId)
            const localVarPath = `/api/dict-defects/{defectId}/set-notes`
                .replace(`{${"defectId"}}`, encodeURIComponent(String(defectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDefectNotesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить дефект
         * @param {CreateDefectPayload} [createDefectPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsPost: async (createDefectPayload?: CreateDefectPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-defects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDefectPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictDefectsApi - functional programming interface
 * @export
 */
export const DictDefectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictDefectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал дефектов - элемент
         * @param {number} defectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDefectsBrowseDefectIdGet(defectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefectBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDefectsBrowseDefectIdGet(defectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал дефектов - список
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDefectsBrowseGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DefectBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDefectsBrowseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить дефект
         * @param {number} defectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDefectsDefectIdDelete(defectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDefectsDefectIdDelete(defectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить дефект
         * @param {number} defectId 
         * @param {UpdateDefectPayload} [updateDefectPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDefectsDefectIdPut(defectId: number, updateDefectPayload?: UpdateDefectPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDefectsDefectIdPut(defectId, updateDefectPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменение групп приборов
         * @param {number} defectId 
         * @param {UpdateDefectDeviceGroupsPayload} [updateDefectDeviceGroupsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDefectsDefectIdSetDeviceGroupsPost(defectId: number, updateDefectDeviceGroupsPayload?: UpdateDefectDeviceGroupsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDefectsDefectIdSetDeviceGroupsPost(defectId, updateDefectDeviceGroupsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить примечание
         * @param {number} defectId 
         * @param {UpdateDefectNotesPayload} [updateDefectNotesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDefectsDefectIdSetNotesPost(defectId: number, updateDefectNotesPayload?: UpdateDefectNotesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDefectsDefectIdSetNotesPost(defectId, updateDefectNotesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить дефект
         * @param {CreateDefectPayload} [createDefectPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDefectsPost(createDefectPayload?: CreateDefectPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDefectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDefectsPost(createDefectPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictDefectsApi - factory interface
 * @export
 */
export const DictDefectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictDefectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал дефектов - элемент
         * @param {DictDefectsApiApiDictDefectsBrowseDefectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsBrowseDefectIdGet(requestParameters: DictDefectsApiApiDictDefectsBrowseDefectIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<DefectBrowseDto> {
            return localVarFp.apiDictDefectsBrowseDefectIdGet(requestParameters.defectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал дефектов - список
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsBrowseGet(options?: AxiosRequestConfig): AxiosPromise<Array<DefectBrowseDto>> {
            return localVarFp.apiDictDefectsBrowseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить дефект
         * @param {DictDefectsApiApiDictDefectsDefectIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsDefectIdDelete(requestParameters: DictDefectsApiApiDictDefectsDefectIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictDefectsDefectIdDelete(requestParameters.defectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить дефект
         * @param {DictDefectsApiApiDictDefectsDefectIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsDefectIdPut(requestParameters: DictDefectsApiApiDictDefectsDefectIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictDefectsDefectIdPut(requestParameters.defectId, requestParameters.updateDefectPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение групп приборов
         * @param {DictDefectsApiApiDictDefectsDefectIdSetDeviceGroupsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsDefectIdSetDeviceGroupsPost(requestParameters: DictDefectsApiApiDictDefectsDefectIdSetDeviceGroupsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictDefectsDefectIdSetDeviceGroupsPost(requestParameters.defectId, requestParameters.updateDefectDeviceGroupsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить примечание
         * @param {DictDefectsApiApiDictDefectsDefectIdSetNotesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsDefectIdSetNotesPost(requestParameters: DictDefectsApiApiDictDefectsDefectIdSetNotesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictDefectsDefectIdSetNotesPost(requestParameters.defectId, requestParameters.updateDefectNotesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить дефект
         * @param {DictDefectsApiApiDictDefectsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDefectsPost(requestParameters: DictDefectsApiApiDictDefectsPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateDefectResponse> {
            return localVarFp.apiDictDefectsPost(requestParameters.createDefectPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictDefectsBrowseDefectIdGet operation in DictDefectsApi.
 * @export
 * @interface DictDefectsApiApiDictDefectsBrowseDefectIdGetRequest
 */
export interface DictDefectsApiApiDictDefectsBrowseDefectIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DictDefectsApiApiDictDefectsBrowseDefectIdGet
     */
    readonly defectId: number
}

/**
 * Request parameters for apiDictDefectsDefectIdDelete operation in DictDefectsApi.
 * @export
 * @interface DictDefectsApiApiDictDefectsDefectIdDeleteRequest
 */
export interface DictDefectsApiApiDictDefectsDefectIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DictDefectsApiApiDictDefectsDefectIdDelete
     */
    readonly defectId: number
}

/**
 * Request parameters for apiDictDefectsDefectIdPut operation in DictDefectsApi.
 * @export
 * @interface DictDefectsApiApiDictDefectsDefectIdPutRequest
 */
export interface DictDefectsApiApiDictDefectsDefectIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof DictDefectsApiApiDictDefectsDefectIdPut
     */
    readonly defectId: number

    /**
     * 
     * @type {UpdateDefectPayload}
     * @memberof DictDefectsApiApiDictDefectsDefectIdPut
     */
    readonly updateDefectPayload?: UpdateDefectPayload
}

/**
 * Request parameters for apiDictDefectsDefectIdSetDeviceGroupsPost operation in DictDefectsApi.
 * @export
 * @interface DictDefectsApiApiDictDefectsDefectIdSetDeviceGroupsPostRequest
 */
export interface DictDefectsApiApiDictDefectsDefectIdSetDeviceGroupsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DictDefectsApiApiDictDefectsDefectIdSetDeviceGroupsPost
     */
    readonly defectId: number

    /**
     * 
     * @type {UpdateDefectDeviceGroupsPayload}
     * @memberof DictDefectsApiApiDictDefectsDefectIdSetDeviceGroupsPost
     */
    readonly updateDefectDeviceGroupsPayload?: UpdateDefectDeviceGroupsPayload
}

/**
 * Request parameters for apiDictDefectsDefectIdSetNotesPost operation in DictDefectsApi.
 * @export
 * @interface DictDefectsApiApiDictDefectsDefectIdSetNotesPostRequest
 */
export interface DictDefectsApiApiDictDefectsDefectIdSetNotesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DictDefectsApiApiDictDefectsDefectIdSetNotesPost
     */
    readonly defectId: number

    /**
     * 
     * @type {UpdateDefectNotesPayload}
     * @memberof DictDefectsApiApiDictDefectsDefectIdSetNotesPost
     */
    readonly updateDefectNotesPayload?: UpdateDefectNotesPayload
}

/**
 * Request parameters for apiDictDefectsPost operation in DictDefectsApi.
 * @export
 * @interface DictDefectsApiApiDictDefectsPostRequest
 */
export interface DictDefectsApiApiDictDefectsPostRequest {
    /**
     * 
     * @type {CreateDefectPayload}
     * @memberof DictDefectsApiApiDictDefectsPost
     */
    readonly createDefectPayload?: CreateDefectPayload
}

/**
 * DictDefectsApi - object-oriented interface
 * @export
 * @class DictDefectsApi
 * @extends {BaseAPI}
 */
export class DictDefectsApi extends BaseAPI {
    /**
     * 
     * @summary Журнал дефектов - элемент
     * @param {DictDefectsApiApiDictDefectsBrowseDefectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDefectsApi
     */
    public apiDictDefectsBrowseDefectIdGet(requestParameters: DictDefectsApiApiDictDefectsBrowseDefectIdGetRequest, options?: AxiosRequestConfig) {
        return DictDefectsApiFp(this.configuration).apiDictDefectsBrowseDefectIdGet(requestParameters.defectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал дефектов - список
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDefectsApi
     */
    public apiDictDefectsBrowseGet(options?: AxiosRequestConfig) {
        return DictDefectsApiFp(this.configuration).apiDictDefectsBrowseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить дефект
     * @param {DictDefectsApiApiDictDefectsDefectIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDefectsApi
     */
    public apiDictDefectsDefectIdDelete(requestParameters: DictDefectsApiApiDictDefectsDefectIdDeleteRequest, options?: AxiosRequestConfig) {
        return DictDefectsApiFp(this.configuration).apiDictDefectsDefectIdDelete(requestParameters.defectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить дефект
     * @param {DictDefectsApiApiDictDefectsDefectIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDefectsApi
     */
    public apiDictDefectsDefectIdPut(requestParameters: DictDefectsApiApiDictDefectsDefectIdPutRequest, options?: AxiosRequestConfig) {
        return DictDefectsApiFp(this.configuration).apiDictDefectsDefectIdPut(requestParameters.defectId, requestParameters.updateDefectPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение групп приборов
     * @param {DictDefectsApiApiDictDefectsDefectIdSetDeviceGroupsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDefectsApi
     */
    public apiDictDefectsDefectIdSetDeviceGroupsPost(requestParameters: DictDefectsApiApiDictDefectsDefectIdSetDeviceGroupsPostRequest, options?: AxiosRequestConfig) {
        return DictDefectsApiFp(this.configuration).apiDictDefectsDefectIdSetDeviceGroupsPost(requestParameters.defectId, requestParameters.updateDefectDeviceGroupsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить примечание
     * @param {DictDefectsApiApiDictDefectsDefectIdSetNotesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDefectsApi
     */
    public apiDictDefectsDefectIdSetNotesPost(requestParameters: DictDefectsApiApiDictDefectsDefectIdSetNotesPostRequest, options?: AxiosRequestConfig) {
        return DictDefectsApiFp(this.configuration).apiDictDefectsDefectIdSetNotesPost(requestParameters.defectId, requestParameters.updateDefectNotesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить дефект
     * @param {DictDefectsApiApiDictDefectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDefectsApi
     */
    public apiDictDefectsPost(requestParameters: DictDefectsApiApiDictDefectsPostRequest = {}, options?: AxiosRequestConfig) {
        return DictDefectsApiFp(this.configuration).apiDictDefectsPost(requestParameters.createDefectPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictDeviceGroupsApi - axios parameter creator
 * @export
 */
export const DictDeviceGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал группы приборов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDeviceGroupsListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-device-groups/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictDeviceGroupsApi - functional programming interface
 * @export
 */
export const DictDeviceGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictDeviceGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал группы приборов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDeviceGroupsListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceGroupBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDeviceGroupsListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictDeviceGroupsApi - factory interface
 * @export
 */
export const DictDeviceGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictDeviceGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал группы приборов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDeviceGroupsListGet(options?: AxiosRequestConfig): AxiosPromise<Array<DeviceGroupBrowseDto>> {
            return localVarFp.apiDictDeviceGroupsListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictDeviceGroupsApi - object-oriented interface
 * @export
 * @class DictDeviceGroupsApi
 * @extends {BaseAPI}
 */
export class DictDeviceGroupsApi extends BaseAPI {
    /**
     * 
     * @summary Журнал группы приборов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDeviceGroupsApi
     */
    public apiDictDeviceGroupsListGet(options?: AxiosRequestConfig) {
        return DictDeviceGroupsApiFp(this.configuration).apiDictDeviceGroupsListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictDocNotesApi - axios parameter creator
 * @export
 */
export const DictDocNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал примечаний - список
         * @param {number} [filterFirmId] 
         * @param {string} [filterDocumentCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesBrowseGet: async (filterFirmId?: number, filterDocumentCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-doc-notes/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterFirmId !== undefined) {
                localVarQueryParameter['Filter.FirmId'] = filterFirmId;
            }

            if (filterDocumentCode !== undefined) {
                localVarQueryParameter['Filter.DocumentCode'] = filterDocumentCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал примечаний - элемент
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesBrowseNoteIdGet: async (noteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('apiDictDocNotesBrowseNoteIdGet', 'noteId', noteId)
            const localVarPath = `/api/dict-doc-notes/browse/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить примечание
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesNoteIdDelete: async (noteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('apiDictDocNotesNoteIdDelete', 'noteId', noteId)
            const localVarPath = `/api/dict-doc-notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить примечание
         * @param {number} noteId 
         * @param {UpdateDocNotePayload} [updateDocNotePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesNoteIdPut: async (noteId: number, updateDocNotePayload?: UpdateDocNotePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('apiDictDocNotesNoteIdPut', 'noteId', noteId)
            const localVarPath = `/api/dict-doc-notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocNotePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить примечание
         * @param {CreateDocNotePayload} [createDocNotePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesPost: async (createDocNotePayload?: CreateDocNotePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-doc-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocNotePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictDocNotesApi - functional programming interface
 * @export
 */
export const DictDocNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictDocNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал примечаний - список
         * @param {number} [filterFirmId] 
         * @param {string} [filterDocumentCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDocNotesBrowseGet(filterFirmId?: number, filterDocumentCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocNoteBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDocNotesBrowseGet(filterFirmId, filterDocumentCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал примечаний - элемент
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDocNotesBrowseNoteIdGet(noteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocNoteBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDocNotesBrowseNoteIdGet(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить примечание
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDocNotesNoteIdDelete(noteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDocNotesNoteIdDelete(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить примечание
         * @param {number} noteId 
         * @param {UpdateDocNotePayload} [updateDocNotePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDocNotesNoteIdPut(noteId: number, updateDocNotePayload?: UpdateDocNotePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDocNotesNoteIdPut(noteId, updateDocNotePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить примечание
         * @param {CreateDocNotePayload} [createDocNotePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictDocNotesPost(createDocNotePayload?: CreateDocNotePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDocNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictDocNotesPost(createDocNotePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictDocNotesApi - factory interface
 * @export
 */
export const DictDocNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictDocNotesApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал примечаний - список
         * @param {DictDocNotesApiApiDictDocNotesBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesBrowseGet(requestParameters: DictDocNotesApiApiDictDocNotesBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<DocNoteBrowseDto>> {
            return localVarFp.apiDictDocNotesBrowseGet(requestParameters.filterFirmId, requestParameters.filterDocumentCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал примечаний - элемент
         * @param {DictDocNotesApiApiDictDocNotesBrowseNoteIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesBrowseNoteIdGet(requestParameters: DictDocNotesApiApiDictDocNotesBrowseNoteIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<DocNoteBrowseDto> {
            return localVarFp.apiDictDocNotesBrowseNoteIdGet(requestParameters.noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить примечание
         * @param {DictDocNotesApiApiDictDocNotesNoteIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesNoteIdDelete(requestParameters: DictDocNotesApiApiDictDocNotesNoteIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictDocNotesNoteIdDelete(requestParameters.noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить примечание
         * @param {DictDocNotesApiApiDictDocNotesNoteIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesNoteIdPut(requestParameters: DictDocNotesApiApiDictDocNotesNoteIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictDocNotesNoteIdPut(requestParameters.noteId, requestParameters.updateDocNotePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить примечание
         * @param {DictDocNotesApiApiDictDocNotesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictDocNotesPost(requestParameters: DictDocNotesApiApiDictDocNotesPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateDocNoteResponse> {
            return localVarFp.apiDictDocNotesPost(requestParameters.createDocNotePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictDocNotesBrowseGet operation in DictDocNotesApi.
 * @export
 * @interface DictDocNotesApiApiDictDocNotesBrowseGetRequest
 */
export interface DictDocNotesApiApiDictDocNotesBrowseGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DictDocNotesApiApiDictDocNotesBrowseGet
     */
    readonly filterFirmId?: number

    /**
     * 
     * @type {string}
     * @memberof DictDocNotesApiApiDictDocNotesBrowseGet
     */
    readonly filterDocumentCode?: string
}

/**
 * Request parameters for apiDictDocNotesBrowseNoteIdGet operation in DictDocNotesApi.
 * @export
 * @interface DictDocNotesApiApiDictDocNotesBrowseNoteIdGetRequest
 */
export interface DictDocNotesApiApiDictDocNotesBrowseNoteIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DictDocNotesApiApiDictDocNotesBrowseNoteIdGet
     */
    readonly noteId: number
}

/**
 * Request parameters for apiDictDocNotesNoteIdDelete operation in DictDocNotesApi.
 * @export
 * @interface DictDocNotesApiApiDictDocNotesNoteIdDeleteRequest
 */
export interface DictDocNotesApiApiDictDocNotesNoteIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DictDocNotesApiApiDictDocNotesNoteIdDelete
     */
    readonly noteId: number
}

/**
 * Request parameters for apiDictDocNotesNoteIdPut operation in DictDocNotesApi.
 * @export
 * @interface DictDocNotesApiApiDictDocNotesNoteIdPutRequest
 */
export interface DictDocNotesApiApiDictDocNotesNoteIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof DictDocNotesApiApiDictDocNotesNoteIdPut
     */
    readonly noteId: number

    /**
     * 
     * @type {UpdateDocNotePayload}
     * @memberof DictDocNotesApiApiDictDocNotesNoteIdPut
     */
    readonly updateDocNotePayload?: UpdateDocNotePayload
}

/**
 * Request parameters for apiDictDocNotesPost operation in DictDocNotesApi.
 * @export
 * @interface DictDocNotesApiApiDictDocNotesPostRequest
 */
export interface DictDocNotesApiApiDictDocNotesPostRequest {
    /**
     * 
     * @type {CreateDocNotePayload}
     * @memberof DictDocNotesApiApiDictDocNotesPost
     */
    readonly createDocNotePayload?: CreateDocNotePayload
}

/**
 * DictDocNotesApi - object-oriented interface
 * @export
 * @class DictDocNotesApi
 * @extends {BaseAPI}
 */
export class DictDocNotesApi extends BaseAPI {
    /**
     * 
     * @summary Журнал примечаний - список
     * @param {DictDocNotesApiApiDictDocNotesBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDocNotesApi
     */
    public apiDictDocNotesBrowseGet(requestParameters: DictDocNotesApiApiDictDocNotesBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return DictDocNotesApiFp(this.configuration).apiDictDocNotesBrowseGet(requestParameters.filterFirmId, requestParameters.filterDocumentCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал примечаний - элемент
     * @param {DictDocNotesApiApiDictDocNotesBrowseNoteIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDocNotesApi
     */
    public apiDictDocNotesBrowseNoteIdGet(requestParameters: DictDocNotesApiApiDictDocNotesBrowseNoteIdGetRequest, options?: AxiosRequestConfig) {
        return DictDocNotesApiFp(this.configuration).apiDictDocNotesBrowseNoteIdGet(requestParameters.noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить примечание
     * @param {DictDocNotesApiApiDictDocNotesNoteIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDocNotesApi
     */
    public apiDictDocNotesNoteIdDelete(requestParameters: DictDocNotesApiApiDictDocNotesNoteIdDeleteRequest, options?: AxiosRequestConfig) {
        return DictDocNotesApiFp(this.configuration).apiDictDocNotesNoteIdDelete(requestParameters.noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить примечание
     * @param {DictDocNotesApiApiDictDocNotesNoteIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDocNotesApi
     */
    public apiDictDocNotesNoteIdPut(requestParameters: DictDocNotesApiApiDictDocNotesNoteIdPutRequest, options?: AxiosRequestConfig) {
        return DictDocNotesApiFp(this.configuration).apiDictDocNotesNoteIdPut(requestParameters.noteId, requestParameters.updateDocNotePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить примечание
     * @param {DictDocNotesApiApiDictDocNotesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictDocNotesApi
     */
    public apiDictDocNotesPost(requestParameters: DictDocNotesApiApiDictDocNotesPostRequest = {}, options?: AxiosRequestConfig) {
        return DictDocNotesApiFp(this.configuration).apiDictDocNotesPost(requestParameters.createDocNotePayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictEmployeesApi - axios parameter creator
 * @export
 */
export const DictEmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал сотрудников
         * @param {number} [filterFirmId] 
         * @param {number} [filterPravoPod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictEmployeesBrowseGet: async (filterFirmId?: number, filterPravoPod?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-employees/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterFirmId !== undefined) {
                localVarQueryParameter['Filter.FirmId'] = filterFirmId;
            }

            if (filterPravoPod !== undefined) {
                localVarQueryParameter['Filter.PravoPod'] = filterPravoPod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictEmployeesApi - functional programming interface
 * @export
 */
export const DictEmployeesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictEmployeesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал сотрудников
         * @param {number} [filterFirmId] 
         * @param {number} [filterPravoPod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictEmployeesBrowseGet(filterFirmId?: number, filterPravoPod?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictEmployeesBrowseGet(filterFirmId, filterPravoPod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictEmployeesApi - factory interface
 * @export
 */
export const DictEmployeesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictEmployeesApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал сотрудников
         * @param {DictEmployeesApiApiDictEmployeesBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictEmployeesBrowseGet(requestParameters: DictEmployeesApiApiDictEmployeesBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<EmployeeBrowseDto>> {
            return localVarFp.apiDictEmployeesBrowseGet(requestParameters.filterFirmId, requestParameters.filterPravoPod, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictEmployeesBrowseGet operation in DictEmployeesApi.
 * @export
 * @interface DictEmployeesApiApiDictEmployeesBrowseGetRequest
 */
export interface DictEmployeesApiApiDictEmployeesBrowseGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DictEmployeesApiApiDictEmployeesBrowseGet
     */
    readonly filterFirmId?: number

    /**
     * 
     * @type {number}
     * @memberof DictEmployeesApiApiDictEmployeesBrowseGet
     */
    readonly filterPravoPod?: number
}

/**
 * DictEmployeesApi - object-oriented interface
 * @export
 * @class DictEmployeesApi
 * @extends {BaseAPI}
 */
export class DictEmployeesApi extends BaseAPI {
    /**
     * 
     * @summary Журнал сотрудников
     * @param {DictEmployeesApiApiDictEmployeesBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictEmployeesApi
     */
    public apiDictEmployeesBrowseGet(requestParameters: DictEmployeesApiApiDictEmployeesBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return DictEmployeesApiFp(this.configuration).apiDictEmployeesBrowseGet(requestParameters.filterFirmId, requestParameters.filterPravoPod, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictInformerNewsApi - axios parameter creator
 * @export
 */
export const DictInformerNewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список уведомлений для Информера
         * @param {number} [firmId] Ид фирмы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictInformerNewsBrowseGet: async (firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-informer-news/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить уведомление для Информера
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictInformerNewsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDictInformerNewsIdDelete', 'id', id)
            const localVarPath = `/api/dict-informer-news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить уведомление для Информера
         * @param {number} id 
         * @param {InformerNewsRecordUpdatePayload} [informerNewsRecordUpdatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictInformerNewsIdPut: async (id: number, informerNewsRecordUpdatePayload?: InformerNewsRecordUpdatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDictInformerNewsIdPut', 'id', id)
            const localVarPath = `/api/dict-informer-news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(informerNewsRecordUpdatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать уведомление для Информера
         * @param {number} [firmId] 
         * @param {NewsType} [newsType] 
         * @param {string} [startAt] 
         * @param {string} [expireAt] 
         * @param {string} [fileDescription] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictInformerNewsPost: async (firmId?: number, newsType?: NewsType, startAt?: string, expireAt?: string, fileDescription?: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-informer-news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['firmId'] = firmId;
            }

            if (newsType !== undefined) {
                localVarQueryParameter['newsType'] = newsType;
            }

            if (startAt !== undefined) {
                localVarQueryParameter['startAt'] = startAt;
            }

            if (expireAt !== undefined) {
                localVarQueryParameter['expireAt'] = expireAt;
            }

            if (fileDescription !== undefined) {
                localVarQueryParameter['fileDescription'] = fileDescription;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictInformerNewsApi - functional programming interface
 * @export
 */
export const DictInformerNewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictInformerNewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список уведомлений для Информера
         * @param {number} [firmId] Ид фирмы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictInformerNewsBrowseGet(firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsFileBrowseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictInformerNewsBrowseGet(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить уведомление для Информера
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictInformerNewsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictInformerNewsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить уведомление для Информера
         * @param {number} id 
         * @param {InformerNewsRecordUpdatePayload} [informerNewsRecordUpdatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictInformerNewsIdPut(id: number, informerNewsRecordUpdatePayload?: InformerNewsRecordUpdatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictInformerNewsIdPut(id, informerNewsRecordUpdatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать уведомление для Информера
         * @param {number} [firmId] 
         * @param {NewsType} [newsType] 
         * @param {string} [startAt] 
         * @param {string} [expireAt] 
         * @param {string} [fileDescription] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictInformerNewsPost(firmId?: number, newsType?: NewsType, startAt?: string, expireAt?: string, fileDescription?: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictInformerNewsPost(firmId, newsType, startAt, expireAt, fileDescription, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictInformerNewsApi - factory interface
 * @export
 */
export const DictInformerNewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictInformerNewsApiFp(configuration)
    return {
        /**
         * 
         * @summary Список уведомлений для Информера
         * @param {DictInformerNewsApiApiDictInformerNewsBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictInformerNewsBrowseGet(requestParameters: DictInformerNewsApiApiDictInformerNewsBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<NewsFileBrowseResponse>> {
            return localVarFp.apiDictInformerNewsBrowseGet(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить уведомление для Информера
         * @param {DictInformerNewsApiApiDictInformerNewsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictInformerNewsIdDelete(requestParameters: DictInformerNewsApiApiDictInformerNewsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictInformerNewsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить уведомление для Информера
         * @param {DictInformerNewsApiApiDictInformerNewsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictInformerNewsIdPut(requestParameters: DictInformerNewsApiApiDictInformerNewsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictInformerNewsIdPut(requestParameters.id, requestParameters.informerNewsRecordUpdatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать уведомление для Информера
         * @param {DictInformerNewsApiApiDictInformerNewsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictInformerNewsPost(requestParameters: DictInformerNewsApiApiDictInformerNewsPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictInformerNewsPost(requestParameters.firmId, requestParameters.newsType, requestParameters.startAt, requestParameters.expireAt, requestParameters.fileDescription, requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictInformerNewsBrowseGet operation in DictInformerNewsApi.
 * @export
 * @interface DictInformerNewsApiApiDictInformerNewsBrowseGetRequest
 */
export interface DictInformerNewsApiApiDictInformerNewsBrowseGetRequest {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof DictInformerNewsApiApiDictInformerNewsBrowseGet
     */
    readonly firmId?: number
}

/**
 * Request parameters for apiDictInformerNewsIdDelete operation in DictInformerNewsApi.
 * @export
 * @interface DictInformerNewsApiApiDictInformerNewsIdDeleteRequest
 */
export interface DictInformerNewsApiApiDictInformerNewsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DictInformerNewsApiApiDictInformerNewsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiDictInformerNewsIdPut operation in DictInformerNewsApi.
 * @export
 * @interface DictInformerNewsApiApiDictInformerNewsIdPutRequest
 */
export interface DictInformerNewsApiApiDictInformerNewsIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof DictInformerNewsApiApiDictInformerNewsIdPut
     */
    readonly id: number

    /**
     * 
     * @type {InformerNewsRecordUpdatePayload}
     * @memberof DictInformerNewsApiApiDictInformerNewsIdPut
     */
    readonly informerNewsRecordUpdatePayload?: InformerNewsRecordUpdatePayload
}

/**
 * Request parameters for apiDictInformerNewsPost operation in DictInformerNewsApi.
 * @export
 * @interface DictInformerNewsApiApiDictInformerNewsPostRequest
 */
export interface DictInformerNewsApiApiDictInformerNewsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DictInformerNewsApiApiDictInformerNewsPost
     */
    readonly firmId?: number

    /**
     * 
     * @type {NewsType}
     * @memberof DictInformerNewsApiApiDictInformerNewsPost
     */
    readonly newsType?: NewsType

    /**
     * 
     * @type {string}
     * @memberof DictInformerNewsApiApiDictInformerNewsPost
     */
    readonly startAt?: string

    /**
     * 
     * @type {string}
     * @memberof DictInformerNewsApiApiDictInformerNewsPost
     */
    readonly expireAt?: string

    /**
     * 
     * @type {string}
     * @memberof DictInformerNewsApiApiDictInformerNewsPost
     */
    readonly fileDescription?: string

    /**
     * 
     * @type {File}
     * @memberof DictInformerNewsApiApiDictInformerNewsPost
     */
    readonly file?: File
}

/**
 * DictInformerNewsApi - object-oriented interface
 * @export
 * @class DictInformerNewsApi
 * @extends {BaseAPI}
 */
export class DictInformerNewsApi extends BaseAPI {
    /**
     * 
     * @summary Список уведомлений для Информера
     * @param {DictInformerNewsApiApiDictInformerNewsBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictInformerNewsApi
     */
    public apiDictInformerNewsBrowseGet(requestParameters: DictInformerNewsApiApiDictInformerNewsBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return DictInformerNewsApiFp(this.configuration).apiDictInformerNewsBrowseGet(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить уведомление для Информера
     * @param {DictInformerNewsApiApiDictInformerNewsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictInformerNewsApi
     */
    public apiDictInformerNewsIdDelete(requestParameters: DictInformerNewsApiApiDictInformerNewsIdDeleteRequest, options?: AxiosRequestConfig) {
        return DictInformerNewsApiFp(this.configuration).apiDictInformerNewsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить уведомление для Информера
     * @param {DictInformerNewsApiApiDictInformerNewsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictInformerNewsApi
     */
    public apiDictInformerNewsIdPut(requestParameters: DictInformerNewsApiApiDictInformerNewsIdPutRequest, options?: AxiosRequestConfig) {
        return DictInformerNewsApiFp(this.configuration).apiDictInformerNewsIdPut(requestParameters.id, requestParameters.informerNewsRecordUpdatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать уведомление для Информера
     * @param {DictInformerNewsApiApiDictInformerNewsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictInformerNewsApi
     */
    public apiDictInformerNewsPost(requestParameters: DictInformerNewsApiApiDictInformerNewsPostRequest = {}, options?: AxiosRequestConfig) {
        return DictInformerNewsApiFp(this.configuration).apiDictInformerNewsPost(requestParameters.firmId, requestParameters.newsType, requestParameters.startAt, requestParameters.expireAt, requestParameters.fileDescription, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictMaintainabilityApi - axios parameter creator
 * @export
 */
export const DictMaintainabilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал ремонтопригодности
         * @param {string} [filterSeK] Производственный код
         * @param {string} [filterSku] Код по каталогу продаж
         * @param {OrderByDirection} [orderBySeK] Сортировка по производственному код
         * @param {OrderByDirection} [orderBySku] Сортировка по коду каталогу продаж
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictMaintainabilityBrowseGet: async (filterSeK?: string, filterSku?: string, orderBySeK?: OrderByDirection, orderBySku?: OrderByDirection, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-maintainability/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterSeK !== undefined) {
                localVarQueryParameter['Filter.se_k'] = filterSeK;
            }

            if (filterSku !== undefined) {
                localVarQueryParameter['Filter.sku'] = filterSku;
            }

            if (orderBySeK !== undefined) {
                localVarQueryParameter['OrderBy.se_k'] = orderBySeK;
            }

            if (orderBySku !== undefined) {
                localVarQueryParameter['OrderBy.sku'] = orderBySku;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить ремонтопригодность устройства
         * @param {number} idSe 
         * @param {UpdateMaintainabilityDevicePayload} [updateMaintainabilityDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictMaintainabilityIdSePost: async (idSe: number, updateMaintainabilityDevicePayload?: UpdateMaintainabilityDevicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idSe' is not null or undefined
            assertParamExists('apiDictMaintainabilityIdSePost', 'idSe', idSe)
            const localVarPath = `/api/dict-maintainability/{idSe}`
                .replace(`{${"idSe"}}`, encodeURIComponent(String(idSe)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMaintainabilityDevicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Варианты ремонтопригодности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictMaintainabilityRepairsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-maintainability/repairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictMaintainabilityApi - functional programming interface
 * @export
 */
export const DictMaintainabilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictMaintainabilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал ремонтопригодности
         * @param {string} [filterSeK] Производственный код
         * @param {string} [filterSku] Код по каталогу продаж
         * @param {OrderByDirection} [orderBySeK] Сортировка по производственному код
         * @param {OrderByDirection} [orderBySku] Сортировка по коду каталогу продаж
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictMaintainabilityBrowseGet(filterSeK?: string, filterSku?: string, orderBySeK?: OrderByDirection, orderBySku?: OrderByDirection, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowseMaintainabilityPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictMaintainabilityBrowseGet(filterSeK, filterSku, orderBySeK, orderBySku, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить ремонтопригодность устройства
         * @param {number} idSe 
         * @param {UpdateMaintainabilityDevicePayload} [updateMaintainabilityDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictMaintainabilityIdSePost(idSe: number, updateMaintainabilityDevicePayload?: UpdateMaintainabilityDevicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictMaintainabilityIdSePost(idSe, updateMaintainabilityDevicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Варианты ремонтопригодности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictMaintainabilityRepairsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintainabilityRepairDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictMaintainabilityRepairsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictMaintainabilityApi - factory interface
 * @export
 */
export const DictMaintainabilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictMaintainabilityApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал ремонтопригодности
         * @param {DictMaintainabilityApiApiDictMaintainabilityBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictMaintainabilityBrowseGet(requestParameters: DictMaintainabilityApiApiDictMaintainabilityBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BrowseMaintainabilityPagingResult> {
            return localVarFp.apiDictMaintainabilityBrowseGet(requestParameters.filterSeK, requestParameters.filterSku, requestParameters.orderBySeK, requestParameters.orderBySku, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить ремонтопригодность устройства
         * @param {DictMaintainabilityApiApiDictMaintainabilityIdSePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictMaintainabilityIdSePost(requestParameters: DictMaintainabilityApiApiDictMaintainabilityIdSePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictMaintainabilityIdSePost(requestParameters.idSe, requestParameters.updateMaintainabilityDevicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Варианты ремонтопригодности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictMaintainabilityRepairsGet(options?: AxiosRequestConfig): AxiosPromise<Array<MaintainabilityRepairDto>> {
            return localVarFp.apiDictMaintainabilityRepairsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictMaintainabilityBrowseGet operation in DictMaintainabilityApi.
 * @export
 * @interface DictMaintainabilityApiApiDictMaintainabilityBrowseGetRequest
 */
export interface DictMaintainabilityApiApiDictMaintainabilityBrowseGetRequest {
    /**
     * Производственный код
     * @type {string}
     * @memberof DictMaintainabilityApiApiDictMaintainabilityBrowseGet
     */
    readonly filterSeK?: string

    /**
     * Код по каталогу продаж
     * @type {string}
     * @memberof DictMaintainabilityApiApiDictMaintainabilityBrowseGet
     */
    readonly filterSku?: string

    /**
     * Сортировка по производственному код
     * @type {OrderByDirection}
     * @memberof DictMaintainabilityApiApiDictMaintainabilityBrowseGet
     */
    readonly orderBySeK?: OrderByDirection

    /**
     * Сортировка по коду каталогу продаж
     * @type {OrderByDirection}
     * @memberof DictMaintainabilityApiApiDictMaintainabilityBrowseGet
     */
    readonly orderBySku?: OrderByDirection

    /**
     * 
     * @type {number}
     * @memberof DictMaintainabilityApiApiDictMaintainabilityBrowseGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof DictMaintainabilityApiApiDictMaintainabilityBrowseGet
     */
    readonly pagingPageSize?: number
}

/**
 * Request parameters for apiDictMaintainabilityIdSePost operation in DictMaintainabilityApi.
 * @export
 * @interface DictMaintainabilityApiApiDictMaintainabilityIdSePostRequest
 */
export interface DictMaintainabilityApiApiDictMaintainabilityIdSePostRequest {
    /**
     * 
     * @type {number}
     * @memberof DictMaintainabilityApiApiDictMaintainabilityIdSePost
     */
    readonly idSe: number

    /**
     * 
     * @type {UpdateMaintainabilityDevicePayload}
     * @memberof DictMaintainabilityApiApiDictMaintainabilityIdSePost
     */
    readonly updateMaintainabilityDevicePayload?: UpdateMaintainabilityDevicePayload
}

/**
 * DictMaintainabilityApi - object-oriented interface
 * @export
 * @class DictMaintainabilityApi
 * @extends {BaseAPI}
 */
export class DictMaintainabilityApi extends BaseAPI {
    /**
     * 
     * @summary Журнал ремонтопригодности
     * @param {DictMaintainabilityApiApiDictMaintainabilityBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictMaintainabilityApi
     */
    public apiDictMaintainabilityBrowseGet(requestParameters: DictMaintainabilityApiApiDictMaintainabilityBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return DictMaintainabilityApiFp(this.configuration).apiDictMaintainabilityBrowseGet(requestParameters.filterSeK, requestParameters.filterSku, requestParameters.orderBySeK, requestParameters.orderBySku, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить ремонтопригодность устройства
     * @param {DictMaintainabilityApiApiDictMaintainabilityIdSePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictMaintainabilityApi
     */
    public apiDictMaintainabilityIdSePost(requestParameters: DictMaintainabilityApiApiDictMaintainabilityIdSePostRequest, options?: AxiosRequestConfig) {
        return DictMaintainabilityApiFp(this.configuration).apiDictMaintainabilityIdSePost(requestParameters.idSe, requestParameters.updateMaintainabilityDevicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Варианты ремонтопригодности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictMaintainabilityApi
     */
    public apiDictMaintainabilityRepairsGet(options?: AxiosRequestConfig) {
        return DictMaintainabilityApiFp(this.configuration).apiDictMaintainabilityRepairsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictProductionDevicesApi - axios parameter creator
 * @export
 */
export const DictProductionDevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал приборов - элемент
         * @param {string} sek 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictProductionDevicesBrowseSekGet: async (sek: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sek' is not null or undefined
            assertParamExists('apiDictProductionDevicesBrowseSekGet', 'sek', sek)
            const localVarPath = `/api/dict-production-devices/browse/{sek}`
                .replace(`{${"sek"}}`, encodeURIComponent(String(sek)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictProductionDevicesApi - functional programming interface
 * @export
 */
export const DictProductionDevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictProductionDevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал приборов - элемент
         * @param {string} sek 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictProductionDevicesBrowseSekGet(sek: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionDeviceBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictProductionDevicesBrowseSekGet(sek, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictProductionDevicesApi - factory interface
 * @export
 */
export const DictProductionDevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictProductionDevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал приборов - элемент
         * @param {DictProductionDevicesApiApiDictProductionDevicesBrowseSekGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictProductionDevicesBrowseSekGet(requestParameters: DictProductionDevicesApiApiDictProductionDevicesBrowseSekGetRequest, options?: AxiosRequestConfig): AxiosPromise<ProductionDeviceBrowseDto> {
            return localVarFp.apiDictProductionDevicesBrowseSekGet(requestParameters.sek, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictProductionDevicesBrowseSekGet operation in DictProductionDevicesApi.
 * @export
 * @interface DictProductionDevicesApiApiDictProductionDevicesBrowseSekGetRequest
 */
export interface DictProductionDevicesApiApiDictProductionDevicesBrowseSekGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DictProductionDevicesApiApiDictProductionDevicesBrowseSekGet
     */
    readonly sek: string
}

/**
 * DictProductionDevicesApi - object-oriented interface
 * @export
 * @class DictProductionDevicesApi
 * @extends {BaseAPI}
 */
export class DictProductionDevicesApi extends BaseAPI {
    /**
     * 
     * @summary Журнал приборов - элемент
     * @param {DictProductionDevicesApiApiDictProductionDevicesBrowseSekGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictProductionDevicesApi
     */
    public apiDictProductionDevicesBrowseSekGet(requestParameters: DictProductionDevicesApiApiDictProductionDevicesBrowseSekGetRequest, options?: AxiosRequestConfig) {
        return DictProductionDevicesApiFp(this.configuration).apiDictProductionDevicesBrowseSekGet(requestParameters.sek, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictRepairDevicesApi - axios parameter creator
 * @export
 */
export const DictRepairDevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал ремонтных приборов
         * @param {string} [filterSe] 
         * @param {string} [filterSku] 
         * @param {string} [filterRepairDeviceNameRu] 
         * @param {string} [filterRepairDeviceNameEn] 
         * @param {number} [filterRepairGroupId] 
         * @param {boolean} [filterOnlyWithSku] 
         * @param {OrderByDirection} [orderBySe] 
         * @param {OrderByDirection} [orderBySku] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairDevicesBrowseGet: async (filterSe?: string, filterSku?: string, filterRepairDeviceNameRu?: string, filterRepairDeviceNameEn?: string, filterRepairGroupId?: number, filterOnlyWithSku?: boolean, orderBySe?: OrderByDirection, orderBySku?: OrderByDirection, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-repair-devices/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterSe !== undefined) {
                localVarQueryParameter['Filter.Se'] = filterSe;
            }

            if (filterSku !== undefined) {
                localVarQueryParameter['Filter.Sku'] = filterSku;
            }

            if (filterRepairDeviceNameRu !== undefined) {
                localVarQueryParameter['Filter.RepairDeviceNameRu'] = filterRepairDeviceNameRu;
            }

            if (filterRepairDeviceNameEn !== undefined) {
                localVarQueryParameter['Filter.RepairDeviceNameEn'] = filterRepairDeviceNameEn;
            }

            if (filterRepairGroupId !== undefined) {
                localVarQueryParameter['Filter.RepairGroupId'] = filterRepairGroupId;
            }

            if (filterOnlyWithSku !== undefined) {
                localVarQueryParameter['Filter.OnlyWithSku'] = filterOnlyWithSku;
            }

            if (orderBySe !== undefined) {
                localVarQueryParameter['OrderBy.Se'] = orderBySe;
            }

            if (orderBySku !== undefined) {
                localVarQueryParameter['OrderBy.Sku'] = orderBySku;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить группу для прибора
         * @param {string} sek 
         * @param {UpdateRepairDevicePayload} [updateRepairDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairDevicesSekPut: async (sek: string, updateRepairDevicePayload?: UpdateRepairDevicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sek' is not null or undefined
            assertParamExists('apiDictRepairDevicesSekPut', 'sek', sek)
            const localVarPath = `/api/dict-repair-devices/{sek}`
                .replace(`{${"sek"}}`, encodeURIComponent(String(sek)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairDevicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить упаковочные инструкции для прибора
         * @param {string} sek 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairDevicesSekRemovePackingInstructionsPost: async (sek: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sek' is not null or undefined
            assertParamExists('apiDictRepairDevicesSekRemovePackingInstructionsPost', 'sek', sek)
            const localVarPath = `/api/dict-repair-devices/{sek}/remove-packing-instructions`
                .replace(`{${"sek"}}`, encodeURIComponent(String(sek)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить упаковочные инструкции для прибора
         * @param {string} sek 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairDevicesSekUploadPackingInstructionsPost: async (sek: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sek' is not null or undefined
            assertParamExists('apiDictRepairDevicesSekUploadPackingInstructionsPost', 'sek', sek)
            const localVarPath = `/api/dict-repair-devices/{sek}/upload-packing-instructions`
                .replace(`{${"sek"}}`, encodeURIComponent(String(sek)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictRepairDevicesApi - functional programming interface
 * @export
 */
export const DictRepairDevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictRepairDevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал ремонтных приборов
         * @param {string} [filterSe] 
         * @param {string} [filterSku] 
         * @param {string} [filterRepairDeviceNameRu] 
         * @param {string} [filterRepairDeviceNameEn] 
         * @param {number} [filterRepairGroupId] 
         * @param {boolean} [filterOnlyWithSku] 
         * @param {OrderByDirection} [orderBySe] 
         * @param {OrderByDirection} [orderBySku] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictRepairDevicesBrowseGet(filterSe?: string, filterSku?: string, filterRepairDeviceNameRu?: string, filterRepairDeviceNameEn?: string, filterRepairGroupId?: number, filterOnlyWithSku?: boolean, orderBySe?: OrderByDirection, orderBySku?: OrderByDirection, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowseRepairDevicesPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictRepairDevicesBrowseGet(filterSe, filterSku, filterRepairDeviceNameRu, filterRepairDeviceNameEn, filterRepairGroupId, filterOnlyWithSku, orderBySe, orderBySku, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить группу для прибора
         * @param {string} sek 
         * @param {UpdateRepairDevicePayload} [updateRepairDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictRepairDevicesSekPut(sek: string, updateRepairDevicePayload?: UpdateRepairDevicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictRepairDevicesSekPut(sek, updateRepairDevicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить упаковочные инструкции для прибора
         * @param {string} sek 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictRepairDevicesSekRemovePackingInstructionsPost(sek: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictRepairDevicesSekRemovePackingInstructionsPost(sek, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузить упаковочные инструкции для прибора
         * @param {string} sek 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictRepairDevicesSekUploadPackingInstructionsPost(sek: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictRepairDevicesSekUploadPackingInstructionsPost(sek, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictRepairDevicesApi - factory interface
 * @export
 */
export const DictRepairDevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictRepairDevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал ремонтных приборов
         * @param {DictRepairDevicesApiApiDictRepairDevicesBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairDevicesBrowseGet(requestParameters: DictRepairDevicesApiApiDictRepairDevicesBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BrowseRepairDevicesPagingResult> {
            return localVarFp.apiDictRepairDevicesBrowseGet(requestParameters.filterSe, requestParameters.filterSku, requestParameters.filterRepairDeviceNameRu, requestParameters.filterRepairDeviceNameEn, requestParameters.filterRepairGroupId, requestParameters.filterOnlyWithSku, requestParameters.orderBySe, requestParameters.orderBySku, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить группу для прибора
         * @param {DictRepairDevicesApiApiDictRepairDevicesSekPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairDevicesSekPut(requestParameters: DictRepairDevicesApiApiDictRepairDevicesSekPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictRepairDevicesSekPut(requestParameters.sek, requestParameters.updateRepairDevicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить упаковочные инструкции для прибора
         * @param {DictRepairDevicesApiApiDictRepairDevicesSekRemovePackingInstructionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairDevicesSekRemovePackingInstructionsPost(requestParameters: DictRepairDevicesApiApiDictRepairDevicesSekRemovePackingInstructionsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictRepairDevicesSekRemovePackingInstructionsPost(requestParameters.sek, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить упаковочные инструкции для прибора
         * @param {DictRepairDevicesApiApiDictRepairDevicesSekUploadPackingInstructionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairDevicesSekUploadPackingInstructionsPost(requestParameters: DictRepairDevicesApiApiDictRepairDevicesSekUploadPackingInstructionsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictRepairDevicesSekUploadPackingInstructionsPost(requestParameters.sek, requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictRepairDevicesBrowseGet operation in DictRepairDevicesApi.
 * @export
 * @interface DictRepairDevicesApiApiDictRepairDevicesBrowseGetRequest
 */
export interface DictRepairDevicesApiApiDictRepairDevicesBrowseGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly filterSe?: string

    /**
     * 
     * @type {string}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly filterSku?: string

    /**
     * 
     * @type {string}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly filterRepairDeviceNameRu?: string

    /**
     * 
     * @type {string}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly filterRepairDeviceNameEn?: string

    /**
     * 
     * @type {number}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly filterRepairGroupId?: number

    /**
     * 
     * @type {boolean}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly filterOnlyWithSku?: boolean

    /**
     * 
     * @type {OrderByDirection}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly orderBySe?: OrderByDirection

    /**
     * 
     * @type {OrderByDirection}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly orderBySku?: OrderByDirection

    /**
     * 
     * @type {number}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesBrowseGet
     */
    readonly pagingPageSize?: number
}

/**
 * Request parameters for apiDictRepairDevicesSekPut operation in DictRepairDevicesApi.
 * @export
 * @interface DictRepairDevicesApiApiDictRepairDevicesSekPutRequest
 */
export interface DictRepairDevicesApiApiDictRepairDevicesSekPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesSekPut
     */
    readonly sek: string

    /**
     * 
     * @type {UpdateRepairDevicePayload}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesSekPut
     */
    readonly updateRepairDevicePayload?: UpdateRepairDevicePayload
}

/**
 * Request parameters for apiDictRepairDevicesSekRemovePackingInstructionsPost operation in DictRepairDevicesApi.
 * @export
 * @interface DictRepairDevicesApiApiDictRepairDevicesSekRemovePackingInstructionsPostRequest
 */
export interface DictRepairDevicesApiApiDictRepairDevicesSekRemovePackingInstructionsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesSekRemovePackingInstructionsPost
     */
    readonly sek: string
}

/**
 * Request parameters for apiDictRepairDevicesSekUploadPackingInstructionsPost operation in DictRepairDevicesApi.
 * @export
 * @interface DictRepairDevicesApiApiDictRepairDevicesSekUploadPackingInstructionsPostRequest
 */
export interface DictRepairDevicesApiApiDictRepairDevicesSekUploadPackingInstructionsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesSekUploadPackingInstructionsPost
     */
    readonly sek: string

    /**
     * 
     * @type {File}
     * @memberof DictRepairDevicesApiApiDictRepairDevicesSekUploadPackingInstructionsPost
     */
    readonly file?: File
}

/**
 * DictRepairDevicesApi - object-oriented interface
 * @export
 * @class DictRepairDevicesApi
 * @extends {BaseAPI}
 */
export class DictRepairDevicesApi extends BaseAPI {
    /**
     * 
     * @summary Журнал ремонтных приборов
     * @param {DictRepairDevicesApiApiDictRepairDevicesBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictRepairDevicesApi
     */
    public apiDictRepairDevicesBrowseGet(requestParameters: DictRepairDevicesApiApiDictRepairDevicesBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return DictRepairDevicesApiFp(this.configuration).apiDictRepairDevicesBrowseGet(requestParameters.filterSe, requestParameters.filterSku, requestParameters.filterRepairDeviceNameRu, requestParameters.filterRepairDeviceNameEn, requestParameters.filterRepairGroupId, requestParameters.filterOnlyWithSku, requestParameters.orderBySe, requestParameters.orderBySku, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить группу для прибора
     * @param {DictRepairDevicesApiApiDictRepairDevicesSekPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictRepairDevicesApi
     */
    public apiDictRepairDevicesSekPut(requestParameters: DictRepairDevicesApiApiDictRepairDevicesSekPutRequest, options?: AxiosRequestConfig) {
        return DictRepairDevicesApiFp(this.configuration).apiDictRepairDevicesSekPut(requestParameters.sek, requestParameters.updateRepairDevicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить упаковочные инструкции для прибора
     * @param {DictRepairDevicesApiApiDictRepairDevicesSekRemovePackingInstructionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictRepairDevicesApi
     */
    public apiDictRepairDevicesSekRemovePackingInstructionsPost(requestParameters: DictRepairDevicesApiApiDictRepairDevicesSekRemovePackingInstructionsPostRequest, options?: AxiosRequestConfig) {
        return DictRepairDevicesApiFp(this.configuration).apiDictRepairDevicesSekRemovePackingInstructionsPost(requestParameters.sek, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить упаковочные инструкции для прибора
     * @param {DictRepairDevicesApiApiDictRepairDevicesSekUploadPackingInstructionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictRepairDevicesApi
     */
    public apiDictRepairDevicesSekUploadPackingInstructionsPost(requestParameters: DictRepairDevicesApiApiDictRepairDevicesSekUploadPackingInstructionsPostRequest, options?: AxiosRequestConfig) {
        return DictRepairDevicesApiFp(this.configuration).apiDictRepairDevicesSekUploadPackingInstructionsPost(requestParameters.sek, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictRepairGroupsApi - axios parameter creator
 * @export
 */
export const DictRepairGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал ремонтных групп (нормачасы)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairGroupsBrowseGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-repair-groups/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить запись
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairGroupsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDictRepairGroupsIdDelete', 'id', id)
            const localVarPath = `/api/dict-repair-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить запись
         * @param {number} id 
         * @param {UpdateRepairGroupPayload} [updateRepairGroupPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairGroupsIdPut: async (id: number, updateRepairGroupPayload?: UpdateRepairGroupPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDictRepairGroupsIdPut', 'id', id)
            const localVarPath = `/api/dict-repair-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairGroupPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить запись
         * @param {CreateRepairGroupPayload} [createRepairGroupPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairGroupsPost: async (createRepairGroupPayload?: CreateRepairGroupPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-repair-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairGroupPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictRepairGroupsApi - functional programming interface
 * @export
 */
export const DictRepairGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictRepairGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал ремонтных групп (нормачасы)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictRepairGroupsBrowseGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairGroupBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictRepairGroupsBrowseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить запись
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictRepairGroupsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictRepairGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить запись
         * @param {number} id 
         * @param {UpdateRepairGroupPayload} [updateRepairGroupPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictRepairGroupsIdPut(id: number, updateRepairGroupPayload?: UpdateRepairGroupPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictRepairGroupsIdPut(id, updateRepairGroupPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить запись
         * @param {CreateRepairGroupPayload} [createRepairGroupPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictRepairGroupsPost(createRepairGroupPayload?: CreateRepairGroupPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRepairGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictRepairGroupsPost(createRepairGroupPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictRepairGroupsApi - factory interface
 * @export
 */
export const DictRepairGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictRepairGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал ремонтных групп (нормачасы)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairGroupsBrowseGet(options?: AxiosRequestConfig): AxiosPromise<Array<RepairGroupBrowseDto>> {
            return localVarFp.apiDictRepairGroupsBrowseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить запись
         * @param {DictRepairGroupsApiApiDictRepairGroupsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairGroupsIdDelete(requestParameters: DictRepairGroupsApiApiDictRepairGroupsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictRepairGroupsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить запись
         * @param {DictRepairGroupsApiApiDictRepairGroupsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairGroupsIdPut(requestParameters: DictRepairGroupsApiApiDictRepairGroupsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictRepairGroupsIdPut(requestParameters.id, requestParameters.updateRepairGroupPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить запись
         * @param {DictRepairGroupsApiApiDictRepairGroupsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictRepairGroupsPost(requestParameters: DictRepairGroupsApiApiDictRepairGroupsPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateRepairGroupResponse> {
            return localVarFp.apiDictRepairGroupsPost(requestParameters.createRepairGroupPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictRepairGroupsIdDelete operation in DictRepairGroupsApi.
 * @export
 * @interface DictRepairGroupsApiApiDictRepairGroupsIdDeleteRequest
 */
export interface DictRepairGroupsApiApiDictRepairGroupsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DictRepairGroupsApiApiDictRepairGroupsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiDictRepairGroupsIdPut operation in DictRepairGroupsApi.
 * @export
 * @interface DictRepairGroupsApiApiDictRepairGroupsIdPutRequest
 */
export interface DictRepairGroupsApiApiDictRepairGroupsIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof DictRepairGroupsApiApiDictRepairGroupsIdPut
     */
    readonly id: number

    /**
     * 
     * @type {UpdateRepairGroupPayload}
     * @memberof DictRepairGroupsApiApiDictRepairGroupsIdPut
     */
    readonly updateRepairGroupPayload?: UpdateRepairGroupPayload
}

/**
 * Request parameters for apiDictRepairGroupsPost operation in DictRepairGroupsApi.
 * @export
 * @interface DictRepairGroupsApiApiDictRepairGroupsPostRequest
 */
export interface DictRepairGroupsApiApiDictRepairGroupsPostRequest {
    /**
     * 
     * @type {CreateRepairGroupPayload}
     * @memberof DictRepairGroupsApiApiDictRepairGroupsPost
     */
    readonly createRepairGroupPayload?: CreateRepairGroupPayload
}

/**
 * DictRepairGroupsApi - object-oriented interface
 * @export
 * @class DictRepairGroupsApi
 * @extends {BaseAPI}
 */
export class DictRepairGroupsApi extends BaseAPI {
    /**
     * 
     * @summary Журнал ремонтных групп (нормачасы)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictRepairGroupsApi
     */
    public apiDictRepairGroupsBrowseGet(options?: AxiosRequestConfig) {
        return DictRepairGroupsApiFp(this.configuration).apiDictRepairGroupsBrowseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить запись
     * @param {DictRepairGroupsApiApiDictRepairGroupsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictRepairGroupsApi
     */
    public apiDictRepairGroupsIdDelete(requestParameters: DictRepairGroupsApiApiDictRepairGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return DictRepairGroupsApiFp(this.configuration).apiDictRepairGroupsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить запись
     * @param {DictRepairGroupsApiApiDictRepairGroupsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictRepairGroupsApi
     */
    public apiDictRepairGroupsIdPut(requestParameters: DictRepairGroupsApiApiDictRepairGroupsIdPutRequest, options?: AxiosRequestConfig) {
        return DictRepairGroupsApiFp(this.configuration).apiDictRepairGroupsIdPut(requestParameters.id, requestParameters.updateRepairGroupPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить запись
     * @param {DictRepairGroupsApiApiDictRepairGroupsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictRepairGroupsApi
     */
    public apiDictRepairGroupsPost(requestParameters: DictRepairGroupsApiApiDictRepairGroupsPostRequest = {}, options?: AxiosRequestConfig) {
        return DictRepairGroupsApiFp(this.configuration).apiDictRepairGroupsPost(requestParameters.createRepairGroupPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictSerialsApi - axios parameter creator
 * @export
 */
export const DictSerialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал серийных номеров - список
         * @param {string} [filterSerial] Серийный номер
         * @param {number} [filterCounterpartyId] Ключ контрагента
         * @param {OrderByDirection} [orderBySerial] Сортировка по серийному номеру
         * @param {OrderByDirection} [orderByCounterpartyName] Сортировка по наименованию контрагента
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsBrowseGet: async (filterSerial?: string, filterCounterpartyId?: number, orderBySerial?: OrderByDirection, orderByCounterpartyName?: OrderByDirection, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-serials/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterSerial !== undefined) {
                localVarQueryParameter['Filter.Serial'] = filterSerial;
            }

            if (filterCounterpartyId !== undefined) {
                localVarQueryParameter['Filter.CounterpartyId'] = filterCounterpartyId;
            }

            if (orderBySerial !== undefined) {
                localVarQueryParameter['OrderBy.Serial'] = orderBySerial;
            }

            if (orderByCounterpartyName !== undefined) {
                localVarQueryParameter['OrderBy.CounterpartyName'] = orderByCounterpartyName;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал серийных номеров - элемент
         * @param {string} serialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsBrowseSerialIdGet: async (serialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialId' is not null or undefined
            assertParamExists('apiDictSerialsBrowseSerialIdGet', 'serialId', serialId)
            const localVarPath = `/api/dict-serials/browse/{serialId}`
                .replace(`{${"serialId"}}`, encodeURIComponent(String(serialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить запись
         * @param {CreateSerialPayload} [createSerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsPost: async (createSerialPayload?: CreateSerialPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-serials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSerialPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить запись
         * @param {string} serialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsSerialIdDelete: async (serialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialId' is not null or undefined
            assertParamExists('apiDictSerialsSerialIdDelete', 'serialId', serialId)
            const localVarPath = `/api/dict-serials/{serialId}`
                .replace(`{${"serialId"}}`, encodeURIComponent(String(serialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить запись
         * @param {string} serialId 
         * @param {UpdateSerialPayload} [updateSerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsSerialIdPut: async (serialId: string, updateSerialPayload?: UpdateSerialPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialId' is not null or undefined
            assertParamExists('apiDictSerialsSerialIdPut', 'serialId', serialId)
            const localVarPath = `/api/dict-serials/{serialId}`
                .replace(`{${"serialId"}}`, encodeURIComponent(String(serialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSerialPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить примечание
         * @param {string} serial 
         * @param {UpdateSerialNotesPayload} [updateSerialNotesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsSerialSetNotesPost: async (serial: string, updateSerialNotesPayload?: UpdateSerialNotesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('apiDictSerialsSerialSetNotesPost', 'serial', serial)
            const localVarPath = `/api/dict-serials/{serial}/set-notes`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSerialNotesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictSerialsApi - functional programming interface
 * @export
 */
export const DictSerialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictSerialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал серийных номеров - список
         * @param {string} [filterSerial] Серийный номер
         * @param {number} [filterCounterpartyId] Ключ контрагента
         * @param {OrderByDirection} [orderBySerial] Сортировка по серийному номеру
         * @param {OrderByDirection} [orderByCounterpartyName] Сортировка по наименованию контрагента
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictSerialsBrowseGet(filterSerial?: string, filterCounterpartyId?: number, orderBySerial?: OrderByDirection, orderByCounterpartyName?: OrderByDirection, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowseSerialsPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictSerialsBrowseGet(filterSerial, filterCounterpartyId, orderBySerial, orderByCounterpartyName, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал серийных номеров - элемент
         * @param {string} serialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictSerialsBrowseSerialIdGet(serialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SerialBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictSerialsBrowseSerialIdGet(serialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить запись
         * @param {CreateSerialPayload} [createSerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictSerialsPost(createSerialPayload?: CreateSerialPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictSerialsPost(createSerialPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить запись
         * @param {string} serialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictSerialsSerialIdDelete(serialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictSerialsSerialIdDelete(serialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить запись
         * @param {string} serialId 
         * @param {UpdateSerialPayload} [updateSerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictSerialsSerialIdPut(serialId: string, updateSerialPayload?: UpdateSerialPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictSerialsSerialIdPut(serialId, updateSerialPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить примечание
         * @param {string} serial 
         * @param {UpdateSerialNotesPayload} [updateSerialNotesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictSerialsSerialSetNotesPost(serial: string, updateSerialNotesPayload?: UpdateSerialNotesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictSerialsSerialSetNotesPost(serial, updateSerialNotesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictSerialsApi - factory interface
 * @export
 */
export const DictSerialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictSerialsApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал серийных номеров - список
         * @param {DictSerialsApiApiDictSerialsBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsBrowseGet(requestParameters: DictSerialsApiApiDictSerialsBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BrowseSerialsPagingResult> {
            return localVarFp.apiDictSerialsBrowseGet(requestParameters.filterSerial, requestParameters.filterCounterpartyId, requestParameters.orderBySerial, requestParameters.orderByCounterpartyName, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал серийных номеров - элемент
         * @param {DictSerialsApiApiDictSerialsBrowseSerialIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsBrowseSerialIdGet(requestParameters: DictSerialsApiApiDictSerialsBrowseSerialIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<SerialBrowseDto> {
            return localVarFp.apiDictSerialsBrowseSerialIdGet(requestParameters.serialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить запись
         * @param {DictSerialsApiApiDictSerialsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsPost(requestParameters: DictSerialsApiApiDictSerialsPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictSerialsPost(requestParameters.createSerialPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить запись
         * @param {DictSerialsApiApiDictSerialsSerialIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsSerialIdDelete(requestParameters: DictSerialsApiApiDictSerialsSerialIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictSerialsSerialIdDelete(requestParameters.serialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить запись
         * @param {DictSerialsApiApiDictSerialsSerialIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsSerialIdPut(requestParameters: DictSerialsApiApiDictSerialsSerialIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictSerialsSerialIdPut(requestParameters.serialId, requestParameters.updateSerialPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить примечание
         * @param {DictSerialsApiApiDictSerialsSerialSetNotesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictSerialsSerialSetNotesPost(requestParameters: DictSerialsApiApiDictSerialsSerialSetNotesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictSerialsSerialSetNotesPost(requestParameters.serial, requestParameters.updateSerialNotesPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictSerialsBrowseGet operation in DictSerialsApi.
 * @export
 * @interface DictSerialsApiApiDictSerialsBrowseGetRequest
 */
export interface DictSerialsApiApiDictSerialsBrowseGetRequest {
    /**
     * Серийный номер
     * @type {string}
     * @memberof DictSerialsApiApiDictSerialsBrowseGet
     */
    readonly filterSerial?: string

    /**
     * Ключ контрагента
     * @type {number}
     * @memberof DictSerialsApiApiDictSerialsBrowseGet
     */
    readonly filterCounterpartyId?: number

    /**
     * Сортировка по серийному номеру
     * @type {OrderByDirection}
     * @memberof DictSerialsApiApiDictSerialsBrowseGet
     */
    readonly orderBySerial?: OrderByDirection

    /**
     * Сортировка по наименованию контрагента
     * @type {OrderByDirection}
     * @memberof DictSerialsApiApiDictSerialsBrowseGet
     */
    readonly orderByCounterpartyName?: OrderByDirection

    /**
     * 
     * @type {number}
     * @memberof DictSerialsApiApiDictSerialsBrowseGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof DictSerialsApiApiDictSerialsBrowseGet
     */
    readonly pagingPageSize?: number
}

/**
 * Request parameters for apiDictSerialsBrowseSerialIdGet operation in DictSerialsApi.
 * @export
 * @interface DictSerialsApiApiDictSerialsBrowseSerialIdGetRequest
 */
export interface DictSerialsApiApiDictSerialsBrowseSerialIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DictSerialsApiApiDictSerialsBrowseSerialIdGet
     */
    readonly serialId: string
}

/**
 * Request parameters for apiDictSerialsPost operation in DictSerialsApi.
 * @export
 * @interface DictSerialsApiApiDictSerialsPostRequest
 */
export interface DictSerialsApiApiDictSerialsPostRequest {
    /**
     * 
     * @type {CreateSerialPayload}
     * @memberof DictSerialsApiApiDictSerialsPost
     */
    readonly createSerialPayload?: CreateSerialPayload
}

/**
 * Request parameters for apiDictSerialsSerialIdDelete operation in DictSerialsApi.
 * @export
 * @interface DictSerialsApiApiDictSerialsSerialIdDeleteRequest
 */
export interface DictSerialsApiApiDictSerialsSerialIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DictSerialsApiApiDictSerialsSerialIdDelete
     */
    readonly serialId: string
}

/**
 * Request parameters for apiDictSerialsSerialIdPut operation in DictSerialsApi.
 * @export
 * @interface DictSerialsApiApiDictSerialsSerialIdPutRequest
 */
export interface DictSerialsApiApiDictSerialsSerialIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DictSerialsApiApiDictSerialsSerialIdPut
     */
    readonly serialId: string

    /**
     * 
     * @type {UpdateSerialPayload}
     * @memberof DictSerialsApiApiDictSerialsSerialIdPut
     */
    readonly updateSerialPayload?: UpdateSerialPayload
}

/**
 * Request parameters for apiDictSerialsSerialSetNotesPost operation in DictSerialsApi.
 * @export
 * @interface DictSerialsApiApiDictSerialsSerialSetNotesPostRequest
 */
export interface DictSerialsApiApiDictSerialsSerialSetNotesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DictSerialsApiApiDictSerialsSerialSetNotesPost
     */
    readonly serial: string

    /**
     * 
     * @type {UpdateSerialNotesPayload}
     * @memberof DictSerialsApiApiDictSerialsSerialSetNotesPost
     */
    readonly updateSerialNotesPayload?: UpdateSerialNotesPayload
}

/**
 * DictSerialsApi - object-oriented interface
 * @export
 * @class DictSerialsApi
 * @extends {BaseAPI}
 */
export class DictSerialsApi extends BaseAPI {
    /**
     * 
     * @summary Журнал серийных номеров - список
     * @param {DictSerialsApiApiDictSerialsBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictSerialsApi
     */
    public apiDictSerialsBrowseGet(requestParameters: DictSerialsApiApiDictSerialsBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return DictSerialsApiFp(this.configuration).apiDictSerialsBrowseGet(requestParameters.filterSerial, requestParameters.filterCounterpartyId, requestParameters.orderBySerial, requestParameters.orderByCounterpartyName, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал серийных номеров - элемент
     * @param {DictSerialsApiApiDictSerialsBrowseSerialIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictSerialsApi
     */
    public apiDictSerialsBrowseSerialIdGet(requestParameters: DictSerialsApiApiDictSerialsBrowseSerialIdGetRequest, options?: AxiosRequestConfig) {
        return DictSerialsApiFp(this.configuration).apiDictSerialsBrowseSerialIdGet(requestParameters.serialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить запись
     * @param {DictSerialsApiApiDictSerialsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictSerialsApi
     */
    public apiDictSerialsPost(requestParameters: DictSerialsApiApiDictSerialsPostRequest = {}, options?: AxiosRequestConfig) {
        return DictSerialsApiFp(this.configuration).apiDictSerialsPost(requestParameters.createSerialPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить запись
     * @param {DictSerialsApiApiDictSerialsSerialIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictSerialsApi
     */
    public apiDictSerialsSerialIdDelete(requestParameters: DictSerialsApiApiDictSerialsSerialIdDeleteRequest, options?: AxiosRequestConfig) {
        return DictSerialsApiFp(this.configuration).apiDictSerialsSerialIdDelete(requestParameters.serialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить запись
     * @param {DictSerialsApiApiDictSerialsSerialIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictSerialsApi
     */
    public apiDictSerialsSerialIdPut(requestParameters: DictSerialsApiApiDictSerialsSerialIdPutRequest, options?: AxiosRequestConfig) {
        return DictSerialsApiFp(this.configuration).apiDictSerialsSerialIdPut(requestParameters.serialId, requestParameters.updateSerialPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить примечание
     * @param {DictSerialsApiApiDictSerialsSerialSetNotesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictSerialsApi
     */
    public apiDictSerialsSerialSetNotesPost(requestParameters: DictSerialsApiApiDictSerialsSerialSetNotesPostRequest, options?: AxiosRequestConfig) {
        return DictSerialsApiFp(this.configuration).apiDictSerialsSerialSetNotesPost(requestParameters.serial, requestParameters.updateSerialNotesPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictStockAreasApi - axios parameter creator
 * @export
 */
export const DictStockAreasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавить персональный участок ремонта
         * @param {CreateInnerManufacturingAreaPayload} [createInnerManufacturingAreaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictStockAreasAddInnerManufacturingAreaPost: async (createInnerManufacturingAreaPayload?: CreateInnerManufacturingAreaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-stock-areas/add-inner-manufacturing-area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInnerManufacturingAreaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список складов
         * @param {number} [firmId] Id компании
         * @param {StockAreaType} [areaType] Тип склада
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictStockAreasGet: async (firmId?: number, areaType?: StockAreaType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-stock-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (areaType !== undefined) {
                localVarQueryParameter['AreaType'] = areaType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictStockAreasApi - functional programming interface
 * @export
 */
export const DictStockAreasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictStockAreasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Добавить персональный участок ремонта
         * @param {CreateInnerManufacturingAreaPayload} [createInnerManufacturingAreaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictStockAreasAddInnerManufacturingAreaPost(createInnerManufacturingAreaPayload?: CreateInnerManufacturingAreaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedStockAreaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictStockAreasAddInnerManufacturingAreaPost(createInnerManufacturingAreaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список складов
         * @param {number} [firmId] Id компании
         * @param {StockAreaType} [areaType] Тип склада
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictStockAreasGet(firmId?: number, areaType?: StockAreaType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockAreaDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictStockAreasGet(firmId, areaType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictStockAreasApi - factory interface
 * @export
 */
export const DictStockAreasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictStockAreasApiFp(configuration)
    return {
        /**
         * 
         * @summary Добавить персональный участок ремонта
         * @param {DictStockAreasApiApiDictStockAreasAddInnerManufacturingAreaPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictStockAreasAddInnerManufacturingAreaPost(requestParameters: DictStockAreasApiApiDictStockAreasAddInnerManufacturingAreaPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreatedStockAreaDto> {
            return localVarFp.apiDictStockAreasAddInnerManufacturingAreaPost(requestParameters.createInnerManufacturingAreaPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список складов
         * @param {DictStockAreasApiApiDictStockAreasGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictStockAreasGet(requestParameters: DictStockAreasApiApiDictStockAreasGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<StockAreaDto>> {
            return localVarFp.apiDictStockAreasGet(requestParameters.firmId, requestParameters.areaType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictStockAreasAddInnerManufacturingAreaPost operation in DictStockAreasApi.
 * @export
 * @interface DictStockAreasApiApiDictStockAreasAddInnerManufacturingAreaPostRequest
 */
export interface DictStockAreasApiApiDictStockAreasAddInnerManufacturingAreaPostRequest {
    /**
     * 
     * @type {CreateInnerManufacturingAreaPayload}
     * @memberof DictStockAreasApiApiDictStockAreasAddInnerManufacturingAreaPost
     */
    readonly createInnerManufacturingAreaPayload?: CreateInnerManufacturingAreaPayload
}

/**
 * Request parameters for apiDictStockAreasGet operation in DictStockAreasApi.
 * @export
 * @interface DictStockAreasApiApiDictStockAreasGetRequest
 */
export interface DictStockAreasApiApiDictStockAreasGetRequest {
    /**
     * Id компании
     * @type {number}
     * @memberof DictStockAreasApiApiDictStockAreasGet
     */
    readonly firmId?: number

    /**
     * Тип склада
     * @type {StockAreaType}
     * @memberof DictStockAreasApiApiDictStockAreasGet
     */
    readonly areaType?: StockAreaType
}

/**
 * DictStockAreasApi - object-oriented interface
 * @export
 * @class DictStockAreasApi
 * @extends {BaseAPI}
 */
export class DictStockAreasApi extends BaseAPI {
    /**
     * 
     * @summary Добавить персональный участок ремонта
     * @param {DictStockAreasApiApiDictStockAreasAddInnerManufacturingAreaPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictStockAreasApi
     */
    public apiDictStockAreasAddInnerManufacturingAreaPost(requestParameters: DictStockAreasApiApiDictStockAreasAddInnerManufacturingAreaPostRequest = {}, options?: AxiosRequestConfig) {
        return DictStockAreasApiFp(this.configuration).apiDictStockAreasAddInnerManufacturingAreaPost(requestParameters.createInnerManufacturingAreaPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список складов
     * @param {DictStockAreasApiApiDictStockAreasGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictStockAreasApi
     */
    public apiDictStockAreasGet(requestParameters: DictStockAreasApiApiDictStockAreasGetRequest = {}, options?: AxiosRequestConfig) {
        return DictStockAreasApiFp(this.configuration).apiDictStockAreasGet(requestParameters.firmId, requestParameters.areaType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictTimeRatesApi - axios parameter creator
 * @export
 */
export const DictTimeRatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Изменить норму времени
         * @param {string} area 
         * @param {number} year 
         * @param {number} month 
         * @param {UpdateTimeRatePayload} [updateTimeRatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictTimeRatesAreaAreaYearYearMonthMonthPut: async (area: string, year: number, month: number, updateTimeRatePayload?: UpdateTimeRatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'area' is not null or undefined
            assertParamExists('apiDictTimeRatesAreaAreaYearYearMonthMonthPut', 'area', area)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiDictTimeRatesAreaAreaYearYearMonthMonthPut', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('apiDictTimeRatesAreaAreaYearYearMonthMonthPut', 'month', month)
            const localVarPath = `/api/dict-time-rates/area/{area}/year/{year}/month/{month}`
                .replace(`{${"area"}}`, encodeURIComponent(String(area)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTimeRatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Нормы времени
         * @param {number} [firmId] Ид фирмы
         * @param {number} [year] Год
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictTimeRatesBrowseGet: async (firmId?: number, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-time-rates/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить нормы времени на месяц
         * @param {CreateTimeRatesPayload} [createTimeRatesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictTimeRatesPost: async (createTimeRatesPayload?: CreateTimeRatesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-time-rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTimeRatesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictTimeRatesApi - functional programming interface
 * @export
 */
export const DictTimeRatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictTimeRatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Изменить норму времени
         * @param {string} area 
         * @param {number} year 
         * @param {number} month 
         * @param {UpdateTimeRatePayload} [updateTimeRatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictTimeRatesAreaAreaYearYearMonthMonthPut(area: string, year: number, month: number, updateTimeRatePayload?: UpdateTimeRatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictTimeRatesAreaAreaYearYearMonthMonthPut(area, year, month, updateTimeRatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Нормы времени
         * @param {number} [firmId] Ид фирмы
         * @param {number} [year] Год
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictTimeRatesBrowseGet(firmId?: number, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeRateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictTimeRatesBrowseGet(firmId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить нормы времени на месяц
         * @param {CreateTimeRatesPayload} [createTimeRatesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictTimeRatesPost(createTimeRatesPayload?: CreateTimeRatesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictTimeRatesPost(createTimeRatesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictTimeRatesApi - factory interface
 * @export
 */
export const DictTimeRatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictTimeRatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Изменить норму времени
         * @param {DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictTimeRatesAreaAreaYearYearMonthMonthPut(requestParameters: DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictTimeRatesAreaAreaYearYearMonthMonthPut(requestParameters.area, requestParameters.year, requestParameters.month, requestParameters.updateTimeRatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Нормы времени
         * @param {DictTimeRatesApiApiDictTimeRatesBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictTimeRatesBrowseGet(requestParameters: DictTimeRatesApiApiDictTimeRatesBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<TimeRateDto>> {
            return localVarFp.apiDictTimeRatesBrowseGet(requestParameters.firmId, requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить нормы времени на месяц
         * @param {DictTimeRatesApiApiDictTimeRatesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictTimeRatesPost(requestParameters: DictTimeRatesApiApiDictTimeRatesPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictTimeRatesPost(requestParameters.createTimeRatesPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictTimeRatesAreaAreaYearYearMonthMonthPut operation in DictTimeRatesApi.
 * @export
 * @interface DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPutRequest
 */
export interface DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPut
     */
    readonly area: string

    /**
     * 
     * @type {number}
     * @memberof DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPut
     */
    readonly year: number

    /**
     * 
     * @type {number}
     * @memberof DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPut
     */
    readonly month: number

    /**
     * 
     * @type {UpdateTimeRatePayload}
     * @memberof DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPut
     */
    readonly updateTimeRatePayload?: UpdateTimeRatePayload
}

/**
 * Request parameters for apiDictTimeRatesBrowseGet operation in DictTimeRatesApi.
 * @export
 * @interface DictTimeRatesApiApiDictTimeRatesBrowseGetRequest
 */
export interface DictTimeRatesApiApiDictTimeRatesBrowseGetRequest {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof DictTimeRatesApiApiDictTimeRatesBrowseGet
     */
    readonly firmId?: number

    /**
     * Год
     * @type {number}
     * @memberof DictTimeRatesApiApiDictTimeRatesBrowseGet
     */
    readonly year?: number
}

/**
 * Request parameters for apiDictTimeRatesPost operation in DictTimeRatesApi.
 * @export
 * @interface DictTimeRatesApiApiDictTimeRatesPostRequest
 */
export interface DictTimeRatesApiApiDictTimeRatesPostRequest {
    /**
     * 
     * @type {CreateTimeRatesPayload}
     * @memberof DictTimeRatesApiApiDictTimeRatesPost
     */
    readonly createTimeRatesPayload?: CreateTimeRatesPayload
}

/**
 * DictTimeRatesApi - object-oriented interface
 * @export
 * @class DictTimeRatesApi
 * @extends {BaseAPI}
 */
export class DictTimeRatesApi extends BaseAPI {
    /**
     * 
     * @summary Изменить норму времени
     * @param {DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictTimeRatesApi
     */
    public apiDictTimeRatesAreaAreaYearYearMonthMonthPut(requestParameters: DictTimeRatesApiApiDictTimeRatesAreaAreaYearYearMonthMonthPutRequest, options?: AxiosRequestConfig) {
        return DictTimeRatesApiFp(this.configuration).apiDictTimeRatesAreaAreaYearYearMonthMonthPut(requestParameters.area, requestParameters.year, requestParameters.month, requestParameters.updateTimeRatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Нормы времени
     * @param {DictTimeRatesApiApiDictTimeRatesBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictTimeRatesApi
     */
    public apiDictTimeRatesBrowseGet(requestParameters: DictTimeRatesApiApiDictTimeRatesBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return DictTimeRatesApiFp(this.configuration).apiDictTimeRatesBrowseGet(requestParameters.firmId, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить нормы времени на месяц
     * @param {DictTimeRatesApiApiDictTimeRatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictTimeRatesApi
     */
    public apiDictTimeRatesPost(requestParameters: DictTimeRatesApiApiDictTimeRatesPostRequest = {}, options?: AxiosRequestConfig) {
        return DictTimeRatesApiFp(this.configuration).apiDictTimeRatesPost(requestParameters.createTimeRatesPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictWorksApi - axios parameter creator
 * @export
 */
export const DictWorksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал выполненных работ - список)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksBrowseGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-works/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал выполненых работ - элемент
         * @param {number} workId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksBrowseWorkIdGet: async (workId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workId' is not null or undefined
            assertParamExists('apiDictWorksBrowseWorkIdGet', 'workId', workId)
            const localVarPath = `/api/dict-works/browse/{workId}`
                .replace(`{${"workId"}}`, encodeURIComponent(String(workId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить работу
         * @param {CreateWorkPayload} [createWorkPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksPost: async (createWorkPayload?: CreateWorkPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dict-works`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить работу
         * @param {number} workId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksWorkIdDelete: async (workId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workId' is not null or undefined
            assertParamExists('apiDictWorksWorkIdDelete', 'workId', workId)
            const localVarPath = `/api/dict-works/{workId}`
                .replace(`{${"workId"}}`, encodeURIComponent(String(workId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить работу
         * @param {number} workId 
         * @param {UpdateWorkPayload} [updateWorkPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksWorkIdPut: async (workId: number, updateWorkPayload?: UpdateWorkPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workId' is not null or undefined
            assertParamExists('apiDictWorksWorkIdPut', 'workId', workId)
            const localVarPath = `/api/dict-works/{workId}`
                .replace(`{${"workId"}}`, encodeURIComponent(String(workId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictWorksApi - functional programming interface
 * @export
 */
export const DictWorksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictWorksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал выполненных работ - список)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictWorksBrowseGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictWorksBrowseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал выполненых работ - элемент
         * @param {number} workId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictWorksBrowseWorkIdGet(workId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictWorksBrowseWorkIdGet(workId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить работу
         * @param {CreateWorkPayload} [createWorkPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictWorksPost(createWorkPayload?: CreateWorkPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictWorksPost(createWorkPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить работу
         * @param {number} workId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictWorksWorkIdDelete(workId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictWorksWorkIdDelete(workId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить работу
         * @param {number} workId 
         * @param {UpdateWorkPayload} [updateWorkPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDictWorksWorkIdPut(workId: number, updateWorkPayload?: UpdateWorkPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDictWorksWorkIdPut(workId, updateWorkPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictWorksApi - factory interface
 * @export
 */
export const DictWorksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictWorksApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал выполненных работ - список)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksBrowseGet(options?: AxiosRequestConfig): AxiosPromise<Array<WorkDto>> {
            return localVarFp.apiDictWorksBrowseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал выполненых работ - элемент
         * @param {DictWorksApiApiDictWorksBrowseWorkIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksBrowseWorkIdGet(requestParameters: DictWorksApiApiDictWorksBrowseWorkIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<WorkDto> {
            return localVarFp.apiDictWorksBrowseWorkIdGet(requestParameters.workId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить работу
         * @param {DictWorksApiApiDictWorksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksPost(requestParameters: DictWorksApiApiDictWorksPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateWorkResponse> {
            return localVarFp.apiDictWorksPost(requestParameters.createWorkPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить работу
         * @param {DictWorksApiApiDictWorksWorkIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksWorkIdDelete(requestParameters: DictWorksApiApiDictWorksWorkIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictWorksWorkIdDelete(requestParameters.workId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить работу
         * @param {DictWorksApiApiDictWorksWorkIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDictWorksWorkIdPut(requestParameters: DictWorksApiApiDictWorksWorkIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDictWorksWorkIdPut(requestParameters.workId, requestParameters.updateWorkPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDictWorksBrowseWorkIdGet operation in DictWorksApi.
 * @export
 * @interface DictWorksApiApiDictWorksBrowseWorkIdGetRequest
 */
export interface DictWorksApiApiDictWorksBrowseWorkIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DictWorksApiApiDictWorksBrowseWorkIdGet
     */
    readonly workId: number
}

/**
 * Request parameters for apiDictWorksPost operation in DictWorksApi.
 * @export
 * @interface DictWorksApiApiDictWorksPostRequest
 */
export interface DictWorksApiApiDictWorksPostRequest {
    /**
     * 
     * @type {CreateWorkPayload}
     * @memberof DictWorksApiApiDictWorksPost
     */
    readonly createWorkPayload?: CreateWorkPayload
}

/**
 * Request parameters for apiDictWorksWorkIdDelete operation in DictWorksApi.
 * @export
 * @interface DictWorksApiApiDictWorksWorkIdDeleteRequest
 */
export interface DictWorksApiApiDictWorksWorkIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DictWorksApiApiDictWorksWorkIdDelete
     */
    readonly workId: number
}

/**
 * Request parameters for apiDictWorksWorkIdPut operation in DictWorksApi.
 * @export
 * @interface DictWorksApiApiDictWorksWorkIdPutRequest
 */
export interface DictWorksApiApiDictWorksWorkIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof DictWorksApiApiDictWorksWorkIdPut
     */
    readonly workId: number

    /**
     * 
     * @type {UpdateWorkPayload}
     * @memberof DictWorksApiApiDictWorksWorkIdPut
     */
    readonly updateWorkPayload?: UpdateWorkPayload
}

/**
 * DictWorksApi - object-oriented interface
 * @export
 * @class DictWorksApi
 * @extends {BaseAPI}
 */
export class DictWorksApi extends BaseAPI {
    /**
     * 
     * @summary Журнал выполненных работ - список)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictWorksApi
     */
    public apiDictWorksBrowseGet(options?: AxiosRequestConfig) {
        return DictWorksApiFp(this.configuration).apiDictWorksBrowseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал выполненых работ - элемент
     * @param {DictWorksApiApiDictWorksBrowseWorkIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictWorksApi
     */
    public apiDictWorksBrowseWorkIdGet(requestParameters: DictWorksApiApiDictWorksBrowseWorkIdGetRequest, options?: AxiosRequestConfig) {
        return DictWorksApiFp(this.configuration).apiDictWorksBrowseWorkIdGet(requestParameters.workId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить работу
     * @param {DictWorksApiApiDictWorksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictWorksApi
     */
    public apiDictWorksPost(requestParameters: DictWorksApiApiDictWorksPostRequest = {}, options?: AxiosRequestConfig) {
        return DictWorksApiFp(this.configuration).apiDictWorksPost(requestParameters.createWorkPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить работу
     * @param {DictWorksApiApiDictWorksWorkIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictWorksApi
     */
    public apiDictWorksWorkIdDelete(requestParameters: DictWorksApiApiDictWorksWorkIdDeleteRequest, options?: AxiosRequestConfig) {
        return DictWorksApiFp(this.configuration).apiDictWorksWorkIdDelete(requestParameters.workId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить работу
     * @param {DictWorksApiApiDictWorksWorkIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictWorksApi
     */
    public apiDictWorksWorkIdPut(requestParameters: DictWorksApiApiDictWorksWorkIdPutRequest, options?: AxiosRequestConfig) {
        return DictWorksApiFp(this.configuration).apiDictWorksWorkIdPut(requestParameters.workId, requestParameters.updateWorkPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpDevicesApi - axios parameter creator
 * @export
 */
export const OpDevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список CE для доукомплектации
         * @param {string} [filterSe] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesAddedCeListGet: async (filterSe?: string, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/added-ce/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterSe !== undefined) {
                localVarQueryParameter['Filter.Se'] = filterSe;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Состав прибора для доукомплектации
         * @param {string} se 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesAddedCeSeDetailsGet: async (se: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'se' is not null or undefined
            assertParamExists('apiOpDevicesAddedCeSeDetailsGet', 'se', se)
            const localVarPath = `/api/op-devices/added-ce/{se}/details`
                .replace(`{${"se"}}`, encodeURIComponent(String(se)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение типов батарей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesBatteriesTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/batteries/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить приборы для доукомплектации
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {DeviceAddAddedCEPayload} [deviceAddAddedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost: async (deviceId: number, deviceFirmId: number, deviceAddAddedCEPayload?: DeviceAddAddedCEPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost', 'deviceId', deviceId)
            // verify required parameter 'deviceFirmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost', 'deviceFirmId', deviceFirmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{deviceFirmId}/added-ce`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"deviceFirmId"}}`, encodeURIComponent(String(deviceFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAddAddedCEPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возврат без ремонта
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {DeviceReturnWithoutRepairPayload} [deviceReturnWithoutRepairPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost: async (deviceId: number, deviceFirmId: number, deviceReturnWithoutRepairPayload?: DeviceReturnWithoutRepairPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost', 'deviceId', deviceId)
            // verify required parameter 'deviceFirmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost', 'deviceFirmId', deviceFirmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{deviceFirmId}/features/return-without-repair`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"deviceFirmId"}}`, encodeURIComponent(String(deviceFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceReturnWithoutRepairPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Установить гарантию
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {SetWarrantyPayload} [setWarrantyPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost: async (deviceId: number, deviceFirmId: number, setWarrantyPayload?: SetWarrantyPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost', 'deviceId', deviceId)
            // verify required parameter 'deviceFirmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost', 'deviceFirmId', deviceFirmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{deviceFirmId}/general/set-warranty`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"deviceFirmId"}}`, encodeURIComponent(String(deviceFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setWarrantyPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить заменённые СЕ
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {DeviceAddReplacedCEPayload} [deviceAddReplacedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost: async (deviceId: number, deviceFirmId: number, deviceAddReplacedCEPayload?: DeviceAddReplacedCEPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost', 'deviceId', deviceId)
            // verify required parameter 'deviceFirmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost', 'deviceFirmId', deviceFirmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{deviceFirmId}/replaced-ce`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"deviceFirmId"}}`, encodeURIComponent(String(deviceFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAddReplacedCEPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить приборы для доукомплектации
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceDeleteAddedCEPayload} [deviceDeleteAddedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete: async (deviceId: number, firmId: number, deviceDeleteAddedCEPayload?: DeviceDeleteAddedCEPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/added-ce`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceDeleteAddedCEPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить прибор для доукомплектации
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {string} se 
         * @param {DeviceUpdateAddedCEPayload} [deviceUpdateAddedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut: async (deviceId: number, firmId: number, se: string, deviceUpdateAddedCEPayload?: DeviceUpdateAddedCEPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut', 'firmId', firmId)
            // verify required parameter 'se' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut', 'se', se)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/added-ce/{se}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)))
                .replace(`{${"se"}}`, encodeURIComponent(String(se)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceUpdateAddedCEPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Заменить серийный номер батареи
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} batteryId 
         * @param {ChangeBatterySerialPayload} [changeBatterySerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost: async (deviceId: number, firmId: number, batteryId: number, changeBatterySerialPayload?: ChangeBatterySerialPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost', 'firmId', firmId)
            // verify required parameter 'batteryId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost', 'batteryId', batteryId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/batteries/{batteryId}/change-serial`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)))
                .replace(`{${"batteryId"}}`, encodeURIComponent(String(batteryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeBatterySerialPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Исправить серийный номер батареи
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} batteryId 
         * @param {CorrectBatterySerialPayload} [correctBatterySerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost: async (deviceId: number, firmId: number, batteryId: number, correctBatterySerialPayload?: CorrectBatterySerialPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost', 'firmId', firmId)
            // verify required parameter 'batteryId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost', 'batteryId', batteryId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/batteries/{batteryId}/correct-serial`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)))
                .replace(`{${"batteryId"}}`, encodeURIComponent(String(batteryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correctBatterySerialPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить батарею
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} batteryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete: async (deviceId: number, firmId: number, batteryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete', 'firmId', firmId)
            // verify required parameter 'batteryId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete', 'batteryId', batteryId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/batteries/{batteryId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)))
                .replace(`{${"batteryId"}}`, encodeURIComponent(String(batteryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить батарею
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} batteryId 
         * @param {UpdateBatteryPayload} [updateBatteryPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut: async (deviceId: number, firmId: number, batteryId: number, updateBatteryPayload?: UpdateBatteryPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut', 'firmId', firmId)
            // verify required parameter 'batteryId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut', 'batteryId', batteryId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/batteries/{batteryId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)))
                .replace(`{${"batteryId"}}`, encodeURIComponent(String(batteryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBatteryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить батарею
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {AddBatteryPayload} [addBatteryPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost: async (deviceId: number, firmId: number, addBatteryPayload?: AddBatteryPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/batteries`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addBatteryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить дефекты
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceDeleteDefectsPayload} [deviceDeleteDefectsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete: async (deviceId: number, firmId: number, deviceDeleteDefectsPayload?: DeviceDeleteDefectsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/defects`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceDeleteDefectsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить дефекты
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceAddDefectsPayload} [deviceAddDefectsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost: async (deviceId: number, firmId: number, deviceAddDefectsPayload?: DeviceAddDefectsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/defects`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAddDefectsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить дефект
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [defectId] 
         * @param {DeviceUpdateDefectPayload} [deviceUpdateDefectPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut: async (deviceId: number, firmId: number, defectId?: number, deviceUpdateDefectPayload?: DeviceUpdateDefectPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/defects`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (defectId !== undefined) {
                localVarQueryParameter['defectId'] = defectId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceUpdateDefectPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Информация о приборе
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/details`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отмена выставления счёта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/features/cancel-invoice`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка возможности возврата без ремонта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/features/check-return-without-repair`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Назначить контроль
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceSetPaymentConsentPayload} [deviceSetPaymentConsentPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost: async (deviceId: number, firmId: number, deviceSetPaymentConsentPayload?: DeviceSetPaymentConsentPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/features/set-payment-consent`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceSetPaymentConsentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Установить стоимость ремонта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceSetRepairCostPayload} [deviceSetRepairCostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost: async (deviceId: number, firmId: number, deviceSetRepairCostPayload?: DeviceSetRepairCostPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/features/set-repair-cost`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceSetRepairCostPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Назначить контроль
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {AssignControlPayload} [assignControlPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost: async (deviceId: number, firmId: number, assignControlPayload?: AssignControlPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/assign-control`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignControlPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary События аудита по прибору
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/audit`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Заменить прибор
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {ChangeSePayload} [changeSePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost: async (deviceId: number, firmId: number, changeSePayload?: ChangeSePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/change-se`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeSePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Заменить серийный номер прибора
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {ChangeSerialPayload} [changeSerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost: async (deviceId: number, firmId: number, changeSerialPayload?: ChangeSerialPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/change-serial`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeSerialPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка возможности замены прибора
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/check-change-se`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка возможности замены серийного номера
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/check-change-serial`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пройти контроль
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {CompleteControlPayload} [completeControlPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost: async (deviceId: number, firmId: number, completeControlPayload?: CompleteControlPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/complete-control`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeControlPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Исправить серийный номер прибора
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {CorrectSerialPayload} [correctSerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost: async (deviceId: number, firmId: number, correctSerialPayload?: CorrectSerialPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/correct-serial`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correctSerialPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary История проводок по прибору
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/history`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запаковать прибор
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/pack`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Поиск существующего прибора при исправлении серийного номера 
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {string} [serial] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet: async (deviceId: number, firmId: number, serial?: string, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/search-correct-serial`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (serial !== undefined) {
                localVarQueryParameter['Serial'] = serial;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получения серийного номера в формате EAN13
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/serial-ean13`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Установить предварительная стоимость ремонта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {SetPreliminaryCostPayload} [setPreliminaryCostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost: async (deviceId: number, firmId: number, setPreliminaryCostPayload?: SetPreliminaryCostPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/set-preliminary-repair-cost`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPreliminaryCostPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Установить подтверждение повторного ремонта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {SetRepeatedRepairConfirmedPayload} [setRepeatedRepairConfirmedPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost: async (deviceId: number, firmId: number, setRepeatedRepairConfirmedPayload?: SetRepeatedRepairConfirmedPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/general/set-repeated-repair-confirmed`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setRepeatedRepairConfirmedPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/lock`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтвердить упаковку (кнопка \'Упаковано\') 
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/packaging/confirm`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение информации по упаковке
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/packaging`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить информацию по упаковке
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {PackagingInformationUpdatePayload} [packagingInformationUpdatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut: async (deviceId: number, firmId: number, packagingInformationUpdatePayload?: PackagingInformationUpdatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/packaging`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packagingInformationUpdatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить прибор
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {UpdateDevicePayload} [updateDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdPut: async (deviceId: number, firmId: number, updateDevicePayload?: UpdateDevicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdPut', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdPut', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDevicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить счёт на ремонт прибора в Excel
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost: async (deviceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/repair-cost-calculation/export-excel`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить счёт на ремонт прибора в PDF
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost: async (deviceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/repair-cost-calculation/export-pdf`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение счёт на ремонт прибора (РСР)
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/repair-cost-calculation`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cчёт на ремонт прибора - загрузка данных
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/repair-cost-calculation/load-data`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить счёт на ремонт прибора
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {RepairCostCalculationUpdatePayload} [repairCostCalculationUpdatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut: async (deviceId: number, firmId: number, repairCostCalculationUpdatePayload?: RepairCostCalculationUpdatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/repair-cost-calculation`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairCostCalculationUpdatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить заменённые СЕ
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceDeleteReplacedCEPayload} [deviceDeleteReplacedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete: async (deviceId: number, firmId: number, deviceDeleteReplacedCEPayload?: DeviceDeleteReplacedCEPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/replaced-ce`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceDeleteReplacedCEPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить приборы
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {string} se 
         * @param {DeviceUpdateReplacedCEPayload} [deviceUpdateReplacedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut: async (deviceId: number, firmId: number, se: string, deviceUpdateReplacedCEPayload?: DeviceUpdateReplacedCEPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut', 'firmId', firmId)
            // verify required parameter 'se' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut', 'se', se)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/replaced-ce/{se}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)))
                .replace(`{${"se"}}`, encodeURIComponent(String(se)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceUpdateReplacedCEPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить техническое заключение
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/technical-conclusion`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить техническое заключение в Excel
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost: async (deviceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/technical-conclusion/export-excel`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить техническое заключение в PDF
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost: async (deviceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/technical-conclusion/export-pdf`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение технического заключения (ТЗ)
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/technical-conclusion`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка дефектов для технического заключения
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {TechnicalConclusionLoadDefectsPayload} [technicalConclusionLoadDefectsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost: async (deviceId: number, firmId: number, technicalConclusionLoadDefectsPayload?: TechnicalConclusionLoadDefectsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/technical-conclusion/load-defects`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(technicalConclusionLoadDefectsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить техническое заключение
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {TechnicalConclusionUpdatePayload} [technicalConclusionUpdatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut: async (deviceId: number, firmId: number, technicalConclusionUpdatePayload?: TechnicalConclusionUpdatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/technical-conclusion`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(technicalConclusionUpdatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить техническое заключение upgradable device в Excel
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost: async (deviceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/technical-conclusion-upgradable/export-excel`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить техническое заключение upgradable device в PDF
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost: async (deviceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/technical-conclusion-upgradable/export-pdf`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost: async (deviceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/unlock`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить типовые работы
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceDeleteWorksPayload} [deviceDeleteWorksPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete: async (deviceId: number, firmId: number, deviceDeleteWorksPayload?: DeviceDeleteWorksPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/works`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceDeleteWorksPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить типовые работы
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceAddWorksPayload} [deviceAddWorksPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost: async (deviceId: number, firmId: number, deviceAddWorksPayload?: DeviceAddWorksPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost', 'deviceId', deviceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost', 'firmId', firmId)
            const localVarPath = `/api/op-devices/device/{deviceId}/firm/{firmId}/works`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAddWorksPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Информация о приборе - выгрузка в Pdf
         * @param {number} [deviceId] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {PaperSizeType} [paperSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesExportPdfPost: async (deviceId?: number, firmId?: number, lcid?: number, paperSize?: PaperSizeType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['firmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }

            if (paperSize !== undefined) {
                localVarQueryParameter['paperSize'] = paperSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список комплектации прибора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesFeaturesEquipmentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/features/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка серийного номера на уникальность
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesGeneralCheckSerialExistGet: async (serial?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/general/check-serial-exist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (serial !== undefined) {
                localVarQueryParameter['Serial'] = serial;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить данные прибора из производства по серийному номеру
         * @param {string} [serial] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesGeneralProductionDeviceBySerialGet: async (serial?: string, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/general/production-device-by-serial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (serial !== undefined) {
                localVarQueryParameter['Serial'] = serial;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список приборов на замену
         * @param {string} [filterSe] 
         * @param {string} [filterSku] 
         * @param {string} [filterNameRu] 
         * @param {string} [filterNameEn] 
         * @param {boolean} [filterOnlyWithSku] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesGeneralReplacementDevicesGet: async (filterSe?: string, filterSku?: string, filterNameRu?: string, filterNameEn?: string, filterOnlyWithSku?: boolean, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/general/replacement-devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterSe !== undefined) {
                localVarQueryParameter['Filter.Se'] = filterSe;
            }

            if (filterSku !== undefined) {
                localVarQueryParameter['Filter.Sku'] = filterSku;
            }

            if (filterNameRu !== undefined) {
                localVarQueryParameter['Filter.NameRu'] = filterNameRu;
            }

            if (filterNameEn !== undefined) {
                localVarQueryParameter['Filter.NameEn'] = filterNameEn;
            }

            if (filterOnlyWithSku !== undefined) {
                localVarQueryParameter['Filter.OnlyWithSku'] = filterOnlyWithSku;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список заблокированных приборов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesLockedListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/locked-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список CE на замену
         * @param {string} [filterSe] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesReplacedCeListGet: async (filterSe?: string, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/replaced-ce/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterSe !== undefined) {
                localVarQueryParameter['Filter.Se'] = filterSe;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Состав прибора на замену
         * @param {string} se 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesReplacedCeSeDetailsGet: async (se: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'se' is not null or undefined
            assertParamExists('apiOpDevicesReplacedCeSeDetailsGet', 'se', se)
            const localVarPath = `/api/op-devices/replaced-ce/{se}/details`
                .replace(`{${"se"}}`, encodeURIComponent(String(se)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список типовых работ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesWorksListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-devices/works/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpDevicesApi - functional programming interface
 * @export
 */
export const OpDevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpDevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список CE для доукомплектации
         * @param {string} [filterSe] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesAddedCeListGet(filterSe?: string, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddedCEPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesAddedCeListGet(filterSe, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Состав прибора для доукомплектации
         * @param {string} se 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesAddedCeSeDetailsGet(se: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddedCEDetailsBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesAddedCeSeDetailsGet(se, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение типов батарей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesBatteriesTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceBatteryTypeBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesBatteriesTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить приборы для доукомплектации
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {DeviceAddAddedCEPayload} [deviceAddAddedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost(deviceId: number, deviceFirmId: number, deviceAddAddedCEPayload?: DeviceAddAddedCEPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost(deviceId, deviceFirmId, deviceAddAddedCEPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Возврат без ремонта
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {DeviceReturnWithoutRepairPayload} [deviceReturnWithoutRepairPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost(deviceId: number, deviceFirmId: number, deviceReturnWithoutRepairPayload?: DeviceReturnWithoutRepairPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost(deviceId, deviceFirmId, deviceReturnWithoutRepairPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Установить гарантию
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {SetWarrantyPayload} [setWarrantyPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost(deviceId: number, deviceFirmId: number, setWarrantyPayload?: SetWarrantyPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost(deviceId, deviceFirmId, setWarrantyPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить заменённые СЕ
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {DeviceAddReplacedCEPayload} [deviceAddReplacedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost(deviceId: number, deviceFirmId: number, deviceAddReplacedCEPayload?: DeviceAddReplacedCEPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost(deviceId, deviceFirmId, deviceAddReplacedCEPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить приборы для доукомплектации
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceDeleteAddedCEPayload} [deviceDeleteAddedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete(deviceId: number, firmId: number, deviceDeleteAddedCEPayload?: DeviceDeleteAddedCEPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete(deviceId, firmId, deviceDeleteAddedCEPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить прибор для доукомплектации
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {string} se 
         * @param {DeviceUpdateAddedCEPayload} [deviceUpdateAddedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut(deviceId: number, firmId: number, se: string, deviceUpdateAddedCEPayload?: DeviceUpdateAddedCEPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut(deviceId, firmId, se, deviceUpdateAddedCEPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Заменить серийный номер батареи
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} batteryId 
         * @param {ChangeBatterySerialPayload} [changeBatterySerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost(deviceId: number, firmId: number, batteryId: number, changeBatterySerialPayload?: ChangeBatterySerialPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost(deviceId, firmId, batteryId, changeBatterySerialPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Исправить серийный номер батареи
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} batteryId 
         * @param {CorrectBatterySerialPayload} [correctBatterySerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost(deviceId: number, firmId: number, batteryId: number, correctBatterySerialPayload?: CorrectBatterySerialPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost(deviceId, firmId, batteryId, correctBatterySerialPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить батарею
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} batteryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete(deviceId: number, firmId: number, batteryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete(deviceId, firmId, batteryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить батарею
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} batteryId 
         * @param {UpdateBatteryPayload} [updateBatteryPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut(deviceId: number, firmId: number, batteryId: number, updateBatteryPayload?: UpdateBatteryPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut(deviceId, firmId, batteryId, updateBatteryPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить батарею
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {AddBatteryPayload} [addBatteryPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost(deviceId: number, firmId: number, addBatteryPayload?: AddBatteryPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost(deviceId, firmId, addBatteryPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить дефекты
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceDeleteDefectsPayload} [deviceDeleteDefectsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete(deviceId: number, firmId: number, deviceDeleteDefectsPayload?: DeviceDeleteDefectsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete(deviceId, firmId, deviceDeleteDefectsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить дефекты
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceAddDefectsPayload} [deviceAddDefectsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost(deviceId: number, firmId: number, deviceAddDefectsPayload?: DeviceAddDefectsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost(deviceId, firmId, deviceAddDefectsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить дефект
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [defectId] 
         * @param {DeviceUpdateDefectPayload} [deviceUpdateDefectPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut(deviceId: number, firmId: number, defectId?: number, deviceUpdateDefectPayload?: DeviceUpdateDefectPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut(deviceId, firmId, defectId, deviceUpdateDefectPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Информация о приборе
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отмена выставления счёта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Проверка возможности возврата без ремонта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnWithoutRepairCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Назначить контроль
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceSetPaymentConsentPayload} [deviceSetPaymentConsentPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost(deviceId: number, firmId: number, deviceSetPaymentConsentPayload?: DeviceSetPaymentConsentPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost(deviceId, firmId, deviceSetPaymentConsentPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Установить стоимость ремонта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceSetRepairCostPayload} [deviceSetRepairCostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost(deviceId: number, firmId: number, deviceSetRepairCostPayload?: DeviceSetRepairCostPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceSetRepairCostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost(deviceId, firmId, deviceSetRepairCostPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Назначить контроль
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {AssignControlPayload} [assignControlPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost(deviceId: number, firmId: number, assignControlPayload?: AssignControlPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost(deviceId, firmId, assignControlPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary События аудита по прибору
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceAuditBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Заменить прибор
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {ChangeSePayload} [changeSePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost(deviceId: number, firmId: number, changeSePayload?: ChangeSePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost(deviceId, firmId, changeSePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Заменить серийный номер прибора
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {ChangeSerialPayload} [changeSerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost(deviceId: number, firmId: number, changeSerialPayload?: ChangeSerialPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost(deviceId, firmId, changeSerialPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Проверка возможности замены прибора
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeSeCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Проверка возможности замены серийного номера
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeSerialCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Пройти контроль
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {CompleteControlPayload} [completeControlPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost(deviceId: number, firmId: number, completeControlPayload?: CompleteControlPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost(deviceId, firmId, completeControlPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Исправить серийный номер прибора
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {CorrectSerialPayload} [correctSerialPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost(deviceId: number, firmId: number, correctSerialPayload?: CorrectSerialPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost(deviceId, firmId, correctSerialPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary История проводок по прибору
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceHistoryBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запаковать прибор
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Поиск существующего прибора при исправлении серийного номера 
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {string} [serial] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet(deviceId: number, firmId: number, serial?: string, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorrectSerialSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet(deviceId, firmId, serial, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получения серийного номера в формате EAN13
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceSerialNumberEan13Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Установить предварительная стоимость ремонта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {SetPreliminaryCostPayload} [setPreliminaryCostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost(deviceId: number, firmId: number, setPreliminaryCostPayload?: SetPreliminaryCostPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost(deviceId, firmId, setPreliminaryCostPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Установить подтверждение повторного ремонта
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {SetRepeatedRepairConfirmedPayload} [setRepeatedRepairConfirmedPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost(deviceId: number, firmId: number, setRepeatedRepairConfirmedPayload?: SetRepeatedRepairConfirmedPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost(deviceId, firmId, setRepeatedRepairConfirmedPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтвердить упаковку (кнопка \'Упаковано\') 
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение информации по упаковке
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicePackagingInformationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить информацию по упаковке
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {PackagingInformationUpdatePayload} [packagingInformationUpdatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut(deviceId: number, firmId: number, packagingInformationUpdatePayload?: PackagingInformationUpdatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut(deviceId, firmId, packagingInformationUpdatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить прибор
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {UpdateDevicePayload} [updateDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdPut(deviceId: number, firmId: number, updateDevicePayload?: UpdateDevicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdPut(deviceId, firmId, updateDevicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить счёт на ремонт прибора в Excel
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost(deviceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost(deviceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить счёт на ремонт прибора в PDF
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost(deviceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost(deviceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение счёт на ремонт прибора (РСР)
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceRepairCostCalculationBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cчёт на ремонт прибора - загрузка данных
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить счёт на ремонт прибора
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {RepairCostCalculationUpdatePayload} [repairCostCalculationUpdatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut(deviceId: number, firmId: number, repairCostCalculationUpdatePayload?: RepairCostCalculationUpdatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut(deviceId, firmId, repairCostCalculationUpdatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить заменённые СЕ
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceDeleteReplacedCEPayload} [deviceDeleteReplacedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete(deviceId: number, firmId: number, deviceDeleteReplacedCEPayload?: DeviceDeleteReplacedCEPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete(deviceId, firmId, deviceDeleteReplacedCEPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить приборы
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {string} se 
         * @param {DeviceUpdateReplacedCEPayload} [deviceUpdateReplacedCEPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut(deviceId: number, firmId: number, se: string, deviceUpdateReplacedCEPayload?: DeviceUpdateReplacedCEPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut(deviceId, firmId, se, deviceUpdateReplacedCEPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить техническое заключение
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить техническое заключение в Excel
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost(deviceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost(deviceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить техническое заключение в PDF
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost(deviceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost(deviceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение технического заключения (ТЗ)
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceTechnicalConclusionBrowseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка дефектов для технического заключения
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {TechnicalConclusionLoadDefectsPayload} [technicalConclusionLoadDefectsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost(deviceId: number, firmId: number, technicalConclusionLoadDefectsPayload?: TechnicalConclusionLoadDefectsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost(deviceId, firmId, technicalConclusionLoadDefectsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить техническое заключение
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {TechnicalConclusionUpdatePayload} [technicalConclusionUpdatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut(deviceId: number, firmId: number, technicalConclusionUpdatePayload?: TechnicalConclusionUpdatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut(deviceId, firmId, technicalConclusionUpdatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить техническое заключение upgradable device в Excel
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost(deviceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost(deviceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить техническое заключение upgradable device в PDF
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost(deviceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost(deviceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost(deviceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost(deviceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить типовые работы
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceDeleteWorksPayload} [deviceDeleteWorksPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete(deviceId: number, firmId: number, deviceDeleteWorksPayload?: DeviceDeleteWorksPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete(deviceId, firmId, deviceDeleteWorksPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить типовые работы
         * @param {number} deviceId 
         * @param {number} firmId 
         * @param {DeviceAddWorksPayload} [deviceAddWorksPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost(deviceId: number, firmId: number, deviceAddWorksPayload?: DeviceAddWorksPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost(deviceId, firmId, deviceAddWorksPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Информация о приборе - выгрузка в Pdf
         * @param {number} [deviceId] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {PaperSizeType} [paperSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesExportPdfPost(deviceId?: number, firmId?: number, lcid?: number, paperSize?: PaperSizeType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesExportPdfPost(deviceId, firmId, lcid, paperSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список комплектации прибора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesFeaturesEquipmentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceEquipmentBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesFeaturesEquipmentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Проверка серийного номера на уникальность
         * @param {string} [serial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesGeneralCheckSerialExistGet(serial?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SerialExistCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesGeneralCheckSerialExistGet(serial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить данные прибора из производства по серийному номеру
         * @param {string} [serial] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesGeneralProductionDeviceBySerialGet(serial?: string, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductionDeviceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesGeneralProductionDeviceBySerialGet(serial, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список приборов на замену
         * @param {string} [filterSe] 
         * @param {string} [filterSku] 
         * @param {string} [filterNameRu] 
         * @param {string} [filterNameEn] 
         * @param {boolean} [filterOnlyWithSku] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesGeneralReplacementDevicesGet(filterSe?: string, filterSku?: string, filterNameRu?: string, filterNameEn?: string, filterOnlyWithSku?: boolean, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplacementDevicesPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesGeneralReplacementDevicesGet(filterSe, filterSku, filterNameRu, filterNameEn, filterOnlyWithSku, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список заблокированных приборов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesLockedListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LockedDeviceBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesLockedListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список CE на замену
         * @param {string} [filterSe] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesReplacedCeListGet(filterSe?: string, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplacementCEPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesReplacedCeListGet(filterSe, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Состав прибора на замену
         * @param {string} se 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesReplacedCeSeDetailsGet(se: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReplacementCEDetailsBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesReplacedCeSeDetailsGet(se, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список типовых работ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDevicesWorksListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceWorkBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDevicesWorksListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpDevicesApi - factory interface
 * @export
 */
export const OpDevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpDevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Список CE для доукомплектации
         * @param {OpDevicesApiApiOpDevicesAddedCeListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesAddedCeListGet(requestParameters: OpDevicesApiApiOpDevicesAddedCeListGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AddedCEPagingResult> {
            return localVarFp.apiOpDevicesAddedCeListGet(requestParameters.filterSe, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Состав прибора для доукомплектации
         * @param {OpDevicesApiApiOpDevicesAddedCeSeDetailsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesAddedCeSeDetailsGet(requestParameters: OpDevicesApiApiOpDevicesAddedCeSeDetailsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AddedCEDetailsBrowseDto>> {
            return localVarFp.apiOpDevicesAddedCeSeDetailsGet(requestParameters.se, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение типов батарей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesBatteriesTypesGet(options?: AxiosRequestConfig): AxiosPromise<Array<DeviceBatteryTypeBrowseDto>> {
            return localVarFp.apiOpDevicesBatteriesTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить приборы для доукомплектации
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost(requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.deviceAddAddedCEPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возврат без ремонта
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost(requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.deviceReturnWithoutRepairPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Установить гарантию
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost(requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.setWarrantyPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить заменённые СЕ
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost(requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.deviceAddReplacedCEPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить приборы для доукомплектации
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceDeleteAddedCEPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить прибор для доукомплектации
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut(requestParameters.deviceId, requestParameters.firmId, requestParameters.se, requestParameters.deviceUpdateAddedCEPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Заменить серийный номер батареи
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.batteryId, requestParameters.changeBatterySerialPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Исправить серийный номер батареи
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.batteryId, requestParameters.correctBatterySerialPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить батарею
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.batteryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить батарею
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.batteryId, requestParameters.updateBatteryPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить батарею
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.addBatteryPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить дефекты
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceDeleteDefectsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить дефекты
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceAddDefectsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить дефект
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.defectId, requestParameters.deviceUpdateDefectPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Информация о приборе
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGetRequest, options?: AxiosRequestConfig): AxiosPromise<DeviceBrowseDto> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отмена выставления счёта
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверка возможности возврата без ремонта
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPostRequest, options?: AxiosRequestConfig): AxiosPromise<ReturnWithoutRepairCheckResponse> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Назначить контроль
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceSetPaymentConsentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Установить стоимость ремонта
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPostRequest, options?: AxiosRequestConfig): AxiosPromise<DeviceSetRepairCostResponse> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceSetRepairCostPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Назначить контроль
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.assignControlPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary События аудита по прибору
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DeviceAuditBrowseDto>> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Заменить прибор
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost(requestParameters.deviceId, requestParameters.firmId, requestParameters.changeSePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Заменить серийный номер прибора
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.changeSerialPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверка возможности замены прибора
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGetRequest, options?: AxiosRequestConfig): AxiosPromise<ChangeSeCheckResponse> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверка возможности замены серийного номера
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGetRequest, options?: AxiosRequestConfig): AxiosPromise<ChangeSerialCheckResponse> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Пройти контроль
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.completeControlPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Исправить серийный номер прибора
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.correctSerialPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary История проводок по прибору
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DeviceHistoryBrowseDto>> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запаковать прибор
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Поиск существующего прибора при исправлении серийного номера 
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGetRequest, options?: AxiosRequestConfig): AxiosPromise<CorrectSerialSearchResponse> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet(requestParameters.deviceId, requestParameters.firmId, requestParameters.serial, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получения серийного номера в формате EAN13
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13GetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13GetRequest, options?: AxiosRequestConfig): AxiosPromise<DeviceSerialNumberEan13Dto> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Установить предварительная стоимость ремонта
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.setPreliminaryCostPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Установить подтверждение повторного ремонта
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.setRepeatedRepairConfirmedPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdLockPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdLockPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтвердить упаковку (кнопка \'Упаковано\') 
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение информации по упаковке
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGetRequest, options?: AxiosRequestConfig): AxiosPromise<DevicePackagingInformationDto> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить информацию по упаковке
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.packagingInformationUpdatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить прибор
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.updateDevicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить счёт на ремонт прибора в Excel
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить счёт на ремонт прибора в PDF
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение счёт на ремонт прибора (РСР)
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGetRequest, options?: AxiosRequestConfig): AxiosPromise<DeviceRepairCostCalculationBrowseDto> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cчёт на ремонт прибора - загрузка данных
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить счёт на ремонт прибора
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.repairCostCalculationUpdatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить заменённые СЕ
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceDeleteReplacedCEPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить приборы
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut(requestParameters.deviceId, requestParameters.firmId, requestParameters.se, requestParameters.deviceUpdateReplacedCEPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить техническое заключение
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить техническое заключение в Excel
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить техническое заключение в PDF
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение технического заключения (ТЗ)
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGetRequest, options?: AxiosRequestConfig): AxiosPromise<DeviceTechnicalConclusionBrowseResponse> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка дефектов для технического заключения
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.technicalConclusionLoadDefectsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить техническое заключение
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.technicalConclusionUpdatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить техническое заключение upgradable device в Excel
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить техническое заключение upgradable device в PDF
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить типовые работы
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceDeleteWorksPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить типовые работы
         * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceAddWorksPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Информация о приборе - выгрузка в Pdf
         * @param {OpDevicesApiApiOpDevicesExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesExportPdfPost(requestParameters: OpDevicesApiApiOpDevicesExportPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDevicesExportPdfPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, requestParameters.paperSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список комплектации прибора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesFeaturesEquipmentGet(options?: AxiosRequestConfig): AxiosPromise<Array<DeviceEquipmentBrowseDto>> {
            return localVarFp.apiOpDevicesFeaturesEquipmentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверка серийного номера на уникальность
         * @param {OpDevicesApiApiOpDevicesGeneralCheckSerialExistGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesGeneralCheckSerialExistGet(requestParameters: OpDevicesApiApiOpDevicesGeneralCheckSerialExistGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SerialExistCheckResponse> {
            return localVarFp.apiOpDevicesGeneralCheckSerialExistGet(requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить данные прибора из производства по серийному номеру
         * @param {OpDevicesApiApiOpDevicesGeneralProductionDeviceBySerialGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesGeneralProductionDeviceBySerialGet(requestParameters: OpDevicesApiApiOpDevicesGeneralProductionDeviceBySerialGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetProductionDeviceDto> {
            return localVarFp.apiOpDevicesGeneralProductionDeviceBySerialGet(requestParameters.serial, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список приборов на замену
         * @param {OpDevicesApiApiOpDevicesGeneralReplacementDevicesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesGeneralReplacementDevicesGet(requestParameters: OpDevicesApiApiOpDevicesGeneralReplacementDevicesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ReplacementDevicesPagingResult> {
            return localVarFp.apiOpDevicesGeneralReplacementDevicesGet(requestParameters.filterSe, requestParameters.filterSku, requestParameters.filterNameRu, requestParameters.filterNameEn, requestParameters.filterOnlyWithSku, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список заблокированных приборов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesLockedListGet(options?: AxiosRequestConfig): AxiosPromise<Array<LockedDeviceBrowseDto>> {
            return localVarFp.apiOpDevicesLockedListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список CE на замену
         * @param {OpDevicesApiApiOpDevicesReplacedCeListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesReplacedCeListGet(requestParameters: OpDevicesApiApiOpDevicesReplacedCeListGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ReplacementCEPagingResult> {
            return localVarFp.apiOpDevicesReplacedCeListGet(requestParameters.filterSe, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Состав прибора на замену
         * @param {OpDevicesApiApiOpDevicesReplacedCeSeDetailsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesReplacedCeSeDetailsGet(requestParameters: OpDevicesApiApiOpDevicesReplacedCeSeDetailsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ReplacementCEDetailsBrowseDto>> {
            return localVarFp.apiOpDevicesReplacedCeSeDetailsGet(requestParameters.se, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список типовых работ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDevicesWorksListGet(options?: AxiosRequestConfig): AxiosPromise<Array<DeviceWorkBrowseDto>> {
            return localVarFp.apiOpDevicesWorksListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpDevicesAddedCeListGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesAddedCeListGetRequest
 */
export interface OpDevicesApiApiOpDevicesAddedCeListGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesAddedCeListGet
     */
    readonly filterSe?: string

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesAddedCeListGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesAddedCeListGet
     */
    readonly pagingPageSize?: number
}

/**
 * Request parameters for apiOpDevicesAddedCeSeDetailsGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesAddedCeSeDetailsGetRequest
 */
export interface OpDevicesApiApiOpDevicesAddedCeSeDetailsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesAddedCeSeDetailsGet
     */
    readonly se: string
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost
     */
    readonly deviceFirmId: number

    /**
     * 
     * @type {DeviceAddAddedCEPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost
     */
    readonly deviceAddAddedCEPayload?: DeviceAddAddedCEPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost
     */
    readonly deviceFirmId: number

    /**
     * 
     * @type {DeviceReturnWithoutRepairPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost
     */
    readonly deviceReturnWithoutRepairPayload?: DeviceReturnWithoutRepairPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost
     */
    readonly deviceFirmId: number

    /**
     * 
     * @type {SetWarrantyPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost
     */
    readonly setWarrantyPayload?: SetWarrantyPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost
     */
    readonly deviceFirmId: number

    /**
     * 
     * @type {DeviceAddReplacedCEPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost
     */
    readonly deviceAddReplacedCEPayload?: DeviceAddReplacedCEPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDeleteRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete
     */
    readonly firmId: number

    /**
     * 
     * @type {DeviceDeleteAddedCEPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete
     */
    readonly deviceDeleteAddedCEPayload?: DeviceDeleteAddedCEPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePutRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut
     */
    readonly firmId: number

    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut
     */
    readonly se: string

    /**
     * 
     * @type {DeviceUpdateAddedCEPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut
     */
    readonly deviceUpdateAddedCEPayload?: DeviceUpdateAddedCEPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost
     */
    readonly batteryId: number

    /**
     * 
     * @type {ChangeBatterySerialPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost
     */
    readonly changeBatterySerialPayload?: ChangeBatterySerialPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost
     */
    readonly batteryId: number

    /**
     * 
     * @type {CorrectBatterySerialPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost
     */
    readonly correctBatterySerialPayload?: CorrectBatterySerialPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDeleteRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete
     */
    readonly batteryId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPutRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut
     */
    readonly batteryId: number

    /**
     * 
     * @type {UpdateBatteryPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut
     */
    readonly updateBatteryPayload?: UpdateBatteryPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost
     */
    readonly firmId: number

    /**
     * 
     * @type {AddBatteryPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost
     */
    readonly addBatteryPayload?: AddBatteryPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDeleteRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete
     */
    readonly firmId: number

    /**
     * 
     * @type {DeviceDeleteDefectsPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete
     */
    readonly deviceDeleteDefectsPayload?: DeviceDeleteDefectsPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost
     */
    readonly firmId: number

    /**
     * 
     * @type {DeviceAddDefectsPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost
     */
    readonly deviceAddDefectsPayload?: DeviceAddDefectsPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPutRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut
     */
    readonly defectId?: number

    /**
     * 
     * @type {DeviceUpdateDefectPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut
     */
    readonly deviceUpdateDefectPayload?: DeviceUpdateDefectPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost
     */
    readonly firmId: number

    /**
     * 
     * @type {DeviceSetPaymentConsentPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost
     */
    readonly deviceSetPaymentConsentPayload?: DeviceSetPaymentConsentPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost
     */
    readonly firmId: number

    /**
     * 
     * @type {DeviceSetRepairCostPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost
     */
    readonly deviceSetRepairCostPayload?: DeviceSetRepairCostPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost
     */
    readonly firmId: number

    /**
     * 
     * @type {AssignControlPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost
     */
    readonly assignControlPayload?: AssignControlPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost
     */
    readonly firmId: number

    /**
     * 
     * @type {ChangeSePayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost
     */
    readonly changeSePayload?: ChangeSePayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost
     */
    readonly firmId: number

    /**
     * 
     * @type {ChangeSerialPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost
     */
    readonly changeSerialPayload?: ChangeSerialPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost
     */
    readonly firmId: number

    /**
     * 
     * @type {CompleteControlPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost
     */
    readonly completeControlPayload?: CompleteControlPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost
     */
    readonly firmId: number

    /**
     * 
     * @type {CorrectSerialPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost
     */
    readonly correctSerialPayload?: CorrectSerialPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet
     */
    readonly firmId: number

    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet
     */
    readonly serial?: string

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13GetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13GetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost
     */
    readonly firmId: number

    /**
     * 
     * @type {SetPreliminaryCostPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost
     */
    readonly setPreliminaryCostPayload?: SetPreliminaryCostPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost
     */
    readonly firmId: number

    /**
     * 
     * @type {SetRepeatedRepairConfirmedPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost
     */
    readonly setRepeatedRepairConfirmedPayload?: SetRepeatedRepairConfirmedPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdLockPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdLockPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdLockPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdLockPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPutRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut
     */
    readonly firmId: number

    /**
     * 
     * @type {PackagingInformationUpdatePayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut
     */
    readonly packagingInformationUpdatePayload?: PackagingInformationUpdatePayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdPut operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPutRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPut
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPut
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateDevicePayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPut
     */
    readonly updateDevicePayload?: UpdateDevicePayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPutRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut
     */
    readonly firmId: number

    /**
     * 
     * @type {RepairCostCalculationUpdatePayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut
     */
    readonly repairCostCalculationUpdatePayload?: RepairCostCalculationUpdatePayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDeleteRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete
     */
    readonly firmId: number

    /**
     * 
     * @type {DeviceDeleteReplacedCEPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete
     */
    readonly deviceDeleteReplacedCEPayload?: DeviceDeleteReplacedCEPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePutRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut
     */
    readonly firmId: number

    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut
     */
    readonly se: string

    /**
     * 
     * @type {DeviceUpdateReplacedCEPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut
     */
    readonly deviceUpdateReplacedCEPayload?: DeviceUpdateReplacedCEPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDeleteRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGetRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost
     */
    readonly firmId: number

    /**
     * 
     * @type {TechnicalConclusionLoadDefectsPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost
     */
    readonly technicalConclusionLoadDefectsPayload?: TechnicalConclusionLoadDefectsPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPutRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut
     */
    readonly firmId: number

    /**
     * 
     * @type {TechnicalConclusionUpdatePayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut
     */
    readonly technicalConclusionUpdatePayload?: TechnicalConclusionUpdatePayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksDeleteRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete
     */
    readonly firmId: number

    /**
     * 
     * @type {DeviceDeleteWorksPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete
     */
    readonly deviceDeleteWorksPayload?: DeviceDeleteWorksPayload
}

/**
 * Request parameters for apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksPostRequest
 */
export interface OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost
     */
    readonly firmId: number

    /**
     * 
     * @type {DeviceAddWorksPayload}
     * @memberof OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost
     */
    readonly deviceAddWorksPayload?: DeviceAddWorksPayload
}

/**
 * Request parameters for apiOpDevicesExportPdfPost operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesExportPdfPostRequest
 */
export interface OpDevicesApiApiOpDevicesExportPdfPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesExportPdfPost
     */
    readonly deviceId?: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesExportPdfPost
     */
    readonly firmId?: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesExportPdfPost
     */
    readonly lcid?: number

    /**
     * 
     * @type {PaperSizeType}
     * @memberof OpDevicesApiApiOpDevicesExportPdfPost
     */
    readonly paperSize?: PaperSizeType
}

/**
 * Request parameters for apiOpDevicesGeneralCheckSerialExistGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesGeneralCheckSerialExistGetRequest
 */
export interface OpDevicesApiApiOpDevicesGeneralCheckSerialExistGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesGeneralCheckSerialExistGet
     */
    readonly serial?: string
}

/**
 * Request parameters for apiOpDevicesGeneralProductionDeviceBySerialGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesGeneralProductionDeviceBySerialGetRequest
 */
export interface OpDevicesApiApiOpDevicesGeneralProductionDeviceBySerialGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesGeneralProductionDeviceBySerialGet
     */
    readonly serial?: string

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesGeneralProductionDeviceBySerialGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpDevicesGeneralReplacementDevicesGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesGeneralReplacementDevicesGetRequest
 */
export interface OpDevicesApiApiOpDevicesGeneralReplacementDevicesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesGeneralReplacementDevicesGet
     */
    readonly filterSe?: string

    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesGeneralReplacementDevicesGet
     */
    readonly filterSku?: string

    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesGeneralReplacementDevicesGet
     */
    readonly filterNameRu?: string

    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesGeneralReplacementDevicesGet
     */
    readonly filterNameEn?: string

    /**
     * 
     * @type {boolean}
     * @memberof OpDevicesApiApiOpDevicesGeneralReplacementDevicesGet
     */
    readonly filterOnlyWithSku?: boolean

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesGeneralReplacementDevicesGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesGeneralReplacementDevicesGet
     */
    readonly pagingPageSize?: number
}

/**
 * Request parameters for apiOpDevicesReplacedCeListGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesReplacedCeListGetRequest
 */
export interface OpDevicesApiApiOpDevicesReplacedCeListGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesReplacedCeListGet
     */
    readonly filterSe?: string

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesReplacedCeListGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof OpDevicesApiApiOpDevicesReplacedCeListGet
     */
    readonly pagingPageSize?: number
}

/**
 * Request parameters for apiOpDevicesReplacedCeSeDetailsGet operation in OpDevicesApi.
 * @export
 * @interface OpDevicesApiApiOpDevicesReplacedCeSeDetailsGetRequest
 */
export interface OpDevicesApiApiOpDevicesReplacedCeSeDetailsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OpDevicesApiApiOpDevicesReplacedCeSeDetailsGet
     */
    readonly se: string
}

/**
 * OpDevicesApi - object-oriented interface
 * @export
 * @class OpDevicesApi
 * @extends {BaseAPI}
 */
export class OpDevicesApi extends BaseAPI {
    /**
     * 
     * @summary Список CE для доукомплектации
     * @param {OpDevicesApiApiOpDevicesAddedCeListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesAddedCeListGet(requestParameters: OpDevicesApiApiOpDevicesAddedCeListGetRequest = {}, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesAddedCeListGet(requestParameters.filterSe, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Состав прибора для доукомплектации
     * @param {OpDevicesApiApiOpDevicesAddedCeSeDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesAddedCeSeDetailsGet(requestParameters: OpDevicesApiApiOpDevicesAddedCeSeDetailsGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesAddedCeSeDetailsGet(requestParameters.se, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение типов батарей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesBatteriesTypesGet(options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesBatteriesTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить приборы для доукомплектации
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdAddedCePost(requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.deviceAddAddedCEPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Возврат без ремонта
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdFeaturesReturnWithoutRepairPost(requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.deviceReturnWithoutRepairPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Установить гарантию
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost(requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.setWarrantyPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить заменённые СЕ
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost(requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.deviceAddReplacedCEPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить приборы для доукомплектации
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDeleteRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceDeleteAddedCEPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить прибор для доукомплектации
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePutRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut(requestParameters.deviceId, requestParameters.firmId, requestParameters.se, requestParameters.deviceUpdateAddedCEPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Заменить серийный номер батареи
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdChangeSerialPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.batteryId, requestParameters.changeBatterySerialPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Исправить серийный номер батареи
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.batteryId, requestParameters.correctBatterySerialPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить батарею
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDeleteRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.batteryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить батарею
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPutRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.batteryId, requestParameters.updateBatteryPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить батарею
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.addBatteryPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить дефекты
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDeleteRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceDeleteDefectsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить дефекты
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceAddDefectsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить дефект
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPutRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.defectId, requestParameters.deviceUpdateDefectPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Информация о приборе
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отмена выставления счёта
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверка возможности возврата без ремонта
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCheckReturnWithoutRepairPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Назначить контроль
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetPaymentConsentPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceSetPaymentConsentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Установить стоимость ремонта
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceSetRepairCostPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Назначить контроль
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.assignControlPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary События аудита по прибору
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Заменить прибор
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost(requestParameters.deviceId, requestParameters.firmId, requestParameters.changeSePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Заменить серийный номер прибора
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.changeSerialPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверка возможности замены прибора
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверка возможности замены серийного номера
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Пройти контроль
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.completeControlPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Исправить серийный номер прибора
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.correctSerialPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary История проводок по прибору
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запаковать прибор
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralPackPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Поиск существующего прибора при исправлении серийного номера 
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet(requestParameters.deviceId, requestParameters.firmId, requestParameters.serial, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получения серийного номера в формате EAN13
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13GetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13GetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Установить предварительная стоимость ремонта
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.setPreliminaryCostPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Установить подтверждение повторного ремонта
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.setRepeatedRepairConfirmedPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdLockPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdLockPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтвердить упаковку (кнопка \'Упаковано\') 
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение информации по упаковке
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить информацию по упаковке
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPutRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.packagingInformationUpdatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить прибор
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdPutRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.updateDevicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить счёт на ремонт прибора в Excel
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить счёт на ремонт прибора в PDF
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение счёт на ремонт прибора (РСР)
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cчёт на ремонт прибора - загрузка данных
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить счёт на ремонт прибора
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPutRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.repairCostCalculationUpdatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить заменённые СЕ
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDeleteRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceDeleteReplacedCEPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить приборы
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePutRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeSePut(requestParameters.deviceId, requestParameters.firmId, requestParameters.se, requestParameters.deviceUpdateReplacedCEPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить техническое заключение
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDeleteRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionDelete(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить техническое заключение в Excel
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить техническое заключение в PDF
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение технического заключения (ТЗ)
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка дефектов для технического заключения
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.technicalConclusionLoadDefectsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить техническое заключение
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPutRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut(requestParameters.deviceId, requestParameters.firmId, requestParameters.technicalConclusionUpdatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить техническое заключение upgradable device в Excel
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить техническое заключение upgradable device в PDF
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost(requestParameters.deviceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить типовые работы
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksDeleteRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceDeleteWorksPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить типовые работы
     * @param {OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost(requestParameters: OpDevicesApiApiOpDevicesDeviceDeviceIdFirmFirmIdWorksPostRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.deviceAddWorksPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Информация о приборе - выгрузка в Pdf
     * @param {OpDevicesApiApiOpDevicesExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesExportPdfPost(requestParameters: OpDevicesApiApiOpDevicesExportPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesExportPdfPost(requestParameters.deviceId, requestParameters.firmId, requestParameters.lcid, requestParameters.paperSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список комплектации прибора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesFeaturesEquipmentGet(options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesFeaturesEquipmentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверка серийного номера на уникальность
     * @param {OpDevicesApiApiOpDevicesGeneralCheckSerialExistGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesGeneralCheckSerialExistGet(requestParameters: OpDevicesApiApiOpDevicesGeneralCheckSerialExistGetRequest = {}, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesGeneralCheckSerialExistGet(requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить данные прибора из производства по серийному номеру
     * @param {OpDevicesApiApiOpDevicesGeneralProductionDeviceBySerialGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesGeneralProductionDeviceBySerialGet(requestParameters: OpDevicesApiApiOpDevicesGeneralProductionDeviceBySerialGetRequest = {}, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesGeneralProductionDeviceBySerialGet(requestParameters.serial, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список приборов на замену
     * @param {OpDevicesApiApiOpDevicesGeneralReplacementDevicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesGeneralReplacementDevicesGet(requestParameters: OpDevicesApiApiOpDevicesGeneralReplacementDevicesGetRequest = {}, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesGeneralReplacementDevicesGet(requestParameters.filterSe, requestParameters.filterSku, requestParameters.filterNameRu, requestParameters.filterNameEn, requestParameters.filterOnlyWithSku, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список заблокированных приборов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesLockedListGet(options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesLockedListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список CE на замену
     * @param {OpDevicesApiApiOpDevicesReplacedCeListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesReplacedCeListGet(requestParameters: OpDevicesApiApiOpDevicesReplacedCeListGetRequest = {}, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesReplacedCeListGet(requestParameters.filterSe, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Состав прибора на замену
     * @param {OpDevicesApiApiOpDevicesReplacedCeSeDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesReplacedCeSeDetailsGet(requestParameters: OpDevicesApiApiOpDevicesReplacedCeSeDetailsGetRequest, options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesReplacedCeSeDetailsGet(requestParameters.se, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список типовых работ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDevicesApi
     */
    public apiOpDevicesWorksListGet(options?: AxiosRequestConfig) {
        return OpDevicesApiFp(this.configuration).apiOpDevicesWorksListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpDistributorsApi - axios parameter creator
 * @export
 */
export const OpDistributorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал приборов дистрибьюторов
         * @param {string} periodStart Начало периода, формат yyyy-MM-dd
         * @param {string} periodEnd Конец периода, формат yyyy-MM-dd
         * @param {number} counterpartyId Фильтр по контрагенту
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {string} [soPr] 
         * @param {boolean} [platRem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDistributorsBrowseGet: async (periodStart: string, periodEnd: string, counterpartyId: number, firmId?: number, lcid?: number, soPr?: string, platRem?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodStart' is not null or undefined
            assertParamExists('apiOpDistributorsBrowseGet', 'periodStart', periodStart)
            // verify required parameter 'periodEnd' is not null or undefined
            assertParamExists('apiOpDistributorsBrowseGet', 'periodEnd', periodEnd)
            // verify required parameter 'counterpartyId' is not null or undefined
            assertParamExists('apiOpDistributorsBrowseGet', 'counterpartyId', counterpartyId)
            const localVarPath = `/api/op-distributors/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }

            if (soPr !== undefined) {
                localVarQueryParameter['SoPr'] = soPr;
            }

            if (platRem !== undefined) {
                localVarQueryParameter['PlatRem'] = platRem;
            }

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (counterpartyId !== undefined) {
                localVarQueryParameter['CounterpartyId'] = counterpartyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить инфо по дистрибьютору в Excel
         * @param {ExportDistributorDevicesToExcelPayload} [exportDistributorDevicesToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDistributorsExportExcelPost: async (exportDistributorDevicesToExcelPayload?: ExportDistributorDevicesToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-distributors/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportDistributorDevicesToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить инфо по дистрибьютору в Pdf
         * @param {ExportDistributorDevicesToPdfPayload} [exportDistributorDevicesToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDistributorsExportPdfPost: async (exportDistributorDevicesToPdfPayload?: ExportDistributorDevicesToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-distributors/export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportDistributorDevicesToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {string} dateFrom Дата начала периода, формат yyyy-MM-dd
         * @param {string} dateTo Дата конца периода, формат yyyy-MM-dd
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDistributorsGlobalFilterCounterpartiesGet: async (dateFrom: string, dateTo: string, firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('apiOpDistributorsGlobalFilterCounterpartiesGet', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('apiOpDistributorsGlobalFilterCounterpartiesGet', 'dateTo', dateTo)
            const localVarPath = `/api/op-distributors/global-filter-counterparties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpDistributorsApi - functional programming interface
 * @export
 */
export const OpDistributorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpDistributorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал приборов дистрибьюторов
         * @param {string} periodStart Начало периода, формат yyyy-MM-dd
         * @param {string} periodEnd Конец периода, формат yyyy-MM-dd
         * @param {number} counterpartyId Фильтр по контрагенту
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {string} [soPr] 
         * @param {boolean} [platRem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDistributorsBrowseGet(periodStart: string, periodEnd: string, counterpartyId: number, firmId?: number, lcid?: number, soPr?: string, platRem?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DistributorDeviceBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDistributorsBrowseGet(periodStart, periodEnd, counterpartyId, firmId, lcid, soPr, platRem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить инфо по дистрибьютору в Excel
         * @param {ExportDistributorDevicesToExcelPayload} [exportDistributorDevicesToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDistributorsExportExcelPost(exportDistributorDevicesToExcelPayload?: ExportDistributorDevicesToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDistributorsExportExcelPost(exportDistributorDevicesToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить инфо по дистрибьютору в Pdf
         * @param {ExportDistributorDevicesToPdfPayload} [exportDistributorDevicesToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDistributorsExportPdfPost(exportDistributorDevicesToPdfPayload?: ExportDistributorDevicesToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDistributorsExportPdfPost(exportDistributorDevicesToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {string} dateFrom Дата начала периода, формат yyyy-MM-dd
         * @param {string} dateTo Дата конца периода, формат yyyy-MM-dd
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpDistributorsGlobalFilterCounterpartiesGet(dateFrom: string, dateTo: string, firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DistributorInfoBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpDistributorsGlobalFilterCounterpartiesGet(dateFrom, dateTo, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpDistributorsApi - factory interface
 * @export
 */
export const OpDistributorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpDistributorsApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал приборов дистрибьюторов
         * @param {OpDistributorsApiApiOpDistributorsBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDistributorsBrowseGet(requestParameters: OpDistributorsApiApiOpDistributorsBrowseGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DistributorDeviceBrowseDto>> {
            return localVarFp.apiOpDistributorsBrowseGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.counterpartyId, requestParameters.firmId, requestParameters.lcid, requestParameters.soPr, requestParameters.platRem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить инфо по дистрибьютору в Excel
         * @param {OpDistributorsApiApiOpDistributorsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDistributorsExportExcelPost(requestParameters: OpDistributorsApiApiOpDistributorsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDistributorsExportExcelPost(requestParameters.exportDistributorDevicesToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить инфо по дистрибьютору в Pdf
         * @param {OpDistributorsApiApiOpDistributorsExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDistributorsExportPdfPost(requestParameters: OpDistributorsApiApiOpDistributorsExportPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpDistributorsExportPdfPost(requestParameters.exportDistributorDevicesToPdfPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {OpDistributorsApiApiOpDistributorsGlobalFilterCounterpartiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpDistributorsGlobalFilterCounterpartiesGet(requestParameters: OpDistributorsApiApiOpDistributorsGlobalFilterCounterpartiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DistributorInfoBrowseDto>> {
            return localVarFp.apiOpDistributorsGlobalFilterCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpDistributorsBrowseGet operation in OpDistributorsApi.
 * @export
 * @interface OpDistributorsApiApiOpDistributorsBrowseGetRequest
 */
export interface OpDistributorsApiApiOpDistributorsBrowseGetRequest {
    /**
     * Начало периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpDistributorsApiApiOpDistributorsBrowseGet
     */
    readonly periodStart: string

    /**
     * Конец периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpDistributorsApiApiOpDistributorsBrowseGet
     */
    readonly periodEnd: string

    /**
     * Фильтр по контрагенту
     * @type {number}
     * @memberof OpDistributorsApiApiOpDistributorsBrowseGet
     */
    readonly counterpartyId: number

    /**
     * 
     * @type {number}
     * @memberof OpDistributorsApiApiOpDistributorsBrowseGet
     */
    readonly firmId?: number

    /**
     * 
     * @type {number}
     * @memberof OpDistributorsApiApiOpDistributorsBrowseGet
     */
    readonly lcid?: number

    /**
     * 
     * @type {string}
     * @memberof OpDistributorsApiApiOpDistributorsBrowseGet
     */
    readonly soPr?: string

    /**
     * 
     * @type {boolean}
     * @memberof OpDistributorsApiApiOpDistributorsBrowseGet
     */
    readonly platRem?: boolean
}

/**
 * Request parameters for apiOpDistributorsExportExcelPost operation in OpDistributorsApi.
 * @export
 * @interface OpDistributorsApiApiOpDistributorsExportExcelPostRequest
 */
export interface OpDistributorsApiApiOpDistributorsExportExcelPostRequest {
    /**
     * 
     * @type {ExportDistributorDevicesToExcelPayload}
     * @memberof OpDistributorsApiApiOpDistributorsExportExcelPost
     */
    readonly exportDistributorDevicesToExcelPayload?: ExportDistributorDevicesToExcelPayload
}

/**
 * Request parameters for apiOpDistributorsExportPdfPost operation in OpDistributorsApi.
 * @export
 * @interface OpDistributorsApiApiOpDistributorsExportPdfPostRequest
 */
export interface OpDistributorsApiApiOpDistributorsExportPdfPostRequest {
    /**
     * 
     * @type {ExportDistributorDevicesToPdfPayload}
     * @memberof OpDistributorsApiApiOpDistributorsExportPdfPost
     */
    readonly exportDistributorDevicesToPdfPayload?: ExportDistributorDevicesToPdfPayload
}

/**
 * Request parameters for apiOpDistributorsGlobalFilterCounterpartiesGet operation in OpDistributorsApi.
 * @export
 * @interface OpDistributorsApiApiOpDistributorsGlobalFilterCounterpartiesGetRequest
 */
export interface OpDistributorsApiApiOpDistributorsGlobalFilterCounterpartiesGetRequest {
    /**
     * Дата начала периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpDistributorsApiApiOpDistributorsGlobalFilterCounterpartiesGet
     */
    readonly dateFrom: string

    /**
     * Дата конца периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpDistributorsApiApiOpDistributorsGlobalFilterCounterpartiesGet
     */
    readonly dateTo: string

    /**
     * Id компании
     * @type {number}
     * @memberof OpDistributorsApiApiOpDistributorsGlobalFilterCounterpartiesGet
     */
    readonly firmId?: number
}

/**
 * OpDistributorsApi - object-oriented interface
 * @export
 * @class OpDistributorsApi
 * @extends {BaseAPI}
 */
export class OpDistributorsApi extends BaseAPI {
    /**
     * 
     * @summary Журнал приборов дистрибьюторов
     * @param {OpDistributorsApiApiOpDistributorsBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDistributorsApi
     */
    public apiOpDistributorsBrowseGet(requestParameters: OpDistributorsApiApiOpDistributorsBrowseGetRequest, options?: AxiosRequestConfig) {
        return OpDistributorsApiFp(this.configuration).apiOpDistributorsBrowseGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.counterpartyId, requestParameters.firmId, requestParameters.lcid, requestParameters.soPr, requestParameters.platRem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить инфо по дистрибьютору в Excel
     * @param {OpDistributorsApiApiOpDistributorsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDistributorsApi
     */
    public apiOpDistributorsExportExcelPost(requestParameters: OpDistributorsApiApiOpDistributorsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return OpDistributorsApiFp(this.configuration).apiOpDistributorsExportExcelPost(requestParameters.exportDistributorDevicesToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить инфо по дистрибьютору в Pdf
     * @param {OpDistributorsApiApiOpDistributorsExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDistributorsApi
     */
    public apiOpDistributorsExportPdfPost(requestParameters: OpDistributorsApiApiOpDistributorsExportPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return OpDistributorsApiFp(this.configuration).apiOpDistributorsExportPdfPost(requestParameters.exportDistributorDevicesToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список контрагентов за период
     * @param {OpDistributorsApiApiOpDistributorsGlobalFilterCounterpartiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpDistributorsApi
     */
    public apiOpDistributorsGlobalFilterCounterpartiesGet(requestParameters: OpDistributorsApiApiOpDistributorsGlobalFilterCounterpartiesGetRequest, options?: AxiosRequestConfig) {
        return OpDistributorsApiFp(this.configuration).apiOpDistributorsGlobalFilterCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpExternalServiceCentersApi - axios parameter creator
 * @export
 */
export const OpExternalServiceCentersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Загрузка данных ремонтов внешних сервисный центров из Excel
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpExternalServiceCentersImportFromExcelPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-external-service-centers/import-from-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранение данных ремонтов внешних сервисных центров в базе данных
         * @param {DistributorsDataSavePayload} [distributorsDataSavePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpExternalServiceCentersSaveDataPost: async (distributorsDataSavePayload?: DistributorsDataSavePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-external-service-centers/save-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributorsDataSavePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение гарантиности приборов
         * @param {DeviceWarrantyCheckPayload} [deviceWarrantyCheckPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpExternalServiceCentersWarrantyCheckPost: async (deviceWarrantyCheckPayload?: DeviceWarrantyCheckPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-external-service-centers/warranty-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceWarrantyCheckPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpExternalServiceCentersApi - functional programming interface
 * @export
 */
export const OpExternalServiceCentersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpExternalServiceCentersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Загрузка данных ремонтов внешних сервисный центров из Excel
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpExternalServiceCentersImportFromExcelPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportDistributorsDataFromExcelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpExternalServiceCentersImportFromExcelPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сохранение данных ремонтов внешних сервисных центров в базе данных
         * @param {DistributorsDataSavePayload} [distributorsDataSavePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpExternalServiceCentersSaveDataPost(distributorsDataSavePayload?: DistributorsDataSavePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpExternalServiceCentersSaveDataPost(distributorsDataSavePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение гарантиности приборов
         * @param {DeviceWarrantyCheckPayload} [deviceWarrantyCheckPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpExternalServiceCentersWarrantyCheckPost(deviceWarrantyCheckPayload?: DeviceWarrantyCheckPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceWarrantyCheckDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpExternalServiceCentersWarrantyCheckPost(deviceWarrantyCheckPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpExternalServiceCentersApi - factory interface
 * @export
 */
export const OpExternalServiceCentersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpExternalServiceCentersApiFp(configuration)
    return {
        /**
         * 
         * @summary Загрузка данных ремонтов внешних сервисный центров из Excel
         * @param {OpExternalServiceCentersApiApiOpExternalServiceCentersImportFromExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpExternalServiceCentersImportFromExcelPost(requestParameters: OpExternalServiceCentersApiApiOpExternalServiceCentersImportFromExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ImportDistributorsDataFromExcelResponse> {
            return localVarFp.apiOpExternalServiceCentersImportFromExcelPost(requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранение данных ремонтов внешних сервисных центров в базе данных
         * @param {OpExternalServiceCentersApiApiOpExternalServiceCentersSaveDataPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpExternalServiceCentersSaveDataPost(requestParameters: OpExternalServiceCentersApiApiOpExternalServiceCentersSaveDataPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpExternalServiceCentersSaveDataPost(requestParameters.distributorsDataSavePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение гарантиности приборов
         * @param {OpExternalServiceCentersApiApiOpExternalServiceCentersWarrantyCheckPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpExternalServiceCentersWarrantyCheckPost(requestParameters: OpExternalServiceCentersApiApiOpExternalServiceCentersWarrantyCheckPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<DeviceWarrantyCheckDto>> {
            return localVarFp.apiOpExternalServiceCentersWarrantyCheckPost(requestParameters.deviceWarrantyCheckPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpExternalServiceCentersImportFromExcelPost operation in OpExternalServiceCentersApi.
 * @export
 * @interface OpExternalServiceCentersApiApiOpExternalServiceCentersImportFromExcelPostRequest
 */
export interface OpExternalServiceCentersApiApiOpExternalServiceCentersImportFromExcelPostRequest {
    /**
     * 
     * @type {File}
     * @memberof OpExternalServiceCentersApiApiOpExternalServiceCentersImportFromExcelPost
     */
    readonly file?: File
}

/**
 * Request parameters for apiOpExternalServiceCentersSaveDataPost operation in OpExternalServiceCentersApi.
 * @export
 * @interface OpExternalServiceCentersApiApiOpExternalServiceCentersSaveDataPostRequest
 */
export interface OpExternalServiceCentersApiApiOpExternalServiceCentersSaveDataPostRequest {
    /**
     * 
     * @type {DistributorsDataSavePayload}
     * @memberof OpExternalServiceCentersApiApiOpExternalServiceCentersSaveDataPost
     */
    readonly distributorsDataSavePayload?: DistributorsDataSavePayload
}

/**
 * Request parameters for apiOpExternalServiceCentersWarrantyCheckPost operation in OpExternalServiceCentersApi.
 * @export
 * @interface OpExternalServiceCentersApiApiOpExternalServiceCentersWarrantyCheckPostRequest
 */
export interface OpExternalServiceCentersApiApiOpExternalServiceCentersWarrantyCheckPostRequest {
    /**
     * 
     * @type {DeviceWarrantyCheckPayload}
     * @memberof OpExternalServiceCentersApiApiOpExternalServiceCentersWarrantyCheckPost
     */
    readonly deviceWarrantyCheckPayload?: DeviceWarrantyCheckPayload
}

/**
 * OpExternalServiceCentersApi - object-oriented interface
 * @export
 * @class OpExternalServiceCentersApi
 * @extends {BaseAPI}
 */
export class OpExternalServiceCentersApi extends BaseAPI {
    /**
     * 
     * @summary Загрузка данных ремонтов внешних сервисный центров из Excel
     * @param {OpExternalServiceCentersApiApiOpExternalServiceCentersImportFromExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpExternalServiceCentersApi
     */
    public apiOpExternalServiceCentersImportFromExcelPost(requestParameters: OpExternalServiceCentersApiApiOpExternalServiceCentersImportFromExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return OpExternalServiceCentersApiFp(this.configuration).apiOpExternalServiceCentersImportFromExcelPost(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранение данных ремонтов внешних сервисных центров в базе данных
     * @param {OpExternalServiceCentersApiApiOpExternalServiceCentersSaveDataPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpExternalServiceCentersApi
     */
    public apiOpExternalServiceCentersSaveDataPost(requestParameters: OpExternalServiceCentersApiApiOpExternalServiceCentersSaveDataPostRequest = {}, options?: AxiosRequestConfig) {
        return OpExternalServiceCentersApiFp(this.configuration).apiOpExternalServiceCentersSaveDataPost(requestParameters.distributorsDataSavePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение гарантиности приборов
     * @param {OpExternalServiceCentersApiApiOpExternalServiceCentersWarrantyCheckPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpExternalServiceCentersApi
     */
    public apiOpExternalServiceCentersWarrantyCheckPost(requestParameters: OpExternalServiceCentersApiApiOpExternalServiceCentersWarrantyCheckPostRequest = {}, options?: AxiosRequestConfig) {
        return OpExternalServiceCentersApiFp(this.configuration).apiOpExternalServiceCentersWarrantyCheckPost(requestParameters.deviceWarrantyCheckPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpInvoicesApi - axios parameter creator
 * @export
 */
export const OpInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал инвойсов - получить запись
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/browse/doc/{invoiceId}/firm/{firmId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал инвойсов
         * @param {number} firmId Ключ выбранной фирмы
         * @param {string} periodStart Начало периода, формат yyyy-MM-dd
         * @param {string} periodEnd Конец периода, формат yyyy-MM-dd
         * @param {number} counterpartyId Фильтр по контрагенту
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesBrowseGet: async (firmId: number, periodStart: string, periodEnd: string, counterpartyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesBrowseGet', 'firmId', firmId)
            // verify required parameter 'periodStart' is not null or undefined
            assertParamExists('apiOpInvoicesBrowseGet', 'periodStart', periodStart)
            // verify required parameter 'periodEnd' is not null or undefined
            assertParamExists('apiOpInvoicesBrowseGet', 'periodEnd', periodEnd)
            // verify required parameter 'counterpartyId' is not null or undefined
            assertParamExists('apiOpInvoicesBrowseGet', 'counterpartyId', counterpartyId)
            const localVarPath = `/api/op-invoices/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (counterpartyId !== undefined) {
                localVarQueryParameter['CounterpartyId'] = counterpartyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список стран происхождения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesCountryOriginsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-invoices/country-origins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список приборов для добавления в Исходящий Счет
         * @param {number} [invoiceId] 
         * @param {number} [firmId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDevicesToSendGet: async (invoiceId?: number, firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-invoices/devices-to-send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['firmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подбор лицензий
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/assign-licenses`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Номера коробок приборов
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/box-numbers`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтвердить приборы в документе
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/confirm`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить документ
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDelete: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDelete', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDelete', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтвердить выбранные приборы
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {ConfirmInvoiceDevicesPayload} [confirmInvoiceDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost: async (invoiceId: number, firmId: number, confirmInvoiceDevicesPayload?: ConfirmInvoiceDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/devices/confirm-devices`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmInvoiceDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить устройства из счета
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {DeleteInvoiceDevicesPayload} [deleteInvoiceDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete: async (invoiceId: number, firmId: number, deleteInvoiceDevicesPayload?: DeleteInvoiceDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/devices`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteInvoiceDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список устройств счета
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/devices`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить приборы вручную
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {AddInvoiceDevicesPayload} [addInvoiceDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost: async (invoiceId: number, firmId: number, addInvoiceDevicesPayload?: AddInvoiceDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/devices`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addInvoiceDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отменить выбранные приборы
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {RejectInvoiceDevicesPayload} [rejectInvoiceDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost: async (invoiceId: number, firmId: number, rejectInvoiceDevicesPayload?: RejectInvoiceDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/devices/reject-devices`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectInvoiceDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить коробку прибора по коду СЕ
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoiceDeviceBoxPayload} [updateInvoiceDeviceBoxPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost: async (invoiceId: number, firmId: number, updateInvoiceDeviceBoxPayload?: UpdateInvoiceDeviceBoxPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/devices/update-device-box`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoiceDeviceBoxPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление Страны происхождения у приборов
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateDeviceCountryOriginsPayload} [updateDeviceCountryOriginsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost: async (invoiceId: number, firmId: number, updateDeviceCountryOriginsPayload?: UpdateDeviceCountryOriginsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/devices/update-device-country-origin`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceCountryOriginsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить цену прибора по коду СЕ
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoiceDevicePricePayload} [updateInvoiceDevicePricePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost: async (invoiceId: number, firmId: number, updateInvoiceDevicePricePayload?: UpdateInvoiceDevicePricePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/devices/update-device-price`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoiceDevicePricePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить цену приборов из прайслистов
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/devices/update-prices-from-pricelists`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить сопроводительный документ в Excel
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost: async (invoiceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/export-accompanying-document-excel`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить сопроводительный документ в PDF
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost: async (invoiceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/export-accompanying-document-pdf`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить счет исходящего инвойса в Excel
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/export-outgoing-excel`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить счет исходящего инвойса в Pdf
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/export-outgoing-pdf`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данныx для печатной формы счета
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/for-print`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение данныx для печатной формы счета
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoicePrintDataPayload} [updateInvoicePrintDataPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost: async (invoiceId: number, firmId: number, updateInvoicePrintDataPayload?: UpdateInvoicePrintDataPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/for-print`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoicePrintDataPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Упаковочный лист на печать
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost: async (invoiceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/packing-list-pdf`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить документ
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoicePayload} [updateInvoicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdPut: async (invoiceId: number, firmId: number, updateInvoicePayload?: UpdateInvoicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdPut', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdPut', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отменить приборы в документе
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/reject`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание ТЗ для приборов, в которых оно отстутствует
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {TechnicalConclusionsCreateMissingPayload} [technicalConclusionsCreateMissingPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost: async (invoiceId: number, firmId: number, technicalConclusionsCreateMissingPayload?: TechnicalConclusionsCreateMissingPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/repair-list/create-missing-technical-conclusions`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(technicalConclusionsCreateMissingPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить приборы дефектной ведомости
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateRepairListDevicesPayload} [updateRepairListDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut: async (invoiceId: number, firmId: number, updateRepairListDevicesPayload?: UpdateRepairListDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/repair-list/devices`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairListDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить дефектную ведомость в Excel
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost: async (invoiceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/repair-list/export-excel`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить дефектную ведомость в Pdf
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost: async (invoiceId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/repair-list/export-pdf`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить дефектную ведомость 
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/repair-list`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка дефектов для дефектной ведомости
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {RepairListLoadDefectsPayload} [repairListLoadDefectsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost: async (invoiceId: number, firmId: number, repairListLoadDefectsPayload?: RepairListLoadDefectsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/repair-list/load-defects`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairListLoadDefectsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить свойства дефектной ведомости
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateRepairListPayload} [updateRepairListPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut: async (invoiceId: number, firmId: number, updateRepairListPayload?: UpdateRepairListPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/repair-list`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairListPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправка замененных запчастей в Сбыт
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/send-replacedParts-to-sbyt`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправка в Сбыт (стоимости ремонта и упаковки)
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/send-to-sbyt`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить декларацию
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoiceDeclarationPayload} [updateInvoiceDeclarationPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut: async (invoiceId: number, firmId: number, updateInvoiceDeclarationPayload?: UpdateInvoiceDeclarationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/set-declaration`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoiceDeclarationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить дату прихода
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoiceReceiveDatePayload} [updateInvoiceReceiveDatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut: async (invoiceId: number, firmId: number, updateInvoiceReceiveDatePayload?: UpdateInvoiceReceiveDatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/set-receive-date`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoiceReceiveDatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Списание Лицензий (Внешний СЦ)
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost: async (invoiceId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost', 'invoiceId', invoiceId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/doc/{invoiceId}/firm/{firmId}/use-licenses`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {string} dateFrom Дата начала периода, формат yyyy-MM-dd
         * @param {string} dateTo Дата конца периода, формат yyyy-MM-dd
         * @param {number} firmId Выбранное Предприятие
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesGlobalFilterCounterpartiesGet: async (dateFrom: string, dateTo: string, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('apiOpInvoicesGlobalFilterCounterpartiesGet', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('apiOpInvoicesGlobalFilterCounterpartiesGet', 'dateTo', dateTo)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpInvoicesGlobalFilterCounterpartiesGet', 'firmId', firmId)
            const localVarPath = `/api/op-invoices/global-filter-counterparties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать документ
         * @param {CreateInvoicePayload} [createInvoicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesPost: async (createInvoicePayload?: CreateInvoicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить
         * @param {SendInvoicePayload} [sendInvoicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesSendPost: async (sendInvoicePayload?: SendInvoicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-invoices/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendInvoicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpInvoicesApi - functional programming interface
 * @export
 */
export const OpInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал инвойсов - получить запись
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал инвойсов
         * @param {number} firmId Ключ выбранной фирмы
         * @param {string} periodStart Начало периода, формат yyyy-MM-dd
         * @param {string} periodEnd Конец периода, формат yyyy-MM-dd
         * @param {number} counterpartyId Фильтр по контрагенту
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesBrowseGet(firmId: number, periodStart: string, periodEnd: string, counterpartyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesBrowseGet(firmId, periodStart, periodEnd, counterpartyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список стран происхождения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesCountryOriginsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceCountryOriginDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesCountryOriginsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список приборов для добавления в Исходящий Счет
         * @param {number} [invoiceId] 
         * @param {number} [firmId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDevicesToSendGet(invoiceId?: number, firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceToSendDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDevicesToSendGet(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подбор лицензий
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignInvoiceLicensesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Номера коробок приборов
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтвердить приборы в документе
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить документ
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDelete(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDelete(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтвердить выбранные приборы
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {ConfirmInvoiceDevicesPayload} [confirmInvoiceDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost(invoiceId: number, firmId: number, confirmInvoiceDevicesPayload?: ConfirmInvoiceDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmInvoiceDevicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost(invoiceId, firmId, confirmInvoiceDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить устройства из счета
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {DeleteInvoiceDevicesPayload} [deleteInvoiceDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete(invoiceId: number, firmId: number, deleteInvoiceDevicesPayload?: DeleteInvoiceDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete(invoiceId, firmId, deleteInvoiceDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список устройств счета
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDeviceBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить приборы вручную
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {AddInvoiceDevicesPayload} [addInvoiceDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost(invoiceId: number, firmId: number, addInvoiceDevicesPayload?: AddInvoiceDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost(invoiceId, firmId, addInvoiceDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отменить выбранные приборы
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {RejectInvoiceDevicesPayload} [rejectInvoiceDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost(invoiceId: number, firmId: number, rejectInvoiceDevicesPayload?: RejectInvoiceDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RejectInvoiceDevicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost(invoiceId, firmId, rejectInvoiceDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить коробку прибора по коду СЕ
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoiceDeviceBoxPayload} [updateInvoiceDeviceBoxPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost(invoiceId: number, firmId: number, updateInvoiceDeviceBoxPayload?: UpdateInvoiceDeviceBoxPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost(invoiceId, firmId, updateInvoiceDeviceBoxPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновление Страны происхождения у приборов
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateDeviceCountryOriginsPayload} [updateDeviceCountryOriginsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost(invoiceId: number, firmId: number, updateDeviceCountryOriginsPayload?: UpdateDeviceCountryOriginsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost(invoiceId, firmId, updateDeviceCountryOriginsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить цену прибора по коду СЕ
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoiceDevicePricePayload} [updateInvoiceDevicePricePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost(invoiceId: number, firmId: number, updateInvoiceDevicePricePayload?: UpdateInvoiceDevicePricePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost(invoiceId, firmId, updateInvoiceDevicePricePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить цену приборов из прайслистов
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить сопроводительный документ в Excel
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost(invoiceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost(invoiceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить сопроводительный документ в PDF
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost(invoiceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost(invoiceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить счет исходящего инвойса в Excel
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить счет исходящего инвойса в Pdf
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение данныx для печатной формы счета
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePrintDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменение данныx для печатной формы счета
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoicePrintDataPayload} [updateInvoicePrintDataPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost(invoiceId: number, firmId: number, updateInvoicePrintDataPayload?: UpdateInvoicePrintDataPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost(invoiceId, firmId, updateInvoicePrintDataPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Упаковочный лист на печать
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost(invoiceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost(invoiceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить документ
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoicePayload} [updateInvoicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdPut(invoiceId: number, firmId: number, updateInvoicePayload?: UpdateInvoicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdPut(invoiceId, firmId, updateInvoicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отменить приборы в документе
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RejectInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создание ТЗ для приборов, в которых оно отстутствует
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {TechnicalConclusionsCreateMissingPayload} [technicalConclusionsCreateMissingPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost(invoiceId: number, firmId: number, technicalConclusionsCreateMissingPayload?: TechnicalConclusionsCreateMissingPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost(invoiceId, firmId, technicalConclusionsCreateMissingPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить приборы дефектной ведомости
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateRepairListDevicesPayload} [updateRepairListDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut(invoiceId: number, firmId: number, updateRepairListDevicesPayload?: UpdateRepairListDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut(invoiceId, firmId, updateRepairListDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить дефектную ведомость в Excel
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost(invoiceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost(invoiceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить дефектную ведомость в Pdf
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost(invoiceId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost(invoiceId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить дефектную ведомость 
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairListBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка дефектов для дефектной ведомости
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {RepairListLoadDefectsPayload} [repairListLoadDefectsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost(invoiceId: number, firmId: number, repairListLoadDefectsPayload?: RepairListLoadDefectsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost(invoiceId, firmId, repairListLoadDefectsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить свойства дефектной ведомости
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateRepairListPayload} [updateRepairListPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut(invoiceId: number, firmId: number, updateRepairListPayload?: UpdateRepairListPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut(invoiceId, firmId, updateRepairListPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправка замененных запчастей в Сбыт
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendInvoiceReplacedPartsToSbytResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправка в Сбыт (стоимости ремонта и упаковки)
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить декларацию
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoiceDeclarationPayload} [updateInvoiceDeclarationPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut(invoiceId: number, firmId: number, updateInvoiceDeclarationPayload?: UpdateInvoiceDeclarationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut(invoiceId, firmId, updateInvoiceDeclarationPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить дату прихода
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {UpdateInvoiceReceiveDatePayload} [updateInvoiceReceiveDatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut(invoiceId: number, firmId: number, updateInvoiceReceiveDatePayload?: UpdateInvoiceReceiveDatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut(invoiceId, firmId, updateInvoiceReceiveDatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Списание Лицензий (Внешний СЦ)
         * @param {number} invoiceId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost(invoiceId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost(invoiceId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {string} dateFrom Дата начала периода, формат yyyy-MM-dd
         * @param {string} dateTo Дата конца периода, формат yyyy-MM-dd
         * @param {number} firmId Выбранное Предприятие
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesGlobalFilterCounterpartiesGet(dateFrom: string, dateTo: string, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetInvoiceCounterpartiesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesGlobalFilterCounterpartiesGet(dateFrom, dateTo, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать документ
         * @param {CreateInvoicePayload} [createInvoicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesPost(createInvoicePayload?: CreateInvoicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesPost(createInvoicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправить
         * @param {SendInvoicePayload} [sendInvoicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpInvoicesSendPost(sendInvoicePayload?: SendInvoicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpInvoicesSendPost(sendInvoicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpInvoicesApi - factory interface
 * @export
 */
export const OpInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал инвойсов - получить запись
         * @param {OpInvoicesApiApiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet(requestParameters: OpInvoicesApiApiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<InvoiceBrowseDto> {
            return localVarFp.apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал инвойсов
         * @param {OpInvoicesApiApiOpInvoicesBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesBrowseGet(requestParameters: OpInvoicesApiApiOpInvoicesBrowseGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<InvoiceBrowseDto>> {
            return localVarFp.apiOpInvoicesBrowseGet(requestParameters.firmId, requestParameters.periodStart, requestParameters.periodEnd, requestParameters.counterpartyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список стран происхождения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesCountryOriginsGet(options?: AxiosRequestConfig): AxiosPromise<Array<InvoiceCountryOriginDto>> {
            return localVarFp.apiOpInvoicesCountryOriginsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список приборов для добавления в Исходящий Счет
         * @param {OpInvoicesApiApiOpInvoicesDevicesToSendGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDevicesToSendGet(requestParameters: OpInvoicesApiApiOpInvoicesDevicesToSendGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<DeviceToSendDto>> {
            return localVarFp.apiOpInvoicesDevicesToSendGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подбор лицензий
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPostRequest, options?: AxiosRequestConfig): AxiosPromise<AssignInvoiceLicensesResponse> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Номера коробок приборов
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<number>> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтвердить приборы в документе
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPostRequest, options?: AxiosRequestConfig): AxiosPromise<ConfirmInvoiceResponse> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить документ
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDelete(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDelete(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтвердить выбранные приборы
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPostRequest, options?: AxiosRequestConfig): AxiosPromise<ConfirmInvoiceDevicesResponse> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.confirmInvoiceDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить устройства из счета
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete(requestParameters.invoiceId, requestParameters.firmId, requestParameters.deleteInvoiceDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список устройств счета
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<InvoiceDeviceBrowseDto>> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить приборы вручную
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.addInvoiceDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отменить выбранные приборы
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPostRequest, options?: AxiosRequestConfig): AxiosPromise<RejectInvoiceDevicesResponse> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.rejectInvoiceDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить коробку прибора по коду СЕ
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoiceDeviceBoxPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновление Страны происхождения у приборов
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateDeviceCountryOriginsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить цену прибора по коду СЕ
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoiceDevicePricePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить цену приборов из прайслистов
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить сопроводительный документ в Excel
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить сопроводительный документ в PDF
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить счет исходящего инвойса в Excel
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить счет исходящего инвойса в Pdf
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение данныx для печатной формы счета
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGetRequest, options?: AxiosRequestConfig): AxiosPromise<InvoicePrintDataDto> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение данныx для печатной формы счета
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoicePrintDataPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Упаковочный лист на печать
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить документ
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdPut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdPut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отменить приборы в документе
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRejectPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRejectPostRequest, options?: AxiosRequestConfig): AxiosPromise<RejectInvoiceResponse> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание ТЗ для приборов, в которых оно отстутствует
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.technicalConclusionsCreateMissingPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить приборы дефектной ведомости
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateRepairListDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить дефектную ведомость в Excel
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить дефектную ведомость в Pdf
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить дефектную ведомость 
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGetRequest, options?: AxiosRequestConfig): AxiosPromise<RepairListBrowseDto> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка дефектов для дефектной ведомости
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.repairListLoadDefectsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить свойства дефектной ведомости
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateRepairListPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправка замененных запчастей в Сбыт
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPostRequest, options?: AxiosRequestConfig): AxiosPromise<SendInvoiceReplacedPartsToSbytResponse> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправка в Сбыт (стоимости ремонта и упаковки)
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить декларацию
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoiceDeclarationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить дату прихода
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoiceReceiveDatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Списание Лицензий (Внешний СЦ)
         * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {OpInvoicesApiApiOpInvoicesGlobalFilterCounterpartiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesGlobalFilterCounterpartiesGet(requestParameters: OpInvoicesApiApiOpInvoicesGlobalFilterCounterpartiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<GetInvoiceCounterpartiesDto>> {
            return localVarFp.apiOpInvoicesGlobalFilterCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать документ
         * @param {OpInvoicesApiApiOpInvoicesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesPost(requestParameters: OpInvoicesApiApiOpInvoicesPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateInvoiceResponse> {
            return localVarFp.apiOpInvoicesPost(requestParameters.createInvoicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить
         * @param {OpInvoicesApiApiOpInvoicesSendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpInvoicesSendPost(requestParameters: OpInvoicesApiApiOpInvoicesSendPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpInvoicesSendPost(requestParameters.sendInvoicePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGetRequest
 */
export interface OpInvoicesApiApiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesBrowseGet operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesBrowseGetRequest
 */
export interface OpInvoicesApiApiOpInvoicesBrowseGetRequest {
    /**
     * Ключ выбранной фирмы
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesBrowseGet
     */
    readonly firmId: number

    /**
     * Начало периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpInvoicesApiApiOpInvoicesBrowseGet
     */
    readonly periodStart: string

    /**
     * Конец периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpInvoicesApiApiOpInvoicesBrowseGet
     */
    readonly periodEnd: string

    /**
     * Фильтр по контрагенту
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesBrowseGet
     */
    readonly counterpartyId: number
}

/**
 * Request parameters for apiOpInvoicesDevicesToSendGet operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDevicesToSendGetRequest
 */
export interface OpInvoicesApiApiOpInvoicesDevicesToSendGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDevicesToSendGet
     */
    readonly invoiceId?: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDevicesToSendGet
     */
    readonly firmId?: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGetRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDelete operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDeleteRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDelete
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDelete
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost
     */
    readonly firmId: number

    /**
     * 
     * @type {ConfirmInvoiceDevicesPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost
     */
    readonly confirmInvoiceDevicesPayload?: ConfirmInvoiceDevicesPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDeleteRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete
     */
    readonly firmId: number

    /**
     * 
     * @type {DeleteInvoiceDevicesPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete
     */
    readonly deleteInvoiceDevicesPayload?: DeleteInvoiceDevicesPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGetRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost
     */
    readonly firmId: number

    /**
     * 
     * @type {AddInvoiceDevicesPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost
     */
    readonly addInvoiceDevicesPayload?: AddInvoiceDevicesPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost
     */
    readonly firmId: number

    /**
     * 
     * @type {RejectInvoiceDevicesPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost
     */
    readonly rejectInvoiceDevicesPayload?: RejectInvoiceDevicesPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateInvoiceDeviceBoxPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost
     */
    readonly updateInvoiceDeviceBoxPayload?: UpdateInvoiceDeviceBoxPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateDeviceCountryOriginsPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost
     */
    readonly updateDeviceCountryOriginsPayload?: UpdateDeviceCountryOriginsPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateInvoiceDevicePricePayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost
     */
    readonly updateInvoiceDevicePricePayload?: UpdateInvoiceDevicePricePayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGetRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateInvoicePrintDataPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost
     */
    readonly updateInvoicePrintDataPayload?: UpdateInvoicePrintDataPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdPut operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPutRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPut
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPut
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateInvoicePayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPut
     */
    readonly updateInvoicePayload?: UpdateInvoicePayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRejectPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRejectPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost
     */
    readonly firmId: number

    /**
     * 
     * @type {TechnicalConclusionsCreateMissingPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost
     */
    readonly technicalConclusionsCreateMissingPayload?: TechnicalConclusionsCreateMissingPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPutRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateRepairListDevicesPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut
     */
    readonly updateRepairListDevicesPayload?: UpdateRepairListDevicesPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGetRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost
     */
    readonly firmId: number

    /**
     * 
     * @type {RepairListLoadDefectsPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost
     */
    readonly repairListLoadDefectsPayload?: RepairListLoadDefectsPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPutRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateRepairListPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut
     */
    readonly updateRepairListPayload?: UpdateRepairListPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPutRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateInvoiceDeclarationPayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut
     */
    readonly updateInvoiceDeclarationPayload?: UpdateInvoiceDeclarationPayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePutRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateInvoiceReceiveDatePayload}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut
     */
    readonly updateInvoiceReceiveDatePayload?: UpdateInvoiceReceiveDatePayload
}

/**
 * Request parameters for apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost
     */
    readonly invoiceId: number

    /**
     * 
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesGlobalFilterCounterpartiesGet operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesGlobalFilterCounterpartiesGetRequest
 */
export interface OpInvoicesApiApiOpInvoicesGlobalFilterCounterpartiesGetRequest {
    /**
     * Дата начала периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpInvoicesApiApiOpInvoicesGlobalFilterCounterpartiesGet
     */
    readonly dateFrom: string

    /**
     * Дата конца периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpInvoicesApiApiOpInvoicesGlobalFilterCounterpartiesGet
     */
    readonly dateTo: string

    /**
     * Выбранное Предприятие
     * @type {number}
     * @memberof OpInvoicesApiApiOpInvoicesGlobalFilterCounterpartiesGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpInvoicesPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesPostRequest {
    /**
     * 
     * @type {CreateInvoicePayload}
     * @memberof OpInvoicesApiApiOpInvoicesPost
     */
    readonly createInvoicePayload?: CreateInvoicePayload
}

/**
 * Request parameters for apiOpInvoicesSendPost operation in OpInvoicesApi.
 * @export
 * @interface OpInvoicesApiApiOpInvoicesSendPostRequest
 */
export interface OpInvoicesApiApiOpInvoicesSendPostRequest {
    /**
     * 
     * @type {SendInvoicePayload}
     * @memberof OpInvoicesApiApiOpInvoicesSendPost
     */
    readonly sendInvoicePayload?: SendInvoicePayload
}

/**
 * OpInvoicesApi - object-oriented interface
 * @export
 * @class OpInvoicesApi
 * @extends {BaseAPI}
 */
export class OpInvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Журнал инвойсов - получить запись
     * @param {OpInvoicesApiApiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet(requestParameters: OpInvoicesApiApiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGetRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesBrowseDocInvoiceIdFirmFirmIdGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал инвойсов
     * @param {OpInvoicesApiApiOpInvoicesBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesBrowseGet(requestParameters: OpInvoicesApiApiOpInvoicesBrowseGetRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesBrowseGet(requestParameters.firmId, requestParameters.periodStart, requestParameters.periodEnd, requestParameters.counterpartyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список стран происхождения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesCountryOriginsGet(options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesCountryOriginsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список приборов для добавления в Исходящий Счет
     * @param {OpInvoicesApiApiOpInvoicesDevicesToSendGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDevicesToSendGet(requestParameters: OpInvoicesApiApiOpInvoicesDevicesToSendGetRequest = {}, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDevicesToSendGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подбор лицензий
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdAssignLicensesPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Номера коробок приборов
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGetRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdBoxNumbersGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтвердить приборы в документе
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdConfirmPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить документ
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDelete(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDeleteRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDelete(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтвердить выбранные приборы
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesConfirmDevicesPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.confirmInvoiceDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить устройства из счета
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDeleteRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesDelete(requestParameters.invoiceId, requestParameters.firmId, requestParameters.deleteInvoiceDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список устройств счета
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGetRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить приборы вручную
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.addInvoiceDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отменить выбранные приборы
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesRejectDevicesPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.rejectInvoiceDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить коробку прибора по коду СЕ
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceBoxPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoiceDeviceBoxPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновление Страны происхождения у приборов
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDeviceCountryOriginPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateDeviceCountryOriginsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить цену прибора по коду СЕ
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdateDevicePricePost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoiceDevicePricePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить цену приборов из прайслистов
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdDevicesUpdatePricesFromPricelistsPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить сопроводительный документ в Excel
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentExcelPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить сопроводительный документ в PDF
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdExportAccompanyingDocumentPdfPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить счет исходящего инвойса в Excel
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingExcelPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить счет исходящего инвойса в Pdf
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdExportOutgoingPdfPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение данныx для печатной формы счета
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGetRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение данныx для печатной формы счета
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdForPrintPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoicePrintDataPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Упаковочный лист на печать
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdPackingListPdfPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить документ
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdPut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdPutRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdPut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отменить приборы в документе
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRejectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRejectPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdRejectPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание ТЗ для приборов, в которых оно отстутствует
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListCreateMissingTechnicalConclusionsPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.technicalConclusionsCreateMissingPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить приборы дефектной ведомости
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPutRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListDevicesPut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateRepairListDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить дефектную ведомость в Excel
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportExcelPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить дефектную ведомость в Pdf
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListExportPdfPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить дефектную ведомость 
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGetRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListGet(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка дефектов для дефектной ведомости
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListLoadDefectsPost(requestParameters.invoiceId, requestParameters.firmId, requestParameters.repairListLoadDefectsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить свойства дефектной ведомости
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPutRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdRepairListPut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateRepairListPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправка замененных запчастей в Сбыт
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdSendReplacedPartsToSbytPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправка в Сбыт (стоимости ремонта и упаковки)
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdSendToSbytPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить декларацию
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPutRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdSetDeclarationPut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoiceDeclarationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить дату прихода
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePutRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdSetReceiveDatePut(requestParameters.invoiceId, requestParameters.firmId, requestParameters.updateInvoiceReceiveDatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Списание Лицензий (Внешний СЦ)
     * @param {OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost(requestParameters: OpInvoicesApiApiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPostRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesDocInvoiceIdFirmFirmIdUseLicensesPost(requestParameters.invoiceId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список контрагентов за период
     * @param {OpInvoicesApiApiOpInvoicesGlobalFilterCounterpartiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesGlobalFilterCounterpartiesGet(requestParameters: OpInvoicesApiApiOpInvoicesGlobalFilterCounterpartiesGetRequest, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesGlobalFilterCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать документ
     * @param {OpInvoicesApiApiOpInvoicesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesPost(requestParameters: OpInvoicesApiApiOpInvoicesPostRequest = {}, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesPost(requestParameters.createInvoicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить
     * @param {OpInvoicesApiApiOpInvoicesSendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpInvoicesApi
     */
    public apiOpInvoicesSendPost(requestParameters: OpInvoicesApiApiOpInvoicesSendPostRequest = {}, options?: AxiosRequestConfig) {
        return OpInvoicesApiFp(this.configuration).apiOpInvoicesSendPost(requestParameters.sendInvoicePayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpMaintenanceApi - axios parameter creator
 * @export
 */
export const OpMaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал приборов, требующих согласования платного ремонта
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseApprovalRequiredGet: async (firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-approval-required`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал приборов, требующих внесения дефектов
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseDefectsRequiredGet: async (firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-defects-required`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить 1 прибор для замены в browse после закрытия карточки прибора
         * @param {number} [firmId] Ключ компании
         * @param {boolean} [includeRepairAreas] Включить участки ремонта
         * @param {number} [deviceId] Ключ прибора - прибор
         * @param {number} [deviceFirmId] Ключ прибора - фирма
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseDeviceGet: async (firmId?: number, includeRepairAreas?: boolean, deviceId?: number, deviceFirmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (includeRepairAreas !== undefined) {
                localVarQueryParameter['IncludeRepairAreas'] = includeRepairAreas;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['DeviceId'] = deviceId;
            }

            if (deviceFirmId !== undefined) {
                localVarQueryParameter['DeviceFirmId'] = deviceFirmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал приборов, с просроченным планом ремонта
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseExpiredGet: async (firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-expired`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал приборов
         * @param {number} [firmId] Ключ компании
         * @param {boolean} [includeRepairAreas] Включить участки ремонта
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseGet: async (firmId?: number, includeRepairAreas?: boolean, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (includeRepairAreas !== undefined) {
                localVarQueryParameter['IncludeRepairAreas'] = includeRepairAreas;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал приборов, с приборами согласованными на участок
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowsePlanRepairAgreedGet: async (firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-plan-repair-agreed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал приборов, стоимость ремонта подтверждена
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseRepairCostAgreedGet: async (firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-repair-cost-agreed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал приборов, негарантийные без стоимости ремонта
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseRepairCostRequiredGet: async (firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-repair-cost-required`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал приборов со статусом замена на новый
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseReplacementForNewGet: async (firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-replacement-for-new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал приборов с запрошенным уточнение
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseRequestConfirmationGet: async (firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-request-confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал приборов, план ремонта согласован - требуется передача на ремонт
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseSendToRepairRequiredGet: async (firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/browse-send-to-repair-required`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отменить ремонт
         * @param {ClearRepairDataPayload} [clearRepairDataPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceClearRepairDataPost: async (clearRepairDataPayload?: ClearRepairDataPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/clear-repair-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clearRepairDataPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтвердить приход
         * @param {ConfirmReceiptPayload} [confirmReceiptPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceConfirmReceiptPost: async (confirmReceiptPayload?: ConfirmReceiptPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/confirm-receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmReceiptPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подготовить отгрузку
         * @param {CreateOutgoingInvoicePayload} [createOutgoingInvoicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceCreateOutgoingInvoicePost: async (createOutgoingInvoicePayload?: CreateOutgoingInvoicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/create-outgoing-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOutgoingInvoicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Печать распоряжение в PDF
         * @param {ExportDisposalToPdfPayload} [exportDisposalToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceExportDisposalPdfPost: async (exportDisposalToPdfPayload?: ExportDisposalToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/export-disposal-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportDisposalToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузка приборов в Excel
         * @param {ExportMaintenanceToExcelPayload} [exportMaintenanceToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceExportMaintenanceExcelPost: async (exportMaintenanceToExcelPayload?: ExportMaintenanceToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/export-maintenance-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportMaintenanceToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список открытых отгрузок
         * @param {number} [firmId] Ид фирмы
         * @param {number} [counterpartyId] Ид контрагента
         * @param {boolean} [shipAsRma] Отгружать согласно RMA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceOpenedOutgoingInvoicesGet: async (firmId?: number, counterpartyId?: number, shipAsRma?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/opened-outgoing-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (counterpartyId !== undefined) {
                localVarQueryParameter['CounterpartyId'] = counterpartyId;
            }

            if (shipAsRma !== undefined) {
                localVarQueryParameter['ShipAsRma'] = shipAsRma;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Заменить на новый
         * @param {ReplaceToNewPayload} [replaceToNewPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceReplaceToNewPost: async (replaceToNewPayload?: ReplaceToNewPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/replace-to-new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceToNewPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Вернуть без ремонта
         * @param {ReturnWithoutRepairPayload} [returnWithoutRepairPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceReturnWithoutRepairPost: async (returnWithoutRepairPayload?: ReturnWithoutRepairPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/return-without-repair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnWithoutRepairPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Передать на внешний Сервисный Центр
         * @param {SendToExternalPayload} [sendToExternalPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceSendToExternalPost: async (sendToExternalPayload?: SendToExternalPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/send-to-external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendToExternalPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запланировать
         * @param {SendToRepairPlanPayload} [sendToRepairPlanPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceSendToPlanPost: async (sendToRepairPlanPayload?: SendToRepairPlanPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/send-to-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendToRepairPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Вернуть в резервный фонд
         * @param {ReturnToReplacementAreaPayload} [returnToReplacementAreaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceSendToRaPost: async (returnToReplacementAreaPayload?: ReturnToReplacementAreaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/send-to-ra`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnToReplacementAreaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Передать на участок ремонта (ОТК)
         * @param {SendToRepairPayload} [sendToRepairPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceSendToRepairPost: async (sendToRepairPayload?: SendToRepairPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/send-to-repair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendToRepairPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Акт передачи на печать
         * @param {ExportTransferCertificateToPdfPayload} [exportTransferCertificateToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceTransferCertificatePdfPost: async (exportTransferCertificateToPdfPayload?: ExportTransferCertificateToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-maintenance/transfer-certificate-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportTransferCertificateToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpMaintenanceApi - functional programming interface
 * @export
 */
export const OpMaintenanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpMaintenanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал приборов, требующих согласования платного ремонта
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseApprovalRequiredGet(firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseApprovalRequiredGet(firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал приборов, требующих внесения дефектов
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseDefectsRequiredGet(firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseDefectsRequiredGet(firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить 1 прибор для замены в browse после закрытия карточки прибора
         * @param {number} [firmId] Ключ компании
         * @param {boolean} [includeRepairAreas] Включить участки ремонта
         * @param {number} [deviceId] Ключ прибора - прибор
         * @param {number} [deviceFirmId] Ключ прибора - фирма
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseDeviceGet(firmId?: number, includeRepairAreas?: boolean, deviceId?: number, deviceFirmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceDeviceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseDeviceGet(firmId, includeRepairAreas, deviceId, deviceFirmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал приборов, с просроченным планом ремонта
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseExpiredGet(firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseExpiredGet(firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал приборов
         * @param {number} [firmId] Ключ компании
         * @param {boolean} [includeRepairAreas] Включить участки ремонта
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseGet(firmId?: number, includeRepairAreas?: boolean, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseGet(firmId, includeRepairAreas, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал приборов, с приборами согласованными на участок
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowsePlanRepairAgreedGet(firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowsePlanRepairAgreedGet(firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал приборов, стоимость ремонта подтверждена
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseRepairCostAgreedGet(firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseRepairCostAgreedGet(firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал приборов, негарантийные без стоимости ремонта
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseRepairCostRequiredGet(firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseRepairCostRequiredGet(firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал приборов со статусом замена на новый
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseReplacementForNewGet(firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseReplacementForNewGet(firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал приборов с запрошенным уточнение
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseRequestConfirmationGet(firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseRequestConfirmationGet(firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал приборов, план ремонта согласован - требуется передача на ремонт
         * @param {number} [firmId] Ключ компании
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceBrowseSendToRepairRequiredGet(firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceBrowseSendToRepairRequiredGet(firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отменить ремонт
         * @param {ClearRepairDataPayload} [clearRepairDataPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceClearRepairDataPost(clearRepairDataPayload?: ClearRepairDataPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceClearRepairDataPost(clearRepairDataPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтвердить приход
         * @param {ConfirmReceiptPayload} [confirmReceiptPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceConfirmReceiptPost(confirmReceiptPayload?: ConfirmReceiptPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceConfirmReceiptPost(confirmReceiptPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подготовить отгрузку
         * @param {CreateOutgoingInvoicePayload} [createOutgoingInvoicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceCreateOutgoingInvoicePost(createOutgoingInvoicePayload?: CreateOutgoingInvoicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOutgoingInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceCreateOutgoingInvoicePost(createOutgoingInvoicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Печать распоряжение в PDF
         * @param {ExportDisposalToPdfPayload} [exportDisposalToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceExportDisposalPdfPost(exportDisposalToPdfPayload?: ExportDisposalToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceExportDisposalPdfPost(exportDisposalToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузка приборов в Excel
         * @param {ExportMaintenanceToExcelPayload} [exportMaintenanceToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceExportMaintenanceExcelPost(exportMaintenanceToExcelPayload?: ExportMaintenanceToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceExportMaintenanceExcelPost(exportMaintenanceToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список открытых отгрузок
         * @param {number} [firmId] Ид фирмы
         * @param {number} [counterpartyId] Ид контрагента
         * @param {boolean} [shipAsRma] Отгружать согласно RMA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceOpenedOutgoingInvoicesGet(firmId?: number, counterpartyId?: number, shipAsRma?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceOutgoingInvoiceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceOpenedOutgoingInvoicesGet(firmId, counterpartyId, shipAsRma, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Заменить на новый
         * @param {ReplaceToNewPayload} [replaceToNewPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceReplaceToNewPost(replaceToNewPayload?: ReplaceToNewPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceReplaceToNewPost(replaceToNewPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Вернуть без ремонта
         * @param {ReturnWithoutRepairPayload} [returnWithoutRepairPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceReturnWithoutRepairPost(returnWithoutRepairPayload?: ReturnWithoutRepairPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceReturnWithoutRepairPost(returnWithoutRepairPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Передать на внешний Сервисный Центр
         * @param {SendToExternalPayload} [sendToExternalPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceSendToExternalPost(sendToExternalPayload?: SendToExternalPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendToExternalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceSendToExternalPost(sendToExternalPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запланировать
         * @param {SendToRepairPlanPayload} [sendToRepairPlanPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceSendToPlanPost(sendToRepairPlanPayload?: SendToRepairPlanPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceSendToPlanPost(sendToRepairPlanPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Вернуть в резервный фонд
         * @param {ReturnToReplacementAreaPayload} [returnToReplacementAreaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceSendToRaPost(returnToReplacementAreaPayload?: ReturnToReplacementAreaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceSendToRaPost(returnToReplacementAreaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Передать на участок ремонта (ОТК)
         * @param {SendToRepairPayload} [sendToRepairPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceSendToRepairPost(sendToRepairPayload?: SendToRepairPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceSendToRepairPost(sendToRepairPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Акт передачи на печать
         * @param {ExportTransferCertificateToPdfPayload} [exportTransferCertificateToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpMaintenanceTransferCertificatePdfPost(exportTransferCertificateToPdfPayload?: ExportTransferCertificateToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpMaintenanceTransferCertificatePdfPost(exportTransferCertificateToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpMaintenanceApi - factory interface
 * @export
 */
export const OpMaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpMaintenanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал приборов, требующих согласования платного ремонта
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseApprovalRequiredGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseApprovalRequiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseApprovalRequiredGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowseApprovalRequiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал приборов, требующих внесения дефектов
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseDefectsRequiredGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseDefectsRequiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseDefectsRequiredGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowseDefectsRequiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить 1 прибор для замены в browse после закрытия карточки прибора
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseDeviceGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseDeviceGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseDeviceGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MaintenanceDeviceDto> {
            return localVarFp.apiOpMaintenanceBrowseDeviceGet(requestParameters.firmId, requestParameters.includeRepairAreas, requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал приборов, с просроченным планом ремонта
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseExpiredGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseExpiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseExpiredGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowseExpiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал приборов
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowseGet(requestParameters.firmId, requestParameters.includeRepairAreas, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал приборов, с приборами согласованными на участок
         * @param {OpMaintenanceApiApiOpMaintenanceBrowsePlanRepairAgreedGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowsePlanRepairAgreedGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowsePlanRepairAgreedGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowsePlanRepairAgreedGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал приборов, стоимость ремонта подтверждена
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseRepairCostAgreedGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseRepairCostAgreedGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseRepairCostAgreedGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowseRepairCostAgreedGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал приборов, негарантийные без стоимости ремонта
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseRepairCostRequiredGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseRepairCostRequiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseRepairCostRequiredGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowseRepairCostRequiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал приборов со статусом замена на новый
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseReplacementForNewGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseReplacementForNewGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseReplacementForNewGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowseReplacementForNewGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал приборов с запрошенным уточнение
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseRequestConfirmationGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseRequestConfirmationGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseRequestConfirmationGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowseRequestConfirmationGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал приборов, план ремонта согласован - требуется передача на ремонт
         * @param {OpMaintenanceApiApiOpMaintenanceBrowseSendToRepairRequiredGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceBrowseSendToRepairRequiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseSendToRepairRequiredGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceDeviceDto>> {
            return localVarFp.apiOpMaintenanceBrowseSendToRepairRequiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отменить ремонт
         * @param {OpMaintenanceApiApiOpMaintenanceClearRepairDataPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceClearRepairDataPost(requestParameters: OpMaintenanceApiApiOpMaintenanceClearRepairDataPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceClearRepairDataPost(requestParameters.clearRepairDataPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтвердить приход
         * @param {OpMaintenanceApiApiOpMaintenanceConfirmReceiptPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceConfirmReceiptPost(requestParameters: OpMaintenanceApiApiOpMaintenanceConfirmReceiptPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceConfirmReceiptPost(requestParameters.confirmReceiptPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подготовить отгрузку
         * @param {OpMaintenanceApiApiOpMaintenanceCreateOutgoingInvoicePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceCreateOutgoingInvoicePost(requestParameters: OpMaintenanceApiApiOpMaintenanceCreateOutgoingInvoicePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateOutgoingInvoiceResponse> {
            return localVarFp.apiOpMaintenanceCreateOutgoingInvoicePost(requestParameters.createOutgoingInvoicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Печать распоряжение в PDF
         * @param {OpMaintenanceApiApiOpMaintenanceExportDisposalPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceExportDisposalPdfPost(requestParameters: OpMaintenanceApiApiOpMaintenanceExportDisposalPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceExportDisposalPdfPost(requestParameters.exportDisposalToPdfPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузка приборов в Excel
         * @param {OpMaintenanceApiApiOpMaintenanceExportMaintenanceExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceExportMaintenanceExcelPost(requestParameters: OpMaintenanceApiApiOpMaintenanceExportMaintenanceExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceExportMaintenanceExcelPost(requestParameters.exportMaintenanceToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список открытых отгрузок
         * @param {OpMaintenanceApiApiOpMaintenanceOpenedOutgoingInvoicesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceOpenedOutgoingInvoicesGet(requestParameters: OpMaintenanceApiApiOpMaintenanceOpenedOutgoingInvoicesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MaintenanceOutgoingInvoiceDto>> {
            return localVarFp.apiOpMaintenanceOpenedOutgoingInvoicesGet(requestParameters.firmId, requestParameters.counterpartyId, requestParameters.shipAsRma, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Заменить на новый
         * @param {OpMaintenanceApiApiOpMaintenanceReplaceToNewPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceReplaceToNewPost(requestParameters: OpMaintenanceApiApiOpMaintenanceReplaceToNewPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceReplaceToNewPost(requestParameters.replaceToNewPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Вернуть без ремонта
         * @param {OpMaintenanceApiApiOpMaintenanceReturnWithoutRepairPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceReturnWithoutRepairPost(requestParameters: OpMaintenanceApiApiOpMaintenanceReturnWithoutRepairPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceReturnWithoutRepairPost(requestParameters.returnWithoutRepairPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Передать на внешний Сервисный Центр
         * @param {OpMaintenanceApiApiOpMaintenanceSendToExternalPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceSendToExternalPost(requestParameters: OpMaintenanceApiApiOpMaintenanceSendToExternalPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SendToExternalResponse> {
            return localVarFp.apiOpMaintenanceSendToExternalPost(requestParameters.sendToExternalPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запланировать
         * @param {OpMaintenanceApiApiOpMaintenanceSendToPlanPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceSendToPlanPost(requestParameters: OpMaintenanceApiApiOpMaintenanceSendToPlanPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceSendToPlanPost(requestParameters.sendToRepairPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Вернуть в резервный фонд
         * @param {OpMaintenanceApiApiOpMaintenanceSendToRaPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceSendToRaPost(requestParameters: OpMaintenanceApiApiOpMaintenanceSendToRaPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceSendToRaPost(requestParameters.returnToReplacementAreaPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Передать на участок ремонта (ОТК)
         * @param {OpMaintenanceApiApiOpMaintenanceSendToRepairPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceSendToRepairPost(requestParameters: OpMaintenanceApiApiOpMaintenanceSendToRepairPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceSendToRepairPost(requestParameters.sendToRepairPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Акт передачи на печать
         * @param {OpMaintenanceApiApiOpMaintenanceTransferCertificatePdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpMaintenanceTransferCertificatePdfPost(requestParameters: OpMaintenanceApiApiOpMaintenanceTransferCertificatePdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpMaintenanceTransferCertificatePdfPost(requestParameters.exportTransferCertificateToPdfPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpMaintenanceBrowseApprovalRequiredGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseApprovalRequiredGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseApprovalRequiredGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseApprovalRequiredGet
     */
    readonly firmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseApprovalRequiredGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowseDefectsRequiredGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseDefectsRequiredGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseDefectsRequiredGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseDefectsRequiredGet
     */
    readonly firmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseDefectsRequiredGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowseDeviceGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseDeviceGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseDeviceGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseDeviceGet
     */
    readonly firmId?: number

    /**
     * Включить участки ремонта
     * @type {boolean}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseDeviceGet
     */
    readonly includeRepairAreas?: boolean

    /**
     * Ключ прибора - прибор
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseDeviceGet
     */
    readonly deviceId?: number

    /**
     * Ключ прибора - фирма
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseDeviceGet
     */
    readonly deviceFirmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseDeviceGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowseExpiredGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseExpiredGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseExpiredGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseExpiredGet
     */
    readonly firmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseExpiredGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowseGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseGet
     */
    readonly firmId?: number

    /**
     * Включить участки ремонта
     * @type {boolean}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseGet
     */
    readonly includeRepairAreas?: boolean

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowsePlanRepairAgreedGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowsePlanRepairAgreedGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowsePlanRepairAgreedGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowsePlanRepairAgreedGet
     */
    readonly firmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowsePlanRepairAgreedGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowseRepairCostAgreedGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseRepairCostAgreedGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseRepairCostAgreedGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseRepairCostAgreedGet
     */
    readonly firmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseRepairCostAgreedGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowseRepairCostRequiredGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseRepairCostRequiredGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseRepairCostRequiredGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseRepairCostRequiredGet
     */
    readonly firmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseRepairCostRequiredGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowseReplacementForNewGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseReplacementForNewGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseReplacementForNewGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseReplacementForNewGet
     */
    readonly firmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseReplacementForNewGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowseRequestConfirmationGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseRequestConfirmationGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseRequestConfirmationGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseRequestConfirmationGet
     */
    readonly firmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseRequestConfirmationGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceBrowseSendToRepairRequiredGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceBrowseSendToRepairRequiredGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceBrowseSendToRepairRequiredGetRequest {
    /**
     * Ключ компании
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseSendToRepairRequiredGet
     */
    readonly firmId?: number

    /**
     * Язык
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceBrowseSendToRepairRequiredGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpMaintenanceClearRepairDataPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceClearRepairDataPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceClearRepairDataPostRequest {
    /**
     * 
     * @type {ClearRepairDataPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceClearRepairDataPost
     */
    readonly clearRepairDataPayload?: ClearRepairDataPayload
}

/**
 * Request parameters for apiOpMaintenanceConfirmReceiptPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceConfirmReceiptPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceConfirmReceiptPostRequest {
    /**
     * 
     * @type {ConfirmReceiptPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceConfirmReceiptPost
     */
    readonly confirmReceiptPayload?: ConfirmReceiptPayload
}

/**
 * Request parameters for apiOpMaintenanceCreateOutgoingInvoicePost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceCreateOutgoingInvoicePostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceCreateOutgoingInvoicePostRequest {
    /**
     * 
     * @type {CreateOutgoingInvoicePayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceCreateOutgoingInvoicePost
     */
    readonly createOutgoingInvoicePayload?: CreateOutgoingInvoicePayload
}

/**
 * Request parameters for apiOpMaintenanceExportDisposalPdfPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceExportDisposalPdfPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceExportDisposalPdfPostRequest {
    /**
     * 
     * @type {ExportDisposalToPdfPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceExportDisposalPdfPost
     */
    readonly exportDisposalToPdfPayload?: ExportDisposalToPdfPayload
}

/**
 * Request parameters for apiOpMaintenanceExportMaintenanceExcelPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceExportMaintenanceExcelPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceExportMaintenanceExcelPostRequest {
    /**
     * 
     * @type {ExportMaintenanceToExcelPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceExportMaintenanceExcelPost
     */
    readonly exportMaintenanceToExcelPayload?: ExportMaintenanceToExcelPayload
}

/**
 * Request parameters for apiOpMaintenanceOpenedOutgoingInvoicesGet operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceOpenedOutgoingInvoicesGetRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceOpenedOutgoingInvoicesGetRequest {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceOpenedOutgoingInvoicesGet
     */
    readonly firmId?: number

    /**
     * Ид контрагента
     * @type {number}
     * @memberof OpMaintenanceApiApiOpMaintenanceOpenedOutgoingInvoicesGet
     */
    readonly counterpartyId?: number

    /**
     * Отгружать согласно RMA
     * @type {boolean}
     * @memberof OpMaintenanceApiApiOpMaintenanceOpenedOutgoingInvoicesGet
     */
    readonly shipAsRma?: boolean
}

/**
 * Request parameters for apiOpMaintenanceReplaceToNewPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceReplaceToNewPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceReplaceToNewPostRequest {
    /**
     * 
     * @type {ReplaceToNewPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceReplaceToNewPost
     */
    readonly replaceToNewPayload?: ReplaceToNewPayload
}

/**
 * Request parameters for apiOpMaintenanceReturnWithoutRepairPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceReturnWithoutRepairPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceReturnWithoutRepairPostRequest {
    /**
     * 
     * @type {ReturnWithoutRepairPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceReturnWithoutRepairPost
     */
    readonly returnWithoutRepairPayload?: ReturnWithoutRepairPayload
}

/**
 * Request parameters for apiOpMaintenanceSendToExternalPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceSendToExternalPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceSendToExternalPostRequest {
    /**
     * 
     * @type {SendToExternalPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceSendToExternalPost
     */
    readonly sendToExternalPayload?: SendToExternalPayload
}

/**
 * Request parameters for apiOpMaintenanceSendToPlanPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceSendToPlanPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceSendToPlanPostRequest {
    /**
     * 
     * @type {SendToRepairPlanPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceSendToPlanPost
     */
    readonly sendToRepairPlanPayload?: SendToRepairPlanPayload
}

/**
 * Request parameters for apiOpMaintenanceSendToRaPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceSendToRaPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceSendToRaPostRequest {
    /**
     * 
     * @type {ReturnToReplacementAreaPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceSendToRaPost
     */
    readonly returnToReplacementAreaPayload?: ReturnToReplacementAreaPayload
}

/**
 * Request parameters for apiOpMaintenanceSendToRepairPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceSendToRepairPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceSendToRepairPostRequest {
    /**
     * 
     * @type {SendToRepairPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceSendToRepairPost
     */
    readonly sendToRepairPayload?: SendToRepairPayload
}

/**
 * Request parameters for apiOpMaintenanceTransferCertificatePdfPost operation in OpMaintenanceApi.
 * @export
 * @interface OpMaintenanceApiApiOpMaintenanceTransferCertificatePdfPostRequest
 */
export interface OpMaintenanceApiApiOpMaintenanceTransferCertificatePdfPostRequest {
    /**
     * 
     * @type {ExportTransferCertificateToPdfPayload}
     * @memberof OpMaintenanceApiApiOpMaintenanceTransferCertificatePdfPost
     */
    readonly exportTransferCertificateToPdfPayload?: ExportTransferCertificateToPdfPayload
}

/**
 * OpMaintenanceApi - object-oriented interface
 * @export
 * @class OpMaintenanceApi
 * @extends {BaseAPI}
 */
export class OpMaintenanceApi extends BaseAPI {
    /**
     * 
     * @summary Журнал приборов, требующих согласования платного ремонта
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseApprovalRequiredGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseApprovalRequiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseApprovalRequiredGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseApprovalRequiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал приборов, требующих внесения дефектов
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseDefectsRequiredGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseDefectsRequiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseDefectsRequiredGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseDefectsRequiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить 1 прибор для замены в browse после закрытия карточки прибора
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseDeviceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseDeviceGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseDeviceGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseDeviceGet(requestParameters.firmId, requestParameters.includeRepairAreas, requestParameters.deviceId, requestParameters.deviceFirmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал приборов, с просроченным планом ремонта
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseExpiredGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseExpiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseExpiredGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseExpiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал приборов
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseGet(requestParameters.firmId, requestParameters.includeRepairAreas, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал приборов, с приборами согласованными на участок
     * @param {OpMaintenanceApiApiOpMaintenanceBrowsePlanRepairAgreedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowsePlanRepairAgreedGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowsePlanRepairAgreedGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowsePlanRepairAgreedGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал приборов, стоимость ремонта подтверждена
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseRepairCostAgreedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseRepairCostAgreedGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseRepairCostAgreedGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseRepairCostAgreedGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал приборов, негарантийные без стоимости ремонта
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseRepairCostRequiredGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseRepairCostRequiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseRepairCostRequiredGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseRepairCostRequiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал приборов со статусом замена на новый
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseReplacementForNewGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseReplacementForNewGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseReplacementForNewGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseReplacementForNewGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал приборов с запрошенным уточнение
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseRequestConfirmationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseRequestConfirmationGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseRequestConfirmationGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseRequestConfirmationGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал приборов, план ремонта согласован - требуется передача на ремонт
     * @param {OpMaintenanceApiApiOpMaintenanceBrowseSendToRepairRequiredGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceBrowseSendToRepairRequiredGet(requestParameters: OpMaintenanceApiApiOpMaintenanceBrowseSendToRepairRequiredGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceBrowseSendToRepairRequiredGet(requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отменить ремонт
     * @param {OpMaintenanceApiApiOpMaintenanceClearRepairDataPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceClearRepairDataPost(requestParameters: OpMaintenanceApiApiOpMaintenanceClearRepairDataPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceClearRepairDataPost(requestParameters.clearRepairDataPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтвердить приход
     * @param {OpMaintenanceApiApiOpMaintenanceConfirmReceiptPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceConfirmReceiptPost(requestParameters: OpMaintenanceApiApiOpMaintenanceConfirmReceiptPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceConfirmReceiptPost(requestParameters.confirmReceiptPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подготовить отгрузку
     * @param {OpMaintenanceApiApiOpMaintenanceCreateOutgoingInvoicePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceCreateOutgoingInvoicePost(requestParameters: OpMaintenanceApiApiOpMaintenanceCreateOutgoingInvoicePostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceCreateOutgoingInvoicePost(requestParameters.createOutgoingInvoicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Печать распоряжение в PDF
     * @param {OpMaintenanceApiApiOpMaintenanceExportDisposalPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceExportDisposalPdfPost(requestParameters: OpMaintenanceApiApiOpMaintenanceExportDisposalPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceExportDisposalPdfPost(requestParameters.exportDisposalToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузка приборов в Excel
     * @param {OpMaintenanceApiApiOpMaintenanceExportMaintenanceExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceExportMaintenanceExcelPost(requestParameters: OpMaintenanceApiApiOpMaintenanceExportMaintenanceExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceExportMaintenanceExcelPost(requestParameters.exportMaintenanceToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список открытых отгрузок
     * @param {OpMaintenanceApiApiOpMaintenanceOpenedOutgoingInvoicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceOpenedOutgoingInvoicesGet(requestParameters: OpMaintenanceApiApiOpMaintenanceOpenedOutgoingInvoicesGetRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceOpenedOutgoingInvoicesGet(requestParameters.firmId, requestParameters.counterpartyId, requestParameters.shipAsRma, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Заменить на новый
     * @param {OpMaintenanceApiApiOpMaintenanceReplaceToNewPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceReplaceToNewPost(requestParameters: OpMaintenanceApiApiOpMaintenanceReplaceToNewPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceReplaceToNewPost(requestParameters.replaceToNewPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Вернуть без ремонта
     * @param {OpMaintenanceApiApiOpMaintenanceReturnWithoutRepairPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceReturnWithoutRepairPost(requestParameters: OpMaintenanceApiApiOpMaintenanceReturnWithoutRepairPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceReturnWithoutRepairPost(requestParameters.returnWithoutRepairPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Передать на внешний Сервисный Центр
     * @param {OpMaintenanceApiApiOpMaintenanceSendToExternalPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceSendToExternalPost(requestParameters: OpMaintenanceApiApiOpMaintenanceSendToExternalPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceSendToExternalPost(requestParameters.sendToExternalPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запланировать
     * @param {OpMaintenanceApiApiOpMaintenanceSendToPlanPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceSendToPlanPost(requestParameters: OpMaintenanceApiApiOpMaintenanceSendToPlanPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceSendToPlanPost(requestParameters.sendToRepairPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Вернуть в резервный фонд
     * @param {OpMaintenanceApiApiOpMaintenanceSendToRaPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceSendToRaPost(requestParameters: OpMaintenanceApiApiOpMaintenanceSendToRaPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceSendToRaPost(requestParameters.returnToReplacementAreaPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Передать на участок ремонта (ОТК)
     * @param {OpMaintenanceApiApiOpMaintenanceSendToRepairPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceSendToRepairPost(requestParameters: OpMaintenanceApiApiOpMaintenanceSendToRepairPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceSendToRepairPost(requestParameters.sendToRepairPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Акт передачи на печать
     * @param {OpMaintenanceApiApiOpMaintenanceTransferCertificatePdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpMaintenanceApi
     */
    public apiOpMaintenanceTransferCertificatePdfPost(requestParameters: OpMaintenanceApiApiOpMaintenanceTransferCertificatePdfPostRequest = {}, options?: AxiosRequestConfig) {
        return OpMaintenanceApiFp(this.configuration).apiOpMaintenanceTransferCertificatePdfPost(requestParameters.exportTransferCertificateToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpRepairAreasApi - axios parameter creator
 * @export
 */
export const OpRepairAreasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать акт передачи
         * @param {GetRepairAreaActOfTransferPayload} [getRepairAreaActOfTransferPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasActOfTransferPost: async (getRepairAreaActOfTransferPayload?: GetRepairAreaActOfTransferPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-areas/act-of-transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getRepairAreaActOfTransferPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал участка ремонта
         * @param {number} [firmId] Id компании
         * @param {string} [skl] Номер склада
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasBrowseGet: async (firmId?: number, skl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-areas/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (skl !== undefined) {
                localVarQueryParameter['Skl'] = skl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузка участков ремонта в Excel
         * @param {RepairAreasExportToExcelPayload} [repairAreasExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasExportExcelPost: async (repairAreasExportToExcelPayload?: RepairAreasExportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-areas/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairAreasExportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список участков ремонта
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasGlobalFilterRepairAreasGet: async (firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-areas/global-filter-repair-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Починить
         * @param {FixRepairAreaDevicePayload} [fixRepairAreaDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasRepairPost: async (fixRepairAreaDevicePayload?: FixRepairAreaDevicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-areas/repair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fixRepairAreaDevicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Вернуть
         * @param {ReturnRepairAreaDevicePayload} [returnRepairAreaDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasReturnPost: async (returnRepairAreaDevicePayload?: ReturnRepairAreaDevicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-areas/return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnRepairAreaDevicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отменить ремонт
         * @param {UnFixRepairAreaDevicePayload} [unFixRepairAreaDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasUnrepairPost: async (unFixRepairAreaDevicePayload?: UnFixRepairAreaDevicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-areas/unrepair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unFixRepairAreaDevicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpRepairAreasApi - functional programming interface
 * @export
 */
export const OpRepairAreasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpRepairAreasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать акт передачи
         * @param {GetRepairAreaActOfTransferPayload} [getRepairAreaActOfTransferPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairAreasActOfTransferPost(getRepairAreaActOfTransferPayload?: GetRepairAreaActOfTransferPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairAreasActOfTransferPost(getRepairAreaActOfTransferPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал участка ремонта
         * @param {number} [firmId] Id компании
         * @param {string} [skl] Номер склада
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairAreasBrowseGet(firmId?: number, skl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairAreaDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairAreasBrowseGet(firmId, skl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузка участков ремонта в Excel
         * @param {RepairAreasExportToExcelPayload} [repairAreasExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairAreasExportExcelPost(repairAreasExportToExcelPayload?: RepairAreasExportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairAreasExportExcelPost(repairAreasExportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список участков ремонта
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairAreasGlobalFilterRepairAreasGet(firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairAreaDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairAreasGlobalFilterRepairAreasGet(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Починить
         * @param {FixRepairAreaDevicePayload} [fixRepairAreaDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairAreasRepairPost(fixRepairAreaDevicePayload?: FixRepairAreaDevicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairAreasRepairPost(fixRepairAreaDevicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Вернуть
         * @param {ReturnRepairAreaDevicePayload} [returnRepairAreaDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairAreasReturnPost(returnRepairAreaDevicePayload?: ReturnRepairAreaDevicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairAreasReturnPost(returnRepairAreaDevicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отменить ремонт
         * @param {UnFixRepairAreaDevicePayload} [unFixRepairAreaDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairAreasUnrepairPost(unFixRepairAreaDevicePayload?: UnFixRepairAreaDevicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairAreasUnrepairPost(unFixRepairAreaDevicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpRepairAreasApi - factory interface
 * @export
 */
export const OpRepairAreasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpRepairAreasApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать акт передачи
         * @param {OpRepairAreasApiApiOpRepairAreasActOfTransferPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasActOfTransferPost(requestParameters: OpRepairAreasApiApiOpRepairAreasActOfTransferPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairAreasActOfTransferPost(requestParameters.getRepairAreaActOfTransferPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал участка ремонта
         * @param {OpRepairAreasApiApiOpRepairAreasBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasBrowseGet(requestParameters: OpRepairAreasApiApiOpRepairAreasBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RepairAreaDeviceDto>> {
            return localVarFp.apiOpRepairAreasBrowseGet(requestParameters.firmId, requestParameters.skl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузка участков ремонта в Excel
         * @param {OpRepairAreasApiApiOpRepairAreasExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasExportExcelPost(requestParameters: OpRepairAreasApiApiOpRepairAreasExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairAreasExportExcelPost(requestParameters.repairAreasExportToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список участков ремонта
         * @param {OpRepairAreasApiApiOpRepairAreasGlobalFilterRepairAreasGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasGlobalFilterRepairAreasGet(requestParameters: OpRepairAreasApiApiOpRepairAreasGlobalFilterRepairAreasGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RepairAreaDto>> {
            return localVarFp.apiOpRepairAreasGlobalFilterRepairAreasGet(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Починить
         * @param {OpRepairAreasApiApiOpRepairAreasRepairPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasRepairPost(requestParameters: OpRepairAreasApiApiOpRepairAreasRepairPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairAreasRepairPost(requestParameters.fixRepairAreaDevicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Вернуть
         * @param {OpRepairAreasApiApiOpRepairAreasReturnPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasReturnPost(requestParameters: OpRepairAreasApiApiOpRepairAreasReturnPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairAreasReturnPost(requestParameters.returnRepairAreaDevicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отменить ремонт
         * @param {OpRepairAreasApiApiOpRepairAreasUnrepairPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairAreasUnrepairPost(requestParameters: OpRepairAreasApiApiOpRepairAreasUnrepairPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairAreasUnrepairPost(requestParameters.unFixRepairAreaDevicePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpRepairAreasActOfTransferPost operation in OpRepairAreasApi.
 * @export
 * @interface OpRepairAreasApiApiOpRepairAreasActOfTransferPostRequest
 */
export interface OpRepairAreasApiApiOpRepairAreasActOfTransferPostRequest {
    /**
     * 
     * @type {GetRepairAreaActOfTransferPayload}
     * @memberof OpRepairAreasApiApiOpRepairAreasActOfTransferPost
     */
    readonly getRepairAreaActOfTransferPayload?: GetRepairAreaActOfTransferPayload
}

/**
 * Request parameters for apiOpRepairAreasBrowseGet operation in OpRepairAreasApi.
 * @export
 * @interface OpRepairAreasApiApiOpRepairAreasBrowseGetRequest
 */
export interface OpRepairAreasApiApiOpRepairAreasBrowseGetRequest {
    /**
     * Id компании
     * @type {number}
     * @memberof OpRepairAreasApiApiOpRepairAreasBrowseGet
     */
    readonly firmId?: number

    /**
     * Номер склада
     * @type {string}
     * @memberof OpRepairAreasApiApiOpRepairAreasBrowseGet
     */
    readonly skl?: string
}

/**
 * Request parameters for apiOpRepairAreasExportExcelPost operation in OpRepairAreasApi.
 * @export
 * @interface OpRepairAreasApiApiOpRepairAreasExportExcelPostRequest
 */
export interface OpRepairAreasApiApiOpRepairAreasExportExcelPostRequest {
    /**
     * 
     * @type {RepairAreasExportToExcelPayload}
     * @memberof OpRepairAreasApiApiOpRepairAreasExportExcelPost
     */
    readonly repairAreasExportToExcelPayload?: RepairAreasExportToExcelPayload
}

/**
 * Request parameters for apiOpRepairAreasGlobalFilterRepairAreasGet operation in OpRepairAreasApi.
 * @export
 * @interface OpRepairAreasApiApiOpRepairAreasGlobalFilterRepairAreasGetRequest
 */
export interface OpRepairAreasApiApiOpRepairAreasGlobalFilterRepairAreasGetRequest {
    /**
     * Id компании
     * @type {number}
     * @memberof OpRepairAreasApiApiOpRepairAreasGlobalFilterRepairAreasGet
     */
    readonly firmId?: number
}

/**
 * Request parameters for apiOpRepairAreasRepairPost operation in OpRepairAreasApi.
 * @export
 * @interface OpRepairAreasApiApiOpRepairAreasRepairPostRequest
 */
export interface OpRepairAreasApiApiOpRepairAreasRepairPostRequest {
    /**
     * 
     * @type {FixRepairAreaDevicePayload}
     * @memberof OpRepairAreasApiApiOpRepairAreasRepairPost
     */
    readonly fixRepairAreaDevicePayload?: FixRepairAreaDevicePayload
}

/**
 * Request parameters for apiOpRepairAreasReturnPost operation in OpRepairAreasApi.
 * @export
 * @interface OpRepairAreasApiApiOpRepairAreasReturnPostRequest
 */
export interface OpRepairAreasApiApiOpRepairAreasReturnPostRequest {
    /**
     * 
     * @type {ReturnRepairAreaDevicePayload}
     * @memberof OpRepairAreasApiApiOpRepairAreasReturnPost
     */
    readonly returnRepairAreaDevicePayload?: ReturnRepairAreaDevicePayload
}

/**
 * Request parameters for apiOpRepairAreasUnrepairPost operation in OpRepairAreasApi.
 * @export
 * @interface OpRepairAreasApiApiOpRepairAreasUnrepairPostRequest
 */
export interface OpRepairAreasApiApiOpRepairAreasUnrepairPostRequest {
    /**
     * 
     * @type {UnFixRepairAreaDevicePayload}
     * @memberof OpRepairAreasApiApiOpRepairAreasUnrepairPost
     */
    readonly unFixRepairAreaDevicePayload?: UnFixRepairAreaDevicePayload
}

/**
 * OpRepairAreasApi - object-oriented interface
 * @export
 * @class OpRepairAreasApi
 * @extends {BaseAPI}
 */
export class OpRepairAreasApi extends BaseAPI {
    /**
     * 
     * @summary Создать акт передачи
     * @param {OpRepairAreasApiApiOpRepairAreasActOfTransferPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairAreasApi
     */
    public apiOpRepairAreasActOfTransferPost(requestParameters: OpRepairAreasApiApiOpRepairAreasActOfTransferPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairAreasApiFp(this.configuration).apiOpRepairAreasActOfTransferPost(requestParameters.getRepairAreaActOfTransferPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал участка ремонта
     * @param {OpRepairAreasApiApiOpRepairAreasBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairAreasApi
     */
    public apiOpRepairAreasBrowseGet(requestParameters: OpRepairAreasApiApiOpRepairAreasBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairAreasApiFp(this.configuration).apiOpRepairAreasBrowseGet(requestParameters.firmId, requestParameters.skl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузка участков ремонта в Excel
     * @param {OpRepairAreasApiApiOpRepairAreasExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairAreasApi
     */
    public apiOpRepairAreasExportExcelPost(requestParameters: OpRepairAreasApiApiOpRepairAreasExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairAreasApiFp(this.configuration).apiOpRepairAreasExportExcelPost(requestParameters.repairAreasExportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список участков ремонта
     * @param {OpRepairAreasApiApiOpRepairAreasGlobalFilterRepairAreasGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairAreasApi
     */
    public apiOpRepairAreasGlobalFilterRepairAreasGet(requestParameters: OpRepairAreasApiApiOpRepairAreasGlobalFilterRepairAreasGetRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairAreasApiFp(this.configuration).apiOpRepairAreasGlobalFilterRepairAreasGet(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Починить
     * @param {OpRepairAreasApiApiOpRepairAreasRepairPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairAreasApi
     */
    public apiOpRepairAreasRepairPost(requestParameters: OpRepairAreasApiApiOpRepairAreasRepairPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairAreasApiFp(this.configuration).apiOpRepairAreasRepairPost(requestParameters.fixRepairAreaDevicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Вернуть
     * @param {OpRepairAreasApiApiOpRepairAreasReturnPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairAreasApi
     */
    public apiOpRepairAreasReturnPost(requestParameters: OpRepairAreasApiApiOpRepairAreasReturnPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairAreasApiFp(this.configuration).apiOpRepairAreasReturnPost(requestParameters.returnRepairAreaDevicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отменить ремонт
     * @param {OpRepairAreasApiApiOpRepairAreasUnrepairPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairAreasApi
     */
    public apiOpRepairAreasUnrepairPost(requestParameters: OpRepairAreasApiApiOpRepairAreasUnrepairPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairAreasApiFp(this.configuration).apiOpRepairAreasUnrepairPost(requestParameters.unFixRepairAreaDevicePayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpRepairPlansApi - axios parameter creator
 * @export
 */
export const OpRepairPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Согласовать
         * @param {AgreeRepairPlanPayload} [agreeRepairPlanPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansAgreePost: async (agreeRepairPlanPayload?: AgreeRepairPlanPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/agree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreeRepairPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал плана ремонта
         * @param {string} [areaRepair] Номер участка
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansBrowseGet: async (areaRepair?: string, firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (areaRepair !== undefined) {
                localVarQueryParameter['AreaRepair'] = areaRepair;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить участок
         * @param {ChangeRepairPlanAreaPayload} [changeRepairPlanAreaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansChangeAreaPost: async (changeRepairPlanAreaPayload?: ChangeRepairPlanAreaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/change-area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeRepairPlanAreaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить планувую дату ремонта планов ремонта
         * @param {ChangeRepairPlanDatePlanRepairPayload} [changeRepairPlanDatePlanRepairPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansChangeDatePlanRepairPost: async (changeRepairPlanDatePlanRepairPayload?: ChangeRepairPlanDatePlanRepairPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/change-date-plan-repair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeRepairPlanDatePlanRepairPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить из плана ремонта
         * @param {DeleteRepairPlansPayload} [deleteRepairPlansPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansDeletePost: async (deleteRepairPlansPayload?: DeleteRepairPlansPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRepairPlansPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отказать
         * @param {DisagreeRepairPlanPayload} [disagreeRepairPlanPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansDisagreePost: async (disagreeRepairPlanPayload?: DisagreeRepairPlanPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/disagree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(disagreeRepairPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузка планов ремонта в Excel
         * @param {RepairPlansExportToExcelPayload} [repairPlansExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansExportExcelPost: async (repairPlansExportToExcelPayload?: RepairPlansExportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairPlansExportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список участков ремонта
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansGlobalFilterRepairAreasGet: async (firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/global-filter-repair-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запросить согласование
         * @param {RequestRepairPlanApprovalPayload} [requestRepairPlanApprovalPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansRequestApprovalPost: async (requestRepairPlanApprovalPayload?: RequestRepairPlanApprovalPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/request-approval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestRepairPlanApprovalPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary На участок ремонта
         * @param {ToRepairAreaPayload} [toRepairAreaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansToRepairAreaPost: async (toRepairAreaPayload?: ToRepairAreaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-repair-plans/to-repair-area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toRepairAreaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpRepairPlansApi - functional programming interface
 * @export
 */
export const OpRepairPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpRepairPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Согласовать
         * @param {AgreeRepairPlanPayload} [agreeRepairPlanPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansAgreePost(agreeRepairPlanPayload?: AgreeRepairPlanPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansAgreePost(agreeRepairPlanPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал плана ремонта
         * @param {string} [areaRepair] Номер участка
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansBrowseGet(areaRepair?: string, firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairPlanDeviceBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansBrowseGet(areaRepair, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить участок
         * @param {ChangeRepairPlanAreaPayload} [changeRepairPlanAreaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansChangeAreaPost(changeRepairPlanAreaPayload?: ChangeRepairPlanAreaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansChangeAreaPost(changeRepairPlanAreaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить планувую дату ремонта планов ремонта
         * @param {ChangeRepairPlanDatePlanRepairPayload} [changeRepairPlanDatePlanRepairPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansChangeDatePlanRepairPost(changeRepairPlanDatePlanRepairPayload?: ChangeRepairPlanDatePlanRepairPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansChangeDatePlanRepairPost(changeRepairPlanDatePlanRepairPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить из плана ремонта
         * @param {DeleteRepairPlansPayload} [deleteRepairPlansPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansDeletePost(deleteRepairPlansPayload?: DeleteRepairPlansPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansDeletePost(deleteRepairPlansPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отказать
         * @param {DisagreeRepairPlanPayload} [disagreeRepairPlanPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansDisagreePost(disagreeRepairPlanPayload?: DisagreeRepairPlanPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansDisagreePost(disagreeRepairPlanPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузка планов ремонта в Excel
         * @param {RepairPlansExportToExcelPayload} [repairPlansExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansExportExcelPost(repairPlansExportToExcelPayload?: RepairPlansExportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansExportExcelPost(repairPlansExportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список участков ремонта
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansGlobalFilterRepairAreasGet(firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairPlanAreaDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansGlobalFilterRepairAreasGet(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запросить согласование
         * @param {RequestRepairPlanApprovalPayload} [requestRepairPlanApprovalPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansRequestApprovalPost(requestRepairPlanApprovalPayload?: RequestRepairPlanApprovalPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansRequestApprovalPost(requestRepairPlanApprovalPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary На участок ремонта
         * @param {ToRepairAreaPayload} [toRepairAreaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRepairPlansToRepairAreaPost(toRepairAreaPayload?: ToRepairAreaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRepairPlansToRepairAreaPost(toRepairAreaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpRepairPlansApi - factory interface
 * @export
 */
export const OpRepairPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpRepairPlansApiFp(configuration)
    return {
        /**
         * 
         * @summary Согласовать
         * @param {OpRepairPlansApiApiOpRepairPlansAgreePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansAgreePost(requestParameters: OpRepairPlansApiApiOpRepairPlansAgreePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairPlansAgreePost(requestParameters.agreeRepairPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал плана ремонта
         * @param {OpRepairPlansApiApiOpRepairPlansBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansBrowseGet(requestParameters: OpRepairPlansApiApiOpRepairPlansBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RepairPlanDeviceBrowseDto>> {
            return localVarFp.apiOpRepairPlansBrowseGet(requestParameters.areaRepair, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить участок
         * @param {OpRepairPlansApiApiOpRepairPlansChangeAreaPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansChangeAreaPost(requestParameters: OpRepairPlansApiApiOpRepairPlansChangeAreaPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairPlansChangeAreaPost(requestParameters.changeRepairPlanAreaPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить планувую дату ремонта планов ремонта
         * @param {OpRepairPlansApiApiOpRepairPlansChangeDatePlanRepairPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansChangeDatePlanRepairPost(requestParameters: OpRepairPlansApiApiOpRepairPlansChangeDatePlanRepairPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairPlansChangeDatePlanRepairPost(requestParameters.changeRepairPlanDatePlanRepairPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить из плана ремонта
         * @param {OpRepairPlansApiApiOpRepairPlansDeletePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansDeletePost(requestParameters: OpRepairPlansApiApiOpRepairPlansDeletePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairPlansDeletePost(requestParameters.deleteRepairPlansPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отказать
         * @param {OpRepairPlansApiApiOpRepairPlansDisagreePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansDisagreePost(requestParameters: OpRepairPlansApiApiOpRepairPlansDisagreePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairPlansDisagreePost(requestParameters.disagreeRepairPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузка планов ремонта в Excel
         * @param {OpRepairPlansApiApiOpRepairPlansExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansExportExcelPost(requestParameters: OpRepairPlansApiApiOpRepairPlansExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairPlansExportExcelPost(requestParameters.repairPlansExportToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список участков ремонта
         * @param {OpRepairPlansApiApiOpRepairPlansGlobalFilterRepairAreasGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansGlobalFilterRepairAreasGet(requestParameters: OpRepairPlansApiApiOpRepairPlansGlobalFilterRepairAreasGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RepairPlanAreaDto>> {
            return localVarFp.apiOpRepairPlansGlobalFilterRepairAreasGet(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запросить согласование
         * @param {OpRepairPlansApiApiOpRepairPlansRequestApprovalPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansRequestApprovalPost(requestParameters: OpRepairPlansApiApiOpRepairPlansRequestApprovalPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairPlansRequestApprovalPost(requestParameters.requestRepairPlanApprovalPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary На участок ремонта
         * @param {OpRepairPlansApiApiOpRepairPlansToRepairAreaPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRepairPlansToRepairAreaPost(requestParameters: OpRepairPlansApiApiOpRepairPlansToRepairAreaPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRepairPlansToRepairAreaPost(requestParameters.toRepairAreaPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpRepairPlansAgreePost operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansAgreePostRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansAgreePostRequest {
    /**
     * 
     * @type {AgreeRepairPlanPayload}
     * @memberof OpRepairPlansApiApiOpRepairPlansAgreePost
     */
    readonly agreeRepairPlanPayload?: AgreeRepairPlanPayload
}

/**
 * Request parameters for apiOpRepairPlansBrowseGet operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansBrowseGetRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansBrowseGetRequest {
    /**
     * Номер участка
     * @type {string}
     * @memberof OpRepairPlansApiApiOpRepairPlansBrowseGet
     */
    readonly areaRepair?: string

    /**
     * Id компании
     * @type {number}
     * @memberof OpRepairPlansApiApiOpRepairPlansBrowseGet
     */
    readonly firmId?: number
}

/**
 * Request parameters for apiOpRepairPlansChangeAreaPost operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansChangeAreaPostRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansChangeAreaPostRequest {
    /**
     * 
     * @type {ChangeRepairPlanAreaPayload}
     * @memberof OpRepairPlansApiApiOpRepairPlansChangeAreaPost
     */
    readonly changeRepairPlanAreaPayload?: ChangeRepairPlanAreaPayload
}

/**
 * Request parameters for apiOpRepairPlansChangeDatePlanRepairPost operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansChangeDatePlanRepairPostRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansChangeDatePlanRepairPostRequest {
    /**
     * 
     * @type {ChangeRepairPlanDatePlanRepairPayload}
     * @memberof OpRepairPlansApiApiOpRepairPlansChangeDatePlanRepairPost
     */
    readonly changeRepairPlanDatePlanRepairPayload?: ChangeRepairPlanDatePlanRepairPayload
}

/**
 * Request parameters for apiOpRepairPlansDeletePost operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansDeletePostRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansDeletePostRequest {
    /**
     * 
     * @type {DeleteRepairPlansPayload}
     * @memberof OpRepairPlansApiApiOpRepairPlansDeletePost
     */
    readonly deleteRepairPlansPayload?: DeleteRepairPlansPayload
}

/**
 * Request parameters for apiOpRepairPlansDisagreePost operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansDisagreePostRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansDisagreePostRequest {
    /**
     * 
     * @type {DisagreeRepairPlanPayload}
     * @memberof OpRepairPlansApiApiOpRepairPlansDisagreePost
     */
    readonly disagreeRepairPlanPayload?: DisagreeRepairPlanPayload
}

/**
 * Request parameters for apiOpRepairPlansExportExcelPost operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansExportExcelPostRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansExportExcelPostRequest {
    /**
     * 
     * @type {RepairPlansExportToExcelPayload}
     * @memberof OpRepairPlansApiApiOpRepairPlansExportExcelPost
     */
    readonly repairPlansExportToExcelPayload?: RepairPlansExportToExcelPayload
}

/**
 * Request parameters for apiOpRepairPlansGlobalFilterRepairAreasGet operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansGlobalFilterRepairAreasGetRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansGlobalFilterRepairAreasGetRequest {
    /**
     * Id компании
     * @type {number}
     * @memberof OpRepairPlansApiApiOpRepairPlansGlobalFilterRepairAreasGet
     */
    readonly firmId?: number
}

/**
 * Request parameters for apiOpRepairPlansRequestApprovalPost operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansRequestApprovalPostRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansRequestApprovalPostRequest {
    /**
     * 
     * @type {RequestRepairPlanApprovalPayload}
     * @memberof OpRepairPlansApiApiOpRepairPlansRequestApprovalPost
     */
    readonly requestRepairPlanApprovalPayload?: RequestRepairPlanApprovalPayload
}

/**
 * Request parameters for apiOpRepairPlansToRepairAreaPost operation in OpRepairPlansApi.
 * @export
 * @interface OpRepairPlansApiApiOpRepairPlansToRepairAreaPostRequest
 */
export interface OpRepairPlansApiApiOpRepairPlansToRepairAreaPostRequest {
    /**
     * 
     * @type {ToRepairAreaPayload}
     * @memberof OpRepairPlansApiApiOpRepairPlansToRepairAreaPost
     */
    readonly toRepairAreaPayload?: ToRepairAreaPayload
}

/**
 * OpRepairPlansApi - object-oriented interface
 * @export
 * @class OpRepairPlansApi
 * @extends {BaseAPI}
 */
export class OpRepairPlansApi extends BaseAPI {
    /**
     * 
     * @summary Согласовать
     * @param {OpRepairPlansApiApiOpRepairPlansAgreePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansAgreePost(requestParameters: OpRepairPlansApiApiOpRepairPlansAgreePostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansAgreePost(requestParameters.agreeRepairPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал плана ремонта
     * @param {OpRepairPlansApiApiOpRepairPlansBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansBrowseGet(requestParameters: OpRepairPlansApiApiOpRepairPlansBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansBrowseGet(requestParameters.areaRepair, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить участок
     * @param {OpRepairPlansApiApiOpRepairPlansChangeAreaPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansChangeAreaPost(requestParameters: OpRepairPlansApiApiOpRepairPlansChangeAreaPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansChangeAreaPost(requestParameters.changeRepairPlanAreaPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить планувую дату ремонта планов ремонта
     * @param {OpRepairPlansApiApiOpRepairPlansChangeDatePlanRepairPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansChangeDatePlanRepairPost(requestParameters: OpRepairPlansApiApiOpRepairPlansChangeDatePlanRepairPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansChangeDatePlanRepairPost(requestParameters.changeRepairPlanDatePlanRepairPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить из плана ремонта
     * @param {OpRepairPlansApiApiOpRepairPlansDeletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansDeletePost(requestParameters: OpRepairPlansApiApiOpRepairPlansDeletePostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansDeletePost(requestParameters.deleteRepairPlansPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отказать
     * @param {OpRepairPlansApiApiOpRepairPlansDisagreePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansDisagreePost(requestParameters: OpRepairPlansApiApiOpRepairPlansDisagreePostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansDisagreePost(requestParameters.disagreeRepairPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузка планов ремонта в Excel
     * @param {OpRepairPlansApiApiOpRepairPlansExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansExportExcelPost(requestParameters: OpRepairPlansApiApiOpRepairPlansExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansExportExcelPost(requestParameters.repairPlansExportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список участков ремонта
     * @param {OpRepairPlansApiApiOpRepairPlansGlobalFilterRepairAreasGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansGlobalFilterRepairAreasGet(requestParameters: OpRepairPlansApiApiOpRepairPlansGlobalFilterRepairAreasGetRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansGlobalFilterRepairAreasGet(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запросить согласование
     * @param {OpRepairPlansApiApiOpRepairPlansRequestApprovalPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansRequestApprovalPost(requestParameters: OpRepairPlansApiApiOpRepairPlansRequestApprovalPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansRequestApprovalPost(requestParameters.requestRepairPlanApprovalPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary На участок ремонта
     * @param {OpRepairPlansApiApiOpRepairPlansToRepairAreaPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRepairPlansApi
     */
    public apiOpRepairPlansToRepairAreaPost(requestParameters: OpRepairPlansApiApiOpRepairPlansToRepairAreaPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRepairPlansApiFp(this.configuration).apiOpRepairPlansToRepairAreaPost(requestParameters.toRepairAreaPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpReplacementFundApi - axios parameter creator
 * @export
 */
export const OpReplacementFundApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал подменного фонда
         * @param {number} [firmId] id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpReplacementFundBrowseGet: async (firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-replacement-fund/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpReplacementFundApi - functional programming interface
 * @export
 */
export const OpReplacementFundApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpReplacementFundApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал подменного фонда
         * @param {number} [firmId] id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpReplacementFundBrowseGet(firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReplacementDeviceBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpReplacementFundBrowseGet(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpReplacementFundApi - factory interface
 * @export
 */
export const OpReplacementFundApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpReplacementFundApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал подменного фонда
         * @param {OpReplacementFundApiApiOpReplacementFundBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpReplacementFundBrowseGet(requestParameters: OpReplacementFundApiApiOpReplacementFundBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ReplacementDeviceBrowseDto>> {
            return localVarFp.apiOpReplacementFundBrowseGet(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpReplacementFundBrowseGet operation in OpReplacementFundApi.
 * @export
 * @interface OpReplacementFundApiApiOpReplacementFundBrowseGetRequest
 */
export interface OpReplacementFundApiApiOpReplacementFundBrowseGetRequest {
    /**
     * id компании
     * @type {number}
     * @memberof OpReplacementFundApiApiOpReplacementFundBrowseGet
     */
    readonly firmId?: number
}

/**
 * OpReplacementFundApi - object-oriented interface
 * @export
 * @class OpReplacementFundApi
 * @extends {BaseAPI}
 */
export class OpReplacementFundApi extends BaseAPI {
    /**
     * 
     * @summary Журнал подменного фонда
     * @param {OpReplacementFundApiApiOpReplacementFundBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpReplacementFundApi
     */
    public apiOpReplacementFundBrowseGet(requestParameters: OpReplacementFundApiApiOpReplacementFundBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return OpReplacementFundApiFp(this.configuration).apiOpReplacementFundBrowseGet(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpRmaRequestsApi - axios parameter creator
 * @export
 */
export const OpRmaRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал заявок RMA - новые заявки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsBrowseActiveGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-rma-requests/browse-active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал заявок RMA - список
         * @param {string} periodStart Начало периода, формат yyyy-MM-dd
         * @param {string} periodEnd Конец периода, формат yyyy-MM-dd
         * @param {number} counterpartyId Фильтр по контрагенту
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsBrowseGet: async (periodStart: string, periodEnd: string, counterpartyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodStart' is not null or undefined
            assertParamExists('apiOpRmaRequestsBrowseGet', 'periodStart', periodStart)
            // verify required parameter 'periodEnd' is not null or undefined
            assertParamExists('apiOpRmaRequestsBrowseGet', 'periodEnd', periodEnd)
            // verify required parameter 'counterpartyId' is not null or undefined
            assertParamExists('apiOpRmaRequestsBrowseGet', 'counterpartyId', counterpartyId)
            const localVarPath = `/api/op-rma-requests/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (counterpartyId !== undefined) {
                localVarQueryParameter['CounterpartyId'] = counterpartyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал заявок RMA - элемент списка
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsBrowseIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsBrowseIdGet', 'id', id)
            const localVarPath = `/api/op-rma-requests/browse/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {string} dateFrom Дата начала периода, формат yyyy-MM-dd
         * @param {string} dateTo Дата конца периода, формат yyyy-MM-dd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsGlobalFilterCounterpartiesGet: async (dateFrom: string, dateTo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('apiOpRmaRequestsGlobalFilterCounterpartiesGet', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('apiOpRmaRequestsGlobalFilterCounterpartiesGet', 'dateTo', dateTo)
            const localVarPath = `/api/op-rma-requests/global-filter-counterparties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить запись
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdDelete', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить приборы
         * @param {number} id 
         * @param {DeleteRmaRequestDevicesPayload} [deleteRmaRequestDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesDelete: async (id: number, deleteRmaRequestDevicesPayload?: DeleteRmaRequestDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdDevicesDelete', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRmaRequestDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить прибор
         * @param {number} id 
         * @param {number} deviceId 
         * @param {UpdateRmaRequestDevicePayload} [updateRmaRequestDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesDeviceIdPut: async (id: number, deviceId: number, updateRmaRequestDevicePayload?: UpdateRmaRequestDevicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdDevicesDeviceIdPut', 'id', id)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdDevicesDeviceIdPut', 'deviceId', deviceId)
            const localVarPath = `/api/op-rma-requests/{id}/devices/{deviceId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRmaRequestDevicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список приборов по заявке
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdDevicesGet', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить приборы из файла Excel
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesImportFromExcelPost: async (id: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdDevicesImportFromExcelPost', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}/devices/import-from-excel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить приборы вручную
         * @param {number} id 
         * @param {AddRmaRequestDevicesPayload} [addRmaRequestDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesPost: async (id: number, addRmaRequestDevicesPayload?: AddRmaRequestDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdDevicesPost', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRmaRequestDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить RMA фирму в приборах
         * @param {number} id 
         * @param {UpdateFirmForRmaRequestDevicesPayload} [updateFirmForRmaRequestDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesSetRmaFirmPost: async (id: number, updateFirmForRmaRequestDevicesPayload?: UpdateFirmForRmaRequestDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdDevicesSetRmaFirmPost', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}/devices/set-rma-firm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFirmForRmaRequestDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить запись
         * @param {number} id 
         * @param {UpdateRmaRequestPayload} [updateRmaRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdPut: async (id: number, updateRmaRequestPayload?: UpdateRmaRequestPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdPut', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRmaRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Принять
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdReceivePost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdReceivePost', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}/receive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возврат
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdRejectPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdRejectPost', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список RMA по заявке
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdRmasGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOpRmaRequestsIdRmasGet', 'id', id)
            const localVarPath = `/api/op-rma-requests/{id}/rmas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить запись
         * @param {CreateRmaRequestPayload} [createRmaRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsPost: async (createRmaRequestPayload?: CreateRmaRequestPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-rma-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRmaRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpRmaRequestsApi - functional programming interface
 * @export
 */
export const OpRmaRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpRmaRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал заявок RMA - новые заявки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsBrowseActiveGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RmaRequestBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsBrowseActiveGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал заявок RMA - список
         * @param {string} periodStart Начало периода, формат yyyy-MM-dd
         * @param {string} periodEnd Конец периода, формат yyyy-MM-dd
         * @param {number} counterpartyId Фильтр по контрагенту
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsBrowseGet(periodStart: string, periodEnd: string, counterpartyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RmaRequestBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsBrowseGet(periodStart, periodEnd, counterpartyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал заявок RMA - элемент списка
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsBrowseIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RmaRequestBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsBrowseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {string} dateFrom Дата начала периода, формат yyyy-MM-dd
         * @param {string} dateTo Дата конца периода, формат yyyy-MM-dd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsGlobalFilterCounterpartiesGet(dateFrom: string, dateTo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RmaRequestGlobalCounterpartyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsGlobalFilterCounterpartiesGet(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить запись
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить приборы
         * @param {number} id 
         * @param {DeleteRmaRequestDevicesPayload} [deleteRmaRequestDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdDevicesDelete(id: number, deleteRmaRequestDevicesPayload?: DeleteRmaRequestDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdDevicesDelete(id, deleteRmaRequestDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить прибор
         * @param {number} id 
         * @param {number} deviceId 
         * @param {UpdateRmaRequestDevicePayload} [updateRmaRequestDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdDevicesDeviceIdPut(id: number, deviceId: number, updateRmaRequestDevicePayload?: UpdateRmaRequestDevicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdDevicesDeviceIdPut(id, deviceId, updateRmaRequestDevicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список приборов по заявке
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdDevicesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RmaRequestDeviceBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdDevicesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить приборы из файла Excel
         * @param {number} id 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdDevicesImportFromExcelPost(id: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportRmaRequestDevicesFromExcelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdDevicesImportFromExcelPost(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить приборы вручную
         * @param {number} id 
         * @param {AddRmaRequestDevicesPayload} [addRmaRequestDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdDevicesPost(id: number, addRmaRequestDevicesPayload?: AddRmaRequestDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdDevicesPost(id, addRmaRequestDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить RMA фирму в приборах
         * @param {number} id 
         * @param {UpdateFirmForRmaRequestDevicesPayload} [updateFirmForRmaRequestDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdDevicesSetRmaFirmPost(id: number, updateFirmForRmaRequestDevicesPayload?: UpdateFirmForRmaRequestDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdDevicesSetRmaFirmPost(id, updateFirmForRmaRequestDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить запись
         * @param {number} id 
         * @param {UpdateRmaRequestPayload} [updateRmaRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdPut(id: number, updateRmaRequestPayload?: UpdateRmaRequestPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdPut(id, updateRmaRequestPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Принять
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdReceivePost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdReceivePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Возврат
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdRejectPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdRejectPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список RMA по заявке
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsIdRmasGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RmaRequestRmaLookupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsIdRmasGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить запись
         * @param {CreateRmaRequestPayload} [createRmaRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmaRequestsPost(createRmaRequestPayload?: CreateRmaRequestPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRmaRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmaRequestsPost(createRmaRequestPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpRmaRequestsApi - factory interface
 * @export
 */
export const OpRmaRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpRmaRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал заявок RMA - новые заявки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsBrowseActiveGet(options?: AxiosRequestConfig): AxiosPromise<Array<RmaRequestBrowseDto>> {
            return localVarFp.apiOpRmaRequestsBrowseActiveGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал заявок RMA - список
         * @param {OpRmaRequestsApiApiOpRmaRequestsBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsBrowseGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsBrowseGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RmaRequestBrowseDto>> {
            return localVarFp.apiOpRmaRequestsBrowseGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.counterpartyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал заявок RMA - элемент списка
         * @param {OpRmaRequestsApiApiOpRmaRequestsBrowseIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsBrowseIdGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsBrowseIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<RmaRequestBrowseDto> {
            return localVarFp.apiOpRmaRequestsBrowseIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {OpRmaRequestsApiApiOpRmaRequestsGlobalFilterCounterpartiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsGlobalFilterCounterpartiesGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsGlobalFilterCounterpartiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RmaRequestGlobalCounterpartyDto>> {
            return localVarFp.apiOpRmaRequestsGlobalFilterCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить запись
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDelete(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmaRequestsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить приборы
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesDelete(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmaRequestsIdDevicesDelete(requestParameters.id, requestParameters.deleteRmaRequestDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить прибор
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeviceIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesDeviceIdPut(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeviceIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmaRequestsIdDevicesDeviceIdPut(requestParameters.id, requestParameters.deviceId, requestParameters.updateRmaRequestDevicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список приборов по заявке
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RmaRequestDeviceBrowseDto>> {
            return localVarFp.apiOpRmaRequestsIdDevicesGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить приборы из файла Excel
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesImportFromExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesImportFromExcelPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesImportFromExcelPostRequest, options?: AxiosRequestConfig): AxiosPromise<ImportRmaRequestDevicesFromExcelResponse> {
            return localVarFp.apiOpRmaRequestsIdDevicesImportFromExcelPost(requestParameters.id, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить приборы вручную
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmaRequestsIdDevicesPost(requestParameters.id, requestParameters.addRmaRequestDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить RMA фирму в приборах
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesSetRmaFirmPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdDevicesSetRmaFirmPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesSetRmaFirmPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmaRequestsIdDevicesSetRmaFirmPost(requestParameters.id, requestParameters.updateFirmForRmaRequestDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить запись
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdPut(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmaRequestsIdPut(requestParameters.id, requestParameters.updateRmaRequestPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Принять
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdReceivePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdReceivePost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdReceivePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmaRequestsIdReceivePost(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возврат
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdRejectPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdRejectPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdRejectPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmaRequestsIdRejectPost(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список RMA по заявке
         * @param {OpRmaRequestsApiApiOpRmaRequestsIdRmasGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsIdRmasGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdRmasGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RmaRequestRmaLookupDto>> {
            return localVarFp.apiOpRmaRequestsIdRmasGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить запись
         * @param {OpRmaRequestsApiApiOpRmaRequestsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmaRequestsPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateRmaRequestResponse> {
            return localVarFp.apiOpRmaRequestsPost(requestParameters.createRmaRequestPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpRmaRequestsBrowseGet operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsBrowseGetRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsBrowseGetRequest {
    /**
     * Начало периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsBrowseGet
     */
    readonly periodStart: string

    /**
     * Конец периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsBrowseGet
     */
    readonly periodEnd: string

    /**
     * Фильтр по контрагенту
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsBrowseGet
     */
    readonly counterpartyId: number
}

/**
 * Request parameters for apiOpRmaRequestsBrowseIdGet operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsBrowseIdGetRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsBrowseIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsBrowseIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiOpRmaRequestsGlobalFilterCounterpartiesGet operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsGlobalFilterCounterpartiesGetRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsGlobalFilterCounterpartiesGetRequest {
    /**
     * Дата начала периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsGlobalFilterCounterpartiesGet
     */
    readonly dateFrom: string

    /**
     * Дата конца периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsGlobalFilterCounterpartiesGet
     */
    readonly dateTo: string
}

/**
 * Request parameters for apiOpRmaRequestsIdDelete operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdDeleteRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiOpRmaRequestsIdDevicesDelete operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeleteRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesDelete
     */
    readonly id: number

    /**
     * 
     * @type {DeleteRmaRequestDevicesPayload}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesDelete
     */
    readonly deleteRmaRequestDevicesPayload?: DeleteRmaRequestDevicesPayload
}

/**
 * Request parameters for apiOpRmaRequestsIdDevicesDeviceIdPut operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeviceIdPutRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeviceIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeviceIdPut
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeviceIdPut
     */
    readonly deviceId: number

    /**
     * 
     * @type {UpdateRmaRequestDevicePayload}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeviceIdPut
     */
    readonly updateRmaRequestDevicePayload?: UpdateRmaRequestDevicePayload
}

/**
 * Request parameters for apiOpRmaRequestsIdDevicesGet operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesGetRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesGet
     */
    readonly id: number
}

/**
 * Request parameters for apiOpRmaRequestsIdDevicesImportFromExcelPost operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesImportFromExcelPostRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesImportFromExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesImportFromExcelPost
     */
    readonly id: number

    /**
     * 
     * @type {File}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesImportFromExcelPost
     */
    readonly file?: File
}

/**
 * Request parameters for apiOpRmaRequestsIdDevicesPost operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesPostRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesPost
     */
    readonly id: number

    /**
     * 
     * @type {AddRmaRequestDevicesPayload}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesPost
     */
    readonly addRmaRequestDevicesPayload?: AddRmaRequestDevicesPayload
}

/**
 * Request parameters for apiOpRmaRequestsIdDevicesSetRmaFirmPost operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesSetRmaFirmPostRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdDevicesSetRmaFirmPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesSetRmaFirmPost
     */
    readonly id: number

    /**
     * 
     * @type {UpdateFirmForRmaRequestDevicesPayload}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdDevicesSetRmaFirmPost
     */
    readonly updateFirmForRmaRequestDevicesPayload?: UpdateFirmForRmaRequestDevicesPayload
}

/**
 * Request parameters for apiOpRmaRequestsIdPut operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdPutRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdPut
     */
    readonly id: number

    /**
     * 
     * @type {UpdateRmaRequestPayload}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdPut
     */
    readonly updateRmaRequestPayload?: UpdateRmaRequestPayload
}

/**
 * Request parameters for apiOpRmaRequestsIdReceivePost operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdReceivePostRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdReceivePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdReceivePost
     */
    readonly id: number
}

/**
 * Request parameters for apiOpRmaRequestsIdRejectPost operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdRejectPostRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdRejectPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdRejectPost
     */
    readonly id: number
}

/**
 * Request parameters for apiOpRmaRequestsIdRmasGet operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsIdRmasGetRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsIdRmasGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsIdRmasGet
     */
    readonly id: number
}

/**
 * Request parameters for apiOpRmaRequestsPost operation in OpRmaRequestsApi.
 * @export
 * @interface OpRmaRequestsApiApiOpRmaRequestsPostRequest
 */
export interface OpRmaRequestsApiApiOpRmaRequestsPostRequest {
    /**
     * 
     * @type {CreateRmaRequestPayload}
     * @memberof OpRmaRequestsApiApiOpRmaRequestsPost
     */
    readonly createRmaRequestPayload?: CreateRmaRequestPayload
}

/**
 * OpRmaRequestsApi - object-oriented interface
 * @export
 * @class OpRmaRequestsApi
 * @extends {BaseAPI}
 */
export class OpRmaRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Журнал заявок RMA - новые заявки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsBrowseActiveGet(options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsBrowseActiveGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал заявок RMA - список
     * @param {OpRmaRequestsApiApiOpRmaRequestsBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsBrowseGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsBrowseGetRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsBrowseGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.counterpartyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал заявок RMA - элемент списка
     * @param {OpRmaRequestsApiApiOpRmaRequestsBrowseIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsBrowseIdGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsBrowseIdGetRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsBrowseIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список контрагентов за период
     * @param {OpRmaRequestsApiApiOpRmaRequestsGlobalFilterCounterpartiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsGlobalFilterCounterpartiesGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsGlobalFilterCounterpartiesGetRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsGlobalFilterCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить запись
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdDelete(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDeleteRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить приборы
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdDevicesDelete(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeleteRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdDevicesDelete(requestParameters.id, requestParameters.deleteRmaRequestDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить прибор
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeviceIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdDevicesDeviceIdPut(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesDeviceIdPutRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdDevicesDeviceIdPut(requestParameters.id, requestParameters.deviceId, requestParameters.updateRmaRequestDevicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список приборов по заявке
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdDevicesGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesGetRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdDevicesGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить приборы из файла Excel
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesImportFromExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdDevicesImportFromExcelPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesImportFromExcelPostRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdDevicesImportFromExcelPost(requestParameters.id, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить приборы вручную
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdDevicesPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesPostRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdDevicesPost(requestParameters.id, requestParameters.addRmaRequestDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить RMA фирму в приборах
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdDevicesSetRmaFirmPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdDevicesSetRmaFirmPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdDevicesSetRmaFirmPostRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdDevicesSetRmaFirmPost(requestParameters.id, requestParameters.updateFirmForRmaRequestDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить запись
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdPut(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdPutRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdPut(requestParameters.id, requestParameters.updateRmaRequestPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Принять
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdReceivePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdReceivePost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdReceivePostRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdReceivePost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Возврат
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdRejectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdRejectPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdRejectPostRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdRejectPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список RMA по заявке
     * @param {OpRmaRequestsApiApiOpRmaRequestsIdRmasGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsIdRmasGet(requestParameters: OpRmaRequestsApiApiOpRmaRequestsIdRmasGetRequest, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsIdRmasGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить запись
     * @param {OpRmaRequestsApiApiOpRmaRequestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmaRequestsApi
     */
    public apiOpRmaRequestsPost(requestParameters: OpRmaRequestsApiApiOpRmaRequestsPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRmaRequestsApiFp(this.configuration).apiOpRmaRequestsPost(requestParameters.createRmaRequestPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpRmasApi - axios parameter creator
 * @export
 */
export const OpRmasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary События аудита по Rma
         * @param {string} [eventType] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [docNumber] 
         * @param {number} [counterpartyId] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasAuditBrowseGet: async (eventType?: string, dateFrom?: string, dateTo?: string, docNumber?: string, counterpartyId?: number, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-rmas/audit/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (eventType !== undefined) {
                localVarQueryParameter['EventType'] = eventType;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (docNumber !== undefined) {
                localVarQueryParameter['DocNumber'] = docNumber;
            }

            if (counterpartyId !== undefined) {
                localVarQueryParameter['CounterpartyId'] = counterpartyId;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Типы событий аудита по Rma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasAuditEventsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-rmas/audit/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал RMA - получить запись
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasBrowseDocRmaIdFirmFirmIdGet: async (rmaId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasBrowseDocRmaIdFirmFirmIdGet', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasBrowseDocRmaIdFirmFirmIdGet', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/browse/doc/{rmaId}/firm/{firmId}`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал RMA
         * @param {string} periodStart Начало периода, формат yyyy-MM-dd
         * @param {string} periodEnd Конец периода, формат yyyy-MM-dd
         * @param {number} counterpartyId Фильтр по контрагенту
         * @param {OrderByDirection} [orderByCounterpartyName] 
         * @param {OrderByDirection} [orderByRmaRequestNumber] 
         * @param {OrderByDirection} [orderByRmaNumber] 
         * @param {OrderByDirection} [orderByRmaDate] 
         * @param {OrderByDirection} [orderByInvoiceNumber] 
         * @param {OrderByDirection} [orderByRmaStatus] 
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasBrowseGet: async (periodStart: string, periodEnd: string, counterpartyId: number, orderByCounterpartyName?: OrderByDirection, orderByRmaRequestNumber?: OrderByDirection, orderByRmaNumber?: OrderByDirection, orderByRmaDate?: OrderByDirection, orderByInvoiceNumber?: OrderByDirection, orderByRmaStatus?: OrderByDirection, firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodStart' is not null or undefined
            assertParamExists('apiOpRmasBrowseGet', 'periodStart', periodStart)
            // verify required parameter 'periodEnd' is not null or undefined
            assertParamExists('apiOpRmasBrowseGet', 'periodEnd', periodEnd)
            // verify required parameter 'counterpartyId' is not null or undefined
            assertParamExists('apiOpRmasBrowseGet', 'counterpartyId', counterpartyId)
            const localVarPath = `/api/op-rmas/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderByCounterpartyName !== undefined) {
                localVarQueryParameter['OrderBy.CounterpartyName'] = orderByCounterpartyName;
            }

            if (orderByRmaRequestNumber !== undefined) {
                localVarQueryParameter['OrderBy.RmaRequestNumber'] = orderByRmaRequestNumber;
            }

            if (orderByRmaNumber !== undefined) {
                localVarQueryParameter['OrderBy.RmaNumber'] = orderByRmaNumber;
            }

            if (orderByRmaDate !== undefined) {
                localVarQueryParameter['OrderBy.RmaDate'] = orderByRmaDate;
            }

            if (orderByInvoiceNumber !== undefined) {
                localVarQueryParameter['OrderBy.InvoiceNumber'] = orderByInvoiceNumber;
            }

            if (orderByRmaStatus !== undefined) {
                localVarQueryParameter['OrderBy.RmaStatus'] = orderByRmaStatus;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (counterpartyId !== undefined) {
                localVarQueryParameter['CounterpartyId'] = counterpartyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать RMA документы для заявки
         * @param {number} [rmaRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasCreateForRequestPost: async (rmaRequestId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-rmas/create-for-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (rmaRequestId !== undefined) {
                localVarQueryParameter['rmaRequestId'] = rmaRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать RMA документ
         * @param {CreateRmaPayload} [createRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasCreatePost: async (createRmaPayload?: CreateRmaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-rmas/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRmaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать RMA документ для upgrade заявки
         * @param {CreateUpgradeRmaPayload} [createUpgradeRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasCreateUpgradePost: async (createUpgradeRmaPayload?: CreateUpgradeRmaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-rmas/create-upgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUpgradeRmaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Утвердить RMA (перевод в статус Открыта)
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdApprovePost: async (rmaId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdApprovePost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdApprovePost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/approve`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отмениить утверждение RMA
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost: async (rmaId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/cancel-approval`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отмена RMA или отдельных приборов
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {CancelRmaPayload} [cancelRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdCancelPost: async (rmaId: number, firmId: number, cancelRmaPayload?: CancelRmaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdCancelPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdCancelPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/cancel`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelRmaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка признаков лицензирования
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost: async (rmaId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/check-license-attributes`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка наличия лицензии
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost: async (rmaId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/check-licenses`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтверждение RMA или отдельных приборов
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {ConfirmRmaPayload} [confirmRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdConfirmPost: async (rmaId: number, firmId: number, confirmRmaPayload?: ConfirmRmaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdConfirmPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdConfirmPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/confirm`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmRmaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить RMA документ
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDelete: async (rmaId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDelete', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDelete', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить прибор в Разрешение сканируя штрихкод с прибора
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {AddBarcodeDevicePayload} [addBarcodeDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost: async (rmaId: number, firmId: number, addBarcodeDevicePayload?: AddBarcodeDevicePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/devices-add-barcode`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addBarcodeDevicePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить приборы
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {DeleteRmaDevicesPayload} [deleteRmaDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesDelete: async (rmaId: number, firmId: number, deleteRmaDevicesPayload?: DeleteRmaDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesDelete', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesDelete', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/devices`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRmaDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список устройств RMA
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesGet: async (rmaId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesGet', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesGet', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/devices`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить приборы вручную
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {AddRmaDevicesPayload} [addRmaDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesPost: async (rmaId: number, firmId: number, addRmaDevicesPayload?: AddRmaDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/devices`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRmaDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить приборы
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {UpdateRmaDevicesPayload} [updateRmaDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesPut: async (rmaId: number, firmId: number, updateRmaDevicesPayload?: UpdateRmaDevicesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesPut', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdDevicesPut', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/devices`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRmaDevicesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить RMA в Excel
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdExportExcelPost: async (rmaId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdExportExcelPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdExportExcelPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/export-excel`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выгрузить RMA в PDF
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdExportPdfPost: async (rmaId: number, firmId: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdExportPdfPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdExportPdfPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/export-pdf`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить запись
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {UpdateRmaPayload} [updateRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdPut: async (rmaId: number, firmId: number, updateRmaPayload?: UpdateRmaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdPut', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdPut', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRmaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {ReceiveRmaPayload} [receiveRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdReceivePost: async (rmaId: number, firmId: number, receiveRmaPayload?: ReceiveRmaPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdReceivePost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdReceivePost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/receive`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiveRmaPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отклонить с отменой всех приборов
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdRejectPost: async (rmaId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdRejectPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdRejectPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/reject`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить цену приборов по коду СЕ
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {UpdateRmaDevicePricePayload} [updateRmaDevicePricePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost: async (rmaId: number, firmId: number, updateRmaDevicePricePayload?: UpdateRmaDevicePricePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/update-device-price`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRmaDevicePricePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить цены
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost: async (rmaId: number, firmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rmaId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost', 'rmaId', rmaId)
            // verify required parameter 'firmId' is not null or undefined
            assertParamExists('apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost', 'firmId', firmId)
            const localVarPath = `/api/op-rmas/doc/{rmaId}/firm/{firmId}/update-prices`
                .replace(`{${"rmaId"}}`, encodeURIComponent(String(rmaId)))
                .replace(`{${"firmId"}}`, encodeURIComponent(String(firmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {string} dateFrom Дата начала периода, формат yyyy-MM-dd
         * @param {string} dateTo Дата конца периода, формат yyyy-MM-dd
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasGlobalFilterCounterpartiesGet: async (dateFrom: string, dateTo: string, firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('apiOpRmasGlobalFilterCounterpartiesGet', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('apiOpRmasGlobalFilterCounterpartiesGet', 'dateTo', dateTo)
            const localVarPath = `/api/op-rmas/global-filter-counterparties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpRmasApi - functional programming interface
 * @export
 */
export const OpRmasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpRmasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary События аудита по Rma
         * @param {string} [eventType] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [docNumber] 
         * @param {number} [counterpartyId] 
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasAuditBrowseGet(eventType?: string, dateFrom?: string, dateTo?: string, docNumber?: string, counterpartyId?: number, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowseRmaAuditPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasAuditBrowseGet(eventType, dateFrom, dateTo, docNumber, counterpartyId, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Типы событий аудита по Rma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasAuditEventsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RmaAuditEventTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasAuditEventsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал RMA - получить запись
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasBrowseDocRmaIdFirmFirmIdGet(rmaId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RmaBrowseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasBrowseDocRmaIdFirmFirmIdGet(rmaId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал RMA
         * @param {string} periodStart Начало периода, формат yyyy-MM-dd
         * @param {string} periodEnd Конец периода, формат yyyy-MM-dd
         * @param {number} counterpartyId Фильтр по контрагенту
         * @param {OrderByDirection} [orderByCounterpartyName] 
         * @param {OrderByDirection} [orderByRmaRequestNumber] 
         * @param {OrderByDirection} [orderByRmaNumber] 
         * @param {OrderByDirection} [orderByRmaDate] 
         * @param {OrderByDirection} [orderByInvoiceNumber] 
         * @param {OrderByDirection} [orderByRmaStatus] 
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasBrowseGet(periodStart: string, periodEnd: string, counterpartyId: number, orderByCounterpartyName?: OrderByDirection, orderByRmaRequestNumber?: OrderByDirection, orderByRmaNumber?: OrderByDirection, orderByRmaDate?: OrderByDirection, orderByInvoiceNumber?: OrderByDirection, orderByRmaStatus?: OrderByDirection, firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RmaBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasBrowseGet(periodStart, periodEnd, counterpartyId, orderByCounterpartyName, orderByRmaRequestNumber, orderByRmaNumber, orderByRmaDate, orderByInvoiceNumber, orderByRmaStatus, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать RMA документы для заявки
         * @param {number} [rmaRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasCreateForRequestPost(rmaRequestId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRmaFromRmaRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasCreateForRequestPost(rmaRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать RMA документ
         * @param {CreateRmaPayload} [createRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasCreatePost(createRmaPayload?: CreateRmaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRmaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasCreatePost(createRmaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать RMA документ для upgrade заявки
         * @param {CreateUpgradeRmaPayload} [createUpgradeRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasCreateUpgradePost(createUpgradeRmaPayload?: CreateUpgradeRmaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUpgradeRmaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasCreateUpgradePost(createUpgradeRmaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Утвердить RMA (перевод в статус Открыта)
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdApprovePost(rmaId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdApprovePost(rmaId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отмениить утверждение RMA
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost(rmaId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost(rmaId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отмена RMA или отдельных приборов
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {CancelRmaPayload} [cancelRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdCancelPost(rmaId: number, firmId: number, cancelRmaPayload?: CancelRmaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdCancelPost(rmaId, firmId, cancelRmaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Проверка признаков лицензирования
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost(rmaId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost(rmaId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Проверка наличия лицензии
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost(rmaId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckLicensesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost(rmaId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтверждение RMA или отдельных приборов
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {ConfirmRmaPayload} [confirmRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdConfirmPost(rmaId: number, firmId: number, confirmRmaPayload?: ConfirmRmaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdConfirmPost(rmaId, firmId, confirmRmaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить RMA документ
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdDelete(rmaId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdDelete(rmaId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить прибор в Разрешение сканируя штрихкод с прибора
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {AddBarcodeDevicePayload} [addBarcodeDevicePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost(rmaId: number, firmId: number, addBarcodeDevicePayload?: AddBarcodeDevicePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost(rmaId, firmId, addBarcodeDevicePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить приборы
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {DeleteRmaDevicesPayload} [deleteRmaDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdDevicesDelete(rmaId: number, firmId: number, deleteRmaDevicesPayload?: DeleteRmaDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdDevicesDelete(rmaId, firmId, deleteRmaDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список устройств RMA
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdDevicesGet(rmaId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RmaDeviceBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdDevicesGet(rmaId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить приборы вручную
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {AddRmaDevicesPayload} [addRmaDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdDevicesPost(rmaId: number, firmId: number, addRmaDevicesPayload?: AddRmaDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdDevicesPost(rmaId, firmId, addRmaDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить приборы
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {UpdateRmaDevicesPayload} [updateRmaDevicesPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdDevicesPut(rmaId: number, firmId: number, updateRmaDevicesPayload?: UpdateRmaDevicesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdDevicesPut(rmaId, firmId, updateRmaDevicesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить RMA в Excel
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdExportExcelPost(rmaId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdExportExcelPost(rmaId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выгрузить RMA в PDF
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdExportPdfPost(rmaId: number, firmId: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdExportPdfPost(rmaId, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить запись
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {UpdateRmaPayload} [updateRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdPut(rmaId: number, firmId: number, updateRmaPayload?: UpdateRmaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdPut(rmaId, firmId, updateRmaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {ReceiveRmaPayload} [receiveRmaPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdReceivePost(rmaId: number, firmId: number, receiveRmaPayload?: ReceiveRmaPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdReceivePost(rmaId, firmId, receiveRmaPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отклонить с отменой всех приборов
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdRejectPost(rmaId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdRejectPost(rmaId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить цену приборов по коду СЕ
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {UpdateRmaDevicePricePayload} [updateRmaDevicePricePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost(rmaId: number, firmId: number, updateRmaDevicePricePayload?: UpdateRmaDevicePricePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost(rmaId, firmId, updateRmaDevicePricePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить цены
         * @param {number} rmaId 
         * @param {number} firmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost(rmaId: number, firmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost(rmaId, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {string} dateFrom Дата начала периода, формат yyyy-MM-dd
         * @param {string} dateTo Дата конца периода, формат yyyy-MM-dd
         * @param {number} [firmId] Id компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpRmasGlobalFilterCounterpartiesGet(dateFrom: string, dateTo: string, firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RmaGlobalCounterpartyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpRmasGlobalFilterCounterpartiesGet(dateFrom, dateTo, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpRmasApi - factory interface
 * @export
 */
export const OpRmasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpRmasApiFp(configuration)
    return {
        /**
         * 
         * @summary События аудита по Rma
         * @param {OpRmasApiApiOpRmasAuditBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasAuditBrowseGet(requestParameters: OpRmasApiApiOpRmasAuditBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BrowseRmaAuditPagingResult> {
            return localVarFp.apiOpRmasAuditBrowseGet(requestParameters.eventType, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.docNumber, requestParameters.counterpartyId, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Типы событий аудита по Rma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasAuditEventsGet(options?: AxiosRequestConfig): AxiosPromise<Array<RmaAuditEventTypeDto>> {
            return localVarFp.apiOpRmasAuditEventsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал RMA - получить запись
         * @param {OpRmasApiApiOpRmasBrowseDocRmaIdFirmFirmIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasBrowseDocRmaIdFirmFirmIdGet(requestParameters: OpRmasApiApiOpRmasBrowseDocRmaIdFirmFirmIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<RmaBrowseDto> {
            return localVarFp.apiOpRmasBrowseDocRmaIdFirmFirmIdGet(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал RMA
         * @param {OpRmasApiApiOpRmasBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasBrowseGet(requestParameters: OpRmasApiApiOpRmasBrowseGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RmaBrowseDto>> {
            return localVarFp.apiOpRmasBrowseGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.counterpartyId, requestParameters.orderByCounterpartyName, requestParameters.orderByRmaRequestNumber, requestParameters.orderByRmaNumber, requestParameters.orderByRmaDate, requestParameters.orderByInvoiceNumber, requestParameters.orderByRmaStatus, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать RMA документы для заявки
         * @param {OpRmasApiApiOpRmasCreateForRequestPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasCreateForRequestPost(requestParameters: OpRmasApiApiOpRmasCreateForRequestPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateRmaFromRmaRequestResponse> {
            return localVarFp.apiOpRmasCreateForRequestPost(requestParameters.rmaRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать RMA документ
         * @param {OpRmasApiApiOpRmasCreatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasCreatePost(requestParameters: OpRmasApiApiOpRmasCreatePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateRmaResponse> {
            return localVarFp.apiOpRmasCreatePost(requestParameters.createRmaPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать RMA документ для upgrade заявки
         * @param {OpRmasApiApiOpRmasCreateUpgradePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasCreateUpgradePost(requestParameters: OpRmasApiApiOpRmasCreateUpgradePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateUpgradeRmaResponse> {
            return localVarFp.apiOpRmasCreateUpgradePost(requestParameters.createUpgradeRmaPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Утвердить RMA (перевод в статус Открыта)
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdApprovePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdApprovePost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdApprovePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdApprovePost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отмениить утверждение RMA
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelApprovalPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelApprovalPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отмена RMA или отдельных приборов
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdCancelPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdCancelPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.cancelRmaPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверка признаков лицензирования
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPostRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверка наличия лицензии
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicensesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicensesPostRequest, options?: AxiosRequestConfig): AxiosPromise<CheckLicensesResponse> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтверждение RMA или отдельных приборов
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdConfirmPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdConfirmPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdConfirmPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdConfirmPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.confirmRmaPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить RMA документ
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDelete(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdDelete(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить прибор в Разрешение сканируя штрихкод с прибора
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost(requestParameters.rmaId, requestParameters.firmId, requestParameters.addBarcodeDevicePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить приборы
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesDelete(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdDevicesDelete(requestParameters.rmaId, requestParameters.firmId, requestParameters.deleteRmaDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список устройств RMA
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesGet(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RmaDeviceBrowseDto>> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdDevicesGet(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить приборы вручную
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdDevicesPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.addRmaDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить приборы
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdDevicesPut(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdDevicesPut(requestParameters.rmaId, requestParameters.firmId, requestParameters.updateRmaDevicesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить RMA в Excel
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdExportExcelPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportExcelPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdExportExcelPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выгрузить RMA в PDF
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdExportPdfPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportPdfPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdExportPdfPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить запись
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdPut(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdPut(requestParameters.rmaId, requestParameters.firmId, requestParameters.updateRmaPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdReceivePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdReceivePost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdReceivePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdReceivePost(requestParameters.rmaId, requestParameters.firmId, requestParameters.receiveRmaPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отклонить с отменой всех приборов
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdRejectPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdRejectPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdRejectPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdRejectPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить цену приборов по коду СЕ
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost(requestParameters.rmaId, requestParameters.firmId, requestParameters.updateRmaDevicePricePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить цены
         * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdatePricesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdatePricesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список контрагентов за период
         * @param {OpRmasApiApiOpRmasGlobalFilterCounterpartiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpRmasGlobalFilterCounterpartiesGet(requestParameters: OpRmasApiApiOpRmasGlobalFilterCounterpartiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RmaGlobalCounterpartyDto>> {
            return localVarFp.apiOpRmasGlobalFilterCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpRmasAuditBrowseGet operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasAuditBrowseGetRequest
 */
export interface OpRmasApiApiOpRmasAuditBrowseGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OpRmasApiApiOpRmasAuditBrowseGet
     */
    readonly eventType?: string

    /**
     * 
     * @type {string}
     * @memberof OpRmasApiApiOpRmasAuditBrowseGet
     */
    readonly dateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof OpRmasApiApiOpRmasAuditBrowseGet
     */
    readonly dateTo?: string

    /**
     * 
     * @type {string}
     * @memberof OpRmasApiApiOpRmasAuditBrowseGet
     */
    readonly docNumber?: string

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasAuditBrowseGet
     */
    readonly counterpartyId?: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasAuditBrowseGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasAuditBrowseGet
     */
    readonly pagingPageSize?: number
}

/**
 * Request parameters for apiOpRmasBrowseDocRmaIdFirmFirmIdGet operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasBrowseDocRmaIdFirmFirmIdGetRequest
 */
export interface OpRmasApiApiOpRmasBrowseDocRmaIdFirmFirmIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasBrowseDocRmaIdFirmFirmIdGet
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasBrowseDocRmaIdFirmFirmIdGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpRmasBrowseGet operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasBrowseGetRequest
 */
export interface OpRmasApiApiOpRmasBrowseGetRequest {
    /**
     * Начало периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly periodStart: string

    /**
     * Конец периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly periodEnd: string

    /**
     * Фильтр по контрагенту
     * @type {number}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly counterpartyId: number

    /**
     * 
     * @type {OrderByDirection}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly orderByCounterpartyName?: OrderByDirection

    /**
     * 
     * @type {OrderByDirection}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly orderByRmaRequestNumber?: OrderByDirection

    /**
     * 
     * @type {OrderByDirection}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly orderByRmaNumber?: OrderByDirection

    /**
     * 
     * @type {OrderByDirection}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly orderByRmaDate?: OrderByDirection

    /**
     * 
     * @type {OrderByDirection}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly orderByInvoiceNumber?: OrderByDirection

    /**
     * 
     * @type {OrderByDirection}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly orderByRmaStatus?: OrderByDirection

    /**
     * Id компании
     * @type {number}
     * @memberof OpRmasApiApiOpRmasBrowseGet
     */
    readonly firmId?: number
}

/**
 * Request parameters for apiOpRmasCreateForRequestPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasCreateForRequestPostRequest
 */
export interface OpRmasApiApiOpRmasCreateForRequestPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasCreateForRequestPost
     */
    readonly rmaRequestId?: number
}

/**
 * Request parameters for apiOpRmasCreatePost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasCreatePostRequest
 */
export interface OpRmasApiApiOpRmasCreatePostRequest {
    /**
     * 
     * @type {CreateRmaPayload}
     * @memberof OpRmasApiApiOpRmasCreatePost
     */
    readonly createRmaPayload?: CreateRmaPayload
}

/**
 * Request parameters for apiOpRmasCreateUpgradePost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasCreateUpgradePostRequest
 */
export interface OpRmasApiApiOpRmasCreateUpgradePostRequest {
    /**
     * 
     * @type {CreateUpgradeRmaPayload}
     * @memberof OpRmasApiApiOpRmasCreateUpgradePost
     */
    readonly createUpgradeRmaPayload?: CreateUpgradeRmaPayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdApprovePost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdApprovePostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdApprovePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdApprovePost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdApprovePost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelApprovalPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelApprovalPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdCancelPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelPost
     */
    readonly firmId: number

    /**
     * 
     * @type {CancelRmaPayload}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelPost
     */
    readonly cancelRmaPayload?: CancelRmaPayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicensesPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicensesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdConfirmPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdConfirmPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdConfirmPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdConfirmPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdConfirmPost
     */
    readonly firmId: number

    /**
     * 
     * @type {ConfirmRmaPayload}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdConfirmPost
     */
    readonly confirmRmaPayload?: ConfirmRmaPayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdDelete operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDeleteRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDelete
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDelete
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost
     */
    readonly firmId: number

    /**
     * 
     * @type {AddBarcodeDevicePayload}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost
     */
    readonly addBarcodeDevicePayload?: AddBarcodeDevicePayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdDevicesDelete operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesDeleteRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesDelete
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesDelete
     */
    readonly firmId: number

    /**
     * 
     * @type {DeleteRmaDevicesPayload}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesDelete
     */
    readonly deleteRmaDevicesPayload?: DeleteRmaDevicesPayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdDevicesGet operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesGetRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesGet
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesGet
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdDevicesPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPost
     */
    readonly firmId: number

    /**
     * 
     * @type {AddRmaDevicesPayload}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPost
     */
    readonly addRmaDevicesPayload?: AddRmaDevicesPayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdDevicesPut operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPutRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPut
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPut
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateRmaDevicesPayload}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPut
     */
    readonly updateRmaDevicesPayload?: UpdateRmaDevicesPayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdExportExcelPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportExcelPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportExcelPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportExcelPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportExcelPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdExportPdfPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportPdfPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportPdfPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportPdfPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportPdfPost
     */
    readonly firmId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportPdfPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdPut operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdPutRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdPut
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdPut
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateRmaPayload}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdPut
     */
    readonly updateRmaPayload?: UpdateRmaPayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdReceivePost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdReceivePostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdReceivePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdReceivePost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdReceivePost
     */
    readonly firmId: number

    /**
     * 
     * @type {ReceiveRmaPayload}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdReceivePost
     */
    readonly receiveRmaPayload?: ReceiveRmaPayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdRejectPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdRejectPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdRejectPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdRejectPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdRejectPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost
     */
    readonly firmId: number

    /**
     * 
     * @type {UpdateRmaDevicePricePayload}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost
     */
    readonly updateRmaDevicePricePayload?: UpdateRmaDevicePricePayload
}

/**
 * Request parameters for apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdatePricesPostRequest
 */
export interface OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdatePricesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost
     */
    readonly rmaId: number

    /**
     * 
     * @type {number}
     * @memberof OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost
     */
    readonly firmId: number
}

/**
 * Request parameters for apiOpRmasGlobalFilterCounterpartiesGet operation in OpRmasApi.
 * @export
 * @interface OpRmasApiApiOpRmasGlobalFilterCounterpartiesGetRequest
 */
export interface OpRmasApiApiOpRmasGlobalFilterCounterpartiesGetRequest {
    /**
     * Дата начала периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpRmasApiApiOpRmasGlobalFilterCounterpartiesGet
     */
    readonly dateFrom: string

    /**
     * Дата конца периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof OpRmasApiApiOpRmasGlobalFilterCounterpartiesGet
     */
    readonly dateTo: string

    /**
     * Id компании
     * @type {number}
     * @memberof OpRmasApiApiOpRmasGlobalFilterCounterpartiesGet
     */
    readonly firmId?: number
}

/**
 * OpRmasApi - object-oriented interface
 * @export
 * @class OpRmasApi
 * @extends {BaseAPI}
 */
export class OpRmasApi extends BaseAPI {
    /**
     * 
     * @summary События аудита по Rma
     * @param {OpRmasApiApiOpRmasAuditBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasAuditBrowseGet(requestParameters: OpRmasApiApiOpRmasAuditBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasAuditBrowseGet(requestParameters.eventType, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.docNumber, requestParameters.counterpartyId, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Типы событий аудита по Rma
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasAuditEventsGet(options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasAuditEventsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал RMA - получить запись
     * @param {OpRmasApiApiOpRmasBrowseDocRmaIdFirmFirmIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasBrowseDocRmaIdFirmFirmIdGet(requestParameters: OpRmasApiApiOpRmasBrowseDocRmaIdFirmFirmIdGetRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasBrowseDocRmaIdFirmFirmIdGet(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал RMA
     * @param {OpRmasApiApiOpRmasBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasBrowseGet(requestParameters: OpRmasApiApiOpRmasBrowseGetRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasBrowseGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.counterpartyId, requestParameters.orderByCounterpartyName, requestParameters.orderByRmaRequestNumber, requestParameters.orderByRmaNumber, requestParameters.orderByRmaDate, requestParameters.orderByInvoiceNumber, requestParameters.orderByRmaStatus, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать RMA документы для заявки
     * @param {OpRmasApiApiOpRmasCreateForRequestPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasCreateForRequestPost(requestParameters: OpRmasApiApiOpRmasCreateForRequestPostRequest = {}, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasCreateForRequestPost(requestParameters.rmaRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать RMA документ
     * @param {OpRmasApiApiOpRmasCreatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasCreatePost(requestParameters: OpRmasApiApiOpRmasCreatePostRequest = {}, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasCreatePost(requestParameters.createRmaPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать RMA документ для upgrade заявки
     * @param {OpRmasApiApiOpRmasCreateUpgradePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasCreateUpgradePost(requestParameters: OpRmasApiApiOpRmasCreateUpgradePostRequest = {}, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasCreateUpgradePost(requestParameters.createUpgradeRmaPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Утвердить RMA (перевод в статус Открыта)
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdApprovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdApprovePost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdApprovePostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdApprovePost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отмениить утверждение RMA
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelApprovalPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelApprovalPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdCancelApprovalPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отмена RMA или отдельных приборов
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdCancelPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCancelPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdCancelPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.cancelRmaPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверка признаков лицензирования
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdCheckLicenseAttributesPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверка наличия лицензии
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicensesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdCheckLicensesPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdCheckLicensesPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтверждение RMA или отдельных приборов
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdConfirmPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdConfirmPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdConfirmPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdConfirmPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.confirmRmaPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить RMA документ
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdDelete(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDeleteRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdDelete(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить прибор в Разрешение сканируя штрихкод с прибора
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdDevicesAddBarcodePost(requestParameters.rmaId, requestParameters.firmId, requestParameters.addBarcodeDevicePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить приборы
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdDevicesDelete(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesDeleteRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdDevicesDelete(requestParameters.rmaId, requestParameters.firmId, requestParameters.deleteRmaDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список устройств RMA
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdDevicesGet(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesGetRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdDevicesGet(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить приборы вручную
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdDevicesPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdDevicesPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.addRmaDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить приборы
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdDevicesPut(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdDevicesPutRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdDevicesPut(requestParameters.rmaId, requestParameters.firmId, requestParameters.updateRmaDevicesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить RMA в Excel
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdExportExcelPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportExcelPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdExportExcelPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выгрузить RMA в PDF
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdExportPdfPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdExportPdfPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdExportPdfPost(requestParameters.rmaId, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить запись
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdPut(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdPutRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdPut(requestParameters.rmaId, requestParameters.firmId, requestParameters.updateRmaPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdReceivePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdReceivePost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdReceivePostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdReceivePost(requestParameters.rmaId, requestParameters.firmId, requestParameters.receiveRmaPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отклонить с отменой всех приборов
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdRejectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdRejectPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdRejectPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdRejectPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить цену приборов по коду СЕ
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdUpdateDevicePricePost(requestParameters.rmaId, requestParameters.firmId, requestParameters.updateRmaDevicePricePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить цены
     * @param {OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdatePricesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost(requestParameters: OpRmasApiApiOpRmasDocRmaIdFirmFirmIdUpdatePricesPostRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasDocRmaIdFirmFirmIdUpdatePricesPost(requestParameters.rmaId, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список контрагентов за период
     * @param {OpRmasApiApiOpRmasGlobalFilterCounterpartiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpRmasApi
     */
    public apiOpRmasGlobalFilterCounterpartiesGet(requestParameters: OpRmasApiApiOpRmasGlobalFilterCounterpartiesGetRequest, options?: AxiosRequestConfig) {
        return OpRmasApiFp(this.configuration).apiOpRmasGlobalFilterCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpSearchApi - axios parameter creator
 * @export
 */
export const OpSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Поиск документов прибора
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSearchBrowseDeviceIdDeviceFirmIdGet: async (deviceId: number, deviceFirmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('apiOpSearchBrowseDeviceIdDeviceFirmIdGet', 'deviceId', deviceId)
            // verify required parameter 'deviceFirmId' is not null or undefined
            assertParamExists('apiOpSearchBrowseDeviceIdDeviceFirmIdGet', 'deviceFirmId', deviceFirmId)
            const localVarPath = `/api/op-search/browse/{deviceId}/{deviceFirmId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"deviceFirmId"}}`, encodeURIComponent(String(deviceFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Поиск приборов по серийному номеру
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSearchBrowseSerialGet: async (serial: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('apiOpSearchBrowseSerialGet', 'serial', serial)
            const localVarPath = `/api/op-search/browse/{serial}`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpSearchApi - functional programming interface
 * @export
 */
export const OpSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Поиск документов прибора
         * @param {number} deviceId 
         * @param {number} deviceFirmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpSearchBrowseDeviceIdDeviceFirmIdGet(deviceId: number, deviceFirmId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchDeviceDocumentBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpSearchBrowseDeviceIdDeviceFirmIdGet(deviceId, deviceFirmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Поиск приборов по серийному номеру
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpSearchBrowseSerialGet(serial: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchDeviceBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpSearchBrowseSerialGet(serial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpSearchApi - factory interface
 * @export
 */
export const OpSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpSearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Поиск документов прибора
         * @param {OpSearchApiApiOpSearchBrowseDeviceIdDeviceFirmIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSearchBrowseDeviceIdDeviceFirmIdGet(requestParameters: OpSearchApiApiOpSearchBrowseDeviceIdDeviceFirmIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SearchDeviceDocumentBrowseDto>> {
            return localVarFp.apiOpSearchBrowseDeviceIdDeviceFirmIdGet(requestParameters.deviceId, requestParameters.deviceFirmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Поиск приборов по серийному номеру
         * @param {OpSearchApiApiOpSearchBrowseSerialGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSearchBrowseSerialGet(requestParameters: OpSearchApiApiOpSearchBrowseSerialGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SearchDeviceBrowseDto>> {
            return localVarFp.apiOpSearchBrowseSerialGet(requestParameters.serial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpSearchBrowseDeviceIdDeviceFirmIdGet operation in OpSearchApi.
 * @export
 * @interface OpSearchApiApiOpSearchBrowseDeviceIdDeviceFirmIdGetRequest
 */
export interface OpSearchApiApiOpSearchBrowseDeviceIdDeviceFirmIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OpSearchApiApiOpSearchBrowseDeviceIdDeviceFirmIdGet
     */
    readonly deviceId: number

    /**
     * 
     * @type {number}
     * @memberof OpSearchApiApiOpSearchBrowseDeviceIdDeviceFirmIdGet
     */
    readonly deviceFirmId: number
}

/**
 * Request parameters for apiOpSearchBrowseSerialGet operation in OpSearchApi.
 * @export
 * @interface OpSearchApiApiOpSearchBrowseSerialGetRequest
 */
export interface OpSearchApiApiOpSearchBrowseSerialGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OpSearchApiApiOpSearchBrowseSerialGet
     */
    readonly serial: string
}

/**
 * OpSearchApi - object-oriented interface
 * @export
 * @class OpSearchApi
 * @extends {BaseAPI}
 */
export class OpSearchApi extends BaseAPI {
    /**
     * 
     * @summary Поиск документов прибора
     * @param {OpSearchApiApiOpSearchBrowseDeviceIdDeviceFirmIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpSearchApi
     */
    public apiOpSearchBrowseDeviceIdDeviceFirmIdGet(requestParameters: OpSearchApiApiOpSearchBrowseDeviceIdDeviceFirmIdGetRequest, options?: AxiosRequestConfig) {
        return OpSearchApiFp(this.configuration).apiOpSearchBrowseDeviceIdDeviceFirmIdGet(requestParameters.deviceId, requestParameters.deviceFirmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Поиск приборов по серийному номеру
     * @param {OpSearchApiApiOpSearchBrowseSerialGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpSearchApi
     */
    public apiOpSearchBrowseSerialGet(requestParameters: OpSearchApiApiOpSearchBrowseSerialGetRequest, options?: AxiosRequestConfig) {
        return OpSearchApiFp(this.configuration).apiOpSearchBrowseSerialGet(requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpSparePartsApi - axios parameter creator
 * @export
 */
export const OpSparePartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Выдать на участок ремонта
         * @param {AddRepairAreaSparePartsPayload} [addRepairAreaSparePartsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsAddPost: async (addRepairAreaSparePartsPayload?: AddRepairAreaSparePartsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-spare-parts/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRepairAreaSparePartsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал запчастей
         * @param {string} [repairArea] Код участка ремонта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsBrowseGet: async (repairArea?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-spare-parts/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (repairArea !== undefined) {
                localVarQueryParameter['RepairArea'] = repairArea;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Остатки запчастей в Excel
         * @param {ExportRepairAreaSparePartsToExcelPayload} [exportRepairAreaSparePartsToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsExportExcelPost: async (exportRepairAreaSparePartsToExcelPayload?: ExportRepairAreaSparePartsToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-spare-parts/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportRepairAreaSparePartsToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Остатки запчастей на печать
         * @param {ExportRepairAreaSparePartsToPdfPayload} [exportRepairAreaSparePartsToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsExportPdfPost: async (exportRepairAreaSparePartsToPdfPayload?: ExportRepairAreaSparePartsToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-spare-parts/export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportRepairAreaSparePartsToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возврат с участока ремонта на склад
         * @param {ReturnRepairAreaSparePartsPayload} [returnRepairAreaSparePartsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsReturnPost: async (returnRepairAreaSparePartsPayload?: ReturnRepairAreaSparePartsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-spare-parts/return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnRepairAreaSparePartsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление запчасти сканированием штрих-кода
         * @param {string} [seK] Код СЕ
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsSparePartBySeGet: async (seK?: string, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-spare-parts/spare-part-by-se`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (seK !== undefined) {
                localVarQueryParameter['SeK'] = seK;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpSparePartsApi - functional programming interface
 * @export
 */
export const OpSparePartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpSparePartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Выдать на участок ремонта
         * @param {AddRepairAreaSparePartsPayload} [addRepairAreaSparePartsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpSparePartsAddPost(addRepairAreaSparePartsPayload?: AddRepairAreaSparePartsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpSparePartsAddPost(addRepairAreaSparePartsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал запчастей
         * @param {string} [repairArea] Код участка ремонта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpSparePartsBrowseGet(repairArea?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairAreaSparePartsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpSparePartsBrowseGet(repairArea, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Остатки запчастей в Excel
         * @param {ExportRepairAreaSparePartsToExcelPayload} [exportRepairAreaSparePartsToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpSparePartsExportExcelPost(exportRepairAreaSparePartsToExcelPayload?: ExportRepairAreaSparePartsToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpSparePartsExportExcelPost(exportRepairAreaSparePartsToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Остатки запчастей на печать
         * @param {ExportRepairAreaSparePartsToPdfPayload} [exportRepairAreaSparePartsToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpSparePartsExportPdfPost(exportRepairAreaSparePartsToPdfPayload?: ExportRepairAreaSparePartsToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpSparePartsExportPdfPost(exportRepairAreaSparePartsToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Возврат с участока ремонта на склад
         * @param {ReturnRepairAreaSparePartsPayload} [returnRepairAreaSparePartsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpSparePartsReturnPost(returnRepairAreaSparePartsPayload?: ReturnRepairAreaSparePartsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpSparePartsReturnPost(returnRepairAreaSparePartsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавление запчасти сканированием штрих-кода
         * @param {string} [seK] Код СЕ
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpSparePartsSparePartBySeGet(seK?: string, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SparePartBySeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpSparePartsSparePartBySeGet(seK, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpSparePartsApi - factory interface
 * @export
 */
export const OpSparePartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpSparePartsApiFp(configuration)
    return {
        /**
         * 
         * @summary Выдать на участок ремонта
         * @param {OpSparePartsApiApiOpSparePartsAddPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsAddPost(requestParameters: OpSparePartsApiApiOpSparePartsAddPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpSparePartsAddPost(requestParameters.addRepairAreaSparePartsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал запчастей
         * @param {OpSparePartsApiApiOpSparePartsBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsBrowseGet(requestParameters: OpSparePartsApiApiOpSparePartsBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RepairAreaSparePartsDto>> {
            return localVarFp.apiOpSparePartsBrowseGet(requestParameters.repairArea, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Остатки запчастей в Excel
         * @param {OpSparePartsApiApiOpSparePartsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsExportExcelPost(requestParameters: OpSparePartsApiApiOpSparePartsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpSparePartsExportExcelPost(requestParameters.exportRepairAreaSparePartsToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Остатки запчастей на печать
         * @param {OpSparePartsApiApiOpSparePartsExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsExportPdfPost(requestParameters: OpSparePartsApiApiOpSparePartsExportPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpSparePartsExportPdfPost(requestParameters.exportRepairAreaSparePartsToPdfPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возврат с участока ремонта на склад
         * @param {OpSparePartsApiApiOpSparePartsReturnPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsReturnPost(requestParameters: OpSparePartsApiApiOpSparePartsReturnPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpSparePartsReturnPost(requestParameters.returnRepairAreaSparePartsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление запчасти сканированием штрих-кода
         * @param {OpSparePartsApiApiOpSparePartsSparePartBySeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpSparePartsSparePartBySeGet(requestParameters: OpSparePartsApiApiOpSparePartsSparePartBySeGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SparePartBySeDto> {
            return localVarFp.apiOpSparePartsSparePartBySeGet(requestParameters.seK, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpSparePartsAddPost operation in OpSparePartsApi.
 * @export
 * @interface OpSparePartsApiApiOpSparePartsAddPostRequest
 */
export interface OpSparePartsApiApiOpSparePartsAddPostRequest {
    /**
     * 
     * @type {AddRepairAreaSparePartsPayload}
     * @memberof OpSparePartsApiApiOpSparePartsAddPost
     */
    readonly addRepairAreaSparePartsPayload?: AddRepairAreaSparePartsPayload
}

/**
 * Request parameters for apiOpSparePartsBrowseGet operation in OpSparePartsApi.
 * @export
 * @interface OpSparePartsApiApiOpSparePartsBrowseGetRequest
 */
export interface OpSparePartsApiApiOpSparePartsBrowseGetRequest {
    /**
     * Код участка ремонта
     * @type {string}
     * @memberof OpSparePartsApiApiOpSparePartsBrowseGet
     */
    readonly repairArea?: string
}

/**
 * Request parameters for apiOpSparePartsExportExcelPost operation in OpSparePartsApi.
 * @export
 * @interface OpSparePartsApiApiOpSparePartsExportExcelPostRequest
 */
export interface OpSparePartsApiApiOpSparePartsExportExcelPostRequest {
    /**
     * 
     * @type {ExportRepairAreaSparePartsToExcelPayload}
     * @memberof OpSparePartsApiApiOpSparePartsExportExcelPost
     */
    readonly exportRepairAreaSparePartsToExcelPayload?: ExportRepairAreaSparePartsToExcelPayload
}

/**
 * Request parameters for apiOpSparePartsExportPdfPost operation in OpSparePartsApi.
 * @export
 * @interface OpSparePartsApiApiOpSparePartsExportPdfPostRequest
 */
export interface OpSparePartsApiApiOpSparePartsExportPdfPostRequest {
    /**
     * 
     * @type {ExportRepairAreaSparePartsToPdfPayload}
     * @memberof OpSparePartsApiApiOpSparePartsExportPdfPost
     */
    readonly exportRepairAreaSparePartsToPdfPayload?: ExportRepairAreaSparePartsToPdfPayload
}

/**
 * Request parameters for apiOpSparePartsReturnPost operation in OpSparePartsApi.
 * @export
 * @interface OpSparePartsApiApiOpSparePartsReturnPostRequest
 */
export interface OpSparePartsApiApiOpSparePartsReturnPostRequest {
    /**
     * 
     * @type {ReturnRepairAreaSparePartsPayload}
     * @memberof OpSparePartsApiApiOpSparePartsReturnPost
     */
    readonly returnRepairAreaSparePartsPayload?: ReturnRepairAreaSparePartsPayload
}

/**
 * Request parameters for apiOpSparePartsSparePartBySeGet operation in OpSparePartsApi.
 * @export
 * @interface OpSparePartsApiApiOpSparePartsSparePartBySeGetRequest
 */
export interface OpSparePartsApiApiOpSparePartsSparePartBySeGetRequest {
    /**
     * Код СЕ
     * @type {string}
     * @memberof OpSparePartsApiApiOpSparePartsSparePartBySeGet
     */
    readonly seK?: string

    /**
     * Язык
     * @type {number}
     * @memberof OpSparePartsApiApiOpSparePartsSparePartBySeGet
     */
    readonly lcid?: number
}

/**
 * OpSparePartsApi - object-oriented interface
 * @export
 * @class OpSparePartsApi
 * @extends {BaseAPI}
 */
export class OpSparePartsApi extends BaseAPI {
    /**
     * 
     * @summary Выдать на участок ремонта
     * @param {OpSparePartsApiApiOpSparePartsAddPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpSparePartsApi
     */
    public apiOpSparePartsAddPost(requestParameters: OpSparePartsApiApiOpSparePartsAddPostRequest = {}, options?: AxiosRequestConfig) {
        return OpSparePartsApiFp(this.configuration).apiOpSparePartsAddPost(requestParameters.addRepairAreaSparePartsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал запчастей
     * @param {OpSparePartsApiApiOpSparePartsBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpSparePartsApi
     */
    public apiOpSparePartsBrowseGet(requestParameters: OpSparePartsApiApiOpSparePartsBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return OpSparePartsApiFp(this.configuration).apiOpSparePartsBrowseGet(requestParameters.repairArea, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Остатки запчастей в Excel
     * @param {OpSparePartsApiApiOpSparePartsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpSparePartsApi
     */
    public apiOpSparePartsExportExcelPost(requestParameters: OpSparePartsApiApiOpSparePartsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return OpSparePartsApiFp(this.configuration).apiOpSparePartsExportExcelPost(requestParameters.exportRepairAreaSparePartsToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Остатки запчастей на печать
     * @param {OpSparePartsApiApiOpSparePartsExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpSparePartsApi
     */
    public apiOpSparePartsExportPdfPost(requestParameters: OpSparePartsApiApiOpSparePartsExportPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return OpSparePartsApiFp(this.configuration).apiOpSparePartsExportPdfPost(requestParameters.exportRepairAreaSparePartsToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Возврат с участока ремонта на склад
     * @param {OpSparePartsApiApiOpSparePartsReturnPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpSparePartsApi
     */
    public apiOpSparePartsReturnPost(requestParameters: OpSparePartsApiApiOpSparePartsReturnPostRequest = {}, options?: AxiosRequestConfig) {
        return OpSparePartsApiFp(this.configuration).apiOpSparePartsReturnPost(requestParameters.returnRepairAreaSparePartsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавление запчасти сканированием штрих-кода
     * @param {OpSparePartsApiApiOpSparePartsSparePartBySeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpSparePartsApi
     */
    public apiOpSparePartsSparePartBySeGet(requestParameters: OpSparePartsApiApiOpSparePartsSparePartBySeGetRequest = {}, options?: AxiosRequestConfig) {
        return OpSparePartsApiFp(this.configuration).apiOpSparePartsSparePartBySeGet(requestParameters.seK, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpWarrantyApi - axios parameter creator
 * @export
 */
export const OpWarrantyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал приборов с продленной гарантией
         * @param {string} [filterSerial] Серийный номер
         * @param {string} [filterSeK] Код СЕ
         * @param {OrderByDirection} [orderBySerial] Сортировка по серийному номеру
         * @param {OrderByDirection} [orderBySeK] Сортировка по производственному код
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpWarrantyBrowseGet: async (filterSerial?: string, filterSeK?: string, orderBySerial?: OrderByDirection, orderBySeK?: OrderByDirection, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-warranty/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterSerial !== undefined) {
                localVarQueryParameter['Filter.Serial'] = filterSerial;
            }

            if (filterSeK !== undefined) {
                localVarQueryParameter['Filter.SeK'] = filterSeK;
            }

            if (orderBySerial !== undefined) {
                localVarQueryParameter['OrderBy.Serial'] = orderBySerial;
            }

            if (orderBySeK !== undefined) {
                localVarQueryParameter['OrderBy.SeK'] = orderBySeK;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить расширенные гарантии из файла Excel
         * @param {number} [lcid] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpWarrantyExtendedWarrantyImportFromExcelPost: async (lcid?: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-warranty/extended-warranty/import-from-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранение приборов с расширенной гарантией в БД
         * @param {ExtendedWarrantySavePayload} [extendedWarrantySavePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpWarrantyExtendedWarrantySavePost: async (extendedWarrantySavePayload?: ExtendedWarrantySavePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/op-warranty/extended-warranty/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extendedWarrantySavePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpWarrantyApi - functional programming interface
 * @export
 */
export const OpWarrantyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpWarrantyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал приборов с продленной гарантией
         * @param {string} [filterSerial] Серийный номер
         * @param {string} [filterSeK] Код СЕ
         * @param {OrderByDirection} [orderBySerial] Сортировка по серийному номеру
         * @param {OrderByDirection} [orderBySeK] Сортировка по производственному код
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpWarrantyBrowseGet(filterSerial?: string, filterSeK?: string, orderBySerial?: OrderByDirection, orderBySeK?: OrderByDirection, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowseExtendedWarrantyPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpWarrantyBrowseGet(filterSerial, filterSeK, orderBySerial, orderBySeK, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузить расширенные гарантии из файла Excel
         * @param {number} [lcid] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpWarrantyExtendedWarrantyImportFromExcelPost(lcid?: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportExtendedWarrantyFromExcelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpWarrantyExtendedWarrantyImportFromExcelPost(lcid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сохранение приборов с расширенной гарантией в БД
         * @param {ExtendedWarrantySavePayload} [extendedWarrantySavePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOpWarrantyExtendedWarrantySavePost(extendedWarrantySavePayload?: ExtendedWarrantySavePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOpWarrantyExtendedWarrantySavePost(extendedWarrantySavePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpWarrantyApi - factory interface
 * @export
 */
export const OpWarrantyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpWarrantyApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал приборов с продленной гарантией
         * @param {OpWarrantyApiApiOpWarrantyBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpWarrantyBrowseGet(requestParameters: OpWarrantyApiApiOpWarrantyBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BrowseExtendedWarrantyPagingResult> {
            return localVarFp.apiOpWarrantyBrowseGet(requestParameters.filterSerial, requestParameters.filterSeK, requestParameters.orderBySerial, requestParameters.orderBySeK, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить расширенные гарантии из файла Excel
         * @param {OpWarrantyApiApiOpWarrantyExtendedWarrantyImportFromExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpWarrantyExtendedWarrantyImportFromExcelPost(requestParameters: OpWarrantyApiApiOpWarrantyExtendedWarrantyImportFromExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ImportExtendedWarrantyFromExcelResponse> {
            return localVarFp.apiOpWarrantyExtendedWarrantyImportFromExcelPost(requestParameters.lcid, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранение приборов с расширенной гарантией в БД
         * @param {OpWarrantyApiApiOpWarrantyExtendedWarrantySavePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOpWarrantyExtendedWarrantySavePost(requestParameters: OpWarrantyApiApiOpWarrantyExtendedWarrantySavePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOpWarrantyExtendedWarrantySavePost(requestParameters.extendedWarrantySavePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOpWarrantyBrowseGet operation in OpWarrantyApi.
 * @export
 * @interface OpWarrantyApiApiOpWarrantyBrowseGetRequest
 */
export interface OpWarrantyApiApiOpWarrantyBrowseGetRequest {
    /**
     * Серийный номер
     * @type {string}
     * @memberof OpWarrantyApiApiOpWarrantyBrowseGet
     */
    readonly filterSerial?: string

    /**
     * Код СЕ
     * @type {string}
     * @memberof OpWarrantyApiApiOpWarrantyBrowseGet
     */
    readonly filterSeK?: string

    /**
     * Сортировка по серийному номеру
     * @type {OrderByDirection}
     * @memberof OpWarrantyApiApiOpWarrantyBrowseGet
     */
    readonly orderBySerial?: OrderByDirection

    /**
     * Сортировка по производственному код
     * @type {OrderByDirection}
     * @memberof OpWarrantyApiApiOpWarrantyBrowseGet
     */
    readonly orderBySeK?: OrderByDirection

    /**
     * 
     * @type {number}
     * @memberof OpWarrantyApiApiOpWarrantyBrowseGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof OpWarrantyApiApiOpWarrantyBrowseGet
     */
    readonly pagingPageSize?: number
}

/**
 * Request parameters for apiOpWarrantyExtendedWarrantyImportFromExcelPost operation in OpWarrantyApi.
 * @export
 * @interface OpWarrantyApiApiOpWarrantyExtendedWarrantyImportFromExcelPostRequest
 */
export interface OpWarrantyApiApiOpWarrantyExtendedWarrantyImportFromExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof OpWarrantyApiApiOpWarrantyExtendedWarrantyImportFromExcelPost
     */
    readonly lcid?: number

    /**
     * 
     * @type {File}
     * @memberof OpWarrantyApiApiOpWarrantyExtendedWarrantyImportFromExcelPost
     */
    readonly file?: File
}

/**
 * Request parameters for apiOpWarrantyExtendedWarrantySavePost operation in OpWarrantyApi.
 * @export
 * @interface OpWarrantyApiApiOpWarrantyExtendedWarrantySavePostRequest
 */
export interface OpWarrantyApiApiOpWarrantyExtendedWarrantySavePostRequest {
    /**
     * 
     * @type {ExtendedWarrantySavePayload}
     * @memberof OpWarrantyApiApiOpWarrantyExtendedWarrantySavePost
     */
    readonly extendedWarrantySavePayload?: ExtendedWarrantySavePayload
}

/**
 * OpWarrantyApi - object-oriented interface
 * @export
 * @class OpWarrantyApi
 * @extends {BaseAPI}
 */
export class OpWarrantyApi extends BaseAPI {
    /**
     * 
     * @summary Журнал приборов с продленной гарантией
     * @param {OpWarrantyApiApiOpWarrantyBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpWarrantyApi
     */
    public apiOpWarrantyBrowseGet(requestParameters: OpWarrantyApiApiOpWarrantyBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return OpWarrantyApiFp(this.configuration).apiOpWarrantyBrowseGet(requestParameters.filterSerial, requestParameters.filterSeK, requestParameters.orderBySerial, requestParameters.orderBySeK, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить расширенные гарантии из файла Excel
     * @param {OpWarrantyApiApiOpWarrantyExtendedWarrantyImportFromExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpWarrantyApi
     */
    public apiOpWarrantyExtendedWarrantyImportFromExcelPost(requestParameters: OpWarrantyApiApiOpWarrantyExtendedWarrantyImportFromExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return OpWarrantyApiFp(this.configuration).apiOpWarrantyExtendedWarrantyImportFromExcelPost(requestParameters.lcid, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранение приборов с расширенной гарантией в БД
     * @param {OpWarrantyApiApiOpWarrantyExtendedWarrantySavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpWarrantyApi
     */
    public apiOpWarrantyExtendedWarrantySavePost(requestParameters: OpWarrantyApiApiOpWarrantyExtendedWarrantySavePostRequest = {}, options?: AxiosRequestConfig) {
        return OpWarrantyApiFp(this.configuration).apiOpWarrantyExtendedWarrantySavePost(requestParameters.extendedWarrantySavePayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecurringReportsApi - axios parameter creator
 * @export
 */
export const RecurringReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Перечень сохраненных периодических отчетов
         * @param {ReportType} [fileName] 
         * @param {number} [firmId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecurringReportsBrowseGet: async (fileName?: ReportType, firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/recurring-reports/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['FileName'] = fileName;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Платный ремонт - выгрузка в Excel
         * @param {number} [fileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecurringReportsDownloadGet: async (fileId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/recurring-reports/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecurringReportsApi - functional programming interface
 * @export
 */
export const RecurringReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecurringReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Перечень сохраненных периодических отчетов
         * @param {ReportType} [fileName] 
         * @param {number} [firmId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecurringReportsBrowseGet(fileName?: ReportType, firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportFileBrowseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecurringReportsBrowseGet(fileName, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Платный ремонт - выгрузка в Excel
         * @param {number} [fileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecurringReportsDownloadGet(fileId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecurringReportsDownloadGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecurringReportsApi - factory interface
 * @export
 */
export const RecurringReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecurringReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Перечень сохраненных периодических отчетов
         * @param {RecurringReportsApiApiRecurringReportsBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecurringReportsBrowseGet(requestParameters: RecurringReportsApiApiRecurringReportsBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ReportFileBrowseResponse>> {
            return localVarFp.apiRecurringReportsBrowseGet(requestParameters.fileName, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Платный ремонт - выгрузка в Excel
         * @param {RecurringReportsApiApiRecurringReportsDownloadGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecurringReportsDownloadGet(requestParameters: RecurringReportsApiApiRecurringReportsDownloadGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiRecurringReportsDownloadGet(requestParameters.fileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiRecurringReportsBrowseGet operation in RecurringReportsApi.
 * @export
 * @interface RecurringReportsApiApiRecurringReportsBrowseGetRequest
 */
export interface RecurringReportsApiApiRecurringReportsBrowseGetRequest {
    /**
     * 
     * @type {ReportType}
     * @memberof RecurringReportsApiApiRecurringReportsBrowseGet
     */
    readonly fileName?: ReportType

    /**
     * 
     * @type {number}
     * @memberof RecurringReportsApiApiRecurringReportsBrowseGet
     */
    readonly firmId?: number
}

/**
 * Request parameters for apiRecurringReportsDownloadGet operation in RecurringReportsApi.
 * @export
 * @interface RecurringReportsApiApiRecurringReportsDownloadGetRequest
 */
export interface RecurringReportsApiApiRecurringReportsDownloadGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RecurringReportsApiApiRecurringReportsDownloadGet
     */
    readonly fileId?: number
}

/**
 * RecurringReportsApi - object-oriented interface
 * @export
 * @class RecurringReportsApi
 * @extends {BaseAPI}
 */
export class RecurringReportsApi extends BaseAPI {
    /**
     * 
     * @summary Перечень сохраненных периодических отчетов
     * @param {RecurringReportsApiApiRecurringReportsBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringReportsApi
     */
    public apiRecurringReportsBrowseGet(requestParameters: RecurringReportsApiApiRecurringReportsBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return RecurringReportsApiFp(this.configuration).apiRecurringReportsBrowseGet(requestParameters.fileName, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Платный ремонт - выгрузка в Excel
     * @param {RecurringReportsApiApiRecurringReportsDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringReportsApi
     */
    public apiRecurringReportsDownloadGet(requestParameters: RecurringReportsApiApiRecurringReportsDownloadGetRequest = {}, options?: AxiosRequestConfig) {
        return RecurringReportsApiFp(this.configuration).apiRecurringReportsDownloadGet(requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsAddedPartsApi - axios parameter creator
 * @export
 */
export const ReportsAddedPartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Отчет по доукомплектованным деталям - выгрузка в Excel
         * @param {AddedPartsReportExportPayload} [addedPartsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsAddedPartsExportExcelPost: async (addedPartsReportExportPayload?: AddedPartsReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-added-parts/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addedPartsReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsAddedPartsApi - functional programming interface
 * @export
 */
export const ReportsAddedPartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsAddedPartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Отчет по доукомплектованным деталям - выгрузка в Excel
         * @param {AddedPartsReportExportPayload} [addedPartsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsAddedPartsExportExcelPost(addedPartsReportExportPayload?: AddedPartsReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsAddedPartsExportExcelPost(addedPartsReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsAddedPartsApi - factory interface
 * @export
 */
export const ReportsAddedPartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsAddedPartsApiFp(configuration)
    return {
        /**
         * 
         * @summary Отчет по доукомплектованным деталям - выгрузка в Excel
         * @param {ReportsAddedPartsApiApiReportsAddedPartsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsAddedPartsExportExcelPost(requestParameters: ReportsAddedPartsApiApiReportsAddedPartsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsAddedPartsExportExcelPost(requestParameters.addedPartsReportExportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsAddedPartsExportExcelPost operation in ReportsAddedPartsApi.
 * @export
 * @interface ReportsAddedPartsApiApiReportsAddedPartsExportExcelPostRequest
 */
export interface ReportsAddedPartsApiApiReportsAddedPartsExportExcelPostRequest {
    /**
     * 
     * @type {AddedPartsReportExportPayload}
     * @memberof ReportsAddedPartsApiApiReportsAddedPartsExportExcelPost
     */
    readonly addedPartsReportExportPayload?: AddedPartsReportExportPayload
}

/**
 * ReportsAddedPartsApi - object-oriented interface
 * @export
 * @class ReportsAddedPartsApi
 * @extends {BaseAPI}
 */
export class ReportsAddedPartsApi extends BaseAPI {
    /**
     * 
     * @summary Отчет по доукомплектованным деталям - выгрузка в Excel
     * @param {ReportsAddedPartsApiApiReportsAddedPartsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsAddedPartsApi
     */
    public apiReportsAddedPartsExportExcelPost(requestParameters: ReportsAddedPartsApiApiReportsAddedPartsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsAddedPartsApiFp(this.configuration).apiReportsAddedPartsExportExcelPost(requestParameters.addedPartsReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsCategoryAApi - axios parameter creator
 * @export
 */
export const ReportsCategoryAApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary КатегорияА - выгрузка в Excel
         * @param {CategoryAReportExportPayload} [categoryAReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsCategoryAExportExcelPost: async (categoryAReportExportPayload?: CategoryAReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-category-a/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryAReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Категория А - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsCategoryAReportGet: async (dateFrom?: string, dateTo?: string, firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-category-a/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsCategoryAApi - functional programming interface
 * @export
 */
export const ReportsCategoryAApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsCategoryAApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary КатегорияА - выгрузка в Excel
         * @param {CategoryAReportExportPayload} [categoryAReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsCategoryAExportExcelPost(categoryAReportExportPayload?: CategoryAReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsCategoryAExportExcelPost(categoryAReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Категория А - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsCategoryAReportGet(dateFrom?: string, dateTo?: string, firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryAReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsCategoryAReportGet(dateFrom, dateTo, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsCategoryAApi - factory interface
 * @export
 */
export const ReportsCategoryAApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsCategoryAApiFp(configuration)
    return {
        /**
         * 
         * @summary КатегорияА - выгрузка в Excel
         * @param {ReportsCategoryAApiApiReportsCategoryAExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsCategoryAExportExcelPost(requestParameters: ReportsCategoryAApiApiReportsCategoryAExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsCategoryAExportExcelPost(requestParameters.categoryAReportExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Категория А - данные
         * @param {ReportsCategoryAApiApiReportsCategoryAReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsCategoryAReportGet(requestParameters: ReportsCategoryAApiApiReportsCategoryAReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CategoryAReportDto> {
            return localVarFp.apiReportsCategoryAReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsCategoryAExportExcelPost operation in ReportsCategoryAApi.
 * @export
 * @interface ReportsCategoryAApiApiReportsCategoryAExportExcelPostRequest
 */
export interface ReportsCategoryAApiApiReportsCategoryAExportExcelPostRequest {
    /**
     * 
     * @type {CategoryAReportExportPayload}
     * @memberof ReportsCategoryAApiApiReportsCategoryAExportExcelPost
     */
    readonly categoryAReportExportPayload?: CategoryAReportExportPayload
}

/**
 * Request parameters for apiReportsCategoryAReportGet operation in ReportsCategoryAApi.
 * @export
 * @interface ReportsCategoryAApiApiReportsCategoryAReportGetRequest
 */
export interface ReportsCategoryAApiApiReportsCategoryAReportGetRequest {
    /**
     * Начало периода
     * @type {string}
     * @memberof ReportsCategoryAApiApiReportsCategoryAReportGet
     */
    readonly dateFrom?: string

    /**
     * конец периода
     * @type {string}
     * @memberof ReportsCategoryAApiApiReportsCategoryAReportGet
     */
    readonly dateTo?: string

    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReportsCategoryAApiApiReportsCategoryAReportGet
     */
    readonly firmId?: number

    /**
     * язык
     * @type {number}
     * @memberof ReportsCategoryAApiApiReportsCategoryAReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsCategoryAApi - object-oriented interface
 * @export
 * @class ReportsCategoryAApi
 * @extends {BaseAPI}
 */
export class ReportsCategoryAApi extends BaseAPI {
    /**
     * 
     * @summary КатегорияА - выгрузка в Excel
     * @param {ReportsCategoryAApiApiReportsCategoryAExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCategoryAApi
     */
    public apiReportsCategoryAExportExcelPost(requestParameters: ReportsCategoryAApiApiReportsCategoryAExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsCategoryAApiFp(this.configuration).apiReportsCategoryAExportExcelPost(requestParameters.categoryAReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Категория А - данные
     * @param {ReportsCategoryAApiApiReportsCategoryAReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCategoryAApi
     */
    public apiReportsCategoryAReportGet(requestParameters: ReportsCategoryAApiApiReportsCategoryAReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsCategoryAApiFp(this.configuration).apiReportsCategoryAReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsDefectsComplianceApi - axios parameter creator
 * @export
 */
export const ReportsDefectsComplianceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Соответствие заявленным дефектам - выгрузка в Excel
         * @param {DefectsComplianceExportPayload} [defectsComplianceExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsComplianceExportExcelPost: async (defectsComplianceExportPayload?: DefectsComplianceExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-defects-compliance/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defectsComplianceExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Соответствие заявленным дефектам - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsComplianceReportGet: async (periodStart?: string, periodEnd?: string, firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-defects-compliance/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsDefectsComplianceApi - functional programming interface
 * @export
 */
export const ReportsDefectsComplianceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsDefectsComplianceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Соответствие заявленным дефектам - выгрузка в Excel
         * @param {DefectsComplianceExportPayload} [defectsComplianceExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDefectsComplianceExportExcelPost(defectsComplianceExportPayload?: DefectsComplianceExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDefectsComplianceExportExcelPost(defectsComplianceExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Соответствие заявленным дефектам - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDefectsComplianceReportGet(periodStart?: string, periodEnd?: string, firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefectsComplianceReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDefectsComplianceReportGet(periodStart, periodEnd, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsDefectsComplianceApi - factory interface
 * @export
 */
export const ReportsDefectsComplianceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsDefectsComplianceApiFp(configuration)
    return {
        /**
         * 
         * @summary Соответствие заявленным дефектам - выгрузка в Excel
         * @param {ReportsDefectsComplianceApiApiReportsDefectsComplianceExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsComplianceExportExcelPost(requestParameters: ReportsDefectsComplianceApiApiReportsDefectsComplianceExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsDefectsComplianceExportExcelPost(requestParameters.defectsComplianceExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Соответствие заявленным дефектам - данные
         * @param {ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsComplianceReportGet(requestParameters: ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<DefectsComplianceReportDto> {
            return localVarFp.apiReportsDefectsComplianceReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsDefectsComplianceExportExcelPost operation in ReportsDefectsComplianceApi.
 * @export
 * @interface ReportsDefectsComplianceApiApiReportsDefectsComplianceExportExcelPostRequest
 */
export interface ReportsDefectsComplianceApiApiReportsDefectsComplianceExportExcelPostRequest {
    /**
     * 
     * @type {DefectsComplianceExportPayload}
     * @memberof ReportsDefectsComplianceApiApiReportsDefectsComplianceExportExcelPost
     */
    readonly defectsComplianceExportPayload?: DefectsComplianceExportPayload
}

/**
 * Request parameters for apiReportsDefectsComplianceReportGet operation in ReportsDefectsComplianceApi.
 * @export
 * @interface ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGetRequest
 */
export interface ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGet
     */
    readonly periodStart?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGet
     */
    readonly periodEnd?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGet
     */
    readonly firmId?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsDefectsComplianceApi - object-oriented interface
 * @export
 * @class ReportsDefectsComplianceApi
 * @extends {BaseAPI}
 */
export class ReportsDefectsComplianceApi extends BaseAPI {
    /**
     * 
     * @summary Соответствие заявленным дефектам - выгрузка в Excel
     * @param {ReportsDefectsComplianceApiApiReportsDefectsComplianceExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsDefectsComplianceApi
     */
    public apiReportsDefectsComplianceExportExcelPost(requestParameters: ReportsDefectsComplianceApiApiReportsDefectsComplianceExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsDefectsComplianceApiFp(this.configuration).apiReportsDefectsComplianceExportExcelPost(requestParameters.defectsComplianceExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Соответствие заявленным дефектам - данные
     * @param {ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsDefectsComplianceApi
     */
    public apiReportsDefectsComplianceReportGet(requestParameters: ReportsDefectsComplianceApiApiReportsDefectsComplianceReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsDefectsComplianceApiFp(this.configuration).apiReportsDefectsComplianceReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsDefectsMovementApi - axios parameter creator
 * @export
 */
export const ReportsDefectsMovementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Движение брака по покупателям (RMA) за период - выгрузка в Excel
         * @param {DefectsMovementReportExportPayload} [defectsMovementReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsMovementExportExcelPost: async (defectsMovementReportExportPayload?: DefectsMovementReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-defects-movement/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defectsMovementReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Движение брака по покупателям (RMA) за период - выгрузка в Pdf
         * @param {DefectsMovementReportExportToPdfPayload} [defectsMovementReportExportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsMovementExportPdfPost: async (defectsMovementReportExportToPdfPayload?: DefectsMovementReportExportToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-defects-movement/export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defectsMovementReportExportToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Движение брака по покупателям (RMA) за период - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsMovementReportGet: async (dateFrom?: string, dateTo?: string, firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-defects-movement/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsDefectsMovementApi - functional programming interface
 * @export
 */
export const ReportsDefectsMovementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsDefectsMovementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Движение брака по покупателям (RMA) за период - выгрузка в Excel
         * @param {DefectsMovementReportExportPayload} [defectsMovementReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDefectsMovementExportExcelPost(defectsMovementReportExportPayload?: DefectsMovementReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDefectsMovementExportExcelPost(defectsMovementReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Движение брака по покупателям (RMA) за период - выгрузка в Pdf
         * @param {DefectsMovementReportExportToPdfPayload} [defectsMovementReportExportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDefectsMovementExportPdfPost(defectsMovementReportExportToPdfPayload?: DefectsMovementReportExportToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDefectsMovementExportPdfPost(defectsMovementReportExportToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Движение брака по покупателям (RMA) за период - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDefectsMovementReportGet(dateFrom?: string, dateTo?: string, firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefectsMovementReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDefectsMovementReportGet(dateFrom, dateTo, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsDefectsMovementApi - factory interface
 * @export
 */
export const ReportsDefectsMovementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsDefectsMovementApiFp(configuration)
    return {
        /**
         * 
         * @summary Движение брака по покупателям (RMA) за период - выгрузка в Excel
         * @param {ReportsDefectsMovementApiApiReportsDefectsMovementExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsMovementExportExcelPost(requestParameters: ReportsDefectsMovementApiApiReportsDefectsMovementExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsDefectsMovementExportExcelPost(requestParameters.defectsMovementReportExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Движение брака по покупателям (RMA) за период - выгрузка в Pdf
         * @param {ReportsDefectsMovementApiApiReportsDefectsMovementExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsMovementExportPdfPost(requestParameters: ReportsDefectsMovementApiApiReportsDefectsMovementExportPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsDefectsMovementExportPdfPost(requestParameters.defectsMovementReportExportToPdfPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Движение брака по покупателям (RMA) за период - данные
         * @param {ReportsDefectsMovementApiApiReportsDefectsMovementReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDefectsMovementReportGet(requestParameters: ReportsDefectsMovementApiApiReportsDefectsMovementReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<DefectsMovementReportDto> {
            return localVarFp.apiReportsDefectsMovementReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsDefectsMovementExportExcelPost operation in ReportsDefectsMovementApi.
 * @export
 * @interface ReportsDefectsMovementApiApiReportsDefectsMovementExportExcelPostRequest
 */
export interface ReportsDefectsMovementApiApiReportsDefectsMovementExportExcelPostRequest {
    /**
     * 
     * @type {DefectsMovementReportExportPayload}
     * @memberof ReportsDefectsMovementApiApiReportsDefectsMovementExportExcelPost
     */
    readonly defectsMovementReportExportPayload?: DefectsMovementReportExportPayload
}

/**
 * Request parameters for apiReportsDefectsMovementExportPdfPost operation in ReportsDefectsMovementApi.
 * @export
 * @interface ReportsDefectsMovementApiApiReportsDefectsMovementExportPdfPostRequest
 */
export interface ReportsDefectsMovementApiApiReportsDefectsMovementExportPdfPostRequest {
    /**
     * 
     * @type {DefectsMovementReportExportToPdfPayload}
     * @memberof ReportsDefectsMovementApiApiReportsDefectsMovementExportPdfPost
     */
    readonly defectsMovementReportExportToPdfPayload?: DefectsMovementReportExportToPdfPayload
}

/**
 * Request parameters for apiReportsDefectsMovementReportGet operation in ReportsDefectsMovementApi.
 * @export
 * @interface ReportsDefectsMovementApiApiReportsDefectsMovementReportGetRequest
 */
export interface ReportsDefectsMovementApiApiReportsDefectsMovementReportGetRequest {
    /**
     * Начало периода
     * @type {string}
     * @memberof ReportsDefectsMovementApiApiReportsDefectsMovementReportGet
     */
    readonly dateFrom?: string

    /**
     * конец периода
     * @type {string}
     * @memberof ReportsDefectsMovementApiApiReportsDefectsMovementReportGet
     */
    readonly dateTo?: string

    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReportsDefectsMovementApiApiReportsDefectsMovementReportGet
     */
    readonly firmId?: number

    /**
     * язык
     * @type {number}
     * @memberof ReportsDefectsMovementApiApiReportsDefectsMovementReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsDefectsMovementApi - object-oriented interface
 * @export
 * @class ReportsDefectsMovementApi
 * @extends {BaseAPI}
 */
export class ReportsDefectsMovementApi extends BaseAPI {
    /**
     * 
     * @summary Движение брака по покупателям (RMA) за период - выгрузка в Excel
     * @param {ReportsDefectsMovementApiApiReportsDefectsMovementExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsDefectsMovementApi
     */
    public apiReportsDefectsMovementExportExcelPost(requestParameters: ReportsDefectsMovementApiApiReportsDefectsMovementExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsDefectsMovementApiFp(this.configuration).apiReportsDefectsMovementExportExcelPost(requestParameters.defectsMovementReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Движение брака по покупателям (RMA) за период - выгрузка в Pdf
     * @param {ReportsDefectsMovementApiApiReportsDefectsMovementExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsDefectsMovementApi
     */
    public apiReportsDefectsMovementExportPdfPost(requestParameters: ReportsDefectsMovementApiApiReportsDefectsMovementExportPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsDefectsMovementApiFp(this.configuration).apiReportsDefectsMovementExportPdfPost(requestParameters.defectsMovementReportExportToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Движение брака по покупателям (RMA) за период - данные
     * @param {ReportsDefectsMovementApiApiReportsDefectsMovementReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsDefectsMovementApi
     */
    public apiReportsDefectsMovementReportGet(requestParameters: ReportsDefectsMovementApiApiReportsDefectsMovementReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsDefectsMovementApiFp(this.configuration).apiReportsDefectsMovementReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsDeviceArrivalTimeApi - axios parameter creator
 * @export
 */
export const ReportsDeviceArrivalTimeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Срок поступления приборов на ремонт - выгрузка в Excel
         * @param {DeviceArrivalTimeReportPayload} [deviceArrivalTimeReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDeviceArrivalTimeExportExcelPost: async (deviceArrivalTimeReportPayload?: DeviceArrivalTimeReportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-device-arrival-time/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceArrivalTimeReportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsDeviceArrivalTimeApi - functional programming interface
 * @export
 */
export const ReportsDeviceArrivalTimeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsDeviceArrivalTimeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Срок поступления приборов на ремонт - выгрузка в Excel
         * @param {DeviceArrivalTimeReportPayload} [deviceArrivalTimeReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDeviceArrivalTimeExportExcelPost(deviceArrivalTimeReportPayload?: DeviceArrivalTimeReportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDeviceArrivalTimeExportExcelPost(deviceArrivalTimeReportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsDeviceArrivalTimeApi - factory interface
 * @export
 */
export const ReportsDeviceArrivalTimeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsDeviceArrivalTimeApiFp(configuration)
    return {
        /**
         * 
         * @summary Срок поступления приборов на ремонт - выгрузка в Excel
         * @param {ReportsDeviceArrivalTimeApiApiReportsDeviceArrivalTimeExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDeviceArrivalTimeExportExcelPost(requestParameters: ReportsDeviceArrivalTimeApiApiReportsDeviceArrivalTimeExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsDeviceArrivalTimeExportExcelPost(requestParameters.deviceArrivalTimeReportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsDeviceArrivalTimeExportExcelPost operation in ReportsDeviceArrivalTimeApi.
 * @export
 * @interface ReportsDeviceArrivalTimeApiApiReportsDeviceArrivalTimeExportExcelPostRequest
 */
export interface ReportsDeviceArrivalTimeApiApiReportsDeviceArrivalTimeExportExcelPostRequest {
    /**
     * 
     * @type {DeviceArrivalTimeReportPayload}
     * @memberof ReportsDeviceArrivalTimeApiApiReportsDeviceArrivalTimeExportExcelPost
     */
    readonly deviceArrivalTimeReportPayload?: DeviceArrivalTimeReportPayload
}

/**
 * ReportsDeviceArrivalTimeApi - object-oriented interface
 * @export
 * @class ReportsDeviceArrivalTimeApi
 * @extends {BaseAPI}
 */
export class ReportsDeviceArrivalTimeApi extends BaseAPI {
    /**
     * 
     * @summary Срок поступления приборов на ремонт - выгрузка в Excel
     * @param {ReportsDeviceArrivalTimeApiApiReportsDeviceArrivalTimeExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsDeviceArrivalTimeApi
     */
    public apiReportsDeviceArrivalTimeExportExcelPost(requestParameters: ReportsDeviceArrivalTimeApiApiReportsDeviceArrivalTimeExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsDeviceArrivalTimeApiFp(this.configuration).apiReportsDeviceArrivalTimeExportExcelPost(requestParameters.deviceArrivalTimeReportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsDistributorsDevicesApi - axios parameter creator
 * @export
 */
export const ReportsDistributorsDevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Приборы дистрибьюторов - выгрузка в Excel
         * @param {DistributorsDevicesReportExcelPayload} [distributorsDevicesReportExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDistributorsDevicesExportExcelPost: async (distributorsDevicesReportExcelPayload?: DistributorsDevicesReportExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-distributors-devices/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributorsDevicesReportExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsDistributorsDevicesApi - functional programming interface
 * @export
 */
export const ReportsDistributorsDevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsDistributorsDevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Приборы дистрибьюторов - выгрузка в Excel
         * @param {DistributorsDevicesReportExcelPayload} [distributorsDevicesReportExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDistributorsDevicesExportExcelPost(distributorsDevicesReportExcelPayload?: DistributorsDevicesReportExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDistributorsDevicesExportExcelPost(distributorsDevicesReportExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsDistributorsDevicesApi - factory interface
 * @export
 */
export const ReportsDistributorsDevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsDistributorsDevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Приборы дистрибьюторов - выгрузка в Excel
         * @param {ReportsDistributorsDevicesApiApiReportsDistributorsDevicesExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDistributorsDevicesExportExcelPost(requestParameters: ReportsDistributorsDevicesApiApiReportsDistributorsDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsDistributorsDevicesExportExcelPost(requestParameters.distributorsDevicesReportExcelPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsDistributorsDevicesExportExcelPost operation in ReportsDistributorsDevicesApi.
 * @export
 * @interface ReportsDistributorsDevicesApiApiReportsDistributorsDevicesExportExcelPostRequest
 */
export interface ReportsDistributorsDevicesApiApiReportsDistributorsDevicesExportExcelPostRequest {
    /**
     * 
     * @type {DistributorsDevicesReportExcelPayload}
     * @memberof ReportsDistributorsDevicesApiApiReportsDistributorsDevicesExportExcelPost
     */
    readonly distributorsDevicesReportExcelPayload?: DistributorsDevicesReportExcelPayload
}

/**
 * ReportsDistributorsDevicesApi - object-oriented interface
 * @export
 * @class ReportsDistributorsDevicesApi
 * @extends {BaseAPI}
 */
export class ReportsDistributorsDevicesApi extends BaseAPI {
    /**
     * 
     * @summary Приборы дистрибьюторов - выгрузка в Excel
     * @param {ReportsDistributorsDevicesApiApiReportsDistributorsDevicesExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsDistributorsDevicesApi
     */
    public apiReportsDistributorsDevicesExportExcelPost(requestParameters: ReportsDistributorsDevicesApiApiReportsDistributorsDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsDistributorsDevicesApiFp(this.configuration).apiReportsDistributorsDevicesExportExcelPost(requestParameters.distributorsDevicesReportExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsDistributorsRatingApi - axios parameter creator
 * @export
 */
export const ReportsDistributorsRatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Оценка дистрибьюторов - выгрузка в Excel
         * @param {DistributorsRatingReportExcelPayload} [distributorsRatingReportExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDistributorsRatingExportExcelPost: async (distributorsRatingReportExcelPayload?: DistributorsRatingReportExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-distributors-rating/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributorsRatingReportExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Оценка дистрибьюторов - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDistributorsRatingReportGet: async (dateFrom?: string, dateTo?: string, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-distributors-rating/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsDistributorsRatingApi - functional programming interface
 * @export
 */
export const ReportsDistributorsRatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsDistributorsRatingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Оценка дистрибьюторов - выгрузка в Excel
         * @param {DistributorsRatingReportExcelPayload} [distributorsRatingReportExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDistributorsRatingExportExcelPost(distributorsRatingReportExcelPayload?: DistributorsRatingReportExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDistributorsRatingExportExcelPost(distributorsRatingReportExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Оценка дистрибьюторов - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDistributorsRatingReportGet(dateFrom?: string, dateTo?: string, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DistributorsRatingReportItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDistributorsRatingReportGet(dateFrom, dateTo, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsDistributorsRatingApi - factory interface
 * @export
 */
export const ReportsDistributorsRatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsDistributorsRatingApiFp(configuration)
    return {
        /**
         * 
         * @summary Оценка дистрибьюторов - выгрузка в Excel
         * @param {ReportsDistributorsRatingApiApiReportsDistributorsRatingExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDistributorsRatingExportExcelPost(requestParameters: ReportsDistributorsRatingApiApiReportsDistributorsRatingExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsDistributorsRatingExportExcelPost(requestParameters.distributorsRatingReportExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Оценка дистрибьюторов - данные
         * @param {ReportsDistributorsRatingApiApiReportsDistributorsRatingReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDistributorsRatingReportGet(requestParameters: ReportsDistributorsRatingApiApiReportsDistributorsRatingReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<DistributorsRatingReportItemDto>> {
            return localVarFp.apiReportsDistributorsRatingReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsDistributorsRatingExportExcelPost operation in ReportsDistributorsRatingApi.
 * @export
 * @interface ReportsDistributorsRatingApiApiReportsDistributorsRatingExportExcelPostRequest
 */
export interface ReportsDistributorsRatingApiApiReportsDistributorsRatingExportExcelPostRequest {
    /**
     * 
     * @type {DistributorsRatingReportExcelPayload}
     * @memberof ReportsDistributorsRatingApiApiReportsDistributorsRatingExportExcelPost
     */
    readonly distributorsRatingReportExcelPayload?: DistributorsRatingReportExcelPayload
}

/**
 * Request parameters for apiReportsDistributorsRatingReportGet operation in ReportsDistributorsRatingApi.
 * @export
 * @interface ReportsDistributorsRatingApiApiReportsDistributorsRatingReportGetRequest
 */
export interface ReportsDistributorsRatingApiApiReportsDistributorsRatingReportGetRequest {
    /**
     * Начало периода
     * @type {string}
     * @memberof ReportsDistributorsRatingApiApiReportsDistributorsRatingReportGet
     */
    readonly dateFrom?: string

    /**
     * конец периода
     * @type {string}
     * @memberof ReportsDistributorsRatingApiApiReportsDistributorsRatingReportGet
     */
    readonly dateTo?: string

    /**
     * язык
     * @type {number}
     * @memberof ReportsDistributorsRatingApiApiReportsDistributorsRatingReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsDistributorsRatingApi - object-oriented interface
 * @export
 * @class ReportsDistributorsRatingApi
 * @extends {BaseAPI}
 */
export class ReportsDistributorsRatingApi extends BaseAPI {
    /**
     * 
     * @summary Оценка дистрибьюторов - выгрузка в Excel
     * @param {ReportsDistributorsRatingApiApiReportsDistributorsRatingExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsDistributorsRatingApi
     */
    public apiReportsDistributorsRatingExportExcelPost(requestParameters: ReportsDistributorsRatingApiApiReportsDistributorsRatingExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsDistributorsRatingApiFp(this.configuration).apiReportsDistributorsRatingExportExcelPost(requestParameters.distributorsRatingReportExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Оценка дистрибьюторов - данные
     * @param {ReportsDistributorsRatingApiApiReportsDistributorsRatingReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsDistributorsRatingApi
     */
    public apiReportsDistributorsRatingReportGet(requestParameters: ReportsDistributorsRatingApiApiReportsDistributorsRatingReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsDistributorsRatingApiFp(this.configuration).apiReportsDistributorsRatingReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsGeneralAverageRepairTimeApi - axios parameter creator
 * @export
 */
export const ReportsGeneralAverageRepairTimeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Просмотр сохраненных данных за год
         * @param {number} [firmId] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeBrowseGet: async (firmId?: number, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-general-average-repair-time/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cреднее время ремонта - выгрузка в excel данных по предприятию за месяц
         * @param {ExportAverageRepairPeriodReportPayload} [exportAverageRepairPeriodReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeExportExcelFirmMonthPost: async (exportAverageRepairPeriodReportPayload?: ExportAverageRepairPeriodReportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-general-average-repair-time/export-excel-firm-month`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportAverageRepairPeriodReportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cреднее время ремонта - выгрузка в excel данных по предприятию за период
         * @param {AverageRepairTimePeriodicalReporExportPayload} [averageRepairTimePeriodicalReporExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPost: async (averageRepairTimePeriodicalReporExportPayload?: AverageRepairTimePeriodicalReporExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-general-average-repair-time/export-excel-firm-periodical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(averageRepairTimePeriodicalReporExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cреднее время ремонта - выгрузка в excel данных по предприятиям компании за год
         * @param {ExportAverageRepairPeriodForYearReportPayload} [exportAverageRepairPeriodForYearReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeExportExcelFirmYearPost: async (exportAverageRepairPeriodForYearReportPayload?: ExportAverageRepairPeriodForYearReportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-general-average-repair-time/export-excel-firm-year`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportAverageRepairPeriodForYearReportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Общее среднее время ремонта - выгрузка в excel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeExportExcelGeneralPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-general-average-repair-time/export-excel-general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Общее среднее время ремонта - загрузить данные по компании за месяц из Excel
         * @param {number} [firmId] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeImportPost: async (firmId?: number, year?: number, month?: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-general-average-repair-time/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Общее среднее время ремонта - получить данные по предприятию за месяц
         * @param {number} [firmId] Ключ фирмы
         * @param {number} [year] Год
         * @param {number} [month] Месяц
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeReportFirmMonthGet: async (firmId?: number, year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-general-average-repair-time/report-firm-month`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Общее среднее время ремонта - сохранить данные по предприятию за месяц
         * @param {AverageRepairPeriodReportSavePayload} [averageRepairPeriodReportSavePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeSavePost: async (averageRepairPeriodReportSavePayload?: AverageRepairPeriodReportSavePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-general-average-repair-time/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(averageRepairPeriodReportSavePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsGeneralAverageRepairTimeApi - functional programming interface
 * @export
 */
export const ReportsGeneralAverageRepairTimeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsGeneralAverageRepairTimeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Просмотр сохраненных данных за год
         * @param {number} [firmId] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsGeneralAverageRepairTimeBrowseGet(firmId?: number, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AverageRepairPeriodBrowseItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsGeneralAverageRepairTimeBrowseGet(firmId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cреднее время ремонта - выгрузка в excel данных по предприятию за месяц
         * @param {ExportAverageRepairPeriodReportPayload} [exportAverageRepairPeriodReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsGeneralAverageRepairTimeExportExcelFirmMonthPost(exportAverageRepairPeriodReportPayload?: ExportAverageRepairPeriodReportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsGeneralAverageRepairTimeExportExcelFirmMonthPost(exportAverageRepairPeriodReportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cреднее время ремонта - выгрузка в excel данных по предприятию за период
         * @param {AverageRepairTimePeriodicalReporExportPayload} [averageRepairTimePeriodicalReporExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPost(averageRepairTimePeriodicalReporExportPayload?: AverageRepairTimePeriodicalReporExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPost(averageRepairTimePeriodicalReporExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cреднее время ремонта - выгрузка в excel данных по предприятиям компании за год
         * @param {ExportAverageRepairPeriodForYearReportPayload} [exportAverageRepairPeriodForYearReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsGeneralAverageRepairTimeExportExcelFirmYearPost(exportAverageRepairPeriodForYearReportPayload?: ExportAverageRepairPeriodForYearReportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsGeneralAverageRepairTimeExportExcelFirmYearPost(exportAverageRepairPeriodForYearReportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Общее среднее время ремонта - выгрузка в excel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsGeneralAverageRepairTimeExportExcelGeneralPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsGeneralAverageRepairTimeExportExcelGeneralPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Общее среднее время ремонта - загрузить данные по компании за месяц из Excel
         * @param {number} [firmId] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsGeneralAverageRepairTimeImportPost(firmId?: number, year?: number, month?: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AverageRepairPeriodReportImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsGeneralAverageRepairTimeImportPost(firmId, year, month, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Общее среднее время ремонта - получить данные по предприятию за месяц
         * @param {number} [firmId] Ключ фирмы
         * @param {number} [year] Год
         * @param {number} [month] Месяц
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsGeneralAverageRepairTimeReportFirmMonthGet(firmId?: number, year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AverageRepairPeriodReportItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsGeneralAverageRepairTimeReportFirmMonthGet(firmId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Общее среднее время ремонта - сохранить данные по предприятию за месяц
         * @param {AverageRepairPeriodReportSavePayload} [averageRepairPeriodReportSavePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsGeneralAverageRepairTimeSavePost(averageRepairPeriodReportSavePayload?: AverageRepairPeriodReportSavePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsGeneralAverageRepairTimeSavePost(averageRepairPeriodReportSavePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsGeneralAverageRepairTimeApi - factory interface
 * @export
 */
export const ReportsGeneralAverageRepairTimeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsGeneralAverageRepairTimeApiFp(configuration)
    return {
        /**
         * 
         * @summary Просмотр сохраненных данных за год
         * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeBrowseGet(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AverageRepairPeriodBrowseItemDto>> {
            return localVarFp.apiReportsGeneralAverageRepairTimeBrowseGet(requestParameters.firmId, requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cреднее время ремонта - выгрузка в excel данных по предприятию за месяц
         * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmMonthPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeExportExcelFirmMonthPost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmMonthPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsGeneralAverageRepairTimeExportExcelFirmMonthPost(requestParameters.exportAverageRepairPeriodReportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cреднее время ремонта - выгрузка в excel данных по предприятию за период
         * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPost(requestParameters.averageRepairTimePeriodicalReporExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cреднее время ремонта - выгрузка в excel данных по предприятиям компании за год
         * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmYearPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeExportExcelFirmYearPost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmYearPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsGeneralAverageRepairTimeExportExcelFirmYearPost(requestParameters.exportAverageRepairPeriodForYearReportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Общее среднее время ремонта - выгрузка в excel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeExportExcelGeneralPost(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsGeneralAverageRepairTimeExportExcelGeneralPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Общее среднее время ремонта - загрузить данные по компании за месяц из Excel
         * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeImportPost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AverageRepairPeriodReportImportResponse> {
            return localVarFp.apiReportsGeneralAverageRepairTimeImportPost(requestParameters.firmId, requestParameters.year, requestParameters.month, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Общее среднее время ремонта - получить данные по предприятию за месяц
         * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeReportFirmMonthGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeReportFirmMonthGet(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeReportFirmMonthGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AverageRepairPeriodReportItemDto>> {
            return localVarFp.apiReportsGeneralAverageRepairTimeReportFirmMonthGet(requestParameters.firmId, requestParameters.year, requestParameters.month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Общее среднее время ремонта - сохранить данные по предприятию за месяц
         * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeSavePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsGeneralAverageRepairTimeSavePost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeSavePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsGeneralAverageRepairTimeSavePost(requestParameters.averageRepairPeriodReportSavePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsGeneralAverageRepairTimeBrowseGet operation in ReportsGeneralAverageRepairTimeApi.
 * @export
 * @interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeBrowseGetRequest
 */
export interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeBrowseGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeBrowseGet
     */
    readonly firmId?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeBrowseGet
     */
    readonly year?: number
}

/**
 * Request parameters for apiReportsGeneralAverageRepairTimeExportExcelFirmMonthPost operation in ReportsGeneralAverageRepairTimeApi.
 * @export
 * @interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmMonthPostRequest
 */
export interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmMonthPostRequest {
    /**
     * 
     * @type {ExportAverageRepairPeriodReportPayload}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmMonthPost
     */
    readonly exportAverageRepairPeriodReportPayload?: ExportAverageRepairPeriodReportPayload
}

/**
 * Request parameters for apiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPost operation in ReportsGeneralAverageRepairTimeApi.
 * @export
 * @interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPostRequest
 */
export interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPostRequest {
    /**
     * 
     * @type {AverageRepairTimePeriodicalReporExportPayload}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPost
     */
    readonly averageRepairTimePeriodicalReporExportPayload?: AverageRepairTimePeriodicalReporExportPayload
}

/**
 * Request parameters for apiReportsGeneralAverageRepairTimeExportExcelFirmYearPost operation in ReportsGeneralAverageRepairTimeApi.
 * @export
 * @interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmYearPostRequest
 */
export interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmYearPostRequest {
    /**
     * 
     * @type {ExportAverageRepairPeriodForYearReportPayload}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmYearPost
     */
    readonly exportAverageRepairPeriodForYearReportPayload?: ExportAverageRepairPeriodForYearReportPayload
}

/**
 * Request parameters for apiReportsGeneralAverageRepairTimeImportPost operation in ReportsGeneralAverageRepairTimeApi.
 * @export
 * @interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPostRequest
 */
export interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPost
     */
    readonly firmId?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPost
     */
    readonly year?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPost
     */
    readonly month?: number

    /**
     * 
     * @type {File}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPost
     */
    readonly file?: File
}

/**
 * Request parameters for apiReportsGeneralAverageRepairTimeReportFirmMonthGet operation in ReportsGeneralAverageRepairTimeApi.
 * @export
 * @interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeReportFirmMonthGetRequest
 */
export interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeReportFirmMonthGetRequest {
    /**
     * Ключ фирмы
     * @type {number}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeReportFirmMonthGet
     */
    readonly firmId?: number

    /**
     * Год
     * @type {number}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeReportFirmMonthGet
     */
    readonly year?: number

    /**
     * Месяц
     * @type {number}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeReportFirmMonthGet
     */
    readonly month?: number
}

/**
 * Request parameters for apiReportsGeneralAverageRepairTimeSavePost operation in ReportsGeneralAverageRepairTimeApi.
 * @export
 * @interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeSavePostRequest
 */
export interface ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeSavePostRequest {
    /**
     * 
     * @type {AverageRepairPeriodReportSavePayload}
     * @memberof ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeSavePost
     */
    readonly averageRepairPeriodReportSavePayload?: AverageRepairPeriodReportSavePayload
}

/**
 * ReportsGeneralAverageRepairTimeApi - object-oriented interface
 * @export
 * @class ReportsGeneralAverageRepairTimeApi
 * @extends {BaseAPI}
 */
export class ReportsGeneralAverageRepairTimeApi extends BaseAPI {
    /**
     * 
     * @summary Просмотр сохраненных данных за год
     * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsGeneralAverageRepairTimeApi
     */
    public apiReportsGeneralAverageRepairTimeBrowseGet(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsGeneralAverageRepairTimeApiFp(this.configuration).apiReportsGeneralAverageRepairTimeBrowseGet(requestParameters.firmId, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cреднее время ремонта - выгрузка в excel данных по предприятию за месяц
     * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmMonthPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsGeneralAverageRepairTimeApi
     */
    public apiReportsGeneralAverageRepairTimeExportExcelFirmMonthPost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmMonthPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsGeneralAverageRepairTimeApiFp(this.configuration).apiReportsGeneralAverageRepairTimeExportExcelFirmMonthPost(requestParameters.exportAverageRepairPeriodReportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cреднее время ремонта - выгрузка в excel данных по предприятию за период
     * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsGeneralAverageRepairTimeApi
     */
    public apiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsGeneralAverageRepairTimeApiFp(this.configuration).apiReportsGeneralAverageRepairTimeExportExcelFirmPeriodicalPost(requestParameters.averageRepairTimePeriodicalReporExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cреднее время ремонта - выгрузка в excel данных по предприятиям компании за год
     * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmYearPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsGeneralAverageRepairTimeApi
     */
    public apiReportsGeneralAverageRepairTimeExportExcelFirmYearPost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeExportExcelFirmYearPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsGeneralAverageRepairTimeApiFp(this.configuration).apiReportsGeneralAverageRepairTimeExportExcelFirmYearPost(requestParameters.exportAverageRepairPeriodForYearReportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Общее среднее время ремонта - выгрузка в excel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsGeneralAverageRepairTimeApi
     */
    public apiReportsGeneralAverageRepairTimeExportExcelGeneralPost(options?: AxiosRequestConfig) {
        return ReportsGeneralAverageRepairTimeApiFp(this.configuration).apiReportsGeneralAverageRepairTimeExportExcelGeneralPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Общее среднее время ремонта - загрузить данные по компании за месяц из Excel
     * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsGeneralAverageRepairTimeApi
     */
    public apiReportsGeneralAverageRepairTimeImportPost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeImportPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsGeneralAverageRepairTimeApiFp(this.configuration).apiReportsGeneralAverageRepairTimeImportPost(requestParameters.firmId, requestParameters.year, requestParameters.month, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Общее среднее время ремонта - получить данные по предприятию за месяц
     * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeReportFirmMonthGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsGeneralAverageRepairTimeApi
     */
    public apiReportsGeneralAverageRepairTimeReportFirmMonthGet(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeReportFirmMonthGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsGeneralAverageRepairTimeApiFp(this.configuration).apiReportsGeneralAverageRepairTimeReportFirmMonthGet(requestParameters.firmId, requestParameters.year, requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Общее среднее время ремонта - сохранить данные по предприятию за месяц
     * @param {ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsGeneralAverageRepairTimeApi
     */
    public apiReportsGeneralAverageRepairTimeSavePost(requestParameters: ReportsGeneralAverageRepairTimeApiApiReportsGeneralAverageRepairTimeSavePostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsGeneralAverageRepairTimeApiFp(this.configuration).apiReportsGeneralAverageRepairTimeSavePost(requestParameters.averageRepairPeriodReportSavePayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsIncomeForecastsApi - axios parameter creator
 * @export
 */
export const ReportsIncomeForecastsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Прогноз поступлений - выгрузка в Excel
         * @param {IncomeForecastsExportPayload} [incomeForecastsExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsIncomeForecastsExportExcelPost: async (incomeForecastsExportPayload?: IncomeForecastsExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-income-forecasts/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(incomeForecastsExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Прогноз поступлений - данные
         * @param {number} [firmId] Ключ предприятия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsIncomeForecastsReportGet: async (firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-income-forecasts/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsIncomeForecastsApi - functional programming interface
 * @export
 */
export const ReportsIncomeForecastsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsIncomeForecastsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Прогноз поступлений - выгрузка в Excel
         * @param {IncomeForecastsExportPayload} [incomeForecastsExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsIncomeForecastsExportExcelPost(incomeForecastsExportPayload?: IncomeForecastsExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsIncomeForecastsExportExcelPost(incomeForecastsExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Прогноз поступлений - данные
         * @param {number} [firmId] Ключ предприятия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsIncomeForecastsReportGet(firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeForecastsReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsIncomeForecastsReportGet(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsIncomeForecastsApi - factory interface
 * @export
 */
export const ReportsIncomeForecastsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsIncomeForecastsApiFp(configuration)
    return {
        /**
         * 
         * @summary Прогноз поступлений - выгрузка в Excel
         * @param {ReportsIncomeForecastsApiApiReportsIncomeForecastsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsIncomeForecastsExportExcelPost(requestParameters: ReportsIncomeForecastsApiApiReportsIncomeForecastsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsIncomeForecastsExportExcelPost(requestParameters.incomeForecastsExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Прогноз поступлений - данные
         * @param {ReportsIncomeForecastsApiApiReportsIncomeForecastsReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsIncomeForecastsReportGet(requestParameters: ReportsIncomeForecastsApiApiReportsIncomeForecastsReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<IncomeForecastsReportDto> {
            return localVarFp.apiReportsIncomeForecastsReportGet(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsIncomeForecastsExportExcelPost operation in ReportsIncomeForecastsApi.
 * @export
 * @interface ReportsIncomeForecastsApiApiReportsIncomeForecastsExportExcelPostRequest
 */
export interface ReportsIncomeForecastsApiApiReportsIncomeForecastsExportExcelPostRequest {
    /**
     * 
     * @type {IncomeForecastsExportPayload}
     * @memberof ReportsIncomeForecastsApiApiReportsIncomeForecastsExportExcelPost
     */
    readonly incomeForecastsExportPayload?: IncomeForecastsExportPayload
}

/**
 * Request parameters for apiReportsIncomeForecastsReportGet operation in ReportsIncomeForecastsApi.
 * @export
 * @interface ReportsIncomeForecastsApiApiReportsIncomeForecastsReportGetRequest
 */
export interface ReportsIncomeForecastsApiApiReportsIncomeForecastsReportGetRequest {
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReportsIncomeForecastsApiApiReportsIncomeForecastsReportGet
     */
    readonly firmId?: number
}

/**
 * ReportsIncomeForecastsApi - object-oriented interface
 * @export
 * @class ReportsIncomeForecastsApi
 * @extends {BaseAPI}
 */
export class ReportsIncomeForecastsApi extends BaseAPI {
    /**
     * 
     * @summary Прогноз поступлений - выгрузка в Excel
     * @param {ReportsIncomeForecastsApiApiReportsIncomeForecastsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsIncomeForecastsApi
     */
    public apiReportsIncomeForecastsExportExcelPost(requestParameters: ReportsIncomeForecastsApiApiReportsIncomeForecastsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsIncomeForecastsApiFp(this.configuration).apiReportsIncomeForecastsExportExcelPost(requestParameters.incomeForecastsExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Прогноз поступлений - данные
     * @param {ReportsIncomeForecastsApiApiReportsIncomeForecastsReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsIncomeForecastsApi
     */
    public apiReportsIncomeForecastsReportGet(requestParameters: ReportsIncomeForecastsApiApiReportsIncomeForecastsReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsIncomeForecastsApiFp(this.configuration).apiReportsIncomeForecastsReportGet(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsLicensesUsedApi - axios parameter creator
 * @export
 */
export const ReportsLicensesUsedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Использованные лицензии - выгрузка в Excel
         * @param {LicesesUsedExportPayload} [licesesUsedExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsLicensesUsedExportExcelPost: async (licesesUsedExportPayload?: LicesesUsedExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-licenses-used/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licesesUsedExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Использованные лицензии - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsLicensesUsedReportGet: async (periodStart?: string, periodEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-licenses-used/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsLicensesUsedApi - functional programming interface
 * @export
 */
export const ReportsLicensesUsedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsLicensesUsedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Использованные лицензии - выгрузка в Excel
         * @param {LicesesUsedExportPayload} [licesesUsedExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsLicensesUsedExportExcelPost(licesesUsedExportPayload?: LicesesUsedExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsLicensesUsedExportExcelPost(licesesUsedExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Использованные лицензии - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsLicensesUsedReportGet(periodStart?: string, periodEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicesesUsedReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsLicensesUsedReportGet(periodStart, periodEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsLicensesUsedApi - factory interface
 * @export
 */
export const ReportsLicensesUsedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsLicensesUsedApiFp(configuration)
    return {
        /**
         * 
         * @summary Использованные лицензии - выгрузка в Excel
         * @param {ReportsLicensesUsedApiApiReportsLicensesUsedExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsLicensesUsedExportExcelPost(requestParameters: ReportsLicensesUsedApiApiReportsLicensesUsedExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsLicensesUsedExportExcelPost(requestParameters.licesesUsedExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Использованные лицензии - данные
         * @param {ReportsLicensesUsedApiApiReportsLicensesUsedReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsLicensesUsedReportGet(requestParameters: ReportsLicensesUsedApiApiReportsLicensesUsedReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LicesesUsedReportDto> {
            return localVarFp.apiReportsLicensesUsedReportGet(requestParameters.periodStart, requestParameters.periodEnd, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsLicensesUsedExportExcelPost operation in ReportsLicensesUsedApi.
 * @export
 * @interface ReportsLicensesUsedApiApiReportsLicensesUsedExportExcelPostRequest
 */
export interface ReportsLicensesUsedApiApiReportsLicensesUsedExportExcelPostRequest {
    /**
     * 
     * @type {LicesesUsedExportPayload}
     * @memberof ReportsLicensesUsedApiApiReportsLicensesUsedExportExcelPost
     */
    readonly licesesUsedExportPayload?: LicesesUsedExportPayload
}

/**
 * Request parameters for apiReportsLicensesUsedReportGet operation in ReportsLicensesUsedApi.
 * @export
 * @interface ReportsLicensesUsedApiApiReportsLicensesUsedReportGetRequest
 */
export interface ReportsLicensesUsedApiApiReportsLicensesUsedReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsLicensesUsedApiApiReportsLicensesUsedReportGet
     */
    readonly periodStart?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsLicensesUsedApiApiReportsLicensesUsedReportGet
     */
    readonly periodEnd?: string
}

/**
 * ReportsLicensesUsedApi - object-oriented interface
 * @export
 * @class ReportsLicensesUsedApi
 * @extends {BaseAPI}
 */
export class ReportsLicensesUsedApi extends BaseAPI {
    /**
     * 
     * @summary Использованные лицензии - выгрузка в Excel
     * @param {ReportsLicensesUsedApiApiReportsLicensesUsedExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsLicensesUsedApi
     */
    public apiReportsLicensesUsedExportExcelPost(requestParameters: ReportsLicensesUsedApiApiReportsLicensesUsedExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsLicensesUsedApiFp(this.configuration).apiReportsLicensesUsedExportExcelPost(requestParameters.licesesUsedExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Использованные лицензии - данные
     * @param {ReportsLicensesUsedApiApiReportsLicensesUsedReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsLicensesUsedApi
     */
    public apiReportsLicensesUsedReportGet(requestParameters: ReportsLicensesUsedApiApiReportsLicensesUsedReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsLicensesUsedApiFp(this.configuration).apiReportsLicensesUsedReportGet(requestParameters.periodStart, requestParameters.periodEnd, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsListOfComplaintsApi - axios parameter creator
 * @export
 */
export const ReportsListOfComplaintsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список дистрибьюторов
         * @param {number} [firmId] 
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsCounterpartiesGet: async (firmId?: number, periodStart?: string, periodEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-list-of-complaints/counterparties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - выгрузка в Excel
         * @param {ListOfComplaintsReportExportPayload} [listOfComplaintsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsExportExcelPost: async (listOfComplaintsReportExportPayload?: ListOfComplaintsReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-list-of-complaints/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listOfComplaintsReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [counterpartyId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsReportGet: async (periodStart?: string, periodEnd?: string, firmId?: number, counterpartyId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-list-of-complaints/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (counterpartyId !== undefined) {
                localVarQueryParameter['CounterpartyId'] = counterpartyId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsListOfComplaintsApi - functional programming interface
 * @export
 */
export const ReportsListOfComplaintsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsListOfComplaintsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список дистрибьюторов
         * @param {number} [firmId] 
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsListOfComplaintsCounterpartiesGet(firmId?: number, periodStart?: string, periodEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListOfComplaintsReportCounterpartiesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsListOfComplaintsCounterpartiesGet(firmId, periodStart, periodEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - выгрузка в Excel
         * @param {ListOfComplaintsReportExportPayload} [listOfComplaintsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsListOfComplaintsExportExcelPost(listOfComplaintsReportExportPayload?: ListOfComplaintsReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsListOfComplaintsExportExcelPost(listOfComplaintsReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [counterpartyId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsListOfComplaintsReportGet(periodStart?: string, periodEnd?: string, firmId?: number, counterpartyId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListOfComplaintsReportItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsListOfComplaintsReportGet(periodStart, periodEnd, firmId, counterpartyId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsListOfComplaintsApi - factory interface
 * @export
 */
export const ReportsListOfComplaintsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsListOfComplaintsApiFp(configuration)
    return {
        /**
         * 
         * @summary Список дистрибьюторов
         * @param {ReportsListOfComplaintsApiApiReportsListOfComplaintsCounterpartiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsCounterpartiesGet(requestParameters: ReportsListOfComplaintsApiApiReportsListOfComplaintsCounterpartiesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ListOfComplaintsReportCounterpartiesDto>> {
            return localVarFp.apiReportsListOfComplaintsCounterpartiesGet(requestParameters.firmId, requestParameters.periodStart, requestParameters.periodEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - выгрузка в Excel
         * @param {ReportsListOfComplaintsApiApiReportsListOfComplaintsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsExportExcelPost(requestParameters: ReportsListOfComplaintsApiApiReportsListOfComplaintsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsListOfComplaintsExportExcelPost(requestParameters.listOfComplaintsReportExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - данные
         * @param {ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsReportGet(requestParameters: ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ListOfComplaintsReportItemDto>> {
            return localVarFp.apiReportsListOfComplaintsReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.counterpartyId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsListOfComplaintsCounterpartiesGet operation in ReportsListOfComplaintsApi.
 * @export
 * @interface ReportsListOfComplaintsApiApiReportsListOfComplaintsCounterpartiesGetRequest
 */
export interface ReportsListOfComplaintsApiApiReportsListOfComplaintsCounterpartiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsListOfComplaintsApiApiReportsListOfComplaintsCounterpartiesGet
     */
    readonly firmId?: number

    /**
     * 
     * @type {string}
     * @memberof ReportsListOfComplaintsApiApiReportsListOfComplaintsCounterpartiesGet
     */
    readonly periodStart?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsListOfComplaintsApiApiReportsListOfComplaintsCounterpartiesGet
     */
    readonly periodEnd?: string
}

/**
 * Request parameters for apiReportsListOfComplaintsExportExcelPost operation in ReportsListOfComplaintsApi.
 * @export
 * @interface ReportsListOfComplaintsApiApiReportsListOfComplaintsExportExcelPostRequest
 */
export interface ReportsListOfComplaintsApiApiReportsListOfComplaintsExportExcelPostRequest {
    /**
     * 
     * @type {ListOfComplaintsReportExportPayload}
     * @memberof ReportsListOfComplaintsApiApiReportsListOfComplaintsExportExcelPost
     */
    readonly listOfComplaintsReportExportPayload?: ListOfComplaintsReportExportPayload
}

/**
 * Request parameters for apiReportsListOfComplaintsReportGet operation in ReportsListOfComplaintsApi.
 * @export
 * @interface ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGetRequest
 */
export interface ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGet
     */
    readonly periodStart?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGet
     */
    readonly periodEnd?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGet
     */
    readonly firmId?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGet
     */
    readonly counterpartyId?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsListOfComplaintsApi - object-oriented interface
 * @export
 * @class ReportsListOfComplaintsApi
 * @extends {BaseAPI}
 */
export class ReportsListOfComplaintsApi extends BaseAPI {
    /**
     * 
     * @summary Список дистрибьюторов
     * @param {ReportsListOfComplaintsApiApiReportsListOfComplaintsCounterpartiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsListOfComplaintsApi
     */
    public apiReportsListOfComplaintsCounterpartiesGet(requestParameters: ReportsListOfComplaintsApiApiReportsListOfComplaintsCounterpartiesGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsListOfComplaintsApiFp(this.configuration).apiReportsListOfComplaintsCounterpartiesGet(requestParameters.firmId, requestParameters.periodStart, requestParameters.periodEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал рекламаций (по компаниям) - выгрузка в Excel
     * @param {ReportsListOfComplaintsApiApiReportsListOfComplaintsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsListOfComplaintsApi
     */
    public apiReportsListOfComplaintsExportExcelPost(requestParameters: ReportsListOfComplaintsApiApiReportsListOfComplaintsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsListOfComplaintsApiFp(this.configuration).apiReportsListOfComplaintsExportExcelPost(requestParameters.listOfComplaintsReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал рекламаций (по компаниям) - данные
     * @param {ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsListOfComplaintsApi
     */
    public apiReportsListOfComplaintsReportGet(requestParameters: ReportsListOfComplaintsApiApiReportsListOfComplaintsReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsListOfComplaintsApiFp(this.configuration).apiReportsListOfComplaintsReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.counterpartyId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsListOfComplaintsExternalApi - axios parameter creator
 * @export
 */
export const ReportsListOfComplaintsExternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - выгрузка в Excel
         * @param {ListOfComplaintsExternalReportExportPayload} [listOfComplaintsExternalReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsExternalExportExcelPost: async (listOfComplaintsExternalReportExportPayload?: ListOfComplaintsExternalReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-list-of-complaints-external/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listOfComplaintsExternalReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsExternalReportGet: async (periodStart?: string, periodEnd?: string, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-list-of-complaints-external/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsListOfComplaintsExternalApi - functional programming interface
 * @export
 */
export const ReportsListOfComplaintsExternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsListOfComplaintsExternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - выгрузка в Excel
         * @param {ListOfComplaintsExternalReportExportPayload} [listOfComplaintsExternalReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsListOfComplaintsExternalExportExcelPost(listOfComplaintsExternalReportExportPayload?: ListOfComplaintsExternalReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsListOfComplaintsExternalExportExcelPost(listOfComplaintsExternalReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsListOfComplaintsExternalReportGet(periodStart?: string, periodEnd?: string, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListOfComplaintsExternalReportItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsListOfComplaintsExternalReportGet(periodStart, periodEnd, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsListOfComplaintsExternalApi - factory interface
 * @export
 */
export const ReportsListOfComplaintsExternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsListOfComplaintsExternalApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - выгрузка в Excel
         * @param {ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsExternalExportExcelPost(requestParameters: ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsListOfComplaintsExternalExportExcelPost(requestParameters.listOfComplaintsExternalReportExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Журнал рекламаций (по компаниям) - данные
         * @param {ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOfComplaintsExternalReportGet(requestParameters: ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ListOfComplaintsExternalReportItemDto>> {
            return localVarFp.apiReportsListOfComplaintsExternalReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsListOfComplaintsExternalExportExcelPost operation in ReportsListOfComplaintsExternalApi.
 * @export
 * @interface ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalExportExcelPostRequest
 */
export interface ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalExportExcelPostRequest {
    /**
     * 
     * @type {ListOfComplaintsExternalReportExportPayload}
     * @memberof ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalExportExcelPost
     */
    readonly listOfComplaintsExternalReportExportPayload?: ListOfComplaintsExternalReportExportPayload
}

/**
 * Request parameters for apiReportsListOfComplaintsExternalReportGet operation in ReportsListOfComplaintsExternalApi.
 * @export
 * @interface ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalReportGetRequest
 */
export interface ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalReportGet
     */
    readonly periodStart?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalReportGet
     */
    readonly periodEnd?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsListOfComplaintsExternalApi - object-oriented interface
 * @export
 * @class ReportsListOfComplaintsExternalApi
 * @extends {BaseAPI}
 */
export class ReportsListOfComplaintsExternalApi extends BaseAPI {
    /**
     * 
     * @summary Журнал рекламаций (по компаниям) - выгрузка в Excel
     * @param {ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsListOfComplaintsExternalApi
     */
    public apiReportsListOfComplaintsExternalExportExcelPost(requestParameters: ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsListOfComplaintsExternalApiFp(this.configuration).apiReportsListOfComplaintsExternalExportExcelPost(requestParameters.listOfComplaintsExternalReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Журнал рекламаций (по компаниям) - данные
     * @param {ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsListOfComplaintsExternalApi
     */
    public apiReportsListOfComplaintsExternalReportGet(requestParameters: ReportsListOfComplaintsExternalApiApiReportsListOfComplaintsExternalReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsListOfComplaintsExternalApiFp(this.configuration).apiReportsListOfComplaintsExternalReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsNotShippedDevicesApi - axios parameter creator
 * @export
 */
export const ReportsNotShippedDevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Неотгруженные приборы - выгрузка в Excel
         * @param {NotShippedDevicesReportExportPayload} [notShippedDevicesReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsNotShippedDevicesExportExcelPost: async (notShippedDevicesReportExportPayload?: NotShippedDevicesReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-not-shipped-devices/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notShippedDevicesReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Неотгруженные приборы - данные
         * @param {number} [firmId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsNotShippedDevicesReportGet: async (firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-not-shipped-devices/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsNotShippedDevicesApi - functional programming interface
 * @export
 */
export const ReportsNotShippedDevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsNotShippedDevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Неотгруженные приборы - выгрузка в Excel
         * @param {NotShippedDevicesReportExportPayload} [notShippedDevicesReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsNotShippedDevicesExportExcelPost(notShippedDevicesReportExportPayload?: NotShippedDevicesReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsNotShippedDevicesExportExcelPost(notShippedDevicesReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Неотгруженные приборы - данные
         * @param {number} [firmId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsNotShippedDevicesReportGet(firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotShippedDevicesReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsNotShippedDevicesReportGet(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsNotShippedDevicesApi - factory interface
 * @export
 */
export const ReportsNotShippedDevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsNotShippedDevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Неотгруженные приборы - выгрузка в Excel
         * @param {ReportsNotShippedDevicesApiApiReportsNotShippedDevicesExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsNotShippedDevicesExportExcelPost(requestParameters: ReportsNotShippedDevicesApiApiReportsNotShippedDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsNotShippedDevicesExportExcelPost(requestParameters.notShippedDevicesReportExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Неотгруженные приборы - данные
         * @param {ReportsNotShippedDevicesApiApiReportsNotShippedDevicesReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsNotShippedDevicesReportGet(requestParameters: ReportsNotShippedDevicesApiApiReportsNotShippedDevicesReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<NotShippedDevicesReportDto> {
            return localVarFp.apiReportsNotShippedDevicesReportGet(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsNotShippedDevicesExportExcelPost operation in ReportsNotShippedDevicesApi.
 * @export
 * @interface ReportsNotShippedDevicesApiApiReportsNotShippedDevicesExportExcelPostRequest
 */
export interface ReportsNotShippedDevicesApiApiReportsNotShippedDevicesExportExcelPostRequest {
    /**
     * 
     * @type {NotShippedDevicesReportExportPayload}
     * @memberof ReportsNotShippedDevicesApiApiReportsNotShippedDevicesExportExcelPost
     */
    readonly notShippedDevicesReportExportPayload?: NotShippedDevicesReportExportPayload
}

/**
 * Request parameters for apiReportsNotShippedDevicesReportGet operation in ReportsNotShippedDevicesApi.
 * @export
 * @interface ReportsNotShippedDevicesApiApiReportsNotShippedDevicesReportGetRequest
 */
export interface ReportsNotShippedDevicesApiApiReportsNotShippedDevicesReportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsNotShippedDevicesApiApiReportsNotShippedDevicesReportGet
     */
    readonly firmId?: number
}

/**
 * ReportsNotShippedDevicesApi - object-oriented interface
 * @export
 * @class ReportsNotShippedDevicesApi
 * @extends {BaseAPI}
 */
export class ReportsNotShippedDevicesApi extends BaseAPI {
    /**
     * 
     * @summary Неотгруженные приборы - выгрузка в Excel
     * @param {ReportsNotShippedDevicesApiApiReportsNotShippedDevicesExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsNotShippedDevicesApi
     */
    public apiReportsNotShippedDevicesExportExcelPost(requestParameters: ReportsNotShippedDevicesApiApiReportsNotShippedDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsNotShippedDevicesApiFp(this.configuration).apiReportsNotShippedDevicesExportExcelPost(requestParameters.notShippedDevicesReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Неотгруженные приборы - данные
     * @param {ReportsNotShippedDevicesApiApiReportsNotShippedDevicesReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsNotShippedDevicesApi
     */
    public apiReportsNotShippedDevicesReportGet(requestParameters: ReportsNotShippedDevicesApiApiReportsNotShippedDevicesReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsNotShippedDevicesApiFp(this.configuration).apiReportsNotShippedDevicesReportGet(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsPaidRepairApi - axios parameter creator
 * @export
 */
export const ReportsPaidRepairApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Платный ремонт - выгрузка в Excel
         * @param {GetPaidRepairReportPayload} [getPaidRepairReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsPaidRepairExportExcelPost: async (getPaidRepairReportPayload?: GetPaidRepairReportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-paid-repair/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPaidRepairReportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsPaidRepairApi - functional programming interface
 * @export
 */
export const ReportsPaidRepairApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsPaidRepairApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Платный ремонт - выгрузка в Excel
         * @param {GetPaidRepairReportPayload} [getPaidRepairReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsPaidRepairExportExcelPost(getPaidRepairReportPayload?: GetPaidRepairReportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsPaidRepairExportExcelPost(getPaidRepairReportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsPaidRepairApi - factory interface
 * @export
 */
export const ReportsPaidRepairApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsPaidRepairApiFp(configuration)
    return {
        /**
         * 
         * @summary Платный ремонт - выгрузка в Excel
         * @param {ReportsPaidRepairApiApiReportsPaidRepairExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsPaidRepairExportExcelPost(requestParameters: ReportsPaidRepairApiApiReportsPaidRepairExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsPaidRepairExportExcelPost(requestParameters.getPaidRepairReportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsPaidRepairExportExcelPost operation in ReportsPaidRepairApi.
 * @export
 * @interface ReportsPaidRepairApiApiReportsPaidRepairExportExcelPostRequest
 */
export interface ReportsPaidRepairApiApiReportsPaidRepairExportExcelPostRequest {
    /**
     * 
     * @type {GetPaidRepairReportPayload}
     * @memberof ReportsPaidRepairApiApiReportsPaidRepairExportExcelPost
     */
    readonly getPaidRepairReportPayload?: GetPaidRepairReportPayload
}

/**
 * ReportsPaidRepairApi - object-oriented interface
 * @export
 * @class ReportsPaidRepairApi
 * @extends {BaseAPI}
 */
export class ReportsPaidRepairApi extends BaseAPI {
    /**
     * 
     * @summary Платный ремонт - выгрузка в Excel
     * @param {ReportsPaidRepairApiApiReportsPaidRepairExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsPaidRepairApi
     */
    public apiReportsPaidRepairExportExcelPost(requestParameters: ReportsPaidRepairApiApiReportsPaidRepairExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsPaidRepairApiFp(this.configuration).apiReportsPaidRepairExportExcelPost(requestParameters.getPaidRepairReportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsRepairApi - axios parameter creator
 * @export
 */
export const ReportsRepairApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Отчет \"Данные по СО Репэир\" - выгрузка в Excel
         * @param {RepairReportExportPayload} [repairReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairExportExcelPost: async (repairReportExportPayload?: RepairReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsRepairApi - functional programming interface
 * @export
 */
export const ReportsRepairApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsRepairApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Отчет \"Данные по СО Репэир\" - выгрузка в Excel
         * @param {RepairReportExportPayload} [repairReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairExportExcelPost(repairReportExportPayload?: RepairReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairExportExcelPost(repairReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsRepairApi - factory interface
 * @export
 */
export const ReportsRepairApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsRepairApiFp(configuration)
    return {
        /**
         * 
         * @summary Отчет \"Данные по СО Репэир\" - выгрузка в Excel
         * @param {ReportsRepairApiApiReportsRepairExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairExportExcelPost(requestParameters: ReportsRepairApiApiReportsRepairExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairExportExcelPost(requestParameters.repairReportExportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsRepairExportExcelPost operation in ReportsRepairApi.
 * @export
 * @interface ReportsRepairApiApiReportsRepairExportExcelPostRequest
 */
export interface ReportsRepairApiApiReportsRepairExportExcelPostRequest {
    /**
     * 
     * @type {RepairReportExportPayload}
     * @memberof ReportsRepairApiApiReportsRepairExportExcelPost
     */
    readonly repairReportExportPayload?: RepairReportExportPayload
}

/**
 * ReportsRepairApi - object-oriented interface
 * @export
 * @class ReportsRepairApi
 * @extends {BaseAPI}
 */
export class ReportsRepairApi extends BaseAPI {
    /**
     * 
     * @summary Отчет \"Данные по СО Репэир\" - выгрузка в Excel
     * @param {ReportsRepairApiApiReportsRepairExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairApi
     */
    public apiReportsRepairExportExcelPost(requestParameters: ReportsRepairApiApiReportsRepairExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairApiFp(this.configuration).apiReportsRepairExportExcelPost(requestParameters.repairReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsRepairDeadlinesExceededApi - axios parameter creator
 * @export
 */
export const ReportsRepairDeadlinesExceededApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Превышение целевых сроков ремонта - выгрузка в Excel
         * @param {RepairDeadlinesExceededReportExportPayload} [repairDeadlinesExceededReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairDeadlinesExceededExportExcelPost: async (repairDeadlinesExceededReportExportPayload?: RepairDeadlinesExceededReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-deadlines-exceeded/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairDeadlinesExceededReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsRepairDeadlinesExceededApi - functional programming interface
 * @export
 */
export const ReportsRepairDeadlinesExceededApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsRepairDeadlinesExceededApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Превышение целевых сроков ремонта - выгрузка в Excel
         * @param {RepairDeadlinesExceededReportExportPayload} [repairDeadlinesExceededReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairDeadlinesExceededExportExcelPost(repairDeadlinesExceededReportExportPayload?: RepairDeadlinesExceededReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairDeadlinesExceededExportExcelPost(repairDeadlinesExceededReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsRepairDeadlinesExceededApi - factory interface
 * @export
 */
export const ReportsRepairDeadlinesExceededApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsRepairDeadlinesExceededApiFp(configuration)
    return {
        /**
         * 
         * @summary Превышение целевых сроков ремонта - выгрузка в Excel
         * @param {ReportsRepairDeadlinesExceededApiApiReportsRepairDeadlinesExceededExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairDeadlinesExceededExportExcelPost(requestParameters: ReportsRepairDeadlinesExceededApiApiReportsRepairDeadlinesExceededExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairDeadlinesExceededExportExcelPost(requestParameters.repairDeadlinesExceededReportExportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsRepairDeadlinesExceededExportExcelPost operation in ReportsRepairDeadlinesExceededApi.
 * @export
 * @interface ReportsRepairDeadlinesExceededApiApiReportsRepairDeadlinesExceededExportExcelPostRequest
 */
export interface ReportsRepairDeadlinesExceededApiApiReportsRepairDeadlinesExceededExportExcelPostRequest {
    /**
     * 
     * @type {RepairDeadlinesExceededReportExportPayload}
     * @memberof ReportsRepairDeadlinesExceededApiApiReportsRepairDeadlinesExceededExportExcelPost
     */
    readonly repairDeadlinesExceededReportExportPayload?: RepairDeadlinesExceededReportExportPayload
}

/**
 * ReportsRepairDeadlinesExceededApi - object-oriented interface
 * @export
 * @class ReportsRepairDeadlinesExceededApi
 * @extends {BaseAPI}
 */
export class ReportsRepairDeadlinesExceededApi extends BaseAPI {
    /**
     * 
     * @summary Превышение целевых сроков ремонта - выгрузка в Excel
     * @param {ReportsRepairDeadlinesExceededApiApiReportsRepairDeadlinesExceededExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairDeadlinesExceededApi
     */
    public apiReportsRepairDeadlinesExceededExportExcelPost(requestParameters: ReportsRepairDeadlinesExceededApiApiReportsRepairDeadlinesExceededExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairDeadlinesExceededApiFp(this.configuration).apiReportsRepairDeadlinesExceededExportExcelPost(requestParameters.repairDeadlinesExceededReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsRepairPlansFulfillmentApi - axios parameter creator
 * @export
 */
export const ReportsRepairPlansFulfillmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Выполнение планов ремонта - выгрузка в Excel
         * @param {RepairPlansFulfillmentExportPayload} [repairPlansFulfillmentExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairPlansFulfillmentExportExcelPost: async (repairPlansFulfillmentExportPayload?: RepairPlansFulfillmentExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-plans-fulfillment/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairPlansFulfillmentExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнение планов ремонта - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairPlansFulfillmentReportGet: async (periodStart?: string, periodEnd?: string, firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-plans-fulfillment/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsRepairPlansFulfillmentApi - functional programming interface
 * @export
 */
export const ReportsRepairPlansFulfillmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsRepairPlansFulfillmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Выполнение планов ремонта - выгрузка в Excel
         * @param {RepairPlansFulfillmentExportPayload} [repairPlansFulfillmentExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairPlansFulfillmentExportExcelPost(repairPlansFulfillmentExportPayload?: RepairPlansFulfillmentExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairPlansFulfillmentExportExcelPost(repairPlansFulfillmentExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнение планов ремонта - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairPlansFulfillmentReportGet(periodStart?: string, periodEnd?: string, firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairPlansFulfillmentReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairPlansFulfillmentReportGet(periodStart, periodEnd, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsRepairPlansFulfillmentApi - factory interface
 * @export
 */
export const ReportsRepairPlansFulfillmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsRepairPlansFulfillmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Выполнение планов ремонта - выгрузка в Excel
         * @param {ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairPlansFulfillmentExportExcelPost(requestParameters: ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairPlansFulfillmentExportExcelPost(requestParameters.repairPlansFulfillmentExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнение планов ремонта - данные
         * @param {ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairPlansFulfillmentReportGet(requestParameters: ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RepairPlansFulfillmentReportDto> {
            return localVarFp.apiReportsRepairPlansFulfillmentReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsRepairPlansFulfillmentExportExcelPost operation in ReportsRepairPlansFulfillmentApi.
 * @export
 * @interface ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentExportExcelPostRequest
 */
export interface ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentExportExcelPostRequest {
    /**
     * 
     * @type {RepairPlansFulfillmentExportPayload}
     * @memberof ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentExportExcelPost
     */
    readonly repairPlansFulfillmentExportPayload?: RepairPlansFulfillmentExportPayload
}

/**
 * Request parameters for apiReportsRepairPlansFulfillmentReportGet operation in ReportsRepairPlansFulfillmentApi.
 * @export
 * @interface ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGetRequest
 */
export interface ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGet
     */
    readonly periodStart?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGet
     */
    readonly periodEnd?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGet
     */
    readonly firmId?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsRepairPlansFulfillmentApi - object-oriented interface
 * @export
 * @class ReportsRepairPlansFulfillmentApi
 * @extends {BaseAPI}
 */
export class ReportsRepairPlansFulfillmentApi extends BaseAPI {
    /**
     * 
     * @summary Выполнение планов ремонта - выгрузка в Excel
     * @param {ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairPlansFulfillmentApi
     */
    public apiReportsRepairPlansFulfillmentExportExcelPost(requestParameters: ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairPlansFulfillmentApiFp(this.configuration).apiReportsRepairPlansFulfillmentExportExcelPost(requestParameters.repairPlansFulfillmentExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнение планов ремонта - данные
     * @param {ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairPlansFulfillmentApi
     */
    public apiReportsRepairPlansFulfillmentReportGet(requestParameters: ReportsRepairPlansFulfillmentApiApiReportsRepairPlansFulfillmentReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairPlansFulfillmentApiFp(this.configuration).apiReportsRepairPlansFulfillmentReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsRepairPriceProstafApi - axios parameter creator
 * @export
 */
export const ReportsRepairPriceProstafApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Отчёт по стоимости ремонта (Простаф) - выгрузка в Excel
         * @param {RepairPriceProstafExportPayload} [repairPriceProstafExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairPriceProstafExportExcelPost: async (repairPriceProstafExportPayload?: RepairPriceProstafExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-price-prostaf/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairPriceProstafExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отчёт по стоимости ремонта (Простаф) - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairPriceProstafReportGet: async (periodStart?: string, periodEnd?: string, firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-price-prostaf/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsRepairPriceProstafApi - functional programming interface
 * @export
 */
export const ReportsRepairPriceProstafApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsRepairPriceProstafApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Отчёт по стоимости ремонта (Простаф) - выгрузка в Excel
         * @param {RepairPriceProstafExportPayload} [repairPriceProstafExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairPriceProstafExportExcelPost(repairPriceProstafExportPayload?: RepairPriceProstafExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairPriceProstafExportExcelPost(repairPriceProstafExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отчёт по стоимости ремонта (Простаф) - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairPriceProstafReportGet(periodStart?: string, periodEnd?: string, firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairPriceProstafReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairPriceProstafReportGet(periodStart, periodEnd, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsRepairPriceProstafApi - factory interface
 * @export
 */
export const ReportsRepairPriceProstafApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsRepairPriceProstafApiFp(configuration)
    return {
        /**
         * 
         * @summary Отчёт по стоимости ремонта (Простаф) - выгрузка в Excel
         * @param {ReportsRepairPriceProstafApiApiReportsRepairPriceProstafExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairPriceProstafExportExcelPost(requestParameters: ReportsRepairPriceProstafApiApiReportsRepairPriceProstafExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairPriceProstafExportExcelPost(requestParameters.repairPriceProstafExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отчёт по стоимости ремонта (Простаф) - данные
         * @param {ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairPriceProstafReportGet(requestParameters: ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RepairPriceProstafReportDto> {
            return localVarFp.apiReportsRepairPriceProstafReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsRepairPriceProstafExportExcelPost operation in ReportsRepairPriceProstafApi.
 * @export
 * @interface ReportsRepairPriceProstafApiApiReportsRepairPriceProstafExportExcelPostRequest
 */
export interface ReportsRepairPriceProstafApiApiReportsRepairPriceProstafExportExcelPostRequest {
    /**
     * 
     * @type {RepairPriceProstafExportPayload}
     * @memberof ReportsRepairPriceProstafApiApiReportsRepairPriceProstafExportExcelPost
     */
    readonly repairPriceProstafExportPayload?: RepairPriceProstafExportPayload
}

/**
 * Request parameters for apiReportsRepairPriceProstafReportGet operation in ReportsRepairPriceProstafApi.
 * @export
 * @interface ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGetRequest
 */
export interface ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGet
     */
    readonly periodStart?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGet
     */
    readonly periodEnd?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGet
     */
    readonly firmId?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsRepairPriceProstafApi - object-oriented interface
 * @export
 * @class ReportsRepairPriceProstafApi
 * @extends {BaseAPI}
 */
export class ReportsRepairPriceProstafApi extends BaseAPI {
    /**
     * 
     * @summary Отчёт по стоимости ремонта (Простаф) - выгрузка в Excel
     * @param {ReportsRepairPriceProstafApiApiReportsRepairPriceProstafExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairPriceProstafApi
     */
    public apiReportsRepairPriceProstafExportExcelPost(requestParameters: ReportsRepairPriceProstafApiApiReportsRepairPriceProstafExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairPriceProstafApiFp(this.configuration).apiReportsRepairPriceProstafExportExcelPost(requestParameters.repairPriceProstafExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отчёт по стоимости ремонта (Простаф) - данные
     * @param {ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairPriceProstafApi
     */
    public apiReportsRepairPriceProstafReportGet(requestParameters: ReportsRepairPriceProstafApiApiReportsRepairPriceProstafReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairPriceProstafApiFp(this.configuration).apiReportsRepairPriceProstafReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsRepairTimeApi - axios parameter creator
 * @export
 */
export const ReportsRepairTimeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Время ремонта - выгрузка в Excel
         * @param {RepairTimeReportExportToExcelPayload} [repairTimeReportExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeExportExcelPost: async (repairTimeReportExportToExcelPayload?: RepairTimeReportExportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-time/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairTimeReportExportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Время ремонта - выгрузка в Pdf
         * @param {RepairTimeReportExportToPdfPayload} [repairTimeReportExportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeExportPdfPost: async (repairTimeReportExportToPdfPayload?: RepairTimeReportExportToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-time/export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairTimeReportExportToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Участки ремонта
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeRepairAreasGet: async (periodStart?: string, periodEnd?: string, firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-time/repair-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Время ремонта - данные
         * @param {string} [periodStart] Начало периода
         * @param {string} [periodEnd] Конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {Array<string>} [areas] Перечень участков
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeReportGet: async (periodStart?: string, periodEnd?: string, firmId?: number, areas?: Array<string>, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-time/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (areas) {
                localVarQueryParameter['Areas'] = areas;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsRepairTimeApi - functional programming interface
 * @export
 */
export const ReportsRepairTimeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsRepairTimeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Время ремонта - выгрузка в Excel
         * @param {RepairTimeReportExportToExcelPayload} [repairTimeReportExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairTimeExportExcelPost(repairTimeReportExportToExcelPayload?: RepairTimeReportExportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairTimeExportExcelPost(repairTimeReportExportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Время ремонта - выгрузка в Pdf
         * @param {RepairTimeReportExportToPdfPayload} [repairTimeReportExportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairTimeExportPdfPost(repairTimeReportExportToPdfPayload?: RepairTimeReportExportToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairTimeExportPdfPost(repairTimeReportExportToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Участки ремонта
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [firmId] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairTimeRepairAreasGet(periodStart?: string, periodEnd?: string, firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairTimeReportRepairAreaDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairTimeRepairAreasGet(periodStart, periodEnd, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Время ремонта - данные
         * @param {string} [periodStart] Начало периода
         * @param {string} [periodEnd] Конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {Array<string>} [areas] Перечень участков
         * @param {number} [lcid] Язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairTimeReportGet(periodStart?: string, periodEnd?: string, firmId?: number, areas?: Array<string>, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairTimeReportItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairTimeReportGet(periodStart, periodEnd, firmId, areas, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsRepairTimeApi - factory interface
 * @export
 */
export const ReportsRepairTimeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsRepairTimeApiFp(configuration)
    return {
        /**
         * 
         * @summary Время ремонта - выгрузка в Excel
         * @param {ReportsRepairTimeApiApiReportsRepairTimeExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeExportExcelPost(requestParameters: ReportsRepairTimeApiApiReportsRepairTimeExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairTimeExportExcelPost(requestParameters.repairTimeReportExportToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Время ремонта - выгрузка в Pdf
         * @param {ReportsRepairTimeApiApiReportsRepairTimeExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeExportPdfPost(requestParameters: ReportsRepairTimeApiApiReportsRepairTimeExportPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairTimeExportPdfPost(requestParameters.repairTimeReportExportToPdfPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Участки ремонта
         * @param {ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeRepairAreasGet(requestParameters: ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RepairTimeReportRepairAreaDto>> {
            return localVarFp.apiReportsRepairTimeRepairAreasGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Время ремонта - данные
         * @param {ReportsRepairTimeApiApiReportsRepairTimeReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeReportGet(requestParameters: ReportsRepairTimeApiApiReportsRepairTimeReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RepairTimeReportItemDto>> {
            return localVarFp.apiReportsRepairTimeReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.areas, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsRepairTimeExportExcelPost operation in ReportsRepairTimeApi.
 * @export
 * @interface ReportsRepairTimeApiApiReportsRepairTimeExportExcelPostRequest
 */
export interface ReportsRepairTimeApiApiReportsRepairTimeExportExcelPostRequest {
    /**
     * 
     * @type {RepairTimeReportExportToExcelPayload}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeExportExcelPost
     */
    readonly repairTimeReportExportToExcelPayload?: RepairTimeReportExportToExcelPayload
}

/**
 * Request parameters for apiReportsRepairTimeExportPdfPost operation in ReportsRepairTimeApi.
 * @export
 * @interface ReportsRepairTimeApiApiReportsRepairTimeExportPdfPostRequest
 */
export interface ReportsRepairTimeApiApiReportsRepairTimeExportPdfPostRequest {
    /**
     * 
     * @type {RepairTimeReportExportToPdfPayload}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeExportPdfPost
     */
    readonly repairTimeReportExportToPdfPayload?: RepairTimeReportExportToPdfPayload
}

/**
 * Request parameters for apiReportsRepairTimeRepairAreasGet operation in ReportsRepairTimeApi.
 * @export
 * @interface ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGetRequest
 */
export interface ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGet
     */
    readonly periodStart?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGet
     */
    readonly periodEnd?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGet
     */
    readonly firmId?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGet
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiReportsRepairTimeReportGet operation in ReportsRepairTimeApi.
 * @export
 * @interface ReportsRepairTimeApiApiReportsRepairTimeReportGetRequest
 */
export interface ReportsRepairTimeApiApiReportsRepairTimeReportGetRequest {
    /**
     * Начало периода
     * @type {string}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeReportGet
     */
    readonly periodStart?: string

    /**
     * Конец периода
     * @type {string}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeReportGet
     */
    readonly periodEnd?: string

    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeReportGet
     */
    readonly firmId?: number

    /**
     * Перечень участков
     * @type {Array<string>}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeReportGet
     */
    readonly areas?: Array<string>

    /**
     * Язык
     * @type {number}
     * @memberof ReportsRepairTimeApiApiReportsRepairTimeReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsRepairTimeApi - object-oriented interface
 * @export
 * @class ReportsRepairTimeApi
 * @extends {BaseAPI}
 */
export class ReportsRepairTimeApi extends BaseAPI {
    /**
     * 
     * @summary Время ремонта - выгрузка в Excel
     * @param {ReportsRepairTimeApiApiReportsRepairTimeExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairTimeApi
     */
    public apiReportsRepairTimeExportExcelPost(requestParameters: ReportsRepairTimeApiApiReportsRepairTimeExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairTimeApiFp(this.configuration).apiReportsRepairTimeExportExcelPost(requestParameters.repairTimeReportExportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Время ремонта - выгрузка в Pdf
     * @param {ReportsRepairTimeApiApiReportsRepairTimeExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairTimeApi
     */
    public apiReportsRepairTimeExportPdfPost(requestParameters: ReportsRepairTimeApiApiReportsRepairTimeExportPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairTimeApiFp(this.configuration).apiReportsRepairTimeExportPdfPost(requestParameters.repairTimeReportExportToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Участки ремонта
     * @param {ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairTimeApi
     */
    public apiReportsRepairTimeRepairAreasGet(requestParameters: ReportsRepairTimeApiApiReportsRepairTimeRepairAreasGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairTimeApiFp(this.configuration).apiReportsRepairTimeRepairAreasGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Время ремонта - данные
     * @param {ReportsRepairTimeApiApiReportsRepairTimeReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairTimeApi
     */
    public apiReportsRepairTimeReportGet(requestParameters: ReportsRepairTimeApiApiReportsRepairTimeReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairTimeApiFp(this.configuration).apiReportsRepairTimeReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.firmId, requestParameters.areas, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsRepairTimeAnalisysApi - axios parameter creator
 * @export
 */
export const ReportsRepairTimeAnalisysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Время анализа рекламаций по предприятию - выгрузка в Excel
         * @param {RepairTimeAnalysisReportExportPayload} [repairTimeAnalysisReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeAnalisysExportExcelPost: async (repairTimeAnalysisReportExportPayload?: RepairTimeAnalysisReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-time-analisys/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairTimeAnalysisReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsRepairTimeAnalisysApi - functional programming interface
 * @export
 */
export const ReportsRepairTimeAnalisysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsRepairTimeAnalisysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Время анализа рекламаций по предприятию - выгрузка в Excel
         * @param {RepairTimeAnalysisReportExportPayload} [repairTimeAnalysisReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairTimeAnalisysExportExcelPost(repairTimeAnalysisReportExportPayload?: RepairTimeAnalysisReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairTimeAnalisysExportExcelPost(repairTimeAnalysisReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsRepairTimeAnalisysApi - factory interface
 * @export
 */
export const ReportsRepairTimeAnalisysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsRepairTimeAnalisysApiFp(configuration)
    return {
        /**
         * 
         * @summary Время анализа рекламаций по предприятию - выгрузка в Excel
         * @param {ReportsRepairTimeAnalisysApiApiReportsRepairTimeAnalisysExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeAnalisysExportExcelPost(requestParameters: ReportsRepairTimeAnalisysApiApiReportsRepairTimeAnalisysExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairTimeAnalisysExportExcelPost(requestParameters.repairTimeAnalysisReportExportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsRepairTimeAnalisysExportExcelPost operation in ReportsRepairTimeAnalisysApi.
 * @export
 * @interface ReportsRepairTimeAnalisysApiApiReportsRepairTimeAnalisysExportExcelPostRequest
 */
export interface ReportsRepairTimeAnalisysApiApiReportsRepairTimeAnalisysExportExcelPostRequest {
    /**
     * 
     * @type {RepairTimeAnalysisReportExportPayload}
     * @memberof ReportsRepairTimeAnalisysApiApiReportsRepairTimeAnalisysExportExcelPost
     */
    readonly repairTimeAnalysisReportExportPayload?: RepairTimeAnalysisReportExportPayload
}

/**
 * ReportsRepairTimeAnalisysApi - object-oriented interface
 * @export
 * @class ReportsRepairTimeAnalisysApi
 * @extends {BaseAPI}
 */
export class ReportsRepairTimeAnalisysApi extends BaseAPI {
    /**
     * 
     * @summary Время анализа рекламаций по предприятию - выгрузка в Excel
     * @param {ReportsRepairTimeAnalisysApiApiReportsRepairTimeAnalisysExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairTimeAnalisysApi
     */
    public apiReportsRepairTimeAnalisysExportExcelPost(requestParameters: ReportsRepairTimeAnalisysApiApiReportsRepairTimeAnalisysExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairTimeAnalisysApiFp(this.configuration).apiReportsRepairTimeAnalisysExportExcelPost(requestParameters.repairTimeAnalysisReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsRepairTimeOtkApi - axios parameter creator
 * @export
 */
export const ReportsRepairTimeOtkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Время контроля ОТК по предприятию - выгрузка в Excel
         * @param {RepairTimeOtkReportExportPayload} [repairTimeOtkReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeOtkExportExcelPost: async (repairTimeOtkReportExportPayload?: RepairTimeOtkReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repair-time-otk/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairTimeOtkReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsRepairTimeOtkApi - functional programming interface
 * @export
 */
export const ReportsRepairTimeOtkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsRepairTimeOtkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Время контроля ОТК по предприятию - выгрузка в Excel
         * @param {RepairTimeOtkReportExportPayload} [repairTimeOtkReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairTimeOtkExportExcelPost(repairTimeOtkReportExportPayload?: RepairTimeOtkReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairTimeOtkExportExcelPost(repairTimeOtkReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsRepairTimeOtkApi - factory interface
 * @export
 */
export const ReportsRepairTimeOtkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsRepairTimeOtkApiFp(configuration)
    return {
        /**
         * 
         * @summary Время контроля ОТК по предприятию - выгрузка в Excel
         * @param {ReportsRepairTimeOtkApiApiReportsRepairTimeOtkExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairTimeOtkExportExcelPost(requestParameters: ReportsRepairTimeOtkApiApiReportsRepairTimeOtkExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairTimeOtkExportExcelPost(requestParameters.repairTimeOtkReportExportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsRepairTimeOtkExportExcelPost operation in ReportsRepairTimeOtkApi.
 * @export
 * @interface ReportsRepairTimeOtkApiApiReportsRepairTimeOtkExportExcelPostRequest
 */
export interface ReportsRepairTimeOtkApiApiReportsRepairTimeOtkExportExcelPostRequest {
    /**
     * 
     * @type {RepairTimeOtkReportExportPayload}
     * @memberof ReportsRepairTimeOtkApiApiReportsRepairTimeOtkExportExcelPost
     */
    readonly repairTimeOtkReportExportPayload?: RepairTimeOtkReportExportPayload
}

/**
 * ReportsRepairTimeOtkApi - object-oriented interface
 * @export
 * @class ReportsRepairTimeOtkApi
 * @extends {BaseAPI}
 */
export class ReportsRepairTimeOtkApi extends BaseAPI {
    /**
     * 
     * @summary Время контроля ОТК по предприятию - выгрузка в Excel
     * @param {ReportsRepairTimeOtkApiApiReportsRepairTimeOtkExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairTimeOtkApi
     */
    public apiReportsRepairTimeOtkExportExcelPost(requestParameters: ReportsRepairTimeOtkApiApiReportsRepairTimeOtkExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairTimeOtkApiFp(this.configuration).apiReportsRepairTimeOtkExportExcelPost(requestParameters.repairTimeOtkReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsRepairedDevicesApi - axios parameter creator
 * @export
 */
export const ReportsRepairedDevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Отремонтированные приборы - cписок дистрибьюторов
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairedDevicesCounterpartiesGet: async (dateFrom?: string, dateTo?: string, firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repaired-devices/counterparties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отремонтированные приборы - выгрузка в Excel
         * @param {RepairedDevicesReportExportPayload} [repairedDevicesReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairedDevicesExportExcelPost: async (repairedDevicesReportExportPayload?: RepairedDevicesReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repaired-devices/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairedDevicesReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отремонтированные приборы - выгрузка в Pdf
         * @param {RepairedDevicesReportExportToPdfPayload} [repairedDevicesReportExportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairedDevicesExportPdfPost: async (repairedDevicesReportExportToPdfPayload?: RepairedDevicesReportExportToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repaired-devices/export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairedDevicesReportExportToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отремонтированные приборы - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {number} [konId] id контрагента
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairedDevicesReportGet: async (dateFrom?: string, dateTo?: string, firmId?: number, konId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repaired-devices/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (konId !== undefined) {
                localVarQueryParameter['KonId'] = konId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsRepairedDevicesApi - functional programming interface
 * @export
 */
export const ReportsRepairedDevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsRepairedDevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Отремонтированные приборы - cписок дистрибьюторов
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairedDevicesCounterpartiesGet(dateFrom?: string, dateTo?: string, firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairedDevicesReportCounterpartiesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairedDevicesCounterpartiesGet(dateFrom, dateTo, firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отремонтированные приборы - выгрузка в Excel
         * @param {RepairedDevicesReportExportPayload} [repairedDevicesReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairedDevicesExportExcelPost(repairedDevicesReportExportPayload?: RepairedDevicesReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairedDevicesExportExcelPost(repairedDevicesReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отремонтированные приборы - выгрузка в Pdf
         * @param {RepairedDevicesReportExportToPdfPayload} [repairedDevicesReportExportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairedDevicesExportPdfPost(repairedDevicesReportExportToPdfPayload?: RepairedDevicesReportExportToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairedDevicesExportPdfPost(repairedDevicesReportExportToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отремонтированные приборы - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {number} [konId] id контрагента
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepairedDevicesReportGet(dateFrom?: string, dateTo?: string, firmId?: number, konId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairedDevicesReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepairedDevicesReportGet(dateFrom, dateTo, firmId, konId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsRepairedDevicesApi - factory interface
 * @export
 */
export const ReportsRepairedDevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsRepairedDevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Отремонтированные приборы - cписок дистрибьюторов
         * @param {ReportsRepairedDevicesApiApiReportsRepairedDevicesCounterpartiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairedDevicesCounterpartiesGet(requestParameters: ReportsRepairedDevicesApiApiReportsRepairedDevicesCounterpartiesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RepairedDevicesReportCounterpartiesDto>> {
            return localVarFp.apiReportsRepairedDevicesCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отремонтированные приборы - выгрузка в Excel
         * @param {ReportsRepairedDevicesApiApiReportsRepairedDevicesExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairedDevicesExportExcelPost(requestParameters: ReportsRepairedDevicesApiApiReportsRepairedDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairedDevicesExportExcelPost(requestParameters.repairedDevicesReportExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отремонтированные приборы - выгрузка в Pdf
         * @param {ReportsRepairedDevicesApiApiReportsRepairedDevicesExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairedDevicesExportPdfPost(requestParameters: ReportsRepairedDevicesApiApiReportsRepairedDevicesExportPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepairedDevicesExportPdfPost(requestParameters.repairedDevicesReportExportToPdfPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отремонтированные приборы - данные
         * @param {ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepairedDevicesReportGet(requestParameters: ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RepairedDevicesReportDto> {
            return localVarFp.apiReportsRepairedDevicesReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, requestParameters.konId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsRepairedDevicesCounterpartiesGet operation in ReportsRepairedDevicesApi.
 * @export
 * @interface ReportsRepairedDevicesApiApiReportsRepairedDevicesCounterpartiesGetRequest
 */
export interface ReportsRepairedDevicesApiApiReportsRepairedDevicesCounterpartiesGetRequest {
    /**
     * Начало периода
     * @type {string}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesCounterpartiesGet
     */
    readonly dateFrom?: string

    /**
     * конец периода
     * @type {string}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesCounterpartiesGet
     */
    readonly dateTo?: string

    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesCounterpartiesGet
     */
    readonly firmId?: number
}

/**
 * Request parameters for apiReportsRepairedDevicesExportExcelPost operation in ReportsRepairedDevicesApi.
 * @export
 * @interface ReportsRepairedDevicesApiApiReportsRepairedDevicesExportExcelPostRequest
 */
export interface ReportsRepairedDevicesApiApiReportsRepairedDevicesExportExcelPostRequest {
    /**
     * 
     * @type {RepairedDevicesReportExportPayload}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesExportExcelPost
     */
    readonly repairedDevicesReportExportPayload?: RepairedDevicesReportExportPayload
}

/**
 * Request parameters for apiReportsRepairedDevicesExportPdfPost operation in ReportsRepairedDevicesApi.
 * @export
 * @interface ReportsRepairedDevicesApiApiReportsRepairedDevicesExportPdfPostRequest
 */
export interface ReportsRepairedDevicesApiApiReportsRepairedDevicesExportPdfPostRequest {
    /**
     * 
     * @type {RepairedDevicesReportExportToPdfPayload}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesExportPdfPost
     */
    readonly repairedDevicesReportExportToPdfPayload?: RepairedDevicesReportExportToPdfPayload
}

/**
 * Request parameters for apiReportsRepairedDevicesReportGet operation in ReportsRepairedDevicesApi.
 * @export
 * @interface ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGetRequest
 */
export interface ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGetRequest {
    /**
     * Начало периода
     * @type {string}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGet
     */
    readonly dateFrom?: string

    /**
     * конец периода
     * @type {string}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGet
     */
    readonly dateTo?: string

    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGet
     */
    readonly firmId?: number

    /**
     * id контрагента
     * @type {number}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGet
     */
    readonly konId?: number

    /**
     * язык
     * @type {number}
     * @memberof ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsRepairedDevicesApi - object-oriented interface
 * @export
 * @class ReportsRepairedDevicesApi
 * @extends {BaseAPI}
 */
export class ReportsRepairedDevicesApi extends BaseAPI {
    /**
     * 
     * @summary Отремонтированные приборы - cписок дистрибьюторов
     * @param {ReportsRepairedDevicesApiApiReportsRepairedDevicesCounterpartiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairedDevicesApi
     */
    public apiReportsRepairedDevicesCounterpartiesGet(requestParameters: ReportsRepairedDevicesApiApiReportsRepairedDevicesCounterpartiesGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairedDevicesApiFp(this.configuration).apiReportsRepairedDevicesCounterpartiesGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отремонтированные приборы - выгрузка в Excel
     * @param {ReportsRepairedDevicesApiApiReportsRepairedDevicesExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairedDevicesApi
     */
    public apiReportsRepairedDevicesExportExcelPost(requestParameters: ReportsRepairedDevicesApiApiReportsRepairedDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairedDevicesApiFp(this.configuration).apiReportsRepairedDevicesExportExcelPost(requestParameters.repairedDevicesReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отремонтированные приборы - выгрузка в Pdf
     * @param {ReportsRepairedDevicesApiApiReportsRepairedDevicesExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairedDevicesApi
     */
    public apiReportsRepairedDevicesExportPdfPost(requestParameters: ReportsRepairedDevicesApiApiReportsRepairedDevicesExportPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairedDevicesApiFp(this.configuration).apiReportsRepairedDevicesExportPdfPost(requestParameters.repairedDevicesReportExportToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отремонтированные приборы - данные
     * @param {ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepairedDevicesApi
     */
    public apiReportsRepairedDevicesReportGet(requestParameters: ReportsRepairedDevicesApiApiReportsRepairedDevicesReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepairedDevicesApiFp(this.configuration).apiReportsRepairedDevicesReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, requestParameters.konId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsRepeatedReturnsApi - axios parameter creator
 * @export
 */
export const ReportsRepeatedReturnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Повторные поступления (3 месяца) - выгрузка в Excel
         * @param {RepeatedReturnsReportExportToExcelPayload} [repeatedReturnsReportExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepeatedReturnsExportExcelPost: async (repeatedReturnsReportExportToExcelPayload?: RepeatedReturnsReportExportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repeated-returns/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repeatedReturnsReportExportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Повторные поступления (3 месяца) - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepeatedReturnsReportGet: async (periodStart?: string, periodEnd?: string, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-repeated-returns/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsRepeatedReturnsApi - functional programming interface
 * @export
 */
export const ReportsRepeatedReturnsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsRepeatedReturnsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Повторные поступления (3 месяца) - выгрузка в Excel
         * @param {RepeatedReturnsReportExportToExcelPayload} [repeatedReturnsReportExportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepeatedReturnsExportExcelPost(repeatedReturnsReportExportToExcelPayload?: RepeatedReturnsReportExportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepeatedReturnsExportExcelPost(repeatedReturnsReportExportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Повторные поступления (3 месяца) - данные
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRepeatedReturnsReportGet(periodStart?: string, periodEnd?: string, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepeatedReturnsReportItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRepeatedReturnsReportGet(periodStart, periodEnd, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsRepeatedReturnsApi - factory interface
 * @export
 */
export const ReportsRepeatedReturnsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsRepeatedReturnsApiFp(configuration)
    return {
        /**
         * 
         * @summary Повторные поступления (3 месяца) - выгрузка в Excel
         * @param {ReportsRepeatedReturnsApiApiReportsRepeatedReturnsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepeatedReturnsExportExcelPost(requestParameters: ReportsRepeatedReturnsApiApiReportsRepeatedReturnsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsRepeatedReturnsExportExcelPost(requestParameters.repeatedReturnsReportExportToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Повторные поступления (3 месяца) - данные
         * @param {ReportsRepeatedReturnsApiApiReportsRepeatedReturnsReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRepeatedReturnsReportGet(requestParameters: ReportsRepeatedReturnsApiApiReportsRepeatedReturnsReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RepeatedReturnsReportItemDto>> {
            return localVarFp.apiReportsRepeatedReturnsReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsRepeatedReturnsExportExcelPost operation in ReportsRepeatedReturnsApi.
 * @export
 * @interface ReportsRepeatedReturnsApiApiReportsRepeatedReturnsExportExcelPostRequest
 */
export interface ReportsRepeatedReturnsApiApiReportsRepeatedReturnsExportExcelPostRequest {
    /**
     * 
     * @type {RepeatedReturnsReportExportToExcelPayload}
     * @memberof ReportsRepeatedReturnsApiApiReportsRepeatedReturnsExportExcelPost
     */
    readonly repeatedReturnsReportExportToExcelPayload?: RepeatedReturnsReportExportToExcelPayload
}

/**
 * Request parameters for apiReportsRepeatedReturnsReportGet operation in ReportsRepeatedReturnsApi.
 * @export
 * @interface ReportsRepeatedReturnsApiApiReportsRepeatedReturnsReportGetRequest
 */
export interface ReportsRepeatedReturnsApiApiReportsRepeatedReturnsReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsRepeatedReturnsApiApiReportsRepeatedReturnsReportGet
     */
    readonly periodStart?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsRepeatedReturnsApiApiReportsRepeatedReturnsReportGet
     */
    readonly periodEnd?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsRepeatedReturnsApiApiReportsRepeatedReturnsReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsRepeatedReturnsApi - object-oriented interface
 * @export
 * @class ReportsRepeatedReturnsApi
 * @extends {BaseAPI}
 */
export class ReportsRepeatedReturnsApi extends BaseAPI {
    /**
     * 
     * @summary Повторные поступления (3 месяца) - выгрузка в Excel
     * @param {ReportsRepeatedReturnsApiApiReportsRepeatedReturnsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepeatedReturnsApi
     */
    public apiReportsRepeatedReturnsExportExcelPost(requestParameters: ReportsRepeatedReturnsApiApiReportsRepeatedReturnsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepeatedReturnsApiFp(this.configuration).apiReportsRepeatedReturnsExportExcelPost(requestParameters.repeatedReturnsReportExportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Повторные поступления (3 месяца) - данные
     * @param {ReportsRepeatedReturnsApiApiReportsRepeatedReturnsReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsRepeatedReturnsApi
     */
    public apiReportsRepeatedReturnsReportGet(requestParameters: ReportsRepeatedReturnsApiApiReportsRepeatedReturnsReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsRepeatedReturnsApiFp(this.configuration).apiReportsRepeatedReturnsReportGet(requestParameters.periodStart, requestParameters.periodEnd, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsReplacementPartsApi - axios parameter creator
 * @export
 */
export const ReportsReplacementPartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Отчет по замененным деталям - выгрузка в Excel
         * @param {ReplacementPartsReportExportPayload} [replacementPartsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReplacementPartsExportExcelPost: async (replacementPartsReportExportPayload?: ReplacementPartsReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-replacement-parts/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replacementPartsReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsReplacementPartsApi - functional programming interface
 * @export
 */
export const ReportsReplacementPartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsReplacementPartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Отчет по замененным деталям - выгрузка в Excel
         * @param {ReplacementPartsReportExportPayload} [replacementPartsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReplacementPartsExportExcelPost(replacementPartsReportExportPayload?: ReplacementPartsReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReplacementPartsExportExcelPost(replacementPartsReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsReplacementPartsApi - factory interface
 * @export
 */
export const ReportsReplacementPartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsReplacementPartsApiFp(configuration)
    return {
        /**
         * 
         * @summary Отчет по замененным деталям - выгрузка в Excel
         * @param {ReportsReplacementPartsApiApiReportsReplacementPartsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReplacementPartsExportExcelPost(requestParameters: ReportsReplacementPartsApiApiReportsReplacementPartsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReplacementPartsExportExcelPost(requestParameters.replacementPartsReportExportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsReplacementPartsExportExcelPost operation in ReportsReplacementPartsApi.
 * @export
 * @interface ReportsReplacementPartsApiApiReportsReplacementPartsExportExcelPostRequest
 */
export interface ReportsReplacementPartsApiApiReportsReplacementPartsExportExcelPostRequest {
    /**
     * 
     * @type {ReplacementPartsReportExportPayload}
     * @memberof ReportsReplacementPartsApiApiReportsReplacementPartsExportExcelPost
     */
    readonly replacementPartsReportExportPayload?: ReplacementPartsReportExportPayload
}

/**
 * ReportsReplacementPartsApi - object-oriented interface
 * @export
 * @class ReportsReplacementPartsApi
 * @extends {BaseAPI}
 */
export class ReportsReplacementPartsApi extends BaseAPI {
    /**
     * 
     * @summary Отчет по замененным деталям - выгрузка в Excel
     * @param {ReportsReplacementPartsApiApiReportsReplacementPartsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReplacementPartsApi
     */
    public apiReportsReplacementPartsExportExcelPost(requestParameters: ReportsReplacementPartsApiApiReportsReplacementPartsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsReplacementPartsApiFp(this.configuration).apiReportsReplacementPartsExportExcelPost(requestParameters.replacementPartsReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsReturnLevelApi - axios parameter creator
 * @export
 */
export const ReportsReturnLevelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Загрузка данных - иноформация о продаах и ремонтах
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelDataLoadingInfoGet: async (month?: number, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-return-level/data-loading/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка данных - удаление данных
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {ReturnLevelDataType} [dataType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelDelete: async (year?: number, month?: number, dataType?: ReturnLevelDataType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-return-level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['DataType'] = dataType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - по компании
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearAllPost: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiReportsReturnLevelExportExcelYearAllPost', 'year', year)
            const localVarPath = `/api/reports-return-level/export-excel/{year}/all`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Beltex
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearBeltexPost: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiReportsReturnLevelExportExcelYearBeltexPost', 'year', year)
            const localVarPath = `/api/reports-return-level/export-excel/{year}/beltex`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - полный отчёт
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearFullPost: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiReportsReturnLevelExportExcelYearFullPost', 'year', year)
            const localVarPath = `/api/reports-return-level/export-excel/{year}/full`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Polaris
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearPolarisPost: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiReportsReturnLevelExportExcelYearPolarisPost', 'year', year)
            const localVarPath = `/api/reports-return-level/export-excel/{year}/polaris`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - приборы текущего года
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearPost: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiReportsReturnLevelExportExcelYearPost', 'year', year)
            const localVarPath = `/api/reports-return-level/export-excel/{year}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Pulsar
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearPulsarPost: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiReportsReturnLevelExportExcelYearPulsarPost', 'year', year)
            const localVarPath = `/api/reports-return-level/export-excel/{year}/pulsar`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Total Data
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearTotalDataPost: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiReportsReturnLevelExportExcelYearTotalDataPost', 'year', year)
            const localVarPath = `/api/reports-return-level/export-excel/{year}/total-data`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Yukon
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearYukonPost: async (year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiReportsReturnLevelExportExcelYearYukonPost', 'year', year)
            const localVarPath = `/api/reports-return-level/export-excel/{year}/yukon`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о ремонтах
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelImportRepairsPost: async (month?: number, year?: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-return-level/import-repairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о ремонтах
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelImportRepairsSellmarkPost: async (month?: number, year?: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-return-level/import-repairs-sellmark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о продажах
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelImportSalesPost: async (month?: number, year?: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-return-level/import-sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о продажах Sellmark
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelImportSalesSellmarkPost: async (month?: number, year?: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-return-level/import-sales-sellmark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Уровень возвратов - данные
         * @param {number} [year] 
         * @param {ReturnLevelReportType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelReportGet: async (year?: number, type?: ReturnLevelReportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-return-level/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Года отчёта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelYearsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-return-level/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsReturnLevelApi - functional programming interface
 * @export
 */
export const ReportsReturnLevelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsReturnLevelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Загрузка данных - иноформация о продаах и ремонтах
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelDataLoadingInfoGet(month?: number, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnLevelDataLoadingInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelDataLoadingInfoGet(month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка данных - удаление данных
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {ReturnLevelDataType} [dataType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelDelete(year?: number, month?: number, dataType?: ReturnLevelDataType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelDelete(year, month, dataType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - по компании
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelExportExcelYearAllPost(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelExportExcelYearAllPost(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Beltex
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelExportExcelYearBeltexPost(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelExportExcelYearBeltexPost(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - полный отчёт
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelExportExcelYearFullPost(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelExportExcelYearFullPost(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Polaris
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelExportExcelYearPolarisPost(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelExportExcelYearPolarisPost(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - приборы текущего года
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelExportExcelYearPost(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelExportExcelYearPost(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Pulsar
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelExportExcelYearPulsarPost(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelExportExcelYearPulsarPost(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Total Data
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelExportExcelYearTotalDataPost(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelExportExcelYearTotalDataPost(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Yukon
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelExportExcelYearYukonPost(year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelExportExcelYearYukonPost(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о ремонтах
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelImportRepairsPost(month?: number, year?: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnLevelReportImportRepairsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelImportRepairsPost(month, year, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о ремонтах
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelImportRepairsSellmarkPost(month?: number, year?: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnLevelReportImportRepairsSellmarkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelImportRepairsSellmarkPost(month, year, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о продажах
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelImportSalesPost(month?: number, year?: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnLevelReportImportSalesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelImportSalesPost(month, year, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о продажах Sellmark
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelImportSalesSellmarkPost(month?: number, year?: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnLevelReportImportSalesSellmarkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelImportSalesSellmarkPost(month, year, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Уровень возвратов - данные
         * @param {number} [year] 
         * @param {ReturnLevelReportType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelReportGet(year?: number, type?: ReturnLevelReportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnLevelReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelReportGet(year, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Года отчёта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnLevelYearsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReturnLevelYearReportItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnLevelYearsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsReturnLevelApi - factory interface
 * @export
 */
export const ReportsReturnLevelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsReturnLevelApiFp(configuration)
    return {
        /**
         * 
         * @summary Загрузка данных - иноформация о продаах и ремонтах
         * @param {ReportsReturnLevelApiApiReportsReturnLevelDataLoadingInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelDataLoadingInfoGet(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelDataLoadingInfoGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ReturnLevelDataLoadingInfoDto> {
            return localVarFp.apiReportsReturnLevelDataLoadingInfoGet(requestParameters.month, requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка данных - удаление данных
         * @param {ReportsReturnLevelApiApiReportsReturnLevelDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelDelete(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnLevelDelete(requestParameters.year, requestParameters.month, requestParameters.dataType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - по компании
         * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearAllPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearAllPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearAllPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnLevelExportExcelYearAllPost(requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Beltex
         * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearBeltexPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearBeltexPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearBeltexPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnLevelExportExcelYearBeltexPost(requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - полный отчёт
         * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearFullPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearFullPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearFullPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnLevelExportExcelYearFullPost(requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Polaris
         * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPolarisPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearPolarisPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPolarisPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnLevelExportExcelYearPolarisPost(requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - приборы текущего года
         * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnLevelExportExcelYearPost(requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Pulsar
         * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPulsarPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearPulsarPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPulsarPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnLevelExportExcelYearPulsarPost(requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Total Data
         * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearTotalDataPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearTotalDataPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearTotalDataPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnLevelExportExcelYearTotalDataPost(requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Уровень возвратов - выгрузка в Excel - Yukon
         * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearYukonPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelExportExcelYearYukonPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearYukonPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnLevelExportExcelYearYukonPost(requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о ремонтах
         * @param {ReportsReturnLevelApiApiReportsReturnLevelImportRepairsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelImportRepairsPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelImportRepairsPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ReturnLevelReportImportRepairsResponse> {
            return localVarFp.apiReportsReturnLevelImportRepairsPost(requestParameters.month, requestParameters.year, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о ремонтах
         * @param {ReportsReturnLevelApiApiReportsReturnLevelImportRepairsSellmarkPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelImportRepairsSellmarkPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelImportRepairsSellmarkPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ReturnLevelReportImportRepairsSellmarkResponse> {
            return localVarFp.apiReportsReturnLevelImportRepairsSellmarkPost(requestParameters.month, requestParameters.year, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о продажах
         * @param {ReportsReturnLevelApiApiReportsReturnLevelImportSalesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelImportSalesPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelImportSalesPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ReturnLevelReportImportSalesResponse> {
            return localVarFp.apiReportsReturnLevelImportSalesPost(requestParameters.month, requestParameters.year, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка данных - загрузить данные о продажах Sellmark
         * @param {ReportsReturnLevelApiApiReportsReturnLevelImportSalesSellmarkPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelImportSalesSellmarkPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelImportSalesSellmarkPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ReturnLevelReportImportSalesSellmarkResponse> {
            return localVarFp.apiReportsReturnLevelImportSalesSellmarkPost(requestParameters.month, requestParameters.year, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Уровень возвратов - данные
         * @param {ReportsReturnLevelApiApiReportsReturnLevelReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelReportGet(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ReturnLevelReportDto> {
            return localVarFp.apiReportsReturnLevelReportGet(requestParameters.year, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Года отчёта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnLevelYearsGet(options?: AxiosRequestConfig): AxiosPromise<Array<ReturnLevelYearReportItemDto>> {
            return localVarFp.apiReportsReturnLevelYearsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsReturnLevelDataLoadingInfoGet operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelDataLoadingInfoGetRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelDataLoadingInfoGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelDataLoadingInfoGet
     */
    readonly month?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelDataLoadingInfoGet
     */
    readonly year?: number
}

/**
 * Request parameters for apiReportsReturnLevelDelete operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelDeleteRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelDelete
     */
    readonly year?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelDelete
     */
    readonly month?: number

    /**
     * 
     * @type {ReturnLevelDataType}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelDelete
     */
    readonly dataType?: ReturnLevelDataType
}

/**
 * Request parameters for apiReportsReturnLevelExportExcelYearAllPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearAllPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearAllPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearAllPost
     */
    readonly year: number
}

/**
 * Request parameters for apiReportsReturnLevelExportExcelYearBeltexPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearBeltexPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearBeltexPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearBeltexPost
     */
    readonly year: number
}

/**
 * Request parameters for apiReportsReturnLevelExportExcelYearFullPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearFullPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearFullPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearFullPost
     */
    readonly year: number
}

/**
 * Request parameters for apiReportsReturnLevelExportExcelYearPolarisPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPolarisPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPolarisPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPolarisPost
     */
    readonly year: number
}

/**
 * Request parameters for apiReportsReturnLevelExportExcelYearPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPost
     */
    readonly year: number
}

/**
 * Request parameters for apiReportsReturnLevelExportExcelYearPulsarPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPulsarPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPulsarPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPulsarPost
     */
    readonly year: number
}

/**
 * Request parameters for apiReportsReturnLevelExportExcelYearTotalDataPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearTotalDataPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearTotalDataPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearTotalDataPost
     */
    readonly year: number
}

/**
 * Request parameters for apiReportsReturnLevelExportExcelYearYukonPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearYukonPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearYukonPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearYukonPost
     */
    readonly year: number
}

/**
 * Request parameters for apiReportsReturnLevelImportRepairsPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelImportRepairsPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelImportRepairsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportRepairsPost
     */
    readonly month?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportRepairsPost
     */
    readonly year?: number

    /**
     * 
     * @type {File}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportRepairsPost
     */
    readonly file?: File
}

/**
 * Request parameters for apiReportsReturnLevelImportRepairsSellmarkPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelImportRepairsSellmarkPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelImportRepairsSellmarkPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportRepairsSellmarkPost
     */
    readonly month?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportRepairsSellmarkPost
     */
    readonly year?: number

    /**
     * 
     * @type {File}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportRepairsSellmarkPost
     */
    readonly file?: File
}

/**
 * Request parameters for apiReportsReturnLevelImportSalesPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelImportSalesPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelImportSalesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportSalesPost
     */
    readonly month?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportSalesPost
     */
    readonly year?: number

    /**
     * 
     * @type {File}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportSalesPost
     */
    readonly file?: File
}

/**
 * Request parameters for apiReportsReturnLevelImportSalesSellmarkPost operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelImportSalesSellmarkPostRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelImportSalesSellmarkPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportSalesSellmarkPost
     */
    readonly month?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportSalesSellmarkPost
     */
    readonly year?: number

    /**
     * 
     * @type {File}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelImportSalesSellmarkPost
     */
    readonly file?: File
}

/**
 * Request parameters for apiReportsReturnLevelReportGet operation in ReportsReturnLevelApi.
 * @export
 * @interface ReportsReturnLevelApiApiReportsReturnLevelReportGetRequest
 */
export interface ReportsReturnLevelApiApiReportsReturnLevelReportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelReportGet
     */
    readonly year?: number

    /**
     * 
     * @type {ReturnLevelReportType}
     * @memberof ReportsReturnLevelApiApiReportsReturnLevelReportGet
     */
    readonly type?: ReturnLevelReportType
}

/**
 * ReportsReturnLevelApi - object-oriented interface
 * @export
 * @class ReportsReturnLevelApi
 * @extends {BaseAPI}
 */
export class ReportsReturnLevelApi extends BaseAPI {
    /**
     * 
     * @summary Загрузка данных - иноформация о продаах и ремонтах
     * @param {ReportsReturnLevelApiApiReportsReturnLevelDataLoadingInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelDataLoadingInfoGet(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelDataLoadingInfoGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelDataLoadingInfoGet(requestParameters.month, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка данных - удаление данных
     * @param {ReportsReturnLevelApiApiReportsReturnLevelDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelDelete(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelDeleteRequest = {}, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelDelete(requestParameters.year, requestParameters.month, requestParameters.dataType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Уровень возвратов - выгрузка в Excel - по компании
     * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearAllPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelExportExcelYearAllPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearAllPostRequest, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelExportExcelYearAllPost(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Уровень возвратов - выгрузка в Excel - Beltex
     * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearBeltexPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelExportExcelYearBeltexPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearBeltexPostRequest, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelExportExcelYearBeltexPost(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Уровень возвратов - выгрузка в Excel - полный отчёт
     * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearFullPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelExportExcelYearFullPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearFullPostRequest, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelExportExcelYearFullPost(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Уровень возвратов - выгрузка в Excel - Polaris
     * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPolarisPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelExportExcelYearPolarisPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPolarisPostRequest, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelExportExcelYearPolarisPost(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Уровень возвратов - выгрузка в Excel - приборы текущего года
     * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelExportExcelYearPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPostRequest, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelExportExcelYearPost(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Уровень возвратов - выгрузка в Excel - Pulsar
     * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPulsarPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelExportExcelYearPulsarPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearPulsarPostRequest, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelExportExcelYearPulsarPost(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Уровень возвратов - выгрузка в Excel - Total Data
     * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearTotalDataPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelExportExcelYearTotalDataPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearTotalDataPostRequest, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelExportExcelYearTotalDataPost(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Уровень возвратов - выгрузка в Excel - Yukon
     * @param {ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearYukonPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelExportExcelYearYukonPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelExportExcelYearYukonPostRequest, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelExportExcelYearYukonPost(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка данных - загрузить данные о ремонтах
     * @param {ReportsReturnLevelApiApiReportsReturnLevelImportRepairsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelImportRepairsPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelImportRepairsPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelImportRepairsPost(requestParameters.month, requestParameters.year, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка данных - загрузить данные о ремонтах
     * @param {ReportsReturnLevelApiApiReportsReturnLevelImportRepairsSellmarkPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelImportRepairsSellmarkPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelImportRepairsSellmarkPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelImportRepairsSellmarkPost(requestParameters.month, requestParameters.year, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка данных - загрузить данные о продажах
     * @param {ReportsReturnLevelApiApiReportsReturnLevelImportSalesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelImportSalesPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelImportSalesPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelImportSalesPost(requestParameters.month, requestParameters.year, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка данных - загрузить данные о продажах Sellmark
     * @param {ReportsReturnLevelApiApiReportsReturnLevelImportSalesSellmarkPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelImportSalesSellmarkPost(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelImportSalesSellmarkPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelImportSalesSellmarkPost(requestParameters.month, requestParameters.year, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Уровень возвратов - данные
     * @param {ReportsReturnLevelApiApiReportsReturnLevelReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelReportGet(requestParameters: ReportsReturnLevelApiApiReportsReturnLevelReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelReportGet(requestParameters.year, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Года отчёта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnLevelApi
     */
    public apiReportsReturnLevelYearsGet(options?: AxiosRequestConfig) {
        return ReportsReturnLevelApiFp(this.configuration).apiReportsReturnLevelYearsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsReturnsReasonsApi - axios parameter creator
 * @export
 */
export const ReportsReturnsReasonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Причины возвратов - выгрузка в Excel
         * @param {ReturnsReasonsReportPayload} [returnsReasonsReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnsReasonsExportExcelPost: async (returnsReasonsReportPayload?: ReturnsReasonsReportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-returns-reasons/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnsReasonsReportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsReturnsReasonsApi - functional programming interface
 * @export
 */
export const ReportsReturnsReasonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsReturnsReasonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Причины возвратов - выгрузка в Excel
         * @param {ReturnsReasonsReportPayload} [returnsReasonsReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReturnsReasonsExportExcelPost(returnsReasonsReportPayload?: ReturnsReasonsReportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReturnsReasonsExportExcelPost(returnsReasonsReportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsReturnsReasonsApi - factory interface
 * @export
 */
export const ReportsReturnsReasonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsReturnsReasonsApiFp(configuration)
    return {
        /**
         * 
         * @summary Причины возвратов - выгрузка в Excel
         * @param {ReportsReturnsReasonsApiApiReportsReturnsReasonsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReturnsReasonsExportExcelPost(requestParameters: ReportsReturnsReasonsApiApiReportsReturnsReasonsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsReturnsReasonsExportExcelPost(requestParameters.returnsReasonsReportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsReturnsReasonsExportExcelPost operation in ReportsReturnsReasonsApi.
 * @export
 * @interface ReportsReturnsReasonsApiApiReportsReturnsReasonsExportExcelPostRequest
 */
export interface ReportsReturnsReasonsApiApiReportsReturnsReasonsExportExcelPostRequest {
    /**
     * 
     * @type {ReturnsReasonsReportPayload}
     * @memberof ReportsReturnsReasonsApiApiReportsReturnsReasonsExportExcelPost
     */
    readonly returnsReasonsReportPayload?: ReturnsReasonsReportPayload
}

/**
 * ReportsReturnsReasonsApi - object-oriented interface
 * @export
 * @class ReportsReturnsReasonsApi
 * @extends {BaseAPI}
 */
export class ReportsReturnsReasonsApi extends BaseAPI {
    /**
     * 
     * @summary Причины возвратов - выгрузка в Excel
     * @param {ReportsReturnsReasonsApiApiReportsReturnsReasonsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsReturnsReasonsApi
     */
    public apiReportsReturnsReasonsExportExcelPost(requestParameters: ReportsReturnsReasonsApiApiReportsReturnsReasonsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsReturnsReasonsApiFp(this.configuration).apiReportsReturnsReasonsExportExcelPost(requestParameters.returnsReasonsReportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsServiceCostsApi - axios parameter creator
 * @export
 */
export const ReportsServiceCostsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список загруженных данных по предприятию за год (сформированные отчеты)
         * @param {number} [firmId] Ключ фирмы
         * @param {number} [year] Год
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCostsBrowseGet: async (firmId?: number, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-service-costs/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Расходы по сервису - выгрузка в Excel
         * @param {ServiceCostsReportExportPayload} [serviceCostsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCostsExportExcelPost: async (serviceCostsReportExportPayload?: ServiceCostsReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-service-costs/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceCostsReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка данных по предприятию за месяц (формирование отчета)
         * @param {number} [firmId] Код фирмы
         * @param {number} [year] Год
         * @param {number} [month] Месяц
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCostsLoadDataPost: async (firmId?: number, year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-service-costs/load-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранение данных по предприятию за месяц (сохранение отчета)
         * @param {ServiceCostsReportSaveItemPayload} [serviceCostsReportSaveItemPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCostsSavePost: async (serviceCostsReportSaveItemPayload?: ServiceCostsReportSaveItemPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-service-costs/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceCostsReportSaveItemPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsServiceCostsApi - functional programming interface
 * @export
 */
export const ReportsServiceCostsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsServiceCostsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список загруженных данных по предприятию за год (сформированные отчеты)
         * @param {number} [firmId] Ключ фирмы
         * @param {number} [year] Год
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsServiceCostsBrowseGet(firmId?: number, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceCostsReportBrowseItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsServiceCostsBrowseGet(firmId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Расходы по сервису - выгрузка в Excel
         * @param {ServiceCostsReportExportPayload} [serviceCostsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsServiceCostsExportExcelPost(serviceCostsReportExportPayload?: ServiceCostsReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsServiceCostsExportExcelPost(serviceCostsReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка данных по предприятию за месяц (формирование отчета)
         * @param {number} [firmId] Код фирмы
         * @param {number} [year] Год
         * @param {number} [month] Месяц
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsServiceCostsLoadDataPost(firmId?: number, year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceCostsReportAggregateDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsServiceCostsLoadDataPost(firmId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сохранение данных по предприятию за месяц (сохранение отчета)
         * @param {ServiceCostsReportSaveItemPayload} [serviceCostsReportSaveItemPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsServiceCostsSavePost(serviceCostsReportSaveItemPayload?: ServiceCostsReportSaveItemPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsServiceCostsSavePost(serviceCostsReportSaveItemPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsServiceCostsApi - factory interface
 * @export
 */
export const ReportsServiceCostsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsServiceCostsApiFp(configuration)
    return {
        /**
         * 
         * @summary Список загруженных данных по предприятию за год (сформированные отчеты)
         * @param {ReportsServiceCostsApiApiReportsServiceCostsBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCostsBrowseGet(requestParameters: ReportsServiceCostsApiApiReportsServiceCostsBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ServiceCostsReportBrowseItemDto>> {
            return localVarFp.apiReportsServiceCostsBrowseGet(requestParameters.firmId, requestParameters.year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Расходы по сервису - выгрузка в Excel
         * @param {ReportsServiceCostsApiApiReportsServiceCostsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCostsExportExcelPost(requestParameters: ReportsServiceCostsApiApiReportsServiceCostsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsServiceCostsExportExcelPost(requestParameters.serviceCostsReportExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка данных по предприятию за месяц (формирование отчета)
         * @param {ReportsServiceCostsApiApiReportsServiceCostsLoadDataPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCostsLoadDataPost(requestParameters: ReportsServiceCostsApiApiReportsServiceCostsLoadDataPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ServiceCostsReportAggregateDataDto> {
            return localVarFp.apiReportsServiceCostsLoadDataPost(requestParameters.firmId, requestParameters.year, requestParameters.month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранение данных по предприятию за месяц (сохранение отчета)
         * @param {ReportsServiceCostsApiApiReportsServiceCostsSavePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCostsSavePost(requestParameters: ReportsServiceCostsApiApiReportsServiceCostsSavePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsServiceCostsSavePost(requestParameters.serviceCostsReportSaveItemPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsServiceCostsBrowseGet operation in ReportsServiceCostsApi.
 * @export
 * @interface ReportsServiceCostsApiApiReportsServiceCostsBrowseGetRequest
 */
export interface ReportsServiceCostsApiApiReportsServiceCostsBrowseGetRequest {
    /**
     * Ключ фирмы
     * @type {number}
     * @memberof ReportsServiceCostsApiApiReportsServiceCostsBrowseGet
     */
    readonly firmId?: number

    /**
     * Год
     * @type {number}
     * @memberof ReportsServiceCostsApiApiReportsServiceCostsBrowseGet
     */
    readonly year?: number
}

/**
 * Request parameters for apiReportsServiceCostsExportExcelPost operation in ReportsServiceCostsApi.
 * @export
 * @interface ReportsServiceCostsApiApiReportsServiceCostsExportExcelPostRequest
 */
export interface ReportsServiceCostsApiApiReportsServiceCostsExportExcelPostRequest {
    /**
     * 
     * @type {ServiceCostsReportExportPayload}
     * @memberof ReportsServiceCostsApiApiReportsServiceCostsExportExcelPost
     */
    readonly serviceCostsReportExportPayload?: ServiceCostsReportExportPayload
}

/**
 * Request parameters for apiReportsServiceCostsLoadDataPost operation in ReportsServiceCostsApi.
 * @export
 * @interface ReportsServiceCostsApiApiReportsServiceCostsLoadDataPostRequest
 */
export interface ReportsServiceCostsApiApiReportsServiceCostsLoadDataPostRequest {
    /**
     * Код фирмы
     * @type {number}
     * @memberof ReportsServiceCostsApiApiReportsServiceCostsLoadDataPost
     */
    readonly firmId?: number

    /**
     * Год
     * @type {number}
     * @memberof ReportsServiceCostsApiApiReportsServiceCostsLoadDataPost
     */
    readonly year?: number

    /**
     * Месяц
     * @type {number}
     * @memberof ReportsServiceCostsApiApiReportsServiceCostsLoadDataPost
     */
    readonly month?: number
}

/**
 * Request parameters for apiReportsServiceCostsSavePost operation in ReportsServiceCostsApi.
 * @export
 * @interface ReportsServiceCostsApiApiReportsServiceCostsSavePostRequest
 */
export interface ReportsServiceCostsApiApiReportsServiceCostsSavePostRequest {
    /**
     * 
     * @type {ServiceCostsReportSaveItemPayload}
     * @memberof ReportsServiceCostsApiApiReportsServiceCostsSavePost
     */
    readonly serviceCostsReportSaveItemPayload?: ServiceCostsReportSaveItemPayload
}

/**
 * ReportsServiceCostsApi - object-oriented interface
 * @export
 * @class ReportsServiceCostsApi
 * @extends {BaseAPI}
 */
export class ReportsServiceCostsApi extends BaseAPI {
    /**
     * 
     * @summary Список загруженных данных по предприятию за год (сформированные отчеты)
     * @param {ReportsServiceCostsApiApiReportsServiceCostsBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsServiceCostsApi
     */
    public apiReportsServiceCostsBrowseGet(requestParameters: ReportsServiceCostsApiApiReportsServiceCostsBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsServiceCostsApiFp(this.configuration).apiReportsServiceCostsBrowseGet(requestParameters.firmId, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Расходы по сервису - выгрузка в Excel
     * @param {ReportsServiceCostsApiApiReportsServiceCostsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsServiceCostsApi
     */
    public apiReportsServiceCostsExportExcelPost(requestParameters: ReportsServiceCostsApiApiReportsServiceCostsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsServiceCostsApiFp(this.configuration).apiReportsServiceCostsExportExcelPost(requestParameters.serviceCostsReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка данных по предприятию за месяц (формирование отчета)
     * @param {ReportsServiceCostsApiApiReportsServiceCostsLoadDataPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsServiceCostsApi
     */
    public apiReportsServiceCostsLoadDataPost(requestParameters: ReportsServiceCostsApiApiReportsServiceCostsLoadDataPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsServiceCostsApiFp(this.configuration).apiReportsServiceCostsLoadDataPost(requestParameters.firmId, requestParameters.year, requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранение данных по предприятию за месяц (сохранение отчета)
     * @param {ReportsServiceCostsApiApiReportsServiceCostsSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsServiceCostsApi
     */
    public apiReportsServiceCostsSavePost(requestParameters: ReportsServiceCostsApiApiReportsServiceCostsSavePostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsServiceCostsApiFp(this.configuration).apiReportsServiceCostsSavePost(requestParameters.serviceCostsReportSaveItemPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsSparePartsApi - axios parameter creator
 * @export
 */
export const ReportsSparePartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Комплектация - выгрузка в Excel
         * @param {SparePartsReportToExcelPayload} [sparePartsReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsExportExcelPost: async (sparePartsReportToExcelPayload?: SparePartsReportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sparePartsReportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Участки учета комплектации
         * @param {number} [frimId] Ид фирмы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsGlobalAreasForReportsGet: async (frimId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/global-areas-for-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (frimId !== undefined) {
                localVarQueryParameter['FrimId'] = frimId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Расшифровка поступления комплектации - выгрузка в Excel
         * @param {SparePartsReceiptReportToExcelPayload} [sparePartsReceiptReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsReceiptExportExcelPost: async (sparePartsReceiptReportToExcelPayload?: SparePartsReceiptReportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/spare-parts-receipt-export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sparePartsReceiptReportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Расшифровка поступления комплектации - выгрузка в Pdf
         * @param {SparePartsReceiptReportToPdfPayload} [sparePartsReceiptReportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsReceiptExportPdfPost: async (sparePartsReceiptReportToPdfPayload?: SparePartsReceiptReportToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/spare-parts-receipt-export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sparePartsReceiptReportToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Расшифровка поступления комплектации
         * @param {SparePartsReceiptPayload} [sparePartsReceiptPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsReceiptPost: async (sparePartsReceiptPayload?: SparePartsReceiptPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/spare-parts-receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sparePartsReceiptPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации - выгрузка в Excel
         * @param {SparePartsSpendingReportToExcelPayload} [sparePartsSpendingReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsSpendingExportExcelPost: async (sparePartsSpendingReportToExcelPayload?: SparePartsSpendingReportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/spare-parts-spending-export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sparePartsSpendingReportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации - выгрузка в Pdf
         * @param {SparePartsSpendingReportToPdfPayload} [sparePartsSpendingReportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsSpendingExportPdfPost: async (sparePartsSpendingReportToPdfPayload?: SparePartsSpendingReportToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/spare-parts-spending-export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sparePartsSpendingReportToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации
         * @param {SparePartsSpendingReportPayload} [sparePartsSpendingReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsSpendingPost: async (sparePartsSpendingReportPayload?: SparePartsSpendingReportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/spare-parts-spending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sparePartsSpendingReportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации для передачи в Производство - выгрузка в Excel
         * @param {SparePartsTotalSpendingReportToExcelPayload} [sparePartsTotalSpendingReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsTotalSpendingExportExcelPost: async (sparePartsTotalSpendingReportToExcelPayload?: SparePartsTotalSpendingReportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/spare-parts-total-spending-export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sparePartsTotalSpendingReportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации по Upgradable приборам для передачи в Производство - выгрузка в Excel
         * @param {SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload} [sparePartsTotalSpendingUpgradableDevicesReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPost: async (sparePartsTotalSpendingUpgradableDevicesReportToExcelPayload?: SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-spare-parts/spare-parts-total-spending-upgradable-export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sparePartsTotalSpendingUpgradableDevicesReportToExcelPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsSparePartsApi - functional programming interface
 * @export
 */
export const ReportsSparePartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsSparePartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Комплектация - выгрузка в Excel
         * @param {SparePartsReportToExcelPayload} [sparePartsReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsExportExcelPost(sparePartsReportToExcelPayload?: SparePartsReportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsExportExcelPost(sparePartsReportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Участки учета комплектации
         * @param {number} [frimId] Ид фирмы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsGlobalAreasForReportsGet(frimId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SparePartsAreaDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsGlobalAreasForReportsGet(frimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Расшифровка поступления комплектации - выгрузка в Excel
         * @param {SparePartsReceiptReportToExcelPayload} [sparePartsReceiptReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsSparePartsReceiptExportExcelPost(sparePartsReceiptReportToExcelPayload?: SparePartsReceiptReportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsSparePartsReceiptExportExcelPost(sparePartsReceiptReportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Расшифровка поступления комплектации - выгрузка в Pdf
         * @param {SparePartsReceiptReportToPdfPayload} [sparePartsReceiptReportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsSparePartsReceiptExportPdfPost(sparePartsReceiptReportToPdfPayload?: SparePartsReceiptReportToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsSparePartsReceiptExportPdfPost(sparePartsReceiptReportToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Расшифровка поступления комплектации
         * @param {SparePartsReceiptPayload} [sparePartsReceiptPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsSparePartsReceiptPost(sparePartsReceiptPayload?: SparePartsReceiptPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SparePartsReceiptReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsSparePartsReceiptPost(sparePartsReceiptPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации - выгрузка в Excel
         * @param {SparePartsSpendingReportToExcelPayload} [sparePartsSpendingReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsSparePartsSpendingExportExcelPost(sparePartsSpendingReportToExcelPayload?: SparePartsSpendingReportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsSparePartsSpendingExportExcelPost(sparePartsSpendingReportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации - выгрузка в Pdf
         * @param {SparePartsSpendingReportToPdfPayload} [sparePartsSpendingReportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsSparePartsSpendingExportPdfPost(sparePartsSpendingReportToPdfPayload?: SparePartsSpendingReportToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsSparePartsSpendingExportPdfPost(sparePartsSpendingReportToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации
         * @param {SparePartsSpendingReportPayload} [sparePartsSpendingReportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsSparePartsSpendingPost(sparePartsSpendingReportPayload?: SparePartsSpendingReportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SparePartsSpendingReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsSparePartsSpendingPost(sparePartsSpendingReportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации для передачи в Производство - выгрузка в Excel
         * @param {SparePartsTotalSpendingReportToExcelPayload} [sparePartsTotalSpendingReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsSparePartsTotalSpendingExportExcelPost(sparePartsTotalSpendingReportToExcelPayload?: SparePartsTotalSpendingReportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsSparePartsTotalSpendingExportExcelPost(sparePartsTotalSpendingReportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации по Upgradable приборам для передачи в Производство - выгрузка в Excel
         * @param {SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload} [sparePartsTotalSpendingUpgradableDevicesReportToExcelPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPost(sparePartsTotalSpendingUpgradableDevicesReportToExcelPayload?: SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPost(sparePartsTotalSpendingUpgradableDevicesReportToExcelPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsSparePartsApi - factory interface
 * @export
 */
export const ReportsSparePartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsSparePartsApiFp(configuration)
    return {
        /**
         * 
         * @summary Комплектация - выгрузка в Excel
         * @param {ReportsSparePartsApiApiReportsSparePartsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsSparePartsExportExcelPost(requestParameters.sparePartsReportToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Участки учета комплектации
         * @param {ReportsSparePartsApiApiReportsSparePartsGlobalAreasForReportsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsGlobalAreasForReportsGet(requestParameters: ReportsSparePartsApiApiReportsSparePartsGlobalAreasForReportsGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<SparePartsAreaDto>> {
            return localVarFp.apiReportsSparePartsGlobalAreasForReportsGet(requestParameters.frimId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Расшифровка поступления комплектации - выгрузка в Excel
         * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsReceiptExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsSparePartsSparePartsReceiptExportExcelPost(requestParameters.sparePartsReceiptReportToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Расшифровка поступления комплектации - выгрузка в Pdf
         * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsReceiptExportPdfPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsSparePartsSparePartsReceiptExportPdfPost(requestParameters.sparePartsReceiptReportToPdfPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Расшифровка поступления комплектации
         * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsReceiptPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<SparePartsReceiptReportDto>> {
            return localVarFp.apiReportsSparePartsSparePartsReceiptPost(requestParameters.sparePartsReceiptPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации - выгрузка в Excel
         * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsSpendingExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsSparePartsSparePartsSpendingExportExcelPost(requestParameters.sparePartsSpendingReportToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации - выгрузка в Pdf
         * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsSpendingExportPdfPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsSparePartsSparePartsSpendingExportPdfPost(requestParameters.sparePartsSpendingReportToPdfPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации
         * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsSpendingPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SparePartsSpendingReportDto> {
            return localVarFp.apiReportsSparePartsSparePartsSpendingPost(requestParameters.sparePartsSpendingReportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации для передачи в Производство - выгрузка в Excel
         * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsTotalSpendingExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsSparePartsSparePartsTotalSpendingExportExcelPost(requestParameters.sparePartsTotalSpendingReportToExcelPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Расшифровка расхода комплектации по Upgradable приборам для передачи в Производство - выгрузка в Excel
         * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPost(requestParameters.sparePartsTotalSpendingUpgradableDevicesReportToExcelPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsSparePartsExportExcelPost operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsExportExcelPostRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsExportExcelPostRequest {
    /**
     * 
     * @type {SparePartsReportToExcelPayload}
     * @memberof ReportsSparePartsApiApiReportsSparePartsExportExcelPost
     */
    readonly sparePartsReportToExcelPayload?: SparePartsReportToExcelPayload
}

/**
 * Request parameters for apiReportsSparePartsGlobalAreasForReportsGet operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsGlobalAreasForReportsGetRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsGlobalAreasForReportsGetRequest {
    /**
     * Ид фирмы
     * @type {number}
     * @memberof ReportsSparePartsApiApiReportsSparePartsGlobalAreasForReportsGet
     */
    readonly frimId?: number
}

/**
 * Request parameters for apiReportsSparePartsSparePartsReceiptExportExcelPost operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportExcelPostRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportExcelPostRequest {
    /**
     * 
     * @type {SparePartsReceiptReportToExcelPayload}
     * @memberof ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportExcelPost
     */
    readonly sparePartsReceiptReportToExcelPayload?: SparePartsReceiptReportToExcelPayload
}

/**
 * Request parameters for apiReportsSparePartsSparePartsReceiptExportPdfPost operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportPdfPostRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportPdfPostRequest {
    /**
     * 
     * @type {SparePartsReceiptReportToPdfPayload}
     * @memberof ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportPdfPost
     */
    readonly sparePartsReceiptReportToPdfPayload?: SparePartsReceiptReportToPdfPayload
}

/**
 * Request parameters for apiReportsSparePartsSparePartsReceiptPost operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptPostRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptPostRequest {
    /**
     * 
     * @type {SparePartsReceiptPayload}
     * @memberof ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptPost
     */
    readonly sparePartsReceiptPayload?: SparePartsReceiptPayload
}

/**
 * Request parameters for apiReportsSparePartsSparePartsSpendingExportExcelPost operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportExcelPostRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportExcelPostRequest {
    /**
     * 
     * @type {SparePartsSpendingReportToExcelPayload}
     * @memberof ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportExcelPost
     */
    readonly sparePartsSpendingReportToExcelPayload?: SparePartsSpendingReportToExcelPayload
}

/**
 * Request parameters for apiReportsSparePartsSparePartsSpendingExportPdfPost operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportPdfPostRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportPdfPostRequest {
    /**
     * 
     * @type {SparePartsSpendingReportToPdfPayload}
     * @memberof ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportPdfPost
     */
    readonly sparePartsSpendingReportToPdfPayload?: SparePartsSpendingReportToPdfPayload
}

/**
 * Request parameters for apiReportsSparePartsSparePartsSpendingPost operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingPostRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingPostRequest {
    /**
     * 
     * @type {SparePartsSpendingReportPayload}
     * @memberof ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingPost
     */
    readonly sparePartsSpendingReportPayload?: SparePartsSpendingReportPayload
}

/**
 * Request parameters for apiReportsSparePartsSparePartsTotalSpendingExportExcelPost operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingExportExcelPostRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingExportExcelPostRequest {
    /**
     * 
     * @type {SparePartsTotalSpendingReportToExcelPayload}
     * @memberof ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingExportExcelPost
     */
    readonly sparePartsTotalSpendingReportToExcelPayload?: SparePartsTotalSpendingReportToExcelPayload
}

/**
 * Request parameters for apiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPost operation in ReportsSparePartsApi.
 * @export
 * @interface ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPostRequest
 */
export interface ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPostRequest {
    /**
     * 
     * @type {SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload}
     * @memberof ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPost
     */
    readonly sparePartsTotalSpendingUpgradableDevicesReportToExcelPayload?: SparePartsTotalSpendingUpgradableDevicesReportToExcelPayload
}

/**
 * ReportsSparePartsApi - object-oriented interface
 * @export
 * @class ReportsSparePartsApi
 * @extends {BaseAPI}
 */
export class ReportsSparePartsApi extends BaseAPI {
    /**
     * 
     * @summary Комплектация - выгрузка в Excel
     * @param {ReportsSparePartsApiApiReportsSparePartsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsExportExcelPost(requestParameters.sparePartsReportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Участки учета комплектации
     * @param {ReportsSparePartsApiApiReportsSparePartsGlobalAreasForReportsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsGlobalAreasForReportsGet(requestParameters: ReportsSparePartsApiApiReportsSparePartsGlobalAreasForReportsGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsGlobalAreasForReportsGet(requestParameters.frimId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Расшифровка поступления комплектации - выгрузка в Excel
     * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsSparePartsReceiptExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsSparePartsReceiptExportExcelPost(requestParameters.sparePartsReceiptReportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Расшифровка поступления комплектации - выгрузка в Pdf
     * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsSparePartsReceiptExportPdfPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptExportPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsSparePartsReceiptExportPdfPost(requestParameters.sparePartsReceiptReportToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Расшифровка поступления комплектации
     * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsSparePartsReceiptPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsReceiptPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsSparePartsReceiptPost(requestParameters.sparePartsReceiptPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Расшифровка расхода комплектации - выгрузка в Excel
     * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsSparePartsSpendingExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsSparePartsSpendingExportExcelPost(requestParameters.sparePartsSpendingReportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Расшифровка расхода комплектации - выгрузка в Pdf
     * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsSparePartsSpendingExportPdfPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingExportPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsSparePartsSpendingExportPdfPost(requestParameters.sparePartsSpendingReportToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Расшифровка расхода комплектации
     * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsSparePartsSpendingPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsSpendingPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsSparePartsSpendingPost(requestParameters.sparePartsSpendingReportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Расшифровка расхода комплектации для передачи в Производство - выгрузка в Excel
     * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsSparePartsTotalSpendingExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsSparePartsTotalSpendingExportExcelPost(requestParameters.sparePartsTotalSpendingReportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Расшифровка расхода комплектации по Upgradable приборам для передачи в Производство - выгрузка в Excel
     * @param {ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsSparePartsApi
     */
    public apiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPost(requestParameters: ReportsSparePartsApiApiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsSparePartsApiFp(this.configuration).apiReportsSparePartsSparePartsTotalSpendingUpgradableExportExcelPost(requestParameters.sparePartsTotalSpendingUpgradableDevicesReportToExcelPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsTransportCostsApi - axios parameter creator
 * @export
 */
export const ReportsTransportCostsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Расходы по транспорту - выгрузка в Excel
         * @param {TransportCostsReportExportPayload} [transportCostsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsTransportCostsExportExcelPost: async (transportCostsReportExportPayload?: TransportCostsReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-transport-costs/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transportCostsReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsTransportCostsApi - functional programming interface
 * @export
 */
export const ReportsTransportCostsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsTransportCostsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Расходы по транспорту - выгрузка в Excel
         * @param {TransportCostsReportExportPayload} [transportCostsReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsTransportCostsExportExcelPost(transportCostsReportExportPayload?: TransportCostsReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsTransportCostsExportExcelPost(transportCostsReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsTransportCostsApi - factory interface
 * @export
 */
export const ReportsTransportCostsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsTransportCostsApiFp(configuration)
    return {
        /**
         * 
         * @summary Расходы по транспорту - выгрузка в Excel
         * @param {ReportsTransportCostsApiApiReportsTransportCostsExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsTransportCostsExportExcelPost(requestParameters: ReportsTransportCostsApiApiReportsTransportCostsExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsTransportCostsExportExcelPost(requestParameters.transportCostsReportExportPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsTransportCostsExportExcelPost operation in ReportsTransportCostsApi.
 * @export
 * @interface ReportsTransportCostsApiApiReportsTransportCostsExportExcelPostRequest
 */
export interface ReportsTransportCostsApiApiReportsTransportCostsExportExcelPostRequest {
    /**
     * 
     * @type {TransportCostsReportExportPayload}
     * @memberof ReportsTransportCostsApiApiReportsTransportCostsExportExcelPost
     */
    readonly transportCostsReportExportPayload?: TransportCostsReportExportPayload
}

/**
 * ReportsTransportCostsApi - object-oriented interface
 * @export
 * @class ReportsTransportCostsApi
 * @extends {BaseAPI}
 */
export class ReportsTransportCostsApi extends BaseAPI {
    /**
     * 
     * @summary Расходы по транспорту - выгрузка в Excel
     * @param {ReportsTransportCostsApiApiReportsTransportCostsExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsTransportCostsApi
     */
    public apiReportsTransportCostsExportExcelPost(requestParameters: ReportsTransportCostsApiApiReportsTransportCostsExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsTransportCostsApiFp(this.configuration).apiReportsTransportCostsExportExcelPost(requestParameters.transportCostsReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsUnplannedDevicesApi - axios parameter creator
 * @export
 */
export const ReportsUnplannedDevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Незапланированные приборы - выгрузка в Excel
         * @param {UnplannedDevicesExportPayload} [unplannedDevicesExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsUnplannedDevicesExportExcelPost: async (unplannedDevicesExportPayload?: UnplannedDevicesExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-unplanned-devices/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unplannedDevicesExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Незапланированные приборы - данные
         * @param {number} [firmId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsUnplannedDevicesReportGet: async (firmId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-unplanned-devices/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsUnplannedDevicesApi - functional programming interface
 * @export
 */
export const ReportsUnplannedDevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsUnplannedDevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Незапланированные приборы - выгрузка в Excel
         * @param {UnplannedDevicesExportPayload} [unplannedDevicesExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsUnplannedDevicesExportExcelPost(unplannedDevicesExportPayload?: UnplannedDevicesExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsUnplannedDevicesExportExcelPost(unplannedDevicesExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Незапланированные приборы - данные
         * @param {number} [firmId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsUnplannedDevicesReportGet(firmId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnplannedDevicesReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsUnplannedDevicesReportGet(firmId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsUnplannedDevicesApi - factory interface
 * @export
 */
export const ReportsUnplannedDevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsUnplannedDevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Незапланированные приборы - выгрузка в Excel
         * @param {ReportsUnplannedDevicesApiApiReportsUnplannedDevicesExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsUnplannedDevicesExportExcelPost(requestParameters: ReportsUnplannedDevicesApiApiReportsUnplannedDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsUnplannedDevicesExportExcelPost(requestParameters.unplannedDevicesExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Незапланированные приборы - данные
         * @param {ReportsUnplannedDevicesApiApiReportsUnplannedDevicesReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsUnplannedDevicesReportGet(requestParameters: ReportsUnplannedDevicesApiApiReportsUnplannedDevicesReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UnplannedDevicesReportDto> {
            return localVarFp.apiReportsUnplannedDevicesReportGet(requestParameters.firmId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsUnplannedDevicesExportExcelPost operation in ReportsUnplannedDevicesApi.
 * @export
 * @interface ReportsUnplannedDevicesApiApiReportsUnplannedDevicesExportExcelPostRequest
 */
export interface ReportsUnplannedDevicesApiApiReportsUnplannedDevicesExportExcelPostRequest {
    /**
     * 
     * @type {UnplannedDevicesExportPayload}
     * @memberof ReportsUnplannedDevicesApiApiReportsUnplannedDevicesExportExcelPost
     */
    readonly unplannedDevicesExportPayload?: UnplannedDevicesExportPayload
}

/**
 * Request parameters for apiReportsUnplannedDevicesReportGet operation in ReportsUnplannedDevicesApi.
 * @export
 * @interface ReportsUnplannedDevicesApiApiReportsUnplannedDevicesReportGetRequest
 */
export interface ReportsUnplannedDevicesApiApiReportsUnplannedDevicesReportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsUnplannedDevicesApiApiReportsUnplannedDevicesReportGet
     */
    readonly firmId?: number
}

/**
 * ReportsUnplannedDevicesApi - object-oriented interface
 * @export
 * @class ReportsUnplannedDevicesApi
 * @extends {BaseAPI}
 */
export class ReportsUnplannedDevicesApi extends BaseAPI {
    /**
     * 
     * @summary Незапланированные приборы - выгрузка в Excel
     * @param {ReportsUnplannedDevicesApiApiReportsUnplannedDevicesExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsUnplannedDevicesApi
     */
    public apiReportsUnplannedDevicesExportExcelPost(requestParameters: ReportsUnplannedDevicesApiApiReportsUnplannedDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsUnplannedDevicesApiFp(this.configuration).apiReportsUnplannedDevicesExportExcelPost(requestParameters.unplannedDevicesExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Незапланированные приборы - данные
     * @param {ReportsUnplannedDevicesApiApiReportsUnplannedDevicesReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsUnplannedDevicesApi
     */
    public apiReportsUnplannedDevicesReportGet(requestParameters: ReportsUnplannedDevicesApiApiReportsUnplannedDevicesReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsUnplannedDevicesApiFp(this.configuration).apiReportsUnplannedDevicesReportGet(requestParameters.firmId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsUpgradeDevicesApi - axios parameter creator
 * @export
 */
export const ReportsUpgradeDevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upgrade приборы - выгрузка в Excel
         * @param {UpgradeDevicesExportPayload} [upgradeDevicesExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsUpgradeDevicesExportExcelPost: async (upgradeDevicesExportPayload?: UpgradeDevicesExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-upgrade-devices/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upgradeDevicesExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upgrade приборы - данные
         * @param {number} [firmId] Ключ предприятия
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsUpgradeDevicesReportGet: async (firmId?: number, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-upgrade-devices/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsUpgradeDevicesApi - functional programming interface
 * @export
 */
export const ReportsUpgradeDevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsUpgradeDevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Upgrade приборы - выгрузка в Excel
         * @param {UpgradeDevicesExportPayload} [upgradeDevicesExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsUpgradeDevicesExportExcelPost(upgradeDevicesExportPayload?: UpgradeDevicesExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsUpgradeDevicesExportExcelPost(upgradeDevicesExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upgrade приборы - данные
         * @param {number} [firmId] Ключ предприятия
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsUpgradeDevicesReportGet(firmId?: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpgradeDevicesReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsUpgradeDevicesReportGet(firmId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsUpgradeDevicesApi - factory interface
 * @export
 */
export const ReportsUpgradeDevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsUpgradeDevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Upgrade приборы - выгрузка в Excel
         * @param {ReportsUpgradeDevicesApiApiReportsUpgradeDevicesExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsUpgradeDevicesExportExcelPost(requestParameters: ReportsUpgradeDevicesApiApiReportsUpgradeDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsUpgradeDevicesExportExcelPost(requestParameters.upgradeDevicesExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upgrade приборы - данные
         * @param {ReportsUpgradeDevicesApiApiReportsUpgradeDevicesReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsUpgradeDevicesReportGet(requestParameters: ReportsUpgradeDevicesApiApiReportsUpgradeDevicesReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UpgradeDevicesReportDto> {
            return localVarFp.apiReportsUpgradeDevicesReportGet(requestParameters.firmId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsUpgradeDevicesExportExcelPost operation in ReportsUpgradeDevicesApi.
 * @export
 * @interface ReportsUpgradeDevicesApiApiReportsUpgradeDevicesExportExcelPostRequest
 */
export interface ReportsUpgradeDevicesApiApiReportsUpgradeDevicesExportExcelPostRequest {
    /**
     * 
     * @type {UpgradeDevicesExportPayload}
     * @memberof ReportsUpgradeDevicesApiApiReportsUpgradeDevicesExportExcelPost
     */
    readonly upgradeDevicesExportPayload?: UpgradeDevicesExportPayload
}

/**
 * Request parameters for apiReportsUpgradeDevicesReportGet operation in ReportsUpgradeDevicesApi.
 * @export
 * @interface ReportsUpgradeDevicesApiApiReportsUpgradeDevicesReportGetRequest
 */
export interface ReportsUpgradeDevicesApiApiReportsUpgradeDevicesReportGetRequest {
    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReportsUpgradeDevicesApiApiReportsUpgradeDevicesReportGet
     */
    readonly firmId?: number

    /**
     * Начало периода
     * @type {string}
     * @memberof ReportsUpgradeDevicesApiApiReportsUpgradeDevicesReportGet
     */
    readonly dateFrom?: string

    /**
     * конец периода
     * @type {string}
     * @memberof ReportsUpgradeDevicesApiApiReportsUpgradeDevicesReportGet
     */
    readonly dateTo?: string
}

/**
 * ReportsUpgradeDevicesApi - object-oriented interface
 * @export
 * @class ReportsUpgradeDevicesApi
 * @extends {BaseAPI}
 */
export class ReportsUpgradeDevicesApi extends BaseAPI {
    /**
     * 
     * @summary Upgrade приборы - выгрузка в Excel
     * @param {ReportsUpgradeDevicesApiApiReportsUpgradeDevicesExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsUpgradeDevicesApi
     */
    public apiReportsUpgradeDevicesExportExcelPost(requestParameters: ReportsUpgradeDevicesApiApiReportsUpgradeDevicesExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsUpgradeDevicesApiFp(this.configuration).apiReportsUpgradeDevicesExportExcelPost(requestParameters.upgradeDevicesExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upgrade приборы - данные
     * @param {ReportsUpgradeDevicesApiApiReportsUpgradeDevicesReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsUpgradeDevicesApi
     */
    public apiReportsUpgradeDevicesReportGet(requestParameters: ReportsUpgradeDevicesApiApiReportsUpgradeDevicesReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsUpgradeDevicesApiFp(this.configuration).apiReportsUpgradeDevicesReportGet(requestParameters.firmId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsWarehouseMovementApi - axios parameter creator
 * @export
 */
export const ReportsWarehouseMovementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Движение по складам (RMA) - выгрузка в Excel
         * @param {WarehouseMovementReportExportPayload} [warehouseMovementReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsWarehouseMovementExportExcelPost: async (warehouseMovementReportExportPayload?: WarehouseMovementReportExportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-warehouse-movement/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warehouseMovementReportExportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Движение по складам (RMA) - выгрузка в Pdf
         * @param {WarehouseMovementReportExportToPdfPayload} [warehouseMovementReportExportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsWarehouseMovementExportPdfPost: async (warehouseMovementReportExportToPdfPayload?: WarehouseMovementReportExportToPdfPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-warehouse-movement/export-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warehouseMovementReportExportToPdfPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Движение по складам (RMA) - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsWarehouseMovementReportGet: async (dateFrom?: string, dateTo?: string, firmId?: number, lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports-warehouse-movement/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = dateTo;
            }

            if (firmId !== undefined) {
                localVarQueryParameter['FirmId'] = firmId;
            }

            if (lcid !== undefined) {
                localVarQueryParameter['Lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsWarehouseMovementApi - functional programming interface
 * @export
 */
export const ReportsWarehouseMovementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsWarehouseMovementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Движение по складам (RMA) - выгрузка в Excel
         * @param {WarehouseMovementReportExportPayload} [warehouseMovementReportExportPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsWarehouseMovementExportExcelPost(warehouseMovementReportExportPayload?: WarehouseMovementReportExportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsWarehouseMovementExportExcelPost(warehouseMovementReportExportPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Движение по складам (RMA) - выгрузка в Pdf
         * @param {WarehouseMovementReportExportToPdfPayload} [warehouseMovementReportExportToPdfPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsWarehouseMovementExportPdfPost(warehouseMovementReportExportToPdfPayload?: WarehouseMovementReportExportToPdfPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsWarehouseMovementExportPdfPost(warehouseMovementReportExportToPdfPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Движение по складам (RMA) - данные
         * @param {string} [dateFrom] Начало периода
         * @param {string} [dateTo] конец периода
         * @param {number} [firmId] Ключ предприятия
         * @param {number} [lcid] язык
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsWarehouseMovementReportGet(dateFrom?: string, dateTo?: string, firmId?: number, lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseMovementReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsWarehouseMovementReportGet(dateFrom, dateTo, firmId, lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsWarehouseMovementApi - factory interface
 * @export
 */
export const ReportsWarehouseMovementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsWarehouseMovementApiFp(configuration)
    return {
        /**
         * 
         * @summary Движение по складам (RMA) - выгрузка в Excel
         * @param {ReportsWarehouseMovementApiApiReportsWarehouseMovementExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsWarehouseMovementExportExcelPost(requestParameters: ReportsWarehouseMovementApiApiReportsWarehouseMovementExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsWarehouseMovementExportExcelPost(requestParameters.warehouseMovementReportExportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Движение по складам (RMA) - выгрузка в Pdf
         * @param {ReportsWarehouseMovementApiApiReportsWarehouseMovementExportPdfPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsWarehouseMovementExportPdfPost(requestParameters: ReportsWarehouseMovementApiApiReportsWarehouseMovementExportPdfPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiReportsWarehouseMovementExportPdfPost(requestParameters.warehouseMovementReportExportToPdfPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Движение по складам (RMA) - данные
         * @param {ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsWarehouseMovementReportGet(requestParameters: ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<WarehouseMovementReportDto> {
            return localVarFp.apiReportsWarehouseMovementReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsWarehouseMovementExportExcelPost operation in ReportsWarehouseMovementApi.
 * @export
 * @interface ReportsWarehouseMovementApiApiReportsWarehouseMovementExportExcelPostRequest
 */
export interface ReportsWarehouseMovementApiApiReportsWarehouseMovementExportExcelPostRequest {
    /**
     * 
     * @type {WarehouseMovementReportExportPayload}
     * @memberof ReportsWarehouseMovementApiApiReportsWarehouseMovementExportExcelPost
     */
    readonly warehouseMovementReportExportPayload?: WarehouseMovementReportExportPayload
}

/**
 * Request parameters for apiReportsWarehouseMovementExportPdfPost operation in ReportsWarehouseMovementApi.
 * @export
 * @interface ReportsWarehouseMovementApiApiReportsWarehouseMovementExportPdfPostRequest
 */
export interface ReportsWarehouseMovementApiApiReportsWarehouseMovementExportPdfPostRequest {
    /**
     * 
     * @type {WarehouseMovementReportExportToPdfPayload}
     * @memberof ReportsWarehouseMovementApiApiReportsWarehouseMovementExportPdfPost
     */
    readonly warehouseMovementReportExportToPdfPayload?: WarehouseMovementReportExportToPdfPayload
}

/**
 * Request parameters for apiReportsWarehouseMovementReportGet operation in ReportsWarehouseMovementApi.
 * @export
 * @interface ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGetRequest
 */
export interface ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGetRequest {
    /**
     * Начало периода
     * @type {string}
     * @memberof ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGet
     */
    readonly dateFrom?: string

    /**
     * конец периода
     * @type {string}
     * @memberof ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGet
     */
    readonly dateTo?: string

    /**
     * Ключ предприятия
     * @type {number}
     * @memberof ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGet
     */
    readonly firmId?: number

    /**
     * язык
     * @type {number}
     * @memberof ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGet
     */
    readonly lcid?: number
}

/**
 * ReportsWarehouseMovementApi - object-oriented interface
 * @export
 * @class ReportsWarehouseMovementApi
 * @extends {BaseAPI}
 */
export class ReportsWarehouseMovementApi extends BaseAPI {
    /**
     * 
     * @summary Движение по складам (RMA) - выгрузка в Excel
     * @param {ReportsWarehouseMovementApiApiReportsWarehouseMovementExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsWarehouseMovementApi
     */
    public apiReportsWarehouseMovementExportExcelPost(requestParameters: ReportsWarehouseMovementApiApiReportsWarehouseMovementExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsWarehouseMovementApiFp(this.configuration).apiReportsWarehouseMovementExportExcelPost(requestParameters.warehouseMovementReportExportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Движение по складам (RMA) - выгрузка в Pdf
     * @param {ReportsWarehouseMovementApiApiReportsWarehouseMovementExportPdfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsWarehouseMovementApi
     */
    public apiReportsWarehouseMovementExportPdfPost(requestParameters: ReportsWarehouseMovementApiApiReportsWarehouseMovementExportPdfPostRequest = {}, options?: AxiosRequestConfig) {
        return ReportsWarehouseMovementApiFp(this.configuration).apiReportsWarehouseMovementExportPdfPost(requestParameters.warehouseMovementReportExportToPdfPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Движение по складам (RMA) - данные
     * @param {ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsWarehouseMovementApi
     */
    public apiReportsWarehouseMovementReportGet(requestParameters: ReportsWarehouseMovementApiApiReportsWarehouseMovementReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsWarehouseMovementApiFp(this.configuration).apiReportsWarehouseMovementReportGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.firmId, requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsNotificationsApi - axios parameter creator
 * @export
 */
export const SettingsNotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал подписок пользователей на уведомления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsBrowseGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-notifications/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список уведомлений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsFilterNotificationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-notifications/filter-notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsFilterUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-notifications/filter-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подписка пользователя на рассылку уведомления
         * @param {SubscribePayload} [subscribePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsSubscribePost: async (subscribePayload?: SubscribePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-notifications/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отписка пользователя то рассылки уведомления
         * @param {UnsubscribePayload} [unsubscribePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsUnsubscribeDelete: async (unsubscribePayload?: UnsubscribePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-notifications/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unsubscribePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Включение-отключение предприятий компании
         * @param {UpdateSubscriptionPayload} [updateSubscriptionPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsUpdateSubscriptionPost: async (updateSubscriptionPayload?: UpdateSubscriptionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-notifications/update-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsNotificationsApi - functional programming interface
 * @export
 */
export const SettingsNotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsNotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал подписок пользователей на уведомления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsNotificationsBrowseGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsNotificationsBrowseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список уведомлений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsNotificationsFilterNotificationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsNotificationsFilterNotificationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsNotificationsFilterUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsNotificationsFilterUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подписка пользователя на рассылку уведомления
         * @param {SubscribePayload} [subscribePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsNotificationsSubscribePost(subscribePayload?: SubscribePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsNotificationsSubscribePost(subscribePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отписка пользователя то рассылки уведомления
         * @param {UnsubscribePayload} [unsubscribePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsNotificationsUnsubscribeDelete(unsubscribePayload?: UnsubscribePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsNotificationsUnsubscribeDelete(unsubscribePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Включение-отключение предприятий компании
         * @param {UpdateSubscriptionPayload} [updateSubscriptionPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsNotificationsUpdateSubscriptionPost(updateSubscriptionPayload?: UpdateSubscriptionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsNotificationsUpdateSubscriptionPost(updateSubscriptionPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsNotificationsApi - factory interface
 * @export
 */
export const SettingsNotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsNotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал подписок пользователей на уведомления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsBrowseGet(options?: AxiosRequestConfig): AxiosPromise<Array<NotificationBrowseDto>> {
            return localVarFp.apiSettingsNotificationsBrowseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список уведомлений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsFilterNotificationsGet(options?: AxiosRequestConfig): AxiosPromise<Array<NotificationDto>> {
            return localVarFp.apiSettingsNotificationsFilterNotificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsFilterUsersGet(options?: AxiosRequestConfig): AxiosPromise<Array<NotificationUserDto>> {
            return localVarFp.apiSettingsNotificationsFilterUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подписка пользователя на рассылку уведомления
         * @param {SettingsNotificationsApiApiSettingsNotificationsSubscribePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsSubscribePost(requestParameters: SettingsNotificationsApiApiSettingsNotificationsSubscribePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSettingsNotificationsSubscribePost(requestParameters.subscribePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отписка пользователя то рассылки уведомления
         * @param {SettingsNotificationsApiApiSettingsNotificationsUnsubscribeDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsUnsubscribeDelete(requestParameters: SettingsNotificationsApiApiSettingsNotificationsUnsubscribeDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSettingsNotificationsUnsubscribeDelete(requestParameters.unsubscribePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Включение-отключение предприятий компании
         * @param {SettingsNotificationsApiApiSettingsNotificationsUpdateSubscriptionPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsNotificationsUpdateSubscriptionPost(requestParameters: SettingsNotificationsApiApiSettingsNotificationsUpdateSubscriptionPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSettingsNotificationsUpdateSubscriptionPost(requestParameters.updateSubscriptionPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiSettingsNotificationsSubscribePost operation in SettingsNotificationsApi.
 * @export
 * @interface SettingsNotificationsApiApiSettingsNotificationsSubscribePostRequest
 */
export interface SettingsNotificationsApiApiSettingsNotificationsSubscribePostRequest {
    /**
     * 
     * @type {SubscribePayload}
     * @memberof SettingsNotificationsApiApiSettingsNotificationsSubscribePost
     */
    readonly subscribePayload?: SubscribePayload
}

/**
 * Request parameters for apiSettingsNotificationsUnsubscribeDelete operation in SettingsNotificationsApi.
 * @export
 * @interface SettingsNotificationsApiApiSettingsNotificationsUnsubscribeDeleteRequest
 */
export interface SettingsNotificationsApiApiSettingsNotificationsUnsubscribeDeleteRequest {
    /**
     * 
     * @type {UnsubscribePayload}
     * @memberof SettingsNotificationsApiApiSettingsNotificationsUnsubscribeDelete
     */
    readonly unsubscribePayload?: UnsubscribePayload
}

/**
 * Request parameters for apiSettingsNotificationsUpdateSubscriptionPost operation in SettingsNotificationsApi.
 * @export
 * @interface SettingsNotificationsApiApiSettingsNotificationsUpdateSubscriptionPostRequest
 */
export interface SettingsNotificationsApiApiSettingsNotificationsUpdateSubscriptionPostRequest {
    /**
     * 
     * @type {UpdateSubscriptionPayload}
     * @memberof SettingsNotificationsApiApiSettingsNotificationsUpdateSubscriptionPost
     */
    readonly updateSubscriptionPayload?: UpdateSubscriptionPayload
}

/**
 * SettingsNotificationsApi - object-oriented interface
 * @export
 * @class SettingsNotificationsApi
 * @extends {BaseAPI}
 */
export class SettingsNotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Журнал подписок пользователей на уведомления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsNotificationsApi
     */
    public apiSettingsNotificationsBrowseGet(options?: AxiosRequestConfig) {
        return SettingsNotificationsApiFp(this.configuration).apiSettingsNotificationsBrowseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список уведомлений
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsNotificationsApi
     */
    public apiSettingsNotificationsFilterNotificationsGet(options?: AxiosRequestConfig) {
        return SettingsNotificationsApiFp(this.configuration).apiSettingsNotificationsFilterNotificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список пользователей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsNotificationsApi
     */
    public apiSettingsNotificationsFilterUsersGet(options?: AxiosRequestConfig) {
        return SettingsNotificationsApiFp(this.configuration).apiSettingsNotificationsFilterUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подписка пользователя на рассылку уведомления
     * @param {SettingsNotificationsApiApiSettingsNotificationsSubscribePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsNotificationsApi
     */
    public apiSettingsNotificationsSubscribePost(requestParameters: SettingsNotificationsApiApiSettingsNotificationsSubscribePostRequest = {}, options?: AxiosRequestConfig) {
        return SettingsNotificationsApiFp(this.configuration).apiSettingsNotificationsSubscribePost(requestParameters.subscribePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отписка пользователя то рассылки уведомления
     * @param {SettingsNotificationsApiApiSettingsNotificationsUnsubscribeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsNotificationsApi
     */
    public apiSettingsNotificationsUnsubscribeDelete(requestParameters: SettingsNotificationsApiApiSettingsNotificationsUnsubscribeDeleteRequest = {}, options?: AxiosRequestConfig) {
        return SettingsNotificationsApiFp(this.configuration).apiSettingsNotificationsUnsubscribeDelete(requestParameters.unsubscribePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Включение-отключение предприятий компании
     * @param {SettingsNotificationsApiApiSettingsNotificationsUpdateSubscriptionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsNotificationsApi
     */
    public apiSettingsNotificationsUpdateSubscriptionPost(requestParameters: SettingsNotificationsApiApiSettingsNotificationsUpdateSubscriptionPostRequest = {}, options?: AxiosRequestConfig) {
        return SettingsNotificationsApiFp(this.configuration).apiSettingsNotificationsUpdateSubscriptionPost(requestParameters.updateSubscriptionPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsUsersApi - axios parameter creator
 * @export
 */
export const SettingsUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список пользователей для \'Бэдж сотрудника\'
         * @param {BrowseUsersForBadgePayload} [browseUsersForBadgePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersBrowseBadgePost: async (browseUsersForBadgePayload?: BrowseUsersForBadgePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-users/browse-badge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(browseUsersForBadgePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список пользователей
         * @param {BrowseUsersPayload} [browseUsersPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersBrowsePost: async (browseUsersPayload?: BrowseUsersPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-users/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(browseUsersPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить пользователя для бэджа
         * @param {CreateUserForBadgePayload} [createUserForBadgePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersCreateForBadgePost: async (createUserForBadgePayload?: CreateUserForBadgePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-users/create-for-badge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserForBadgePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить пользователя
         * @param {CreateUserPayload} [createUserPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersCreatePost: async (createUserPayload?: CreateUserPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-users/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить пользователя
         * @param {DeleteUserPayload} [deleteUserPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersDeleteDelete: async (deleteUserPayload?: DeleteUserPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-users/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUserPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить права пользователя
         * @param {UpdateUserPermissionsPayload} [updateUserPermissionsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersSetPermissionsPut: async (updateUserPermissionsPayload?: UpdateUserPermissionsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings-users/set-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPermissionsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выпустить новый бэдж для пользователя
         * @param {string} tn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersTnProduceBadgePost: async (tn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tn' is not null or undefined
            assertParamExists('apiSettingsUsersTnProduceBadgePost', 'tn', tn)
            const localVarPath = `/api/settings-users/{tn}/produce-badge`
                .replace(`{${"tn"}}`, encodeURIComponent(String(tn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsUsersApi - functional programming interface
 * @export
 */
export const SettingsUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список пользователей для \'Бэдж сотрудника\'
         * @param {BrowseUsersForBadgePayload} [browseUsersForBadgePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsUsersBrowseBadgePost(browseUsersForBadgePayload?: BrowseUsersForBadgePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserForBadgeBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsUsersBrowseBadgePost(browseUsersForBadgePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список пользователей
         * @param {BrowseUsersPayload} [browseUsersPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsUsersBrowsePost(browseUsersPayload?: BrowseUsersPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserBrowseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsUsersBrowsePost(browseUsersPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить пользователя для бэджа
         * @param {CreateUserForBadgePayload} [createUserForBadgePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsUsersCreateForBadgePost(createUserForBadgePayload?: CreateUserForBadgePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsUsersCreateForBadgePost(createUserForBadgePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить пользователя
         * @param {CreateUserPayload} [createUserPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsUsersCreatePost(createUserPayload?: CreateUserPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsUsersCreatePost(createUserPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить пользователя
         * @param {DeleteUserPayload} [deleteUserPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsUsersDeleteDelete(deleteUserPayload?: DeleteUserPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsUsersDeleteDelete(deleteUserPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить права пользователя
         * @param {UpdateUserPermissionsPayload} [updateUserPermissionsPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsUsersSetPermissionsPut(updateUserPermissionsPayload?: UpdateUserPermissionsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsUsersSetPermissionsPut(updateUserPermissionsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выпустить новый бэдж для пользователя
         * @param {string} tn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsUsersTnProduceBadgePost(tn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsUsersTnProduceBadgePost(tn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsUsersApi - factory interface
 * @export
 */
export const SettingsUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Список пользователей для \'Бэдж сотрудника\'
         * @param {SettingsUsersApiApiSettingsUsersBrowseBadgePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersBrowseBadgePost(requestParameters: SettingsUsersApiApiSettingsUsersBrowseBadgePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<UserForBadgeBrowseDto>> {
            return localVarFp.apiSettingsUsersBrowseBadgePost(requestParameters.browseUsersForBadgePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список пользователей
         * @param {SettingsUsersApiApiSettingsUsersBrowsePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersBrowsePost(requestParameters: SettingsUsersApiApiSettingsUsersBrowsePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<UserBrowseDto>> {
            return localVarFp.apiSettingsUsersBrowsePost(requestParameters.browseUsersPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить пользователя для бэджа
         * @param {SettingsUsersApiApiSettingsUsersCreateForBadgePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersCreateForBadgePost(requestParameters: SettingsUsersApiApiSettingsUsersCreateForBadgePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSettingsUsersCreateForBadgePost(requestParameters.createUserForBadgePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить пользователя
         * @param {SettingsUsersApiApiSettingsUsersCreatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersCreatePost(requestParameters: SettingsUsersApiApiSettingsUsersCreatePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSettingsUsersCreatePost(requestParameters.createUserPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить пользователя
         * @param {SettingsUsersApiApiSettingsUsersDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersDeleteDelete(requestParameters: SettingsUsersApiApiSettingsUsersDeleteDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSettingsUsersDeleteDelete(requestParameters.deleteUserPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить права пользователя
         * @param {SettingsUsersApiApiSettingsUsersSetPermissionsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersSetPermissionsPut(requestParameters: SettingsUsersApiApiSettingsUsersSetPermissionsPutRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSettingsUsersSetPermissionsPut(requestParameters.updateUserPermissionsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выпустить новый бэдж для пользователя
         * @param {SettingsUsersApiApiSettingsUsersTnProduceBadgePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUsersTnProduceBadgePost(requestParameters: SettingsUsersApiApiSettingsUsersTnProduceBadgePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSettingsUsersTnProduceBadgePost(requestParameters.tn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiSettingsUsersBrowseBadgePost operation in SettingsUsersApi.
 * @export
 * @interface SettingsUsersApiApiSettingsUsersBrowseBadgePostRequest
 */
export interface SettingsUsersApiApiSettingsUsersBrowseBadgePostRequest {
    /**
     * 
     * @type {BrowseUsersForBadgePayload}
     * @memberof SettingsUsersApiApiSettingsUsersBrowseBadgePost
     */
    readonly browseUsersForBadgePayload?: BrowseUsersForBadgePayload
}

/**
 * Request parameters for apiSettingsUsersBrowsePost operation in SettingsUsersApi.
 * @export
 * @interface SettingsUsersApiApiSettingsUsersBrowsePostRequest
 */
export interface SettingsUsersApiApiSettingsUsersBrowsePostRequest {
    /**
     * 
     * @type {BrowseUsersPayload}
     * @memberof SettingsUsersApiApiSettingsUsersBrowsePost
     */
    readonly browseUsersPayload?: BrowseUsersPayload
}

/**
 * Request parameters for apiSettingsUsersCreateForBadgePost operation in SettingsUsersApi.
 * @export
 * @interface SettingsUsersApiApiSettingsUsersCreateForBadgePostRequest
 */
export interface SettingsUsersApiApiSettingsUsersCreateForBadgePostRequest {
    /**
     * 
     * @type {CreateUserForBadgePayload}
     * @memberof SettingsUsersApiApiSettingsUsersCreateForBadgePost
     */
    readonly createUserForBadgePayload?: CreateUserForBadgePayload
}

/**
 * Request parameters for apiSettingsUsersCreatePost operation in SettingsUsersApi.
 * @export
 * @interface SettingsUsersApiApiSettingsUsersCreatePostRequest
 */
export interface SettingsUsersApiApiSettingsUsersCreatePostRequest {
    /**
     * 
     * @type {CreateUserPayload}
     * @memberof SettingsUsersApiApiSettingsUsersCreatePost
     */
    readonly createUserPayload?: CreateUserPayload
}

/**
 * Request parameters for apiSettingsUsersDeleteDelete operation in SettingsUsersApi.
 * @export
 * @interface SettingsUsersApiApiSettingsUsersDeleteDeleteRequest
 */
export interface SettingsUsersApiApiSettingsUsersDeleteDeleteRequest {
    /**
     * 
     * @type {DeleteUserPayload}
     * @memberof SettingsUsersApiApiSettingsUsersDeleteDelete
     */
    readonly deleteUserPayload?: DeleteUserPayload
}

/**
 * Request parameters for apiSettingsUsersSetPermissionsPut operation in SettingsUsersApi.
 * @export
 * @interface SettingsUsersApiApiSettingsUsersSetPermissionsPutRequest
 */
export interface SettingsUsersApiApiSettingsUsersSetPermissionsPutRequest {
    /**
     * 
     * @type {UpdateUserPermissionsPayload}
     * @memberof SettingsUsersApiApiSettingsUsersSetPermissionsPut
     */
    readonly updateUserPermissionsPayload?: UpdateUserPermissionsPayload
}

/**
 * Request parameters for apiSettingsUsersTnProduceBadgePost operation in SettingsUsersApi.
 * @export
 * @interface SettingsUsersApiApiSettingsUsersTnProduceBadgePostRequest
 */
export interface SettingsUsersApiApiSettingsUsersTnProduceBadgePostRequest {
    /**
     * 
     * @type {string}
     * @memberof SettingsUsersApiApiSettingsUsersTnProduceBadgePost
     */
    readonly tn: string
}

/**
 * SettingsUsersApi - object-oriented interface
 * @export
 * @class SettingsUsersApi
 * @extends {BaseAPI}
 */
export class SettingsUsersApi extends BaseAPI {
    /**
     * 
     * @summary Список пользователей для \'Бэдж сотрудника\'
     * @param {SettingsUsersApiApiSettingsUsersBrowseBadgePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsUsersApi
     */
    public apiSettingsUsersBrowseBadgePost(requestParameters: SettingsUsersApiApiSettingsUsersBrowseBadgePostRequest = {}, options?: AxiosRequestConfig) {
        return SettingsUsersApiFp(this.configuration).apiSettingsUsersBrowseBadgePost(requestParameters.browseUsersForBadgePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список пользователей
     * @param {SettingsUsersApiApiSettingsUsersBrowsePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsUsersApi
     */
    public apiSettingsUsersBrowsePost(requestParameters: SettingsUsersApiApiSettingsUsersBrowsePostRequest = {}, options?: AxiosRequestConfig) {
        return SettingsUsersApiFp(this.configuration).apiSettingsUsersBrowsePost(requestParameters.browseUsersPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить пользователя для бэджа
     * @param {SettingsUsersApiApiSettingsUsersCreateForBadgePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsUsersApi
     */
    public apiSettingsUsersCreateForBadgePost(requestParameters: SettingsUsersApiApiSettingsUsersCreateForBadgePostRequest = {}, options?: AxiosRequestConfig) {
        return SettingsUsersApiFp(this.configuration).apiSettingsUsersCreateForBadgePost(requestParameters.createUserForBadgePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить пользователя
     * @param {SettingsUsersApiApiSettingsUsersCreatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsUsersApi
     */
    public apiSettingsUsersCreatePost(requestParameters: SettingsUsersApiApiSettingsUsersCreatePostRequest = {}, options?: AxiosRequestConfig) {
        return SettingsUsersApiFp(this.configuration).apiSettingsUsersCreatePost(requestParameters.createUserPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить пользователя
     * @param {SettingsUsersApiApiSettingsUsersDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsUsersApi
     */
    public apiSettingsUsersDeleteDelete(requestParameters: SettingsUsersApiApiSettingsUsersDeleteDeleteRequest = {}, options?: AxiosRequestConfig) {
        return SettingsUsersApiFp(this.configuration).apiSettingsUsersDeleteDelete(requestParameters.deleteUserPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить права пользователя
     * @param {SettingsUsersApiApiSettingsUsersSetPermissionsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsUsersApi
     */
    public apiSettingsUsersSetPermissionsPut(requestParameters: SettingsUsersApiApiSettingsUsersSetPermissionsPutRequest = {}, options?: AxiosRequestConfig) {
        return SettingsUsersApiFp(this.configuration).apiSettingsUsersSetPermissionsPut(requestParameters.updateUserPermissionsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выпустить новый бэдж для пользователя
     * @param {SettingsUsersApiApiSettingsUsersTnProduceBadgePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsUsersApi
     */
    public apiSettingsUsersTnProduceBadgePost(requestParameters: SettingsUsersApiApiSettingsUsersTnProduceBadgePostRequest, options?: AxiosRequestConfig) {
        return SettingsUsersApiFp(this.configuration).apiSettingsUsersTnProduceBadgePost(requestParameters.tn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAccessApi - axios parameter creator
 * @export
 */
export const UserAccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить данные о текущем пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user-access/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAccessApi - functional programming interface
 * @export
 */
export const UserAccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAccessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить данные о текущем пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAccessApi - factory interface
 * @export
 */
export const UserAccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAccessApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить данные о текущем пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessMeGet(options?: AxiosRequestConfig): AxiosPromise<AuthenticationResult> {
            return localVarFp.apiUserAccessMeGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAccessApi - object-oriented interface
 * @export
 * @class UserAccessApi
 * @extends {BaseAPI}
 */
export class UserAccessApi extends BaseAPI {
    /**
     * 
     * @summary Получить данные о текущем пользователе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public apiUserAccessMeGet(options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).apiUserAccessMeGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserDirectoryApi - axios parameter creator
 * @export
 */
export const UserDirectoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список пользователей - выгрузка в Excel
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDirectoryExportExcelPost: async (lcid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user-directory/export-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lcid !== undefined) {
                localVarQueryParameter['lcid'] = lcid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDirectoryUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user-directory/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить имя пользователя
         * @param {string} tn 
         * @param {SetUserNamePayload} [setUserNamePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDirectoryUsersTnSetUsernamePost: async (tn: string, setUserNamePayload?: SetUserNamePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tn' is not null or undefined
            assertParamExists('apiUserDirectoryUsersTnSetUsernamePost', 'tn', tn)
            const localVarPath = `/api/user-directory/users/{tn}/set-username`
                .replace(`{${"tn"}}`, encodeURIComponent(String(tn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setUserNamePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить версию клиентского приложения
         * @param {string} tn 
         * @param {UpdateClientAppVersionPayload} [updateClientAppVersionPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDirectoryUsersTnUpdateClientAppVersionPost: async (tn: string, updateClientAppVersionPayload?: UpdateClientAppVersionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tn' is not null or undefined
            assertParamExists('apiUserDirectoryUsersTnUpdateClientAppVersionPost', 'tn', tn)
            const localVarPath = `/api/user-directory/users/{tn}/update-client-app-version`
                .replace(`{${"tn"}}`, encodeURIComponent(String(tn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientAppVersionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDirectoryApi - functional programming interface
 * @export
 */
export const UserDirectoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserDirectoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список пользователей - выгрузка в Excel
         * @param {number} [lcid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDirectoryExportExcelPost(lcid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDirectoryExportExcelPost(lcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDirectoryUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserShortProfileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDirectoryUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить имя пользователя
         * @param {string} tn 
         * @param {SetUserNamePayload} [setUserNamePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDirectoryUsersTnSetUsernamePost(tn: string, setUserNamePayload?: SetUserNamePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDirectoryUsersTnSetUsernamePost(tn, setUserNamePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сохранить версию клиентского приложения
         * @param {string} tn 
         * @param {UpdateClientAppVersionPayload} [updateClientAppVersionPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDirectoryUsersTnUpdateClientAppVersionPost(tn: string, updateClientAppVersionPayload?: UpdateClientAppVersionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDirectoryUsersTnUpdateClientAppVersionPost(tn, updateClientAppVersionPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserDirectoryApi - factory interface
 * @export
 */
export const UserDirectoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserDirectoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Список пользователей - выгрузка в Excel
         * @param {UserDirectoryApiApiUserDirectoryExportExcelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDirectoryExportExcelPost(requestParameters: UserDirectoryApiApiUserDirectoryExportExcelPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiUserDirectoryExportExcelPost(requestParameters.lcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDirectoryUsersGet(options?: AxiosRequestConfig): AxiosPromise<Array<UserShortProfileDto>> {
            return localVarFp.apiUserDirectoryUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить имя пользователя
         * @param {UserDirectoryApiApiUserDirectoryUsersTnSetUsernamePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDirectoryUsersTnSetUsernamePost(requestParameters: UserDirectoryApiApiUserDirectoryUsersTnSetUsernamePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiUserDirectoryUsersTnSetUsernamePost(requestParameters.tn, requestParameters.setUserNamePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить версию клиентского приложения
         * @param {UserDirectoryApiApiUserDirectoryUsersTnUpdateClientAppVersionPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDirectoryUsersTnUpdateClientAppVersionPost(requestParameters: UserDirectoryApiApiUserDirectoryUsersTnUpdateClientAppVersionPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiUserDirectoryUsersTnUpdateClientAppVersionPost(requestParameters.tn, requestParameters.updateClientAppVersionPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiUserDirectoryExportExcelPost operation in UserDirectoryApi.
 * @export
 * @interface UserDirectoryApiApiUserDirectoryExportExcelPostRequest
 */
export interface UserDirectoryApiApiUserDirectoryExportExcelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof UserDirectoryApiApiUserDirectoryExportExcelPost
     */
    readonly lcid?: number
}

/**
 * Request parameters for apiUserDirectoryUsersTnSetUsernamePost operation in UserDirectoryApi.
 * @export
 * @interface UserDirectoryApiApiUserDirectoryUsersTnSetUsernamePostRequest
 */
export interface UserDirectoryApiApiUserDirectoryUsersTnSetUsernamePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserDirectoryApiApiUserDirectoryUsersTnSetUsernamePost
     */
    readonly tn: string

    /**
     * 
     * @type {SetUserNamePayload}
     * @memberof UserDirectoryApiApiUserDirectoryUsersTnSetUsernamePost
     */
    readonly setUserNamePayload?: SetUserNamePayload
}

/**
 * Request parameters for apiUserDirectoryUsersTnUpdateClientAppVersionPost operation in UserDirectoryApi.
 * @export
 * @interface UserDirectoryApiApiUserDirectoryUsersTnUpdateClientAppVersionPostRequest
 */
export interface UserDirectoryApiApiUserDirectoryUsersTnUpdateClientAppVersionPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserDirectoryApiApiUserDirectoryUsersTnUpdateClientAppVersionPost
     */
    readonly tn: string

    /**
     * 
     * @type {UpdateClientAppVersionPayload}
     * @memberof UserDirectoryApiApiUserDirectoryUsersTnUpdateClientAppVersionPost
     */
    readonly updateClientAppVersionPayload?: UpdateClientAppVersionPayload
}

/**
 * UserDirectoryApi - object-oriented interface
 * @export
 * @class UserDirectoryApi
 * @extends {BaseAPI}
 */
export class UserDirectoryApi extends BaseAPI {
    /**
     * 
     * @summary Список пользователей - выгрузка в Excel
     * @param {UserDirectoryApiApiUserDirectoryExportExcelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDirectoryApi
     */
    public apiUserDirectoryExportExcelPost(requestParameters: UserDirectoryApiApiUserDirectoryExportExcelPostRequest = {}, options?: AxiosRequestConfig) {
        return UserDirectoryApiFp(this.configuration).apiUserDirectoryExportExcelPost(requestParameters.lcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список пользователей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDirectoryApi
     */
    public apiUserDirectoryUsersGet(options?: AxiosRequestConfig) {
        return UserDirectoryApiFp(this.configuration).apiUserDirectoryUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить имя пользователя
     * @param {UserDirectoryApiApiUserDirectoryUsersTnSetUsernamePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDirectoryApi
     */
    public apiUserDirectoryUsersTnSetUsernamePost(requestParameters: UserDirectoryApiApiUserDirectoryUsersTnSetUsernamePostRequest, options?: AxiosRequestConfig) {
        return UserDirectoryApiFp(this.configuration).apiUserDirectoryUsersTnSetUsernamePost(requestParameters.tn, requestParameters.setUserNamePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить версию клиентского приложения
     * @param {UserDirectoryApiApiUserDirectoryUsersTnUpdateClientAppVersionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDirectoryApi
     */
    public apiUserDirectoryUsersTnUpdateClientAppVersionPost(requestParameters: UserDirectoryApiApiUserDirectoryUsersTnUpdateClientAppVersionPostRequest, options?: AxiosRequestConfig) {
        return UserDirectoryApiFp(this.configuration).apiUserDirectoryUsersTnUpdateClientAppVersionPost(requestParameters.tn, requestParameters.updateClientAppVersionPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsEmailsLogApi - axios parameter creator
 * @export
 */
export const UtilsEmailsLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал писем
         * @param {string} [mailTo] Фильтр по адресату письма
         * @param {string} [queryText] Фильтр по заголовку письма
         * @param {string} [applicationName] Фильтр по приложению которое отправило письмо
         * @param {string} [periodStart] Фильтр по дате отправки письма, начало периода, формат yyyy-MM-dd
         * @param {string} [periodEnd] Фильтр по дате отправки письма, конец периода, формат yyyy-MM-dd
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsEmailsLogBrowseGet: async (mailTo?: string, queryText?: string, applicationName?: string, periodStart?: string, periodEnd?: string, pagingPageIndex?: number, pagingPageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/utils-emails-log/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mailTo !== undefined) {
                localVarQueryParameter['MailTo'] = mailTo;
            }

            if (queryText !== undefined) {
                localVarQueryParameter['QueryText'] = queryText;
            }

            if (applicationName !== undefined) {
                localVarQueryParameter['ApplicationName'] = applicationName;
            }

            if (periodStart !== undefined) {
                localVarQueryParameter['PeriodStart'] = periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['PeriodEnd'] = periodEnd;
            }

            if (pagingPageIndex !== undefined) {
                localVarQueryParameter['Paging.PageIndex'] = pagingPageIndex;
            }

            if (pagingPageSize !== undefined) {
                localVarQueryParameter['Paging.PageSize'] = pagingPageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsEmailsLogApi - functional programming interface
 * @export
 */
export const UtilsEmailsLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsEmailsLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал писем
         * @param {string} [mailTo] Фильтр по адресату письма
         * @param {string} [queryText] Фильтр по заголовку письма
         * @param {string} [applicationName] Фильтр по приложению которое отправило письмо
         * @param {string} [periodStart] Фильтр по дате отправки письма, начало периода, формат yyyy-MM-dd
         * @param {string} [periodEnd] Фильтр по дате отправки письма, конец периода, формат yyyy-MM-dd
         * @param {number} [pagingPageIndex] 
         * @param {number} [pagingPageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilsEmailsLogBrowseGet(mailTo?: string, queryText?: string, applicationName?: string, periodStart?: string, periodEnd?: string, pagingPageIndex?: number, pagingPageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowseEmailsPagingResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilsEmailsLogBrowseGet(mailTo, queryText, applicationName, periodStart, periodEnd, pagingPageIndex, pagingPageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsEmailsLogApi - factory interface
 * @export
 */
export const UtilsEmailsLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsEmailsLogApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал писем
         * @param {UtilsEmailsLogApiApiUtilsEmailsLogBrowseGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsEmailsLogBrowseGet(requestParameters: UtilsEmailsLogApiApiUtilsEmailsLogBrowseGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BrowseEmailsPagingResult> {
            return localVarFp.apiUtilsEmailsLogBrowseGet(requestParameters.mailTo, requestParameters.queryText, requestParameters.applicationName, requestParameters.periodStart, requestParameters.periodEnd, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiUtilsEmailsLogBrowseGet operation in UtilsEmailsLogApi.
 * @export
 * @interface UtilsEmailsLogApiApiUtilsEmailsLogBrowseGetRequest
 */
export interface UtilsEmailsLogApiApiUtilsEmailsLogBrowseGetRequest {
    /**
     * Фильтр по адресату письма
     * @type {string}
     * @memberof UtilsEmailsLogApiApiUtilsEmailsLogBrowseGet
     */
    readonly mailTo?: string

    /**
     * Фильтр по заголовку письма
     * @type {string}
     * @memberof UtilsEmailsLogApiApiUtilsEmailsLogBrowseGet
     */
    readonly queryText?: string

    /**
     * Фильтр по приложению которое отправило письмо
     * @type {string}
     * @memberof UtilsEmailsLogApiApiUtilsEmailsLogBrowseGet
     */
    readonly applicationName?: string

    /**
     * Фильтр по дате отправки письма, начало периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof UtilsEmailsLogApiApiUtilsEmailsLogBrowseGet
     */
    readonly periodStart?: string

    /**
     * Фильтр по дате отправки письма, конец периода, формат yyyy-MM-dd
     * @type {string}
     * @memberof UtilsEmailsLogApiApiUtilsEmailsLogBrowseGet
     */
    readonly periodEnd?: string

    /**
     * 
     * @type {number}
     * @memberof UtilsEmailsLogApiApiUtilsEmailsLogBrowseGet
     */
    readonly pagingPageIndex?: number

    /**
     * 
     * @type {number}
     * @memberof UtilsEmailsLogApiApiUtilsEmailsLogBrowseGet
     */
    readonly pagingPageSize?: number
}

/**
 * UtilsEmailsLogApi - object-oriented interface
 * @export
 * @class UtilsEmailsLogApi
 * @extends {BaseAPI}
 */
export class UtilsEmailsLogApi extends BaseAPI {
    /**
     * 
     * @summary Журнал писем
     * @param {UtilsEmailsLogApiApiUtilsEmailsLogBrowseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsEmailsLogApi
     */
    public apiUtilsEmailsLogBrowseGet(requestParameters: UtilsEmailsLogApiApiUtilsEmailsLogBrowseGetRequest = {}, options?: AxiosRequestConfig) {
        return UtilsEmailsLogApiFp(this.configuration).apiUtilsEmailsLogBrowseGet(requestParameters.mailTo, requestParameters.queryText, requestParameters.applicationName, requestParameters.periodStart, requestParameters.periodEnd, requestParameters.pagingPageIndex, requestParameters.pagingPageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsFaqApi - axios parameter creator
 * @export
 */
export const UtilsFaqApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Удалить запись вопрос - ответ
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsFaqIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilsFaqIdDelete', 'id', id)
            const localVarPath = `/api/utils-faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить запись вопрос - ответ
         * @param {number} id 
         * @param {UpdateFaqPayload} [updateFaqPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsFaqIdPut: async (id: number, updateFaqPayload?: UpdateFaqPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilsFaqIdPut', 'id', id)
            const localVarPath = `/api/utils-faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFaqPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить все записи вопрос - ответ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsFaqListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/utils-faq/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать запись вопрос - ответ
         * @param {CreateFaqPayload} [createFaqPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsFaqPost: async (createFaqPayload?: CreateFaqPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/utils-faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFaqPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsFaqApi - functional programming interface
 * @export
 */
export const UtilsFaqApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsFaqApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Удалить запись вопрос - ответ
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilsFaqIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilsFaqIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить запись вопрос - ответ
         * @param {number} id 
         * @param {UpdateFaqPayload} [updateFaqPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilsFaqIdPut(id: number, updateFaqPayload?: UpdateFaqPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilsFaqIdPut(id, updateFaqPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить все записи вопрос - ответ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilsFaqListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrowseFaqItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilsFaqListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать запись вопрос - ответ
         * @param {CreateFaqPayload} [createFaqPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilsFaqPost(createFaqPayload?: CreateFaqPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilsFaqPost(createFaqPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsFaqApi - factory interface
 * @export
 */
export const UtilsFaqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsFaqApiFp(configuration)
    return {
        /**
         * 
         * @summary Удалить запись вопрос - ответ
         * @param {UtilsFaqApiApiUtilsFaqIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsFaqIdDelete(requestParameters: UtilsFaqApiApiUtilsFaqIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiUtilsFaqIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить запись вопрос - ответ
         * @param {UtilsFaqApiApiUtilsFaqIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsFaqIdPut(requestParameters: UtilsFaqApiApiUtilsFaqIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiUtilsFaqIdPut(requestParameters.id, requestParameters.updateFaqPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить все записи вопрос - ответ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsFaqListGet(options?: AxiosRequestConfig): AxiosPromise<Array<BrowseFaqItemDto>> {
            return localVarFp.apiUtilsFaqListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать запись вопрос - ответ
         * @param {UtilsFaqApiApiUtilsFaqPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsFaqPost(requestParameters: UtilsFaqApiApiUtilsFaqPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiUtilsFaqPost(requestParameters.createFaqPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiUtilsFaqIdDelete operation in UtilsFaqApi.
 * @export
 * @interface UtilsFaqApiApiUtilsFaqIdDeleteRequest
 */
export interface UtilsFaqApiApiUtilsFaqIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof UtilsFaqApiApiUtilsFaqIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiUtilsFaqIdPut operation in UtilsFaqApi.
 * @export
 * @interface UtilsFaqApiApiUtilsFaqIdPutRequest
 */
export interface UtilsFaqApiApiUtilsFaqIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof UtilsFaqApiApiUtilsFaqIdPut
     */
    readonly id: number

    /**
     * 
     * @type {UpdateFaqPayload}
     * @memberof UtilsFaqApiApiUtilsFaqIdPut
     */
    readonly updateFaqPayload?: UpdateFaqPayload
}

/**
 * Request parameters for apiUtilsFaqPost operation in UtilsFaqApi.
 * @export
 * @interface UtilsFaqApiApiUtilsFaqPostRequest
 */
export interface UtilsFaqApiApiUtilsFaqPostRequest {
    /**
     * 
     * @type {CreateFaqPayload}
     * @memberof UtilsFaqApiApiUtilsFaqPost
     */
    readonly createFaqPayload?: CreateFaqPayload
}

/**
 * UtilsFaqApi - object-oriented interface
 * @export
 * @class UtilsFaqApi
 * @extends {BaseAPI}
 */
export class UtilsFaqApi extends BaseAPI {
    /**
     * 
     * @summary Удалить запись вопрос - ответ
     * @param {UtilsFaqApiApiUtilsFaqIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsFaqApi
     */
    public apiUtilsFaqIdDelete(requestParameters: UtilsFaqApiApiUtilsFaqIdDeleteRequest, options?: AxiosRequestConfig) {
        return UtilsFaqApiFp(this.configuration).apiUtilsFaqIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить запись вопрос - ответ
     * @param {UtilsFaqApiApiUtilsFaqIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsFaqApi
     */
    public apiUtilsFaqIdPut(requestParameters: UtilsFaqApiApiUtilsFaqIdPutRequest, options?: AxiosRequestConfig) {
        return UtilsFaqApiFp(this.configuration).apiUtilsFaqIdPut(requestParameters.id, requestParameters.updateFaqPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить все записи вопрос - ответ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsFaqApi
     */
    public apiUtilsFaqListGet(options?: AxiosRequestConfig) {
        return UtilsFaqApiFp(this.configuration).apiUtilsFaqListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать запись вопрос - ответ
     * @param {UtilsFaqApiApiUtilsFaqPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsFaqApi
     */
    public apiUtilsFaqPost(requestParameters: UtilsFaqApiApiUtilsFaqPostRequest = {}, options?: AxiosRequestConfig) {
        return UtilsFaqApiFp(this.configuration).apiUtilsFaqPost(requestParameters.createFaqPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsTodayEventsApi - axios parameter creator
 * @export
 */
export const UtilsTodayEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Журнал текущих событий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsTodayEventsBrowseGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/utils-today-events/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsTodayEventsApi - functional programming interface
 * @export
 */
export const UtilsTodayEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsTodayEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Журнал текущих событий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilsTodayEventsBrowseGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TodayEventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilsTodayEventsBrowseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsTodayEventsApi - factory interface
 * @export
 */
export const UtilsTodayEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsTodayEventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Журнал текущих событий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilsTodayEventsBrowseGet(options?: AxiosRequestConfig): AxiosPromise<Array<TodayEventDto>> {
            return localVarFp.apiUtilsTodayEventsBrowseGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsTodayEventsApi - object-oriented interface
 * @export
 * @class UtilsTodayEventsApi
 * @extends {BaseAPI}
 */
export class UtilsTodayEventsApi extends BaseAPI {
    /**
     * 
     * @summary Журнал текущих событий
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsTodayEventsApi
     */
    public apiUtilsTodayEventsBrowseGet(options?: AxiosRequestConfig) {
        return UtilsTodayEventsApiFp(this.configuration).apiUtilsTodayEventsBrowseGet(options).then((request) => request(this.axios, this.basePath));
    }
}


