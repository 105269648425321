import { useContext, useEffect, useMemo, useState } from "react";
import { find } from "lodash-es";
import { AxiosResponse } from "axios";
import { SelectionMode } from "@fluentui/react";
import { useApi, useHttp, useSelection } from "hooks";
import { UserForBadgeBrowseDto } from "generated-sources/openapi";
import { CommandbarCommands } from "constants/enums";
import { companyOptions } from "constants/options";
import { LanguageContext } from "context/languageContext";
import { AuthContext } from "context/authContext";
import { Table } from "components/common/Table";
import { BasicPanelActions } from "components/common/BasicPanel/components";
import { BasicPanel, BasicPanelContent, BasicPanelDetails } from "components/common/BasicPanel";
import { getColumns, getActions } from "./config";
import { downloadFile } from "utils";
import { CommandWrapper } from "components/common/CommandWrapper";
import { AddBadgeModal } from "./AddBadgeModal";

interface IEmployeeBadgeModalProps {
  onDismiss: () => void;
}

export const EmployeeBadgeModal = (props: IEmployeeBadgeModalProps) => {
  const { loc } = useContext(LanguageContext);
  const { company } = useContext(AuthContext);
  const { settingsUsersApi } = useApi();
  const usersHttp = useHttp();
  const docsHttp = useHttp({ showErrors: true });
  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);
  const [users, setUsers] = useState<UserForBadgeBrowseDto[]>([]);
  const { selection, selectedItem } = useSelection<UserForBadgeBrowseDto>();
  const userCompanyId = Number(find(companyOptions, { text: company })?.key);

  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);

  const downloadBadge = () => {
    docsHttp
      .request(() =>
        settingsUsersApi.apiSettingsUsersTnProduceBadgePost(
          { tn: selectedItem?.tn || "" },
          { responseType: "blob" },
        ),
      )
      .then((res) => {
        downloadFile(res);
        handleModalDismiss();
      });
  };

  const loadUsers = () => {
    usersHttp
      .request(() =>
        settingsUsersApi.apiSettingsUsersBrowseBadgePost({
          browseUsersForBadgePayload: {
            firmId: userCompanyId,
          },
        }),
      )
      .then((res: AxiosResponse) => {
        setUsers(res.data);
      });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (activeCommand === CommandbarCommands.Download) {
      downloadBadge();
    }
  }, [activeCommand]);

  const columns = useMemo(() => getColumns(), []);
  const actions = useMemo(() => getActions(selectedItem), [selectedItem]);

  return (
    <BasicPanel
      title={loc.settings.employeeBadge}
      isLoading={usersHttp.isLoading || docsHttp.isLoading}
      dismissButtonText={loc.buttons.close}
      onDismiss={props.onDismiss}
      errors={docsHttp.errorMessages}
    >
      <BasicPanelActions actions={actions} setActiveCommand={setActiveCommand} />
      <BasicPanelContent>
        <BasicPanelDetails noData={!users.length}>
          <Table
            items={users}
            columns={columns}
            selection={selection}
            selectionMode={SelectionMode.single}
          />
        </BasicPanelDetails>
      </BasicPanelContent>
      <CommandWrapper isActive={activeCommand === CommandbarCommands.Create}>
        <AddBadgeModal
          onSuccess={loadUsers}
          onDismiss={handleModalDismiss}
        />
      </CommandWrapper>
    </BasicPanel>
  );
};
