import { TextField, FontIcon, IStyle } from "@fluentui/react";
import { isUndefined } from "lodash-es";

interface IBasicInputProps {
  value: string;
  onChange: (val: string) => void;
  onBlur?: () => void;
  type?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  reset?: boolean;
  maxLength?: number;
  multiline?: boolean;
  styles?: IStyle;
}

export const BasicInput = (props: IBasicInputProps) => {
  const onResetValueClick = () => props.onChange("");
  const onRenderSuffix = () => <FontIcon iconName="ChromeClose" onClick={onResetValueClick} />;
  return (
    <TextField
      multiline={props.multiline || false}
      type={props.type}
      className={props.className}
      name={props.name}
      value={props.value}
      onChange={(_, value?: string) => {
        if (!isUndefined(value)) props.onChange(value);
      }}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      disabled={props.disabled}
      errorMessage={props.errorMessage}
      maxLength={props.maxLength || 100}
      styles={{ root: props.styles }}
      onRenderSuffix={props.reset ? onRenderSuffix : undefined}
      style={{ resize: props.multiline ? "vertical" : "none", }}
    />
  );
};
