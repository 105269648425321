import loc from "localization";
import { filter, first, isNull } from "lodash-es";
import { IDateFilter } from "components/common/DatePeriodInput";
import { emptyDatePicker } from "constants/strings";
import { transformStringToDate } from "./dateTransformes";
import { isAfter } from "date-fns";

export const validateDefectNumber = (defectNumber: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errors: any = {};
  if (!defectNumber.length) {
    errors.defectNumber = loc.warnings.requiredField;
  }
  if (defectNumber.split(".").length !== 5) {
    errors.defectNumber = `${loc.warnings.wrongFormat}: X.X.X.X.X`;
  }
  return errors;
};

export const validateNormHour = (normHours: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errors: any = {};
  const integerValue = first(normHours.split("."));
  if (integerValue && integerValue?.length > 2) {
    errors.normHours = `${loc.warnings.mustLessThan} 100`;
  }
  return errors;
};

export const validateDate = (dateString: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errors: any = {};
  if (dateString) {
    const date = transformStringToDate(dateString);
    if (isNull(date)) {
      errors.date = loc.warnings.invalidDate;
    }
    if (dateString === emptyDatePicker) {
      errors.date = loc.warnings.requiredField;
    }
  }
  return errors;
};

export const validateDatePicker = (firstDate?: string, secondDate?: string, checkRange?: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errors: any = {};
  if (firstDate) {
    const date1 = transformStringToDate(firstDate);
    if (isNull(date1)) {
      errors.date1 = loc.warnings.invalidDate;
    }
    if (firstDate === emptyDatePicker) {
      errors.date1 = loc.warnings.requiredField;
    }
  }
  if (secondDate) {
    const date2 = transformStringToDate(secondDate);
    if (isNull(date2)) {
      errors.date2 = loc.warnings.invalidDate;
    }
    if (secondDate === emptyDatePicker) {
      errors.date2 = loc.warnings.requiredField;
    }
  }
  if (checkRange && firstDate && secondDate) {
    const date1 = transformStringToDate(firstDate);
    const date2 = transformStringToDate(secondDate);
    if (date1 && date2 && isAfter(date1, date2)) {
      errors.date1 = loc.warnings.dateError;
    };
  };
  return errors;
};

export const validPeriod = (period: IDateFilter) => {
  return Boolean(transformStringToDate(period.dateFrom) && transformStringToDate(period.dateTo));
};

export const serialIsNotValid = (serial?: string): boolean => {
  return filter(serial?.split(""), item => item === "0").join("").length === serial?.length || !serial?.length;
};
